import { gql } from '@apollo/client';

export const getLeads = gql`
  query GetLeads(
    $limit: Int
    $offset: Int
    $sort: [lead_order_by!]
    $status: [String!]
  ) {
    leads: lead(
      limit: $limit
      offset: $offset
      order_by: $sort
      where: { status: { _in: $status } }
    ) {
      id
      due
      content
      createdAt: created_at
      metadata
      source
      updatedAt: updated_at
      user
      status
      # messages: lead_contacts {
      #   id
      # }
      # conversions: lead_conversions {
      #   id
      # }
    }

    agg: lead_aggregate(where: { status: { _in: $status } }) {
      aggregate {
        count
      }
    }
  }
`;

export const getLeadById = gql`
  query getLeadById($id: uuid!) {
    lead: lead_by_pk(id: $id) {
      id
      due
      content
      createdAt: created_at
      metadata
      source
      data
      updatedAt: updated_at
      user
      status
      notes(order_by: { created_at: asc }) {
        id
        data: noteByNote {
          id
          content
          createdAt: created_at
          updatedAt: updated_at
          user
        }
      }
      contacts(order_by: { created_at: desc }) {
        id
        message
        subject
        type
        source
        call_id
        createdAt: created_at
        updatedAt: updated_at
        user {
          id
          name: given_name
          surname: family_name
        }
      }
      contacts_aggregate(where: { type: { _neq: "call" } }) {
        aggregate {
          count
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      conversions {
        id
        value
        createdAt: created_at
        user: user {
          id
          name: given_name
          surname: family_name
        }
        eventByEvent {
          detail {
            title
          }
        }
      }
    }
  }
`;

export const updateLeadById = gql`
  mutation updateLeadById($id: uuid!, $data: lead_set_input!) {
    update_lead_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const updateLeadStatus = gql`
  mutation UpdateLeadStatus($id: uuid!, $status: String!) {
    update_lead_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
      content
      createdAt: created_at
      metadata
      source
      updatedAt: updated_at
      user
      status
      notes {
        id
        data: noteByNote {
          id
          content
          createdAt: created_at
          updatedAt: updated_at
          user
        }
      }
      contacts(order_by: { created_at: desc }) {
        id
        message
        subject
        type
        source
        call_id
        createdAt: created_at
        updatedAt: updated_at
        user {
          id
          name: given_name
          surname: family_name
        }
      }
      contacts_aggregate(where: { type: { _neq: "call" } }) {
        aggregate {
          count
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      conversions {
        id
        value
        createdAt: created_at
        user: user {
          id
          name: given_name
          surname: family_name
        }
        eventByEvent {
          detail {
            title
          }
        }
      }
    }
  }
`;

export const updateLeadsUser = gql`
  mutation UpdateLead($id: uuid!, $user: uuid!) {
    update_lead_by_pk(pk_columns: { id: $id }, _set: { user: $user }) {
      id
      content
      createdAt: created_at
      metadata
      source
      updatedAt: updated_at
      user
      status
      notes {
        id
        data: noteByNote {
          id
          content
          createdAt: created_at
          updatedAt: updated_at
          user
        }
      }
      contacts(order_by: { created_at: desc }) {
        id
        message
        subject
        type
        source
        call_id
        createdAt: created_at
        updatedAt: updated_at
        user {
          id
          name: given_name
          surname: family_name
        }
      }
      contacts_aggregate {
        aggregate {
          count
        }
      }
      notes_aggregate {
        aggregate {
          count
        }
      }
      conversions {
        id
        value
        createdAt: created_at
        user: user {
          id
          name: given_name
          surname: family_name
        }
        eventByEvent {
          detail {
            title
          }
        }
      }
    }
  }
`;

export const addLeadNote = gql`
  mutation LeadNote($data: lead_note_insert_input!) {
    insert_lead_note_one(object: $data) {
      id
      note: noteByNote {
        id
        content
        createdAt: created_at
        updatedAt: updated_at
        user
      }
    }
  }
`;

export const addLeadContact = gql`
  mutation LeadContact($data: lead_contact_insert_input!) {
    insert_lead_contact_one(object: $data) {
      message
      subject
      lead
      type
      source
    }
  }
`;

export const addLeadConversion = gql`
  mutation LeadConversion($data: lead_conversion_insert_input!) {
    insert_lead_conversion_one(object: $data) {
      value
      event
      lead
    }
  }
`;

export const getIncomingStatus = gql`
  query IncomingStatus {
    status: lead_status {
      value
    }
  }
`;

export const getAllLeads = gql`
  query GetLeads($start: timestamptz!, $end: timestamptz!) {
    leads: lead(
      where: {
        _or: [
          {
            _and: [
              { created_at: { _gte: $start } }
              { created_at: { _lte: $end } }
            ]
          }
          {
            _and: [
              { updated_at: { _gte: $start } }
              { updated_at: { _lte: $end } }
            ]
          }
          { _and: [{ due: { _gte: $start } }, { due: { _lte: $end } }] }
          {
            _and: [
              { callback_date: { _gte: $start } }
              { callback_date: { _lte: $end } }
            ]
          }
        ]
      }
      order_by: { updated_at: desc_nulls_last }
    ) {
      id
      due
      content
      callback_date
      createdAt: created_at
      metadata
      source
      data
      updatedAt: updated_at
      user
      status
      notes_aggregate {
        aggregate {
          count
        }
      }
      contacts_aggregate(where: { type: { _neq: "call" } }) {
        aggregate {
          count
        }
      }
      conversions_aggregate {
        aggregate {
          count
        }
      }
      linkedCourses {
        id
        title
      }
      # messages: lead_contacts {
      #   id
      # }
      # conversions: lead_conversions {
      #   id
      # }
    }
  }
`;

export const getAllLeadsById = gql`
  query GetLeadsById($id: [uuid!]!) {
    leads: lead(where: { id: { _in: $id } }) {
      id
      createdAt: created_at
      content
      metadata
      source
      updatedAt: updated_at
      user
      status
      client {
        id
        name: given_name
        surname: family_name
        email
        phone: contact_number
      }
    }
  }
`;

export const sendAssets = gql`
  mutation SendAssets($data: SendAssetInput!) {
    sendAssets(assets: $data) {
      success
    }
  }
`;

export const addDefinedMessage = gql`
  mutation addDefinedMessage($data: defined_messages_insert_input!) {
    insert_defined_messages_one(object: $data) {
      id
      title
      content
      type
    }
  }
`;

export const getDefinedMessages = gql`
  query getDefinedMessages(
    $where: defined_messages_bool_exp
    $orderBy: [defined_messages_order_by!]
    $limit: Int
    $offset: Int
  ) {
    defined_messages(
      where: $where
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      subject
      created_at
      assets
      updated_at
      title
      content
      type
    }
  }
`;

export const updateMessage = gql`
  mutation updateMessage($id: uuid!, $data: defined_messages_set_input!) {
    update_defined_messages_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const deleteMessage = gql`
  mutation deleteMessage($id: uuid!) {
    delete_defined_messages(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
