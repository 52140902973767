import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';

import useOnClickOutside from 'utils/hooks/click-outside';

export default function BulkSelect({
  placeholder,
  options,
  selectedOptions,
  setSelectedOptions,
}) {
  const ref = useRef(null);

  const [state, setState] = useState({
    open: false,
    selectedOptions,
    query: '',
  });

  useOnClickOutside(ref, () => {
    if (state.open) {
      setState((s) => ({
        ...s,
        open: false,
      }));
    }
  });

  const searchResult =
    state.query === ''
      ? options
      : options.filter((option) => {
          return option.title.toLowerCase().includes(state.query.toLowerCase());
        });

  function setSelectedOptionsOptions(e) {
    // If found, remove
    if (selectedOptions.find((option) => option.id === e.id)) {
      setSelectedOptions(
        selectedOptions.filter((category) => {
          return category.id !== e.id;
        }),
      );
      // Otherwise add
    } else {
      setSelectedOptions([...selectedOptions, e]);
    }
  }

  return (
    <div ref={ref} className="w-full">
      <Combobox
        value={selectedOptions.length > 1 ? null : selectedOptions[0]?.title}
        onChange={(e) => setSelectedOptionsOptions(e)}
      >
        <div className="relative">
          <Combobox.Input
            autoComplete="off"
            placeholder={
              selectedOptions.length > 1 ? 'Multiple Selected' : placeholder
            }
            onChange={(event) =>
              setState((s) => ({
                ...s,
                query: event.target.value,
              }))
            }
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 truncate focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light"
            onFocus={() =>
              setState((s) => ({
                ...s,
                open: true,
              }))
            }
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Combobox.Options
          className="absolute w-32 z-20 md:w-96 h-56 py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
          static={state.open}
        >
          {searchResult &&
            searchResult.map((option) => {
              if (
                selectedOptions.find((selected) => selected.id === option.id)
              ) {
                return (
                  <Combobox.Option
                    key={`${option.id} + ${option.title}`}
                    value={option}
                    className={({ active }) =>
                      `cursor-pointer select-none relative py-2 pl-7 pr-4 ${
                        active ? 'text-white bg-purple-600' : 'text-gray-900'
                      } truncate`
                    }
                  >
                    <span className="block capitalize truncate font-medium">
                      {option.title}
                    </span>
                    <span className="absolute inset-y-0 -left-1 flex items-center pl-3">
                      <CheckIcon className="w-4 h-4" aria-hidden="true" />
                    </span>
                  </Combobox.Option>
                );
              } else {
                return (
                  <Combobox.Option
                    key={`${option.id} + ${option.title}`}
                    value={option}
                    className={({ active }) =>
                      `cursor-pointer select-none relative py-2 pl-7 pr-4 ${
                        active ? 'text-white bg-purple-600' : ''
                      } truncate`
                    }
                  >
                    {option.title}
                  </Combobox.Option>
                );
              }
            })}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}
