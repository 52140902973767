import { Listbox, Transition } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { endOfMonth } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import Head from 'next/head';
import { Fragment, useEffect, useRef, useState } from 'react';
import Datepicker from 'tailwind-datepicker-react';

const todayEnd = endOfDay(new Date());

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const options = [
  {
    label: 'Today',
    value: {
      start: startOfDay(new Date()),
      end: todayEnd,
    },
  },
  {
    label: 'Month to date',
    value: {
      start: startOfMonth(new Date()),
      end: endOfMonth(todayEnd),
    },
  },
  {
    label: 'Last 7 days',
    value: {
      start: startOfDay(subDays(new Date(), 7)),
      end: todayEnd,
    },
  },
  {
    label: 'Last 4 weeks',
    value: {
      start: startOfWeek(startOfDay(subDays(new Date(), 7 * 4))),
      end: todayEnd,
    },
  },
  {
    label: 'Last Month',
    value: {
      start: startOfMonth(subMonths(new Date(), 1)),
      end: endOfMonth(subMonths(new Date(), 1)),
    },
  },
  {
    label: 'Last 3 months',
    value: {
      start: startOfMonth(startOfDay(subMonths(new Date(), 3))),
      end: todayEnd,
    },
  },
  {
    label: 'Last 12 months',
    value: {
      start: startOfMonth(startOfDay(subMonths(new Date(), 12))),
      end: todayEnd,
    },
  },
  {
    label: 'Year to date',
    value: {
      start: startOfYear(startOfDay(new Date())),
      end: todayEnd,
    },
  },
];

export default function Period({
  state,
  setState,
  stateName = 'controlOption',
  className = 'mb-6',
}) {
  const el = useRef(null);
  const start = useRef(null);
  const end = useRef(null);
  const [dates, setDates] = useState({
    start: state[stateName].value.start
      ? format(state[stateName].value.start, 'dd/MM/yyyy')
      : null,
    end: state[stateName].value.end
      ? format(endOfDay(state[stateName].value.end), 'dd/MM/yyyy')
      : null,
  });
  const [show, setShow] = useState({
    start: false,
    end: false,
  });

  useEffect(() => {
    // const dateRangePickerEl = document.getElementById(id);
    // // @ts-ignore
    // // import('flowbite-datepicker').then((e) => {
    // // console.log('e', e);
    // // @ts-ignore
    // console.log(Picker);
    // el.current = new Picker.DateRangePicker(dateRangePickerEl, {
    //   //   // options
    //   format: 'dd/mm/yyyy',
    // });
    // // });

    start.current &&
      start.current.addEventListener('changeDate', (x) => {
        if (x && x.detail) {
          setDates({
            ...dates,
            start: x.detail.date,
          });
          setState((s) => ({
            ...s,
            [stateName]: {
              ...s[stateName],
              value: {
                ...s[stateName].value,
                start: x.detail.date,
              },
            },
          }));
        }
      });

    end.current &&
      end.current.addEventListener('changeDate', (x) => {
        if (x && x.detail) {
          setDates({
            ...dates,
            end: x.detail.date,
          });
          setState((s) => ({
            ...s,
            [stateName]: {
              ...s[stateName],
              value: {
                ...s[stateName].value,
                end: x.detail.date,
              },
            },
          }));
        }
      });
  }, []);

  useEffect(() => {
    if (
      // @ts-ignore
      !isEqual(dates.start, state[stateName].value.start) ||
      // @ts-ignore
      !isEqual(dates.end, state[stateName].value.end)
    ) {
      setDates({
        start: state[stateName].value.start,
        end: state[stateName].value.end,
      });
      el.current &&
        el.current.setDates(
          state[stateName].value.start,
          state[stateName].value.end,
        );
    }
  }, [state[stateName].value]);

  const startTheme = {
    background: 'bg-white dark:bg-white',
    todayBtn: '',
    clearBtn: '',
    icons:
      'dark:text-gray-900 dark:bg-white dark:hover:bg-gray-100 dark:hover:text-gray-900',
    text: 'picker-override',
    disabledText: 'disabled-override',
    input:
      'bg-white text-black focus:ring-purple-500 focus:border-gray-300 dark:bg-white dark:border-gray-300  dark:text-gray-900 dark:focus:ring-purple-500 rounded-none dark:focus:ring-purple-500 dark:focus:border-purple-500 border',
    inputIcon: '',
    selected:
      'bg-gray-50 dark:bg-gray-50 ring-2 ring-purple-500 text-[#111827] dark:text-[#111827] hover:bg-[#c7c7c7] m-0.5 dark:hover:bg-[#c7c7c7]',
  };

  const endTheme = {
    background: 'bg-white dark:bg-white',
    todayBtn: '',
    clearBtn: '',
    icons:
      'dark:text-gray-900 dark:bg-white dark:hover:bg-gray-100 dark:hover:text-gray-900',
    text: 'picker-override',
    disabledText: 'disabled-override',
    input:
      'bg-white text-black focus:ring-purple-500 focus:border-gray-300 dark:bg-white dark:border-gray-300  dark:text-gray-900 dark:focus:ring-purple-500 rounded-none rounded-r-md dark:focus:ring-purple-500 dark:focus:border-purple-500 border',
    inputIcon: '',
    selected:
      'bg-gray-50 dark:bg-gray-50 ring-2 ring-purple-500 text-[#111827] dark:text-[#111827] hover:bg-[#c7c7c7] m-0.5 dark:hover:bg-[#c7c7c7] dark:text-gray-900!important',
  };

  const dateOptions = {
    title: 'Select a date',
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    icons: {
      prev: () => (
        <div className="w-5 h-5">
          <ChevronLeftIcon />
        </div>
      ),
      next: () => (
        <div className="w-5 h-5">
          <ChevronRightIcon />
        </div>
      ),
    },
    language: 'en',
  };

  const showHandler = (option) => {
    setShow((s) => ({ ...s, [option]: !s[option] }));
  };

  return (
    <div
      className={`relative z-10 inline-flex shadow-sm rounded-md ${className}`}
    >
      <div className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 w-44 z-0">
        <Listbox
          value={state[stateName]}
          onChange={(e) => setState((s) => ({ ...s, [stateName]: e }))}
        >
          {({ open }) => (
            <>
              <Listbox.Button className="text-xs xl:text-sm bg-white relative w-full px-4 py-2 pr-10 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 h-full rounded-l-md focus:z-10">
                <span className="block truncate">{state[stateName].label}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 top-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.label}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-purple-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-purple-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>

      <div className="pickers">
        <Datepicker
          options={{
            ...dateOptions,
            theme: startTheme,
            defaultDate: state[stateName].value.start,
            maxDate: state[stateName].value.end,
          }}
          onChange={(e) => {
            setDates({
              ...dates,
              start: format(e, 'dd/MM/yyyy'),
            });
            setState((s) => ({
              ...s,
              [stateName]: {
                ...s[stateName],
                value: {
                  ...s[stateName].value,
                  start: e,
                },
              },
            }));
          }}
          show={show.start}
          setShow={() => {
            showHandler('start');
          }}
        />
      </div>
      <div className="pickers">
        <Datepicker
          classNames="dark:text-gray-900"
          options={{
            ...dateOptions,
            theme: endTheme,
            defaultDate: state[stateName].value.end,
            minDate: state[stateName].value.start,
          }}
          onChange={(e) => {
            const date = endOfDay(e);
            setDates({
              ...dates,
              end: format(date, 'dd/MM/yyyy'),
            });
            setState((s) => ({
              ...s,
              [stateName]: {
                ...s[stateName],
                value: {
                  ...s[stateName].value,
                  end: endOfDay(e),
                },
              },
            }));
          }}
          show={show.end}
          setShow={() => {
            showHandler('end');
          }}
        />
      </div>
      {/* <div
        date-rangepicker
        id={id}
        className="flex items-center border border-gray-300 bg-white rounded-r-md border-l-0 overflow-hidden max-w-[275px]"
      >
        <div className="relative border-r">
          <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            name="start"
            type="text"
            className="text-gray-700 text-xs xl:text-sm focus:ring-purple-500 focus:border-purple-500 block w-full pl-8 pr-0 border-none"
            placeholder="Select date start"
            datepicker-format="dd/mm/yyyy"
            defaultValue={dates.start}
            ref={start}
          />
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            name="end"
            type="text"
            className="text-gray-700 text-xs xl:text-sm focus:ring-purple-500 focus:border-purple-500 block w-full pl-10 pr-0 border-none text-sm"
            placeholder="Select date end"
            datepicker-format="dd/mm/yyyy"
            defaultValue={dates.end}
            ref={end}
          />
        </div>
      </div> */}
    </div>
  );
}
