import { useMutation, useQuery } from '@apollo/client';
import { Disclosure } from '@headlessui/react';
import {
  FolderPlusIcon,
  InformationCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { endOfDay, startOfDay } from 'date-fns';
import format from 'date-fns/format';
import dynamic from 'next/dynamic';
import Calls from 'pages/incoming/calls';
import { useState } from 'react';

import AddComplaint from 'components/molecules/panels/complaints/add-complaint';
import ManageComplaint from 'components/molecules/panels/complaints/manage-complaint';
import Period, { options } from 'components/organisms/period';

import escapeHandle from 'utils/blur-on-escape';

import {
  deleteComplaintById,
  deleteComplaintNotes,
  getAllComplaints,
} from 'queries/complaints';

import { useUI } from 'providers/ui';

const Modal = dynamic(() => import('components/molecules/modal'));

const Avatar = dynamic(() => import('components/atoms/avatar'));
const Status = dynamic(() => import('components/atoms/status'));
// const SideNav = dynamic(() => import('../sidenav'));
const Searchbar = dynamic(() => import('components/atoms/inputs/search-bar'));
const Multiselect = dynamic(
  () => import('components/atoms/inputs/multi-select'),
);

// const Breadcrumbs = dynamic(() => import('components/nav/breadcrumbs'));
const Layout = dynamic(() => import('components/layout'));

export enum ModalType {
  None = 'None',
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}

export enum StatusType {
  Open = 'Open',
  Closed = 'Closed',
  AwaitingResponse = 'Awaiting Response',
  RequiresInput = 'Requires Input',
  ResponseSent = 'Response Sent',
}

export default function Complaints() {
  const ui = useUI();

  const [resultSet, setResultSet] = useState(null);
  const [state, setState] = useState({
    expandItem: null,
    expandItemIndex: 0,
    hoveredItem: null,
    isEditingItem: false,
    editingItemID: null,
    selectedCategories: [],
    selectedStatus: [],
    loading: false,
    showModal: ModalType.None,
    data: null,
    lead: null,
    type: null,
  });

  const [dateState, setDateState] = useState({
    selectedStatus: [],
    selectedType: [],
    controlOption: options[5],
  });

  const { data: complaintsData } = useQuery(getAllComplaints, {
    variables: {
      start: startOfDay(dateState.controlOption.value.start),
      end: endOfDay(dateState.controlOption.value.end),
    },
  });

  const [deleteComplaintMutation] = useMutation(deleteComplaintById);
  const [deleteAllComplaintNotes] = useMutation(deleteComplaintNotes);

  const deleteNotes = async (id) => {
    await deleteAllComplaintNotes({
      variables: {
        id: id,
      },
    });
  };
  const deleteComplaint = async (id: string) => {
    if (confirm('Are you sure you want to delete this Complaint?')) {
      try {
        const result = await deleteComplaintMutation({
          variables: {
            id: id,
          },
          refetchQueries: [
            {
              query: getAllComplaints,
              variables: {
                end: endOfDay(dateState.controlOption.value.end),
                start: startOfDay(dateState.controlOption.value.start),
              },
            },
          ],
        });
        if (result) {
          deleteNotes(id);
          ui.setToast(true, {
            type: 'success',
            title: 'Successfully Deleted Complaint',
            message: '',
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const foundComplaintData = (resultSet || complaintsData?.complaints)?.filter(
    (elem) => {
      // If no filter then display all
      if (state.selectedStatus.length === 0) {
        return true;
      }

      // If only resolution
      if (state.selectedStatus.length > 0) {
        const status = state.selectedStatus.map((x) => x.name);
        return status.includes(elem.resolution);
      }
    },
  );

  const closeModal = () => {
    setState((s) => ({ ...s, showModal: ModalType.None }));
  };

  const editMode = () => {
    if (state.showModal === ModalType.Edit) {
      setState((s) => ({ ...s, showModal: ModalType.View }));
      return;
    }
    setState((s) => ({ ...s, showModal: ModalType.Edit }));
  };

  const handleClick = ({ type, lead }) => {
    setState((s) => ({ ...s, type, lead }));
  };

  return (
    <Layout>
      {/* <SideNav>
        <Breadcrumbs /> */}
      <section
        className="xl:mt-6 p-2 px-6 flex space-x-2 items-start"
        onKeyDown={(e) => escapeHandle(e)}
      >
        <h1 className="text-sm xl:text-2xl font-semibold flex-1">Complaints</h1>
        <>
          <div className="inline-flex">
            <Searchbar
              placeholder={'Search By Client'}
              originalData={foundComplaintData || []}
              setResult={(e) => setResultSet(e)}
              searchKeysArray={[
                'employee.given_name',
                'user.given_name',
                'user.family_name',
                'user.email',
              ]}
            />
          </div>
          <div className="inline-flex space-x-2">
            <Multiselect
              placeholder={'Status'}
              options={[
                { id: 1, name: StatusType.Open },
                { id: 2, name: StatusType.Closed },
                { id: 3, name: StatusType.AwaitingResponse },
                { id: 4, name: StatusType.RequiresInput },
                { id: 5, name: StatusType.ResponseSent },
              ]}
              selectedOptions={state.selectedStatus}
              setSelectedOptions={(e) =>
                setState((s) => ({
                  ...s,
                  selectedStatus: e,
                }))
              }
            />
          </div>
          <Period state={dateState} setState={setDateState} />
          <div className="w-32">
            <button
              type="button"
              onClick={() => {
                setState((s) => ({ ...s, showModal: ModalType.Add }));
              }}
              className="btn-primary cursor-pointer whitespace-nowrap"
            >
              Add Complaint
            </button>
          </div>
        </>
      </section>

      {/* MODALS */}
      <Modal
        title="Add Complaint"
        open={state.showModal === ModalType.Add}
        close={closeModal}
      >
        <AddComplaint closeModal={closeModal} dateState={dateState} />
      </Modal>

      <Modal
        title={
          state.showModal === ModalType.View
            ? 'View Complaint'
            : 'Edit Complaint'
        }
        full
        open={
          state.showModal === ModalType.View ||
          state.showModal === ModalType.Edit
        }
        close={closeModal}
      >
        <ManageComplaint
          dateState={dateState}
          complaintId={state.data}
          editMode={editMode}
          closeModal={closeModal}
          mode={state.showModal}
          handleClick={handleClick}
        />
      </Modal>

      {state.lead && (
        <Modal
          full
          open={!!state.lead}
          close={() => setState((s) => ({ ...s, type: null, lead: null }))}
        >
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <Calls callID={state.lead} />
          </div>
        </Modal>
      )}

      <div className="flex-1">
        {foundComplaintData && foundComplaintData?.length > 0 ? (
          <div className="flex-1 overflow-hidden flex flex-col">
            <ul
              role="list"
              className=" border-gray-300 divide-y divide-gray-300 flex flex-col flex-1 overflow-hidden"
            >
              <div className="overflow-hidden xl:p-4 p-2 flex-1">
                <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-full overflow-scroll bg-white">
                  <div className="min-w-full divide-y divide-gray-300 border">
                    <div className="grid grid-cols-12 bg-gray-100">
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-1 p-2">
                        Title
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-2 p-2">
                        Date
                      </div>
                      <div className="text-xs pl-3 xl:text-sm font-semibold text-gray-900 col-span-2 p-2">
                        Created By
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-1 p-2">
                        Assignee
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-2 p-2 ml-5">
                        Client
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-1 p-2 mr-2 ">
                        Status
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-2 p-2">
                        Last Updated
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-1 p-2">
                        Options
                      </div>
                    </div>
                    {foundComplaintData && foundComplaintData?.length > 0 && (
                      <div className="flex flex-col flex-1 relative overflow-auto">
                        <div className="inline-block min-w-full align-middle divide-y divide-gray-200">
                          {foundComplaintData &&
                            foundComplaintData?.map((complaint, i) => {
                              return (
                                <Disclosure
                                  key={complaint.id}
                                  as="div"
                                  className="min-w-full"
                                >
                                  {() => (
                                    <>
                                      <Disclosure.Button
                                        className="flex w-full min-w-full justify-between items-center px-2 py-0 text-left text-sm font-medium text-purple-900 hover:bg-purple-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                                        // onClick={() => {
                                        //   ui.setPanel(true, 'complaint', {
                                        //     dateState: dateState,
                                        //     mode: 'View',
                                        //     edit: false,
                                        //     ...complaint,

                                        //     employee_name:
                                        //       complaint?.employee?.full_name,

                                        //     employee: complaint.employee.id,

                                        //     client: complaint
                                        //       ? complaint?.user?.full_name
                                        //       : '',
                                        //     clientEmail: complaint
                                        //       ? complaint?.user?.email
                                        //       : '',
                                        //     clientPhoneNumber: complaint
                                        //       ? complaint?.user?.contact_number
                                        //       : '',
                                        //     clientJoined: complaint
                                        //       ? complaint?.user?.created_at
                                        //       : '',
                                        //   });
                                        // }}
                                        onClick={() => {
                                          setState((s) => ({
                                            ...s,
                                            data: complaint?.id,
                                            showModal: ModalType.View,
                                          }));
                                        }}
                                      >
                                        <div className="grid grid-cols-12 w-full items-center mr-2 font-normal">
                                          {/* title col */}
                                          <div className="col-span-1 truncate text-xs xl:text-sm text-gray-500 p-2 w-full">
                                            {complaint.title || 0}
                                          </div>

                                          {/* dates col */}
                                          <div className="col-span-2 text-xs xl:text-sm text-gray-500 p-2">
                                            {format(
                                              new Date(complaint.created),
                                              'do MMM yyyy ',
                                            )}
                                            at
                                            {format(
                                              new Date(complaint.created),
                                              ' HH:mm',
                                            )}
                                          </div>

                                          {/* created by col */}
                                          <div className=" flex col-span-2 truncate text-xs xl:text-sm text-gray-500 p-2">
                                            {complaint.employee && (
                                              <Avatar
                                                size={{
                                                  width: 6,
                                                  height: 6,
                                                }}
                                                layout="fill"
                                                src={
                                                  complaint?.employee
                                                    ?.user_assets[0]?.asset?.url
                                                }
                                              />
                                            )}
                                            <span className="leading-6 pl-1 block truncate">
                                              {complaint.user
                                                ? `${complaint.employee.given_name} ${complaint.employee.family_name}`
                                                : 'No User'}
                                            </span>
                                          </div>

                                          {/* assignee col */}
                                          <div className="col-span-1 text-xs xl:text-sm text-gray-500 p-2">
                                            {complaint.employee ? (
                                              <div className="flex">
                                                {/* <Avatar
                                                    id={
                                                      complaint.assignee_id &&
                                                      complaint.assignee_id
                                                    }
                                                  /> */}
                                                <span className="leading-6 pl-1 block truncate">
                                                  {complaint?.assignee?.user
                                                    ?.full_name ||
                                                    'No Assignee'}
                                                </span>
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>

                                          {/* client col */}

                                          <div className="col-span-2 ml-5 text-xs xl:text-sm text-gray-500 p-2">
                                            {complaint.user
                                              ? `${complaint.user.full_name}`
                                              : 'No User'}
                                          </div>

                                          {/* status col */}

                                          <div className="col-span-1  text-xs text-center xl:text-sm mr-2 text-gray-500 ">
                                            <Status
                                              value={complaint.resolution.toUpperCase()}
                                            />
                                          </div>

                                          {/* updated at col */}
                                          <div className="col-span-2 truncate text-xs xl:text-sm text-gray-500 ml-2 p-2">
                                            {format(
                                              new Date(complaint.updated),
                                              'do MMM yyyy ',
                                            )}
                                            at
                                            {format(
                                              new Date(complaint.updated),
                                              ' HH:mm',
                                            )}
                                          </div>

                                          <div className="relative col-span-1 text-xs xl:text-sm text-gray-500 p-2 ml-3 whitespace-nowrap flex items-center justify-start">
                                            <div className="has-tooltip">
                                              <p className="-top-5 tooltip rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xxs text-center ">
                                                Details
                                              </p>
                                              <InformationCircleIcon
                                                className="inline mr-3 w-5 h-5 cursor-pointer text-purple-500 hover:text-purple-700"
                                                // onClick={() => {
                                                //   ui.setPanel(
                                                //     !ui.panel,
                                                //     'complaint',
                                                //     {
                                                //       dateState: dateState,
                                                //       mode: 'View',
                                                //       edit: false,
                                                //       ...complaint,

                                                //       employee_name:
                                                //         complaint?.employee
                                                //           ?.full_name,

                                                //       employee:
                                                //         complaint?.employee?.id,

                                                //       client: complaint
                                                //         ? complaint?.user
                                                //             ?.full_name
                                                //         : '',
                                                //       clientEmail: complaint
                                                //         ? complaint?.user?.email
                                                //         : '',
                                                //       clientPhoneNumber:
                                                //         complaint
                                                //           ? complaint?.user
                                                //               ?.contact_number
                                                //           : '',
                                                //       clientJoined: complaint
                                                //         ? complaint?.user
                                                //             ?.created_at
                                                //         : '',
                                                //     }
                                                //   );
                                                // }}
                                                onClick={() =>
                                                  setState((s) => ({
                                                    ...s,
                                                    showModal: ModalType.View,
                                                  }))
                                                }
                                              />
                                            </div>
                                            <div className="has-tooltip">
                                              <p className="-top-5 tooltip rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xxs text-center ">
                                                Delete
                                              </p>
                                              <TrashIcon
                                                className="inline w-5 h-5 ml-2 cursor-pointer text-purple-600 hover:text-red-400"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  deleteComplaint(complaint.id);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Disclosure.Button>
                                    </>
                                  )}
                                </Disclosure>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ul>
          </div>
        ) : (
          <div className="text-center flex flex-col items-center justify-center my-8">
            <div className="flex flex-1 flex-col h-full flex-grow p-8 px-4 items-center justify-center">
              <FolderPlusIcon className="h-12 w-12 text-purple-400 stroke-1" />
            </div>
            <h3 className="-mt-4 text-sm font-medium text-gray-700">
              No Complaints
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {`No Complaints Meet This Criteria in The Selected Period`}
            </p>
          </div>
        )}
      </div>
      {/* </SideNav> */}
    </Layout>
  );
}
