import { useQuery } from '@apollo/client';

import { getAllPaymentsById } from 'queries/payments';

import { Payment } from './Payment';

export const PaymentsTab = ({ paymentID }: { paymentID: string[] }) => {
  const { data: paymentData } = useQuery(getAllPaymentsById, {
    variables: {
      id: paymentID,
    },
  });

  if (!paymentData?.payment) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden">
      {paymentData?.payment.map((payment, index) => {
        return <Payment payment={payment} key={index} />;
      })}
    </div>
  );
};

export default PaymentsTab;
