import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import Link from 'next/link';

import Status from 'components/atoms/status';

import { currency } from 'utils/formats';

import { getAllEventsById } from 'queries/events';

export const CoursesTab = ({ courseID }: { courseID: string[] }) => {
  const { data: courseData } = useQuery(getAllEventsById, {
    variables: {
      id: courseID,
    },
  });

  if (!courseData?.event) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden">
      {courseData?.event?.map((event, index) => {
        return (
          <Link
            href={`/events/${event?.id}`}
            className="sm:overflow-hidden"
            target="_blank"
            key={index}
          >
            <div className="p-4 space-y-1 max-w-lg mx-auto">
              <div className="">
                <div className="flex justify-between text-sm">
                  <div className="text-sm font-semibold">
                    {event?.detail?.title}
                  </div>
                  <Status value={event?.status} />
                </div>

                <div className="py-1">
                  {event?.seat_price && (
                    <div className="text-xs">{currency(event?.seat_price)}</div>
                  )}
                </div>
                <div className="uppercase">{event?.type}</div>
                <div className="py-1 text-sm">{event?.seats} seats</div>

                <div className="text-xs">{event?.payload?.type}</div>
              </div>

              {event?.start && event?.end && (
                <div className="text-xs">
                  {format(new Date(event.start), 'do LLL y h:mm aaa')} -{' '}
                  {format(new Date(event.end), 'do LLL y h:mm aaa')}
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default CoursesTab;
