import { gql } from '@apollo/client';

export const shortServices = gql`
  query Service {
    service(order_by: { title: desc }) {
      id
      title
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
    }
  }
`;

export const getServices = gql`
  query Service {
    service(order_by: { updated_at: desc }) {
      id
      title
      subtitle
      content
      attributes
      options
      archived
      createdAt: created_at
      updatedAt: updated_at
      media {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`;

export const getServiceById = gql`
  query Service($id: uuid!) {
    service: service_by_pk(id: $id) {
      id
      title
      subtitle
      content
      attributes
      options
      createdAt: created_at
      updatedAt: updated_at
      media {
        id
        asset: assetByAsset {
          id
          props
          url
          type: assetType {
            value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`;
export const completeDocuments = gql`
  mutation completeDocuments($ids: [uuid!]!, $complete: Boolean!) {
    update_form_response_many(
      updates: { where: { id: { _in: $ids } }, _set: { complete: $complete } }
    ) {
      affected_rows
    }
  }
`;

export const completeUploads = gql`
  mutation completeUploads($ids: [uuid!]!, $complete: Boolean!) {
    update_user_upload_request_many(
      updates: { where: { id: { _in: $ids } }, _set: { complete: $complete } }
    ) {
      affected_rows
    }
  }
`;

export const updateService = gql`
  mutation UpdateService($id: uuid!, $data: service_set_input!) {
    service: update_service_by_pk(_set: $data, pk_columns: { id: $id }) {
      id
      title
      attributes
      content
      archived
    }
  }
`;

export const findServices = gql`
  query FindService($term: String!) {
    services: service(where: { _or: [{ title: { _ilike: $term } }] }) {
      id
      title
      subtitle
      pricing {
        id
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            id
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
    }
  }
`;

export const addService = gql`
  mutation AddService($data: service_insert_input!) {
    insert_service_one(object: $data) {
      id
      serviceMetadata {
        id
      }
      media {
        asset
      }
    }
  }
`;

export const addPricing = gql`
  mutation AddPricing($data: pricing_insert_input!) {
    insert_pricing_one(object: $data) {
      id
      price
      discount
      was
      attributes
      label
    }
  }
`;

export const addServicePricing = gql`
  mutation AddServicePricing($data: service_pricing_insert_input!) {
    insert_service_pricing_one(object: $data) {
      service
      pricing
    }
  }
`;

export const addServicePhoto = gql`
  mutation AddServicePhoto($data: service_media_insert_input!) {
    insert_service_media_one(object: $data) {
      id
    }
  }
`;

export const deleteServiceMedia = gql`
  mutation deleteServiceMedia($assetID: uuid!, $serviceID: uuid!) {
    delete_service_media(
      where: {
        _and: { asset: { _eq: $assetID }, service: { _eq: $serviceID } }
      }
    ) {
      returning {
        id
      }
    }
  }
`;
