import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Combobox, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
  EyeIcon,
  LockOpenIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  BellIcon,
  ChatBubbleBottomCenterIcon,
  CheckIcon,
  ChevronDownIcon,
  HandThumbUpIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiCircleLine, RiFileWarningLine } from '@remixicon/react';
import { isBefore } from 'date-fns';
import format from 'date-fns/format';
import { cn } from 'lib/utils';
import dynamic from 'next/dynamic';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Avatar from 'components/atoms/avatar';
import Loader from 'components/atoms/loader';
import Status from 'components/atoms/status';
import Editor from 'components/editor';
import TaskDetail from 'components/task/task-detail';

import { timeAgo } from 'utils/calendar';
import { currency } from 'utils/formats';

import {
  addTaskComment,
  deleteTaskCommentById,
  getTaskAssigneeByTaskId,
  getTaskById,
  getTaskComments,
  updateTaskById,
  updateTaskCommentById,
} from 'queries/tasks';

import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

import CallPanel from './panels/calls';
import CoursePanel from './panels/courses';
import FormPanel from './panels/forms';
import LeadPanel from './panels/leads';
import ModelPanel from './panels/models';
import PaymentPanel from './panels/payment';
import StudentPanel from './panels/students';
import UploadPanel from './panels/upload';
import TaskModal from './task-modal';

const ViewEnrollmentModal = dynamic(
  () => import('components/organisms/enrollment/view-enrollment'),
);
const Modal = dynamic(() => import('components/molecules/modal'));

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
  message: { icon: ChatBubbleBottomCenterIcon, bgColorClass: 'bg-purple-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-600' },
};

enum ViewTypes {
  'description',
  'notes',
  'calls',
  'courses',
  'payments',
  'leads',
  'models',
  'students',
  'uploads',
}

export enum TaskStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  COMPLETE = 'COMPLETE',
}

const schema = yup.object().shape({
  data: yup.mixed().nullable().required('A comment must have data to add'),
});

type FormValues = yup.InferType<typeof schema>;

export default function Tasks({
  taskID,
  teamMembers,
  statusValues = null,
  columns = null,
  refetchTasks = null,
  onComplete,
}) {
  const ui = useUI();
  const org = useOrganization();
  const commentEditorRef = useRef(null);
  const descriptionRef = useRef(null);

  const { user, claims } = useSession();
  const [saving, setSaving] = useState(false);
  const {
    data: taskData,
    refetch,
    loading: loadingData,
  } = useQuery(getTaskById, {
    variables: {
      id: taskID?.id || taskID,
    },
    fetchPolicy: 'network-only',
  });

  // To make assignee switching faster, this is a seperate query.
  const { data: taskAssignee, refetch: refetchAssignee } = useQuery(
    getTaskAssigneeByTaskId,
    {
      variables: {
        id: taskID?.id || taskID,
      },
      fetchPolicy: 'network-only',
    },
  );

  const isOwner =
    taskData?.task?.user === claims['x-hasura-user-id'] ||
    claims['x-hasura-user-id'] === '482f9005-1ee2-454b-af47-b80e2775b4d5';

  const [
    getComments,
    { data: taskCommentsResponse, loading: loadingComments },
  ] = useLazyQuery(getTaskComments, {
    variables: {
      id: taskID,
    },
    fetchPolicy: 'cache-and-network',
  });

  const taskComments = useMemo(() => {
    return taskCommentsResponse &&
      taskCommentsResponse.task_comment &&
      taskCommentsResponse.task_comment.length > 0
      ? taskCommentsResponse.task_comment
      : [];
  }, [taskCommentsResponse]);

  const [updateTask] = useMutation(updateTaskById);
  const [createTaskComment] = useMutation(addTaskComment);
  const [updateTaskComment] = useMutation(updateTaskCommentById);
  const [deleteTaskComment] = useMutation(deleteTaskCommentById);
  const member = user && org.teamMember(claims['x-hasura-user-id']);
  const [selected, setSelected] = useState(taskAssignee?.task?.assignee);

  useEffect(() => {
    if (taskAssignee?.task?.assignee) {
      setSelected(taskAssignee?.task?.assignee);
    }
  }, [taskAssignee?.task?.assignee]);

  const [state, setState] = useState({
    change: false,
    people: teamMembers,
    commentMode: 'add',
    displayTaskDetail: null,
    enrollmentId: null,
    commentId: null,
    viewEnrollmentModal: false,
  });
  const [query, setQuery] = useState('');

  const { handleSubmit, setValue } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    //@ts-ignore
    defaultValues: {
      data: null,
    },
  });

  const changeAssignee = async (id: string) => {
    try {
      await updateTask({
        variables: {
          id: taskData?.task?.id,
          data: { user_id: id },
        },
      });
      await refetchAssignee();
      setState({ ...state, change: false });
      setQuery('');

      const member = teamMembers.find((x) => x?.id === id);
      ui.setToast(true, {
        type: 'success',
        title: 'Success!',
        message:
          'Task assigned to ' + member.given_name + ' ' + member.family_name,
      });

      refetchTasks && (await refetchTasks());
    } catch (err) {
      console.log(err);
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to assign task',
        message: 'Please try again',
      });
    }
  };

  const filteredPeople = React.useMemo(() => {
    return query === ''
      ? teamMembers
      : teamMembers.filter(
          (person) =>
            person?.given_name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')) ||
            person?.family_name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
        );
  }, [query, teamMembers]);

  useEffect(() => {
    getComments({
      variables: {
        id: taskID,
      },
    });
    setState({ ...state, change: false });
  }, [taskID]);

  const action = useCallback(
    (userId: string) => {
      ui.setClient(true, userId);
    },
    [ui.setClient],
  );

  const priorityText =
    (taskData?.task.priority === 1 && 'High Priority') ||
    (taskData?.task.priority === 2 && 'Medium Priority') ||
    (taskData?.task.priority === 3 && 'Low Priority') ||
    (taskData?.task.priority === 0 && 'Completed') ||
    (taskData?.task.priority === -1 && 'Overdue');

  const messages = taskData?.task?.contacts;

  if (loadingData && !taskData) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <p>Loading</p>
      </div>
    );
  }

  if (!taskData) {
    return null;
  }

  const timeline = [
    {
      id: '1',
      type: eventTypes.applied,
      content: 'Task Created on ',
      createdAt: new Date(taskData?.task?.createdAt),
      date: format(new Date(taskData?.task?.createdAt), 'LLL dd'),
      datetime: format(new Date(taskData?.task?.createdAt), 'yyyy-MM-dd'),
    },
  ];

  if (taskData?.task?.conversions && taskData?.task?.conversions.length > 0) {
    taskData?.task?.conversions.forEach((x) => {
      timeline.push({
        id: x.id,
        type: eventTypes.completed,
        content: `${x.user.name} booked ${taskData?.task?.content.name} in ${
          x.eventByEvent.detail.title
        } with a value of ${currency(x.value)}`,
        createdAt: new Date(x.createdAt),
        date: format(new Date(x.createdAt), 'LLL dd'),
        datetime: format(new Date(x.createdAt), 'yyyy-MM-dd'),
      });
    });
  }

  if (messages && messages.length > 0) {
    messages.forEach((m) =>
      timeline.push({
        id: m.id,
        type: eventTypes.message,
        content: `${m.user.name} sent the client ${
          m.type === 'email' ? `an email` : `a text`
        }`,
        createdAt: new Date(m.createdAt),
        date: format(new Date(m.createdAt), 'LLL dd'),
        datetime: format(new Date(m.createdAt), 'yyyy-MM-dd'),
      }),
    );
  }

  timeline.sort((a, b) => {
    // @ts-ignore
    return b.createdAt - a.createdAt;
  });

  const changeStatus = async (status) => {
    try {
      await updateTask({
        variables: {
          id: taskData?.task?.id,
          data: { status: status },
        },
      });

      await refetch();

      refetchTasks && refetchTasks();

      ui.setToast(true, {
        type: 'success',
        title: 'Successfully updated task',
        message:
          'Moved from ' +
          columns[taskData?.task?.status?.value].label +
          ' to ' +
          columns[status].label,
      });
    } catch (error) {
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to change status',
        message: `${error}`,
      });
    }
  };

  function dueSection() {
    if (!taskData?.task?.due) {
      return null;
    }

    if (isBefore(new Date(taskData?.task?.due), new Date())) {
      return (
        <div className="flex items-center space-x-2">
          <BellIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          <span className="text-sm font-medium text-red-500">
            Overdue{' '}
            <time dateTime={taskData.task.due}>
              {format(new Date(taskData.task.due), 'do MMMM HH:mm')}
            </time>
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-2">
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          <span className="text-sm font-medium text-gray-900">
            Due date{' '}
            <time dateTime={taskData.task.due}>
              {format(new Date(taskData.task.due), 'do MMMM HH:mm')}
            </time>
          </span>
        </div>
      );
    }
  }

  const submitComment = async (data) => {
    try {
      setSaving(true);

      if (state.commentMode === 'edit') {
        const payload = {
          variables: {
            id: state.commentId,
            data: {
              task_id: taskID,
              data: data.data,
            },
          },
          refetchQueries: [
            {
              query: getTaskComments,
              variables: {
                id: taskID,
              },
            },
          ],
        };
        commentEditorRef?.current?.reset();
        setState((s) => ({ ...s, commentMode: 'add', commentId: null }));
        await updateTaskComment(payload);
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'Comment updated successfully',
          message: 'Successfully updated comment',
        });
      } else {
        const payload = {
          variables: {
            data: {
              task_id: taskID,
              data: data.data,
            },
          },
          refetchQueries: [
            {
              query: getTaskComments,
              variables: {
                id: taskID,
              },
            },
          ],
        };
        commentEditorRef?.current?.reset();
        await createTaskComment(payload);
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New comment added',
          message: 'Successfully added a new comment',
        });
      }
    } catch (err) {
      setSaving(false);
      ui.setToast(true, {
        type: 'error',
        title:
          state.commentMode === 'edit'
            ? 'Error editing comment'
            : 'Error adding comment',
        message: 'Please try again',
      });
    }
    await getComments({
      variables: {
        id: taskID,
      },
    });
  };

  function editComment(comment) {
    commentEditorRef?.current?.setData(comment.data);
    setState((s) => ({ ...s, commentId: comment.id, commentMode: 'edit' }));
  }

  async function deleteComment(id: string) {
    setSaving(true);
    if (
      confirm(
        'Are you sure you want to delete this comment, you will not be able to retrieve it?',
      ) == true
    ) {
      try {
        await updateTaskComment({
          variables: {
            id,
            data: { archived: true },
          },
          refetchQueries: [
            {
              query: getTaskComments,
              variables: {
                id: taskID,
              },
            },
          ],
        });
        commentEditorRef?.current?.reset();
        setState((s) => ({ ...s, commentMode: 'add', commentId: null }));

        setSaving(false);

        ui.setToast(true, {
          type: 'success',
          title: 'Comment deleted',
          message: 'Successfully deleted comment',
        });
      } catch (error) {
        ui.setToast(true, {
          type: 'error',
          title: 'Failed to delete comment',
          message: `${error}`,
        });
      }
    }
    setSaving(false);
  }

  async function setData() {
    const res = await refetch({
      variables: {
        id: taskID?.id || taskID,
      },
    });

    if (res?.data?.task) {
      descriptionRef?.current?.setData(res?.data?.task?.data);
    }
  }

  const archiveTask = async () => {
    const archived = !taskData?.task?.archived;
    try {
      await updateTask({
        variables: {
          id: taskData?.task?.id,
          data: { archived },
        },
      });

      await refetch();

      refetchTasks && refetchTasks();

      ui.setToast(true, {
        type: 'success',
        title: `${archived ? 'Archived' : 'Unarchived'} task`,
        message: '',
      });
    } catch (error) {
      ui.setToast(true, {
        type: 'error',
        title: `Failed to ${archived ? 'archive' : 'unarchive'} task`,
        message: `${error}`,
      });
    }
  };

  console.log('taskData', taskData);

  return (
    <>
      <main className="flex-1 overflow-y-auto">
        <div className="pb-8 xl:pb-10">
          <div className="mx-auto px-4 sm:px-6 lg:px-8 grid max-w-full grid-cols-3">
            <div className="col-span-2 border-r border-gray-200 pr-8">
              <div>
                <div>
                  <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                    <div>
                      <h1 className="text-2xl font-bold text-gray-900">
                        {taskData?.task?.title}
                      </h1>
                      {taskData?.task.creator ? (
                        <p className="mt-2 text-sm text-gray-500 flex items-center">
                          #{taskData?.task.id.slice(0, 6)} opened by{' '}
                          <span className="font-medium text-gray-900 flex items-center space-x-1 ml-1">
                            <Avatar
                              alt={taskData?.task.creator?.given_name}
                              src={
                                taskData?.task.creator?.user_assets[0]
                                  ?.assetByAsset.url
                              }
                              width={24}
                              height={24}
                              size={{
                                width: 6,
                                height: 6,
                              }}
                            />
                            <span>{taskData.task.creator?.given_name}</span>
                          </span>{' '}
                        </p>
                      ) : (
                        <p className="mt-2 text-sm text-gray-500 flex items-center">
                          #{taskData?.task.id.slice(0, 6)} automatically created
                        </p>
                      )}
                    </div>
                    {isOwner ? (
                      <div className="mt-4 flex space-x-3 md:mt-0">
                        <button
                          type="button"
                          onClick={() => {
                            setState((s) => ({
                              ...s,
                              displayTaskDetail: taskData?.task.id,
                            }));
                          }}
                          className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          <PencilIcon
                            className="-ml-0.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Edit
                        </button>
                      </div>
                    ) : null}
                  </div>

                  <div className="py-3 pb-0 pt-6">
                    <h2 className="sr-only">Description</h2>

                    {taskData?.task.data && (
                      <Editor
                        ref={descriptionRef}
                        readOnly
                        taskId={taskData?.task.id}
                        onUpdate={(json) =>
                          updateTask({
                            variables: {
                              id: taskData?.task?.id,
                              data: { data: json },
                            },
                          })
                        }
                        refetch={async () => {
                          await refetch({
                            variables: {
                              id: taskID?.id || taskID,
                            },
                          });
                        }}
                        content={taskData?.task.data}
                      />
                    )}
                    {!taskData?.task.data && taskData?.task?.content ? (
                      <div className="prose max-w-none">
                        {taskData?.task?.content.split('~').map((str) => (
                          <p className="text-sm my-2" key={str}>
                            {str}
                          </p>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <section
                aria-labelledby="activity-title"
                className="mt-8 xl:mt-10"
              >
                <div>
                  <div className="divide-y divide-gray-200">
                    <div className="pb-4">
                      <h2
                        id="activity-title"
                        className="text-lg font-semibold text-gray-900"
                      >
                        Activity
                      </h2>
                    </div>
                    <div className="pt-6">
                      <div className="flow-root">
                        <ul role="list" className="-mb-8">
                          {!loadingComments ? (
                            taskComments &&
                            taskComments.length > 0 &&
                            taskComments
                              .filter((c) => {
                                if (state.commentId) {
                                  // return c.id === state.commentId;
                                  return false;
                                } else {
                                  return true;
                                }
                              })
                              .map((comment, itemIdx) => {
                                const member =
                                  org.team &&
                                  org.team.length > 0 &&
                                  org.team?.find(
                                    (x) => x.user.id === comment.user_id,
                                  );

                                const img =
                                  member &&
                                  member.user.avatar &&
                                  member.user.avatar.length > 0 &&
                                  member?.user?.avatar[0]?.asset
                                    ? member?.user?.avatar?.[0]?.asset
                                    : null;

                                const isOwned =
                                  comment?.user_id ===
                                  claims['x-hasura-user-id'];

                                return (
                                  <li key={comment.id}>
                                    <div className="relative pb-8">
                                      {itemIdx !== taskComments.length - 1 ? (
                                        <span
                                          className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                                          aria-hidden="true"
                                        />
                                      ) : null}
                                      <div className="relative flex items-start space-x-3">
                                        <>
                                          {img && img.url ? (
                                            <div className="relative">
                                              <img
                                                className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                                src={img?.url}
                                                alt=""
                                              />
                                              <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                                <ChatBubbleLeftEllipsisIcon
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </div>
                                          ) : null}
                                          <div className="min-w-0 flex-1">
                                            <div>
                                              <div className="text-sm">
                                                <p className="font-medium text-gray-900">
                                                  {comment.user.full_name}
                                                </p>
                                              </div>
                                              <p className="mt-0.5 text-sm text-gray-500">
                                                Commented{' '}
                                                {timeAgo(
                                                  new Date(comment.created_at),
                                                )}
                                              </p>
                                            </div>
                                            {comment?.data && (
                                              <Editor
                                                readOnly
                                                onUpdate={() => {}}
                                                content={comment?.data}
                                              />
                                            )}
                                            {comment.created_at !==
                                              comment.updated_at && (
                                              <span className="text-xs text-gray-400 text-right w-full justify-end flex pr-4">
                                                (edited)
                                              </span>
                                            )}
                                            {!comment?.data &&
                                            comment?.content ? (
                                              <div className="prose max-w-none">
                                                {comment?.content
                                                  .split('~')
                                                  .map((str) => (
                                                    <p
                                                      className="text-sm my-2"
                                                      key={str}
                                                    >
                                                      {str}
                                                    </p>
                                                  ))}
                                              </div>
                                            ) : null}
                                          </div>
                                        </>
                                      </div>

                                      {isOwned ? (
                                        <div className="space-x-4 flex top-4 right-4 absolute">
                                          <button
                                            className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            type="button"
                                            disabled={saving}
                                            onClick={() => {
                                              deleteComment(comment.id);
                                            }}
                                          >
                                            <TrashIcon className="h-5 w-5" />
                                            Delete
                                          </button>
                                          {state.commentMode === 'edit' ? (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                commentEditorRef?.current?.reset();
                                                setState((s) => ({
                                                  ...s,
                                                  commentId: null,
                                                  commentMode: 'add',
                                                }));
                                              }}
                                              className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                              <XMarkIcon className="h-5 w-5 text-gray-400" />
                                              Cancel
                                            </button>
                                          ) : (
                                            <button
                                              className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                              type="button"
                                              onClick={() => {
                                                editComment(comment);
                                              }}
                                            >
                                              <PencilIcon className="h-5 w-5 text-gray-400" />
                                              Edit
                                            </button>
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  </li>
                                );
                              })
                          ) : (
                            <div className="flex items-center justify-center min-h-96">
                              <Loader />
                            </div>
                          )}
                        </ul>
                      </div>
                      <div className="mt-6" id="comment-editor">
                        <div className="flex space-x-3">
                          <div className="flex-shrink-0">
                            <div className="relative mt-2">
                              {member &&
                              member?.user?.avatar &&
                              member?.user?.avatar.length > 0 ? (
                                <div className="w-10 h-10">
                                  <Avatar
                                    alt={member?.user?.name}
                                    src={member?.user?.avatar?.[0]?.asset?.url}
                                    width={40}
                                    height={40}
                                    size={{
                                      width: 10,
                                      height: 10,
                                    }}
                                  />
                                </div>
                              ) : null}
                              <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                <ChatBubbleLeftEllipsisIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1">
                            <form onSubmit={handleSubmit(submitComment)}>
                              <div>
                                <label htmlFor="comment" className="sr-only">
                                  Comment
                                </label>
                                <div className="block w-full p-2">
                                  <Editor
                                    ref={commentEditorRef}
                                    onUpdate={(json) => {
                                      // @ts-ignore
                                      setValue('data', json);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mt-3 pr-2 flex items-center justify-end space-x-4">
                                {state.commentMode === 'edit' ? (
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        commentEditorRef?.current?.reset();
                                        setState((s) => ({
                                          ...s,
                                          commentId: null,
                                          commentMode: 'add',
                                        }));
                                      }}
                                      className="inline-flex justify-center gap-x-1.5 h-10 items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                      <XMarkIcon className="h-5 w-5 text-gray-400" />
                                      Cancel
                                    </button>
                                  </div>
                                ) : null}
                                <div>
                                  <button
                                    type="submit"
                                    disabled={saving}
                                    className="btn-primary"
                                  >
                                    {state.commentMode === 'edit'
                                      ? 'Update comment'
                                      : 'Add comment'}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <aside className="block pl-8">
              <h2 className="sr-only">Details</h2>
              <div className="space-y-5">
                <div className="flex items-center space-x-2 w-full justify-between">
                  <Status value={priorityText} />
                  {taskData.task.status.value !== TaskStatus.COMPLETE ? (
                    <button
                      type="button"
                      onClick={() => {
                        changeStatus(TaskStatus.COMPLETE);
                        onComplete();
                      }}
                      className="inline-flex justify-center gap-x-1.5 rounded-md bg-white w-40 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <CheckCircleIcon
                        className="-ml-0.5 h-5 w-5 text-green-500"
                        strokeWidth={2}
                        aria-hidden="true"
                      />
                      <span>Complete task</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        changeStatus(TaskStatus.TODO);
                        onComplete();
                      }}
                      className="inline-flex justify-center gap-x-1.5 rounded-md bg-white w-40 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <RiCircleLine
                        className="-ml-0.5 h-5 w-5 text-indigo-500"
                        strokeWidth={2}
                        aria-hidden="true"
                      />
                      <span>Reopen task</span>
                    </button>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center flex-1 space-x-2">
                    {taskData.task.status.value === TaskStatus.IN_PROGRESS ? (
                      <>
                        <LockOpenIcon
                          className="h-5 w-5 text-blue-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-blue-700">
                          Open task
                        </span>
                      </>
                    ) : null}
                    {taskData.task.status.value === TaskStatus.TODO ? (
                      <>
                        <ClipboardDocumentCheckIcon
                          className="h-5 w-5 text-indigo-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-indigo-500">
                          Todo
                        </span>
                      </>
                    ) : null}
                    {taskData.task.status.value === TaskStatus.IN_REVIEW ? (
                      <>
                        <EyeIcon
                          className="h-5 w-5 text-orange-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-orange-500">
                          In Review
                        </span>
                      </>
                    ) : null}
                    {taskData.task.status.value === 'PRIORITY' ? (
                      <>
                        <RiFileWarningLine
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-red-500">
                          High Priority
                        </span>
                      </>
                    ) : null}
                    {taskData.task.status.value === 'COMPLETE' ? (
                      <>
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-green-700">
                          Completed task
                        </span>
                      </>
                    ) : null}
                  </div>
                  <select
                    name="status"
                    className="appearance-none block w-36 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
                    defaultValue={taskData?.task?.status?.value}
                    onChange={(e) => changeStatus(e.target.value)}
                  >
                    {statusValues &&
                      statusValues.status
                        .filter((x) => x.value !== 'ARCHIVED')
                        .map((status) => (
                          <option value={status.value} key={status.value}>
                            {columns[status.value]
                              ? columns[status.value].label.toLowerCase() ===
                                'done'
                                ? 'Complete'
                                : columns[status.value].label
                              : ''}
                          </option>
                        ))}
                  </select>
                </div>

                <div className="flex items-center space-x-2">
                  <ChatBubbleLeftEllipsisIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm font-medium text-gray-900">
                    {taskData.task.comments.length} comments
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <CalendarIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm font-medium text-gray-900">
                    Created on{' '}
                    <time dateTime={taskData.task.createdAt}>
                      {format(
                        new Date(taskData.task.createdAt),
                        'do MMMM HH:mm',
                      )}
                    </time>
                  </span>
                </div>
                {dueSection()}
                {taskData?.task?.enrollments?.[0]?.enrollment_id && (
                  <button
                    type="button"
                    onClick={() =>
                      setState((s) => ({
                        ...s,
                        enrollmentId:
                          taskData?.task?.enrollments?.[0]?.enrollment_id,
                        viewEnrollmentModal: true,
                      }))
                    }
                    className="btn-primary"
                  >
                    View Enrollment
                  </button>
                )}
              </div>
              <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
                <div>
                  <div className="w-full justify-between items-center flex">
                    <h2 className="text-sm font-medium text-gray-500">
                      Assignees
                    </h2>
                    {taskAssignee?.task?.assignee && !state.change ? (
                      <button
                        className="flex justify-center w-18 py-1.5 px-2.5 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-purple-600 transition-all duration-75"
                        onClick={() =>
                          setState((s) => ({ ...s, change: true }))
                        }
                      >
                        Change
                      </button>
                    ) : (
                      taskAssignee?.task?.assignee && (
                        <button
                          className="flex justify-center w-18 py-1.5 px-2.5 border border-transparent rounded-md shadow-sm text-xs font-medium text-gray-700 bg-gray-300 transition-all duration-75"
                          onClick={() => {
                            setState((s) => ({ ...s, change: false }));
                          }}
                        >
                          Cancel
                        </button>
                      )
                    )}
                  </div>
                  {taskAssignee?.task?.assignee && !state.change ? (
                    <div className="flex items-center space-x-3 mt-3">
                      <div className="flex-shrink-0">
                        <Avatar
                          alt={taskAssignee?.task.assignee.given_name}
                          src={
                            taskAssignee?.task.assignee.user_assets[0]
                              ?.assetByAsset.url
                          }
                          width={24}
                          height={24}
                          size={{
                            width: 6,
                            height: 6,
                          }}
                        />
                      </div>
                      <div className="text-sm font-medium text-gray-900">
                        {taskAssignee?.task.assignee.given_name}
                      </div>
                    </div>
                  ) : (
                    <div className="border rounded-lg mt-2">
                      <Combobox
                        value={selected}
                        onChange={(event) => changeAssignee(event?.id)}
                      >
                        <div className="relative mt-1">
                          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="w-full border-none py-1 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                              displayValue={(person: any) =>
                                person &&
                                `${person?.given_name} ${person?.family_name}`
                              }
                              onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 top-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon
                                className="h-4 w-4 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                          >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full divide-y divide-gray-100 overflow-auto rounded-md bg-white z-[5] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {filteredPeople &&
                              filteredPeople?.length === 0 &&
                              query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                  Nothing found.
                                </div>
                              ) : (
                                filteredPeople &&
                                filteredPeople.map((person) => (
                                  <Combobox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-1.5 pl-4 pr-4 ${
                                        active
                                          ? 'bg-teal-600 text-white'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={cn(
                                            `truncate flex items-center gap-3`,
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal',
                                            active
                                              ? 'text-white'
                                              : 'text-gray-600',
                                          )}
                                        >
                                          {person?.avatar ? (
                                            <img
                                              src={person?.avatar}
                                              className="h-7 w-7 rounded-full"
                                              alt=""
                                            />
                                          ) : (
                                            <UserCircleIcon className="w-8 h-8" />
                                          )}
                                          {person.given_name}{' '}
                                          {person?.family_name}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 right-4  flex items-center pl-3 ${
                                              active
                                                ? 'text-white'
                                                : 'text-teal-600'
                                            }`}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    </div>
                  )}
                </div>

                <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {taskData?.task?.payments.length > 0 && (
                          <PaymentPanel
                            paymentId={taskData?.task?.payments.map(
                              (payment) => payment.payment_id,
                            )}
                          />
                        )}
                        {taskData?.task?.models.length > 0 && (
                          <ModelPanel
                            action={action}
                            modelIds={taskData?.task?.models.map(
                              (model) => model.session_id,
                            )}
                          />
                        )}
                        {taskData?.task?.leads.length > 0 && (
                          <LeadPanel
                            leadIds={taskData?.task?.leads.map(
                              (lead) => lead.lead_id,
                            )}
                          />
                        )}
                        {taskData?.task?.students.length > 0 && (
                          <StudentPanel
                            action={action}
                            studentIds={taskData?.task?.students.map(
                              (model) => model.user_id,
                            )}
                          />
                        )}
                        {taskData?.task?.calls.length > 0 && (
                          <CallPanel
                            callIds={taskData?.task?.calls.map(
                              (call) => call.call_id,
                            )}
                          />
                        )}

                        {taskData?.task?.forms.length > 0 && (
                          <FormPanel
                            action={action}
                            formIds={taskData?.task?.forms.map(
                              (form) => form.form_id,
                            )}
                          />
                        )}
                        {taskData?.task?.uploads.length > 0 && (
                          <UploadPanel
                            action={action}
                            uploadIds={taskData?.task?.uploads.map(
                              (upload) => upload.upload_id,
                            )}
                          />
                        )}

                        {taskData?.task?.courses.length > 0 && (
                          <CoursePanel
                            courseIds={taskData?.task?.courses.map(
                              (course) => course.event_id,
                            )}
                          />
                        )}
                      </ul>
                    </li>
                  </ul>
                </nav>

                <button
                  onClick={archiveTask}
                  type="button"
                  className="btn-white whitespace-nowrap w-full"
                >
                  {taskData?.task?.archived ? 'Unarchive' : 'Archive'}
                </button>
              </div>
            </aside>
          </div>
        </div>
      </main>
      {state.viewEnrollmentModal && state.enrollmentId && (
        <Modal
          title={`Enrollment: ${state.enrollmentId}`}
          open={state.viewEnrollmentModal}
          close={() => setState({ ...state, viewEnrollmentModal: false })}
          full
        >
          <ViewEnrollmentModal
            enrollmentId={state.enrollmentId}
            onComplete={() => {
              setState({
                ...state,
                viewEnrollmentModal: false,
              });
            }}
          />
        </Modal>
      )}
      <TaskModal
        full
        overflow="overflow-y-scroll"
        open={state.displayTaskDetail}
        close={() => {
          setData();
          setState((s) => ({ ...s, displayTaskDetail: null }));
        }}
      >
        {state.displayTaskDetail ? (
          <TaskDetail
            close={() => {
              setData();
              setState((s) => ({ ...s, displayTaskDetail: null }));
            }}
            refetchTasks={refetchTasks}
            taskID={state.displayTaskDetail}
          />
        ) : null}
      </TaskModal>
    </>
  );
}
