// @ts-nocheck
import { cn } from 'lib/utils';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

function Component(props, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="divide-y items">
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={cn(
              `item ${index === selectedIndex ? 'is-selected' : ''}`,
              'text-left hover:bg-gray-50 py-2 px-4 w-full',
            )}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item?.name}
            {item?.email && (
              <p className="text-xs text-gray-500">{item.email}</p>
            )}
          </button>
        ))
      ) : (
        <div className="item text-left py-2 px-4 w-full">No result</div>
      )}
    </div>
  );
}

export default forwardRef(Component);
