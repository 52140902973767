export const getCourseGroup = async (courseID: string) => {
  try {
    const response = await fetch(
      `/api/coassemble?url=courses/${courseID}/groups?sort=created&page=0&length=1`,
    );
    if (response.ok) {
      let res = await response.json();

      if ((res && res.length === 0) || !res) {
        const response = await fetch(
          `/api/coassemble?url=courses/${courseID}/groups`,
          {
            method: 'POST',
          },
        );
        console.log('CREATE RESPONSE', response);
        const json = await response.json();
        res = [json];
      }
      return res;
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

export const getCourseEnrolments = async (courseID: string) => {
  try {
    const response = await fetch(
      `/api/coassemble?url=enrolments?course=${courseID}`,
    );
    if (response.ok) {
      let res = await response.json();
      return res;
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (error) {
    return error;
  }
};
