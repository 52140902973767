export const eventsData = [
  {
    id: 'caa0fed9-f7f6-4025-9952-f53bc605d6d9',
    title: 'Lemon Bottle Fat Dissolving',
    url: 'https://www.cosmetic.college/courses/aesthetics/lemon-bottle-fat-dissolving/',
    contentful_id: '4vK2hn0kplJNLsgXuu9RRZ',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6cpxSsLwnCOhcFqX0a2mnr%2F4b7dceb7640927a0a7ef4f6b35150dd8%2F1689189373256-Lemon-Bottle-Injections-Charmelle-London.jpg&w=3840&q=75',
  },
  {
    id: 'ab170dd8-6132-4873-9392-f9cf87f114c0',
    title: 'Laser Hair Removal',
    url: 'https://www.cosmetic.college/courses/beauty/laser-hair-removal/',
    contentful_id: '5MrEz8xx8s0YFtNHRCes3i',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3fp2bgY1mgeIycFMKZu6Ff%2Faebfcbc13a813cef831e6f587d627b1b%2FScreenshot_2022-09-25_at_10.00.34.png&w=3840&q=75',
  },
  {
    id: '8ab7870c-f7d3-4785-977f-345349e86459',
    title: 'Lymphatic Drainage Massage',
    url: 'https://www.cosmetic.college/courses/beauty/lymphatic-drainage-massage/',
    contentful_id: '488iYiL8DQJhMYKq2uYSJu',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1GbfeqG0LKZBze8uwukvAz%2F849b0b4f1e5f719aafd1b007376b3f42%2Fdeep-tissue-massage_274689-10676.jpeg&w=3840&q=75',
  },

  {
    id: '346db466-4615-4af0-92e4-5f21d661c323',
    title: 'Cryotherapy (Fat Freezing)',
    url: 'https://www.cosmetic.college/courses/beauty/cryotherapy-fat-freezing/',
    contentful_id: '5CFmmQpPtRwh4KGSQQzK4V',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FxCagOuhgXiCr6UXqAg6GF%2Fa1daae4f2d6e3f6cfc7f273f5a4bb25b%2FIMG_2281.JPG&w=3840&q=75',
  },

  {
    id: 'decc8deb-f737-47c0-87b7-27182d43a377',
    title: 'Body Sculpting',
    url: 'https://www.cosmetic.college/courses/beauty/body-ss%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1GbfeqG0LKZBze8uwukvAz%2F849b0b4f1e5f719aafd1b007376b3f42%2Fing-cavitation-fat-reduction-and-skin-tightening/',
    contentful_id: '4KqUlfqwadKPLwapzVjS8C',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F11SBdmyIY80N6K9Quhn3W9%2F2c7743311ea0964593bb49faa509bc44%2FWoman-getting-an-anti-cellulite-lase-treatment-at-the-spa-1296x728-header.webp&w=3840&q=75',
  },

  {
    id: 'afebe317-9752-471e-a278-ef00968c291e',
    title: 'Radiofrequency Skin Tightening',
    url: 'https://www.cosmetic.college/courses/beauty/radiofrequency-skin-tightening/',
    contentful_id: '1iJgY9PO5WqDGsskW6VzVi',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2FYSJD8niJXblRHQevm8o4%2F4d225e2c83635fc7d03c43030523c0f7%2Frf_body_tightening.jpg&w=3840&q=75',
  },

  {
    id: '9b68a3f3-a279-4b47-b857-a34ea20539f5',
    title: 'Ultrasound Cavitation',
    url: 'https://www.cosmetic.college/courses/beauty/ultrasound-cavitation/',
    contentful_id: '4nuqBWSRkjfoWpZgw9CZWt',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FZsd9SBZGeOuo1IldHAm5I%2Fc47a69b34edb90db53edb8c989623676%2FULTRASOUND-CAVITATION-LASER-TREATMENT-SOUTHEND-ON-SEA-ESSEX-BODY-AESTHETICS-2.jpeg&w=3840&q=75',
  },
  {
    id: '5777217f-4c41-4cea-b809-2a5fdaf6a5ab',
    title: 'Lash Lift & Tint',
    url: 'https://www.cosmetic.college/courses/beauty/lash-lift-and-tint/',
    contentful_id: '4rJIxg4Q1PW5a7zPLy2mfu',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7cnBdS3bbGRKgE2ofhf8x%2Fa4725b3fe931ecc06caa22e642158ad7%2FIMG_2325.JPG&w=2048&q=75',
  },
  {
    id: 'abf7f1e8-4d5e-4b7a-946f-5bb244cfc485',
    title: 'Brow Lamination',
    url: 'https://www.cosmetic.college/courses/beauty/brow-lamination/',
    contentful_id: '3IwfdDZOSnx2CMnt5n8sWy',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2Fh00yNsDwQGGuLFMIpodEu%2F03f9d254466e032164a8f27cb40a714d%2FImage_from_iOS-25.jpg&w=2048&q=75',
  },
  {
    id: '264345a1-4643-430a-8702-d24ce822437b',
    title: 'Face & Body Waxing',
    url: 'https://www.cosmetic.college/courses/beauty/waxing/',
    contentful_id: '6ZxDq77L8fEXVZ6XKNdimt',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F54Ozio8nWfZFsbTkIdn3e3%2Ff02b837096e68cd3443ebfcfb75b51c2%2Fwaxing_course_image.jpg&w=2048&q=75',
  },
  {
    id: '9c9b49be-1e52-4fe3-a202-b488dc202b26',
    title: 'Microneedling',
    url: 'https://www.cosmetic.college/courses/aesthetics/microneedling/',
    contentful_id: 'MVCSjhMGPdqn9NmuQTH2V',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F227uAlpBQJNTyLE1HUh0PM%2F2d6d29cf57a340b284cf8e2803db2c81%2FIMG_2277.JPG&w=2048&q=75',
  },
  {
    id: '7f924499-5e7c-4450-ad5a-fc37330d493b',
    title: 'Chemical Skin Peels',
    url: 'https://www.cosmetic.college/courses/aesthetics/chemical-skin-peels/',
    contentful_id: '5zsbHrKTJmEe0qkPpjbkAK',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2GeMvNf0ZTj7LLlcHPjb2r%2F5f130ab9a8e82966ab4bde5f2712123c%2FScreenshot_2022-05-06_at_07.24.54.png&w=2048&q=75',
  },
  {
    id: 'b9b6a23a-2246-4c80-83ac-31c945a8e408',
    title: 'Facial And Skincare',
    url: 'https://www.cosmetic.college/courses/beauty/facial-and-skincare/',
    contentful_id: '2z0uQjiikvrYMCssvzcTc',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7pemi0ofl1yirLUUkez9AU%2Ff9c529666eddc68f3a5884260965589d%2FIMG_2646.JPG&w=2048&q=75',
  },
  {
    id: 'b4999466-03ea-43b3-892d-f868a030d18a',
    title: 'Wood Therapy',
    url: 'https://www.cosmetic.college/courses/beauty/wood-therapy/',
    contentful_id: '3DZKSSNjbH2xTOYqDaUhxs',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F4BIykRFLSm3xCZJWnjCn6v%2F014c599139c3ea14f36ee816df793c43%2Fwood-therapy.jpg&w=2048&q=75',
  },
  {
    id: '6f342c22-4ae2-4a46-96de-6f8704698958',
    title: 'Gel Polish & Dry Manicure',
    url: 'https://www.cosmetic.college/courses/beauty/dry-manicure-gel-polish/',
    contentful_id: '7vKN900e6cikYJSmWkBEF4',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3k2DPTECXahks6z7ImM16W%2F5c8d9f79948211d46f66f0b66b87cd17%2Fgel-nails.jpg&w=2048&q=75',
  },
  {
    id: '45d5e47c-4888-4278-a3fe-0fbf9ed190c3',
    title: 'High Definition Brows',
    url: 'https://www.cosmetic.college/courses/beauty/high-definition-brows/',
    contentful_id: '1QwH86cybcvUSXv7x9cn03',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FyRBp1eTtNfXBSTURIoqHY%2F77c633793baa11a7802520dc4db33e6d%2FScreenshot_2022-05-13_at_18.11.45.png&w=2048&q=75',
  },
  {
    id: '5ee40e35-4966-423d-9ff8-be115acd398f',
    title: 'Intimate Waxing',
    url: 'https://www.cosmetic.college/courses/beauty/intimate-waxing/',
    contentful_id: '06TNw8GMxTkQUGF45gOzr',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5Rr0MTSr2ghXNY7l0mYEGs%2Fa8afb31ba765c3d2fc3af3b8120df04b%2Fintimate-waxing-3.jpg&w=2048&q=75',
  },
  {
    id: 'de4b5e91-c285-4a65-b7e7-453e19809389',
    title: 'LED Light Therapy',
    url: 'https://www.cosmetic.college/courses/beauty/led-light-therapy/',
    contentful_id: '5afXlsvdKqg5zv0tXryuKi',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7lWg4V4sLdW62LBq34sShA%2F80a69f0b2ef16d42c3e269215e2b023d%2FIMG_2645.JPG&w=2048&q=75',
  },
  {
    id: '32860f42-cb96-4cd7-99ee-d4f92eb1fddc',
    title: 'Individual Classic Eyelash Extensions',
    url: 'https://www.cosmetic.college/courses/beauty/classic-eyelash-extensions/',
    contentful_id: '1iAr7hRwvueASdZsHp6Q9Q',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FFPvCZX3aoAOXgiHzU9oQu%2F94b9728385c7f0f3a2cab3eca9e456e6%2FIMG_2327.JPG&w=2048&q=75',
  },
  {
    id: '331f350a-c561-4327-85ee-1f20689e1c3e',
    title: 'Plasma Fibroblast Skin Tightening',
    url: 'https://www.cosmetic.college/courses/aesthetics/plasma-fibroblast-skin-tightening/',
    contentful_id: '3A7cAfReqYwEbPa3XMAC9E',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3bfWR9RwOYh34ZTpjmrZy%2Fe9c3a62cad8db6d26ad390457c4539af%2FIMG_2288.JPG&w=2048&q=75',
  },
  {
    id: '31a06075-0480-41d4-9c27-7feb9d9e84b2',
    title: 'Derma roller',
    url: 'https://www.cosmetic.college/courses/aesthetics/dermaroller/',
    contentful_id: '4fkH4ufldkJgJ6Mbp12FE7',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2ZN2w9ZwQFdh84WW0Tgqfm%2F0a707f70b0d6aefa63f0d430c890d1fb%2Fbest-derma-rollers-over-50.jpg.webp&w=2048&q=75',
  },
  {
    id: '996a9163-ca62-475b-8ba9-843abf40bcb8',
    title: 'Russian Lash Extensions',
    url: 'https://www.cosmetic.college/courses/beauty/russian-lash-extensions/',
    contentful_id: '6LAwbkO2b1fawoUHpPdd1O',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1BXztkaAdMAVGlgIFjKu2X%2F4291b0827bbc9f162951d85b6c2b4a1f%2FIMG_2606_2.JPG&w=2048&q=75',
  },
  {
    id: '2acf5c13-4c6f-4d24-b2a4-c215d46b6e8c',
    title: 'Dermaplaning',
    url: 'https://www.cosmetic.college/courses/beauty/dermaplaning/',
    contentful_id: '1SK1G16xUC8JSWl5ZAQVXd',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F7amdNmvpxQqiBH7JzhZaq1%2F8627c027865fbf47bb9c54ee35f0176b%2FIMG_2609.JPG&w=2048&q=75',
  },
  {
    id: '9df8cadd-d527-4210-9fc4-d6783ee3a911',
    title: 'Face HiFu',
    url: 'https://www.cosmetic.college/courses/beauty/face-hifu/',
    contentful_id: '5PFSNJ2SPeeNIX4wUgzBg7',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FywmyIeAsLAMDiNRWtpD6A%2F7b828619213c8a16837a1017a217b103%2FHIFU_treatment.png&w=2048&q=75',
  },
  {
    id: 'de89fdb3-a5c5-41a5-bb86-c2ca1ea5e4f6',
    title: 'Body HiFu',
    url: 'https://www.cosmetic.college/courses/beauty/body-hifu-training/',
    contentful_id: '3plE8jZ7rbW2qvJtdaXJsQ',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2IpDLixKf8GU6LhU9m2NLr%2Fa359a42e453fd370947753f8ee82eade%2FHIFU-treatment-body-contouring.png&w=2048&q=75',
  },
  {
    id: 'e7a1a953-6ad8-4add-bd79-26d1555c8ef4',
    title: 'Hydradermabrasion',
    url: 'https://www.cosmetic.college/courses/beauty/hydra-dermabrasion-facials/',
    contentful_id: '1KLoVdDS2EyxDeapgfX8e9',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F3Bay6IbDkdbbJNHsfW9U8u%2F522815fea71240ea618c30430e3d404b%2FIMG_2282.JPG&w=2048&q=75',
  },
  {
    id: '20e39d7f-9ff6-4138-a2a8-7ec8822d2b66',
    title: 'Foundation Anti Wrinkle Injections',
    url: 'https://www.cosmetic.college/courses/aesthetics/foundation-anti-wrinkle-injections-course/',
    contentful_id: '2k54aNHI2bYAJrdgjo1JyW',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1fWdkzoML0WuGEDsYG3cxq%2F347e199f55602de6a61782f703fbce7a%2FIMG_2655.JPG&w=2048&q=75',
  },
  {
    id: '154b7122-58d5-47a5-b08d-3cada6532aaa',
    title: 'Foundation Dermal Fillers',
    url: 'https://www.cosmetic.college/courses/aesthetics/foundation-dermal-fillers/',
    contentful_id: '38cwi3gDzvcD9mrlIHMOvD',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F17DOtzUlXWU6WmUges3DZP%2Fd2ff7bd72d232b78e4e4f4c02879279c%2FIMG_2603_2.JPG&w=2048&q=75',
  },
  {
    id: '3dda54aa-444b-4065-9294-3bbbc19b0cfc',
    title: 'Foundation Lip Dermal Fillers',
    url: 'https://www.cosmetic.college/courses/aesthetics/lip-dermal-filler-course/',
    contentful_id: '21JXr7i11XFH0B5C7MReyp',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5TC0ywzfmbc3zg3Mm2DYwe%2F7f3462c5a8c186ecf4d008c17c5c436c%2FCD144E7C-85A9-4D1C-BA85-E7E4F4BB94F7.JPG&w=2048&q=75',
  },
  {
    id: '1f46024c-7bef-40e1-8866-ee893c5a287f',
    title: 'Dermal Fillers and Anti-Wrinkle Package',
    url: 'https://www.cosmetic.college/courses/packages/dermal-fillers-and-anti-wrinkle-package/',
    contentful_id: '32hEfiCzA35ii8gfDjdjIt',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FpA8RRlOM4Y4Anumwa2OBn%2F0416cec5961870e769709083c6065385%2FC6E6A85F-F967-4A9B-A7EF-863B338427EE.JPG&w=2048&q=75',
  },
  {
    id: '9b4d5646-eec6-43b6-be74-6c100fdc6308',
    title: 'Russian Lip Enhancement Masterclass',
    url: 'https://www.cosmetic.college/courses/aesthetics/russian-lip-enhancement-masterclass/',
    contentful_id: '4LbD8KNm8fMGKwjAE0Q6lu',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1B73JSPIICERP2TgAxY91Z%2F7937306a8217b021414bc65fb6bd2b8b%2FScreenshot_2021-02-28_at_07.45.13.png&w=2048&q=75',
  },
  {
    id: 'c550ca68-f9e3-4407-9261-656f602c291d',
    title: 'PRP Vampire Facial',
    url: 'https://www.cosmetic.college/courses/aesthetics/prp-vampire-facial/',
    contentful_id: '5yEYTNKTUmqNNj21DAxeeX',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5FyxZvuSVTTNdS0hNhUHBD%2F0b912d62ad27dafadf1bab51c9603ea3%2Fprp-vampire-facial.jpg&w=2048&q=75',
  },
  {
    id: '79604da6-4bde-40d9-97b9-2d022fe221dc',
    title: 'Vitamin B12 Injections',
    url: 'https://www.cosmetic.college/courses/aesthetics/vitamin-b12-injections/',
    contentful_id: '3qLThLmaU5lWR63ivKHIg7',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1ykf5A11d1pYlfnP6vOXIW%2F3e14b26ef5a4d1b791e671d5798ed39d%2FIMG_2648.JPG&w=2048&q=75',
  },
  {
    id: 'dc420aad-4faf-4165-b514-725249aeb605',
    title: 'Phlebotomy',
    url: 'https://www.cosmetic.college/courses/aesthetics/phlebotomy/',
    contentful_id: '1OEuS45O6mchuXNLsfidYn',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5dBbWxdOWM9dMdOPanBBRx%2F8f70fab27c1b36b22b168e6347865999%2FIMG_2605_2.JPG&w=2048&q=75',
  },
  {
    id: '99e1e043-abdc-493d-8fd0-2ec24d3b2bee',
    title: 'BB Glow Foundation',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/bb-glow/',
    contentful_id: '4MK5Uavvd0BUCqaz03xuB5',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F1msnhcI97EWvwA5FHlYMlj%2F28fda10e10176aa2c778f2197ade6675%2FIMG_2354.JPG&w=2048&q=75',
  },
  {
    id: '344c2203-22ec-4a4e-ba81-690c36862b07',
    title: 'Tear Trough & Non-Surgical Rhinoplasty Masterclass',
    url: 'https://www.cosmetic.college/courses/aesthetics/tear-trough-masterclass/',
    contentful_id: 'tW3wogRfBWWlFvJwErklH',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2FoesUsrXno9GsH2fz58U3b%2F4f7a10772e7549e943280c07aad5935f%2Ftear_t.png&w=2048&q=75',
  },
  {
    id: '80d8ffa3-d181-4ac7-bd6f-507ddd7faa03',
    title: 'Cheeks, Jawline & Chin Dermal Fillers',
    url: 'https://www.cosmetic.college/courses/aesthetics/jawline-dermal-fillers-masterclass/',
    contentful_id: '44lVe6JTh8xv3rnLOtzU4J',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F62MVX0hFBSEssx4ZkWb5GS%2Fd37304a9939a4266994a0908ebb5af3a%2FIMG_0458.jpg&w=2048&q=75',
  },
  {
    id: 'a1d98a16-737c-4e3d-ad9e-1f4c72e9a023',
    title: 'Buttock Augmentation',
    url: 'https://www.cosmetic.college/courses/aesthetics/buttock-augmentation/',
    contentful_id: '6WHBqSwRbvEOSfSihJB2ox',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6MMj9zFSOWCx1125pLUCQ%2Ff9112f6decefde03527f217b2693dd53%2Fbrazilian-butt-lift-fat-grafting.jpeg&w=2048&q=75',
  },
  {
    id: '3456c162-bc9a-4331-a7a2-ff595b0ced77',
    title: 'Aqualyx Fat Dissolving',
    url: 'https://www.cosmetic.college/courses/aesthetics/aqualyx-fat-dissolving/',
    contentful_id: '4LVd3KgqBQ43prSvHXJSSz',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6G3rJcoBDMaPmjm9VPurJo%2F218b2708a42d4e54bc3fa4aefece622a%2FIMG_2290.jpg&w=2048&q=75',
  },
  {
    id: '5ede21bb-5e0d-46a8-8cdd-9edc87509077',
    title: 'Lidocaine Infusion',
    url: 'https://www.cosmetic.college/courses/aesthetics/lidocaine-infusion-training-course/',
    contentful_id: '5s5LPmOBSuVxbEEFZE4R8V',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6Dn2aaY8EXxzSzsB9VG7lB%2Feaa3a2e48a78abf551ffb03ae43096dc%2Fdental-block.jpg.webp&w=2048&q=75',
  },
  {
    id: 'bbfced9b-c09f-4e0c-89ff-8a6edf328810',
    title: 'Ombre Brows',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/ombre-brows/',
    contentful_id: '7k6tzCaKfzdZBInwiptfpS',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2JSq9yNyobEFxQfHuJhatg%2Fc3bdaefdeeeb929b13045aa3cf8b8997%2FIMG_2276.JPG&w=2048&q=75',
  },
  {
    id: '5ef13fe0-9f92-477c-b517-2a9f2544e0f7',
    title: 'Lip Blush',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/lip-blush-training/',
    contentful_id: '7lwhKEjHB8yfL0lRukNZmx',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6dmeHnGRqVCcwzBlEZWBnJ%2F1f901f6140945f05420bf1f4efffbec5%2FIMG_2643.JPG&w=2048&q=75',
  },
  {
    id: 'cd4d2c3a-6f6e-4f73-af49-80ac52809728',
    title: 'Permanent Makeup Saline Removal & Correction',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/semi-permanent-makeup-saline-removal/',
    contentful_id: 'Gty6IG6IQiEVhhcwJgeqz',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F5coHuLOtEihUTBDJSMD9Vv%2F5819f3bf27b3f5e4b9af3f26942d4e9e%2FIMG_2286.JPG&w=2048&q=75',
  },
  {
    id: '098def47-aef9-4430-abf3-de89ac3fe870',
    title: 'Full Face Permanent Makeup',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/ultimate-permanent-makeup/',
    contentful_id: '7uqXSMraDGmyvE3jrLq8rJ',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F6YyXfKZ93vd5Hf6fvzkLgu%2F697a78244deec636bc38a632e5238dab%2FIMG_2307.JPG&w=2048&q=75',
  },
  {
    id: '065c040f-3508-4589-8bb7-d9c8c5ffe0f7',
    title: 'Scalp Micropigmentation',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/scalp-micropigmentation/',
    contentful_id: '6AkTamsyeFInPa3eIGShOH',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F12l1EXcUvhfw7RjONoP6X6%2Fec2f82b9c4ae0ca6def50d6b897aa79a%2Fscalp-micropigmentation3-1024x682.jpg&w=2048&q=75',
  },
  {
    id: 'bd0e4d7a-8285-4c8f-b5cb-51a552547426',
    title: 'Microblading',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/microblading/',
    contentful_id: '1JVRyfuE5h4pMmiDBo8kEd',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2Xw4v6OoaecADVUno6dniD%2F5463f7cc747572ee7283d53a70996647%2FIMG_2283.JPG&w=2048&q=75',
  },
  {
    id: 'f8e48e80-b3e8-4457-9b80-85b70adf82a6',
    title: 'Eyeliner & Lash Enhancements',
    url: 'https://www.cosmetic.college/courses/semi-permanent-makeup/eyeliner-and-lash-enhancements/',
    contentful_id: '4VQ9DYmEYM5kMfdAcrd7Nu',
    source:
      'https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F55dzmvNLOk5pkT5gbLAAat%2Feca5e6843b885c4143f10b3785094353%2FIMG_2285.JPG&w=2048&q=75',
  },
];

//0df38dfd-3a9e-4514-8e04-54afd21b4574
//6119b3e9-7077-47df-b2e6-62162d5addf3
