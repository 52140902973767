import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  concat,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/nextjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { differenceInSeconds } from 'date-fns';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Logo from '../assets/logo.svg';

export interface ExtendedUser extends CognitoUser {
  imageUrl?: string;
  username: string;
  attributes: {
    [key: string]: any;
  };
}

interface State {
  client: ApolloClient<NormalizedCacheObject> | null;
  loading: boolean;
  user?: ExtendedUser;
  type?: string;
  token?: string;
  claims?: { [key: string]: string };
  role?: string;
}

const initialState = {
  loading: true,
};

interface SessionContextValue {
  client: ApolloClient<NormalizedCacheObject>;
  loading: boolean;
  user?: ExtendedUser;
  type?: string;
  token?: string;
  claims?: { [key: string]: string };
  role?: string;
  setContext: React.Dispatch<React.SetStateAction<State>>;
  logout: () => void;
}

const SessionContext = createContext(initialState as SessionContextValue);

const currentUser = async () => {
  try {
    const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (!data) {
      return {};
    }

    const { idToken } = data.signInUserSession;

    const claims =
      idToken.payload && idToken.payload['https://hasura.io/jwt/claims']
        ? JSON.parse(idToken.payload['https://hasura.io/jwt/claims'])
        : {};

    const token = idToken.jwtToken;

    const userData = {
      user: data,
      claims,
      // type,
      token,
      role: claims && claims['x-hasura-role'],
    };

    try {
      Sentry.configureScope(function (scope) {
        const u = {
          id: data.attributes.sub,
          email: data.attributes.email,
        };
        scope.setUser(u);
      });
    } catch (err) {
      console.log(err);
    }

    console.groupEnd();

    return userData;
  } catch (err) {
    console.log('err', err);
    console.log('User not authenticated');
    if (window.location.pathname !== '/sign-in') {
      window.location.href = '/sign-in';
    }
    return {};
  }
};

function useExtendedState<T>(initialState: T) {
  const [state, setState] = useState<T>(initialState);
  const getLatestState = () => {
    return new Promise<T>((resolve, reject) => {
      setState((s) => {
        resolve(s);
        return s;
      });
    });
  };

  return [state, setState, getLatestState] as const;
}

const SP = ({ Component, pageProps, children }) => {
  // const idle = useIdle(5000);

  const [state, setState, getLatestState] = useExtendedState<State>({
    client: null,
    ...initialState,
  });

  const [links, setLinks] = useState({
    ws: null,
  });

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible' && state.user) {
      refresh();
    }
  };

  useEffect(() => {
    async function start() {
      const userData = await currentUser();
      // @ts-ignore
      if (userData && userData.token) {
        setState((s) => ({
          ...s,
          ...userData,
        }));
      } else {
        setState((s) => ({
          ...s,
          loading: false,
        }));
      }
    }

    start().catch(console.error);

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  useEffect(() => {
    const init = async () => {
      const cache = new InMemoryCache({});

      const headers = {
        // @ts-ignore
        authorization: `Bearer ${state.token}`,
      };

      const ws = new WebSocketLink({
        uri: process.env.NEXT_PUBLIC_API_URL.replace('https://', 'wss://'),
        options: {
          reconnect: true,
          connectionParams: {
            headers,
          },
        },
      });

      const newClient = new ApolloClient({
        link: logoutLink.concat(
          concat(
            new ApolloLink((operation, forward) => {
              // @ts-ignore
              if (state.token) {
                operation.setContext({
                  headers,
                });
              }
              return forward(operation);
            }),
            ws
              ? split(
                  ({ query }) => {
                    const definition = getMainDefinition(query);
                    return (
                      definition.kind === 'OperationDefinition' &&
                      definition.operation === 'subscription'
                    );
                  },
                  ws,
                  new HttpLink({
                    credentials: 'include',
                    uri: process.env.NEXT_PUBLIC_API_URL,
                  }),
                )
              : new HttpLink({
                  credentials: 'include',
                  uri: process.env.NEXT_PUBLIC_API_URL,
                }),
          ),
        ),
        cache,
      });

      setLinks({
        ws,
      });

      setState((s) => ({
        ...s,
        loading: false,
        client: newClient,
      }));
    };

    if (state.token && state.user) {
      init().catch(console.error);
    }
  }, [state.token, state.user]);

  // useEffect(() => {
  //   if (!idle && state.user) {
  //     refresh();
  //   }
  // }, [idle]);

  const refresh = async () => {
    try {
      console.groupCollapsed('running refresh');
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const valid = cognitoUser.getSignInUserSession().isValid();
      const expiration = cognitoUser
        .getSignInUserSession()
        .getAccessToken()
        .getExpiration();

      const diff = differenceInSeconds(expiration * 1000, new Date());

      console.log('diff', diff);

      console.log(
        'will refresh',
        (cognitoUser && !valid) || (cognitoUser && diff / 60 < 10),
      );

      if ((cognitoUser && !valid) || (cognitoUser && diff / 60 < 10)) {
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          async (err, session) => {
            console.log('renewed session', err, session);
            const { accessToken } = session;
            const cognitoUser = await Auth.currentAuthenticatedUser();
            setState((s) => ({ ...s, token: accessToken, user: cognitoUser }));
          },
        );
      }
      console.groupEnd();
    } catch (e) {
      console.log('Unable to refresh Token', e);
      logout();
    }
  };

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      setTimeout(() => (window.location.href = '/sign-in'), 500);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const logoutLink = onError(({ networkError }) => {
    if (
      networkError &&
      // @ts-ignore
      networkError.statusCode &&
      // @ts-ignore
      networkError.statusCode === 401
    ) {
      currentUser()
        .then((user) => {
          setState((s) => ({ ...s, ...user, loading: false }));
        })
        .catch((err) => {
          console.log(err);
          setState((s) => ({ ...s, user: null, loading: false }));
        });
    }
  });

  const value: SessionContextValue = useMemo(
    () => ({
      ...state,
      logout,
      setContext: setState,
    }),
    [state, logout],
  );

  return (
    <SessionContext.Provider value={value}>
      {state.loading && (
        <div className="bg-gray-50 flex flex-col justify-center items-center w-screen h-screen text-purple-600">
          <Logo className="mx-auto w-auto max-w-xs" />
        </div>
      )}
      {!state.loading && !state.user && <Component {...pageProps} />}
      {!state.loading && state.user && state.client && (
        <ApolloProvider client={state.client}>{children}</ApolloProvider>
      )}
    </SessionContext.Provider>
  );
};

const SessionProvider = SP;

export const useSession = () => useContext(SessionContext);

export default SessionProvider;
