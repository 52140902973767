import { useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import { columns as taskColumns } from 'pages/tasks';
import { memo, useMemo } from 'react';

import Modal from 'components/molecules/modal';
import NewClientModal from 'components/organisms/client/new-client-modal';
import { columns as callColumns } from 'components/organisms/enrollment/view-enrollment';

import { getIncomingStatus as getIncomingStatuses } from 'queries/leads';
import { getIncomingStatus as getTaskStatuses } from 'queries/tasks';

import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

import NewContactModal from './template/contact';

const NewModelSession = dynamic(
  () => import('components/organisms/globals/new-model-session'),
);
const NewEnrollmentModal = dynamic(
  () => import('components/organisms/enrollment/new-enrollment'),
);

const NewNoteModal = dynamic(
  () => import('components/organisms/globals/new-note'),
);

const NewQuoteModal = dynamic(
  () => import('components/organisms/enrollment/new-quote'),
);

const ViewEnrollmentModal = dynamic(
  () => import('components/organisms/enrollment/view-enrollment'),
);

const Tasks = dynamic(() => import('pages/tasks/tasks'));

const Calls = dynamic(() => import('pages/incoming/calls'));
const Leads = dynamic(() => import('pages/incoming/leads'));

export enum GlobalsEnum {
  contactClient = 'contactClient',
  newModelSession = 'newModelSession',
  newEnrollment = 'newEnrollment',
  newNote = 'newNote',
  newClient = 'newClient',
  newQuote = 'newQuote',
  payment = 'payment',
  viewEnrollment = 'viewEnrollment',
  viewTask = 'viewTask',
  viewCall = 'viewCall',
  viewLead = 'viewLead',
}

enum GlobalsModalSize {
  'large',
  'xlarge',
  'xxlarge',
  'full',
  'auto',
}

const Globals = memo(function Panel() {
  const ui = useUI();
  const org = useOrganization();
  const { client } = useSession();
  const { data: taskStatusValues } = useQuery<{
    status: { value: string }[];
  }>(getTaskStatuses);

  const { data: incomingStatusValues } = useQuery<{
    status: { value: string }[];
  }>(getIncomingStatuses);

  const teamMembers = useMemo(() => {
    return org && org?.team && org.team.length > 0
      ? org.team
          .filter((x) => x.active)
          .map((member) => ({
            id: member.user?.id,
            given_name: member.user?.name,
            family_name: member.user?.surname,
            avatar: member.user?.avatar[0]?.asset?.url,
          }))
      : [];
  }, [org]);

  let modal = null;
  let size = GlobalsModalSize.xlarge;
  let allowContentScroll = false; // Would add overflow-y-auto.

  if (ui.type === GlobalsEnum.newClient) {
    modal = (
      <NewClientModal
        onComplete={() => ui.setGlobal(false, null, null)}
        prefill={ui?.globalData}
      />
    );
  }
  if (ui.type === GlobalsEnum.newModelSession) {
    modal = (
      <NewModelSession
        onComplete={() => ui.setGlobal(false, null, null)}
        prefill={ui?.globalData}
      />
    );
  }
  if (ui.type === GlobalsEnum.newEnrollment) {
    modal = (
      <NewEnrollmentModal
        prefill={ui?.globalData}
        onComplete={() => {
          ui.setGlobal(false, null, null);
          client.refetchQueries({
            include: ['all'],
          });
          document.dispatchEvent(new Event('refresh'));
        }}
      />
    );
  }
  if (ui.type === GlobalsEnum.newQuote) {
    modal = (
      <NewQuoteModal
        prefill={ui?.globalData}
        onComplete={() => {
          ui.setGlobal(false, null, null);
          client.refetchQueries({
            include: ['all'],
          });
          document.dispatchEvent(new Event('refresh'));
        }}
      />
    );
  }

  if (ui.type === GlobalsEnum.contactClient) {
    allowContentScroll = true;
    modal = (
      <NewContactModal
        users={ui?.globalData?.users}
        template={ui?.globalData?.template}
        onClose={() => {
          ui.setGlobal(false, null, null);
        }}
      />
    );
  }

  if (ui.type === GlobalsEnum.newNote) {
    modal = (
      <NewNoteModal
        onComplete={() => {
          ui.setGlobal(false, null, null);
        }}
      />
    );
  }

  if (ui.type === GlobalsEnum.viewEnrollment) {
    size = GlobalsModalSize.full;
    modal = <ViewEnrollmentModal enrollmentId={ui?.globalData.reference_id} />;
  }

  if (ui.type === GlobalsEnum.viewTask) {
    size = GlobalsModalSize.full;
    modal = (
      <Tasks
        teamMembers={teamMembers}
        taskID={ui?.globalData.reference_id}
        onComplete={() => {
          ui.setGlobal(false, null, null);
        }}
        statusValues={taskStatusValues}
        columns={taskColumns}
      />
    );
  }

  if (ui.type === GlobalsEnum.viewCall) {
    allowContentScroll = true;
    size = GlobalsModalSize.auto;
    modal = (
      <Calls
        callID={ui?.globalData.reference_id}
        statusValues={incomingStatusValues}
        columns={callColumns}
      />
    );
  }

  if (ui.type === GlobalsEnum.viewLead) {
    allowContentScroll = true;
    size = GlobalsModalSize.auto;
    modal = (
      <Leads
        leadID={ui?.globalData.reference_id}
        statusValues={incomingStatusValues}
        columns={callColumns}
      />
    );
  }

  if (modal) {
    return (
      <Modal
        // @ts-ignore
        large={size === GlobalsModalSize.large}
        xlarge={size === GlobalsModalSize.xlarge}
        // @ts-ignore
        xxlarge={size === GlobalsModalSize.xxlarge}
        full={size === GlobalsModalSize.full}
        auto={size === GlobalsModalSize.auto}
        title={ui.type === GlobalsEnum.newNote ? 'New Note' : undefined}
        open={ui.global}
        close={() => ui.setGlobal(false, null, null)}
        overflow={allowContentScroll ? 'overflow-y-auto' : undefined}
      >
        {modal}
      </Modal>
    );
  } else return null;
});

export default Globals;
