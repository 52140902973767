import { useMutation, useQuery } from '@apollo/client';
import { Combobox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/nextjs';
import { format, getTime } from 'date-fns';
import { subDays } from 'date-fns';
import { cn } from 'lib/utils';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';

import Loader from 'components/atoms/loader';
import Editor from 'components/editor';

import {
  addTask,
  addTaskComment,
  getTaskById,
  getTaskComments,
  updateTaskById,
  updateTaskCommentById,
} from 'queries/tasks';

import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

// @ts-ignore
const TimeAgo = dynamic(() => import('react-timeago'), {
  ssr: false,
});

type Props = {
  taskID: string;
  close: () => void;
  refetchTasks?: () => void;
};

export default function TaskDetail({ taskID, close, refetchTasks }: Props) {
  const ui = useUI();

  const [query, setQuery] = useState('');
  const [saving, setSaving] = useState(false);
  const [newComment, setNewComment] = useState(false);

  const org = useOrganization();
  const session = useSession();

  const commentEditorRef = useRef(null);
  const [createTaskComment] = useMutation(addTaskComment);
  const [editTaskComment] = useMutation(updateTaskCommentById);
  const { data: singleTaskData, loading: loadingTask } = useQuery(getTaskById, {
    variables: {
      id: taskID,
    },
    fetchPolicy: 'network-only',
  });

  const { data: taskCommentsResponse } = useQuery(getTaskComments, {
    variables: {
      id: taskID,
    },
    fetchPolicy: 'cache-and-network',
  });

  let taskComments = taskCommentsResponse && taskCommentsResponse.task_comment;

  const [createTask] = useMutation(addTask);
  const [editTask] = useMutation(updateTaskById);

  const [error, setError] = useState(false);

  const textInputClass =
    'appearance-none block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light bg-slate-50 focus:bg-white focus:outline-none focus:ring-purple-500 focus:border-purple-500 disabled:opacity-50';

  const singleTask = singleTaskData && singleTaskData.task;

  let isOwned;

  if (singleTask && singleTask?.creator?.id) {
    isOwned = singleTask.creator.id === session.claims['x-hasura-user-id'];
  } else {
    isOwned = 'NEW';
  }

  // const isOwned =
  //   singleTask && singleTask.creator.id === session.claims["x-hasura-user-id"];

  const className =
    textInputClass +
    (error ? ' text-red-500 border-purple-500 font-medium' : '');

  const dueDate = new Date(singleTask && singleTask.due);
  const dueTime =
    singleTask && format(getTime(new Date(singleTask.due)), 'hh:mm');

  const priorityArray = [
    {
      value: 3,
      displayText: 'Low',
    },
    {
      value: 2,
      displayText: 'Medium',
    },
    {
      value: 1,
      displayText: 'High',
    },
  ];

  const typeArray = [
    { value: 'daily', displayText: 'Daily' },
    { value: 'weekly', displayText: 'Weekly' },
    { value: 'monthly', displayText: 'Monthly' },
  ];

  const statusArray = [
    { value: 'TODO', displayText: 'Todo' },
    { value: 'IN_PROGRESS', displayText: 'In Progress' },
    { value: 'IN_REVIEW', displayText: 'In Review' },
    { value: 'COMPLETE', displayText: 'Complete' },
  ];

  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    setValue: setValueA,
    reset: resetA,
    watch: watchA,
    control: controlA,
    getValues: getValuesA,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      dueDate: '',
      dueTime: '',
      member: '',
      data: null,
      status: 'TODO',
      priority: 3,
      title: '',
      content: '',
      type: '',
    },
  });

  const {
    register: registerB,
    handleSubmit: handleSubmitB,
    reset: resetB,
    setValue: setValueB,
    formState,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      comment: '',
    },
  });

  useEffect(() => {
    if (singleTask && dueDate) {
      setValueA('title', singleTask.title);
      setValueA('content', singleTask.content);

      setValueA('data', singleTask.data);
      setValueA('dueDate', format(dueDate, 'yyyy-MM-dd'));
      // setValueA('targetDate', format(singleTask?.targetDate, 'yyyy-MM-dd'));
      setValueA('dueTime', dueTime);
      setValueA('member', singleTask.assignee.id);
      // @ts-ignore
      setValueA('status', singleTask.status === 'COMPLETE' ? true : false);
      setValueA('priority', singleTask.priority);
    } else {
      setValueA('dueDate', format(new Date(), 'yyyy-MM-dd'));
    }
  }, [singleTask]);

  useEffect(() => {
    if (singleTask) {
      commentEditorRef?.current?.setData(
        singleTask?.data ?? singleTask?.content,
      );
    }
  }, [commentEditorRef, singleTask]);

  useEffect(() => {
    //@ts-ignore
    if (newComment && newComment.content && newComment.id) {
      //@ts-ignore
      setValueB('comment', newComment.content);
    } else {
      setValueB('comment', '');
    }
  }, [newComment]);

  const editComment = async (data, existing) => {
    // return;

    // Update existing comment
    if (existing && existing.content && existing.id) {
      // return
      if (!data.comment) return;
      try {
        setSaving(true);
        await editTaskComment({
          variables: {
            id: existing.id,
            data: {
              content: data.comment,
            },
          },
          refetchQueries: [
            {
              query: getTaskComments,
              variables: {
                id: taskID,
              },
            },
          ],
        });
        resetB();
        refetchTasks && refetchTasks();
        setSaving(false);
        setNewComment(false);
        ui.setToast(true, {
          type: 'success',
          title: 'Note updated',
        });
      } catch (err) {
        setSaving(false);
        ui.setToast(true, {
          type: 'error',
          title: 'Error updating comment',
        });
        Sentry.captureException(err);
      }
    } else {
      // Add new comment
      if (!data.comment) return;
      try {
        setSaving(true);

        const payload = {
          variables: {
            data: {
              task_id: taskID,
              content: data.comment,
            },
          },
          refetchQueries: [
            {
              query: getTaskComments,
              variables: {
                id: taskID,
              },
            },
          ],
        };

        await createTaskComment(payload);
        refetchTasks && refetchTasks();
        resetB();
        setSaving(false);
        setNewComment(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New comment added',
        });
      } catch (err) {
        setSaving(false);
        ui.setToast(true, {
          type: 'error',
          title: 'Error adding comment',
        });
        Sentry.captureException(err);
      }
    }
  };

  const submit = async (data) => {
    console.log(data, 'data');
    setSaving(true);
    try {
      if (singleTask) {
        try {
          await editTask({
            variables: {
              id: singleTask.id,
              data: {
                title: data.title,
                data: data.data,
                due: new Date(Date.parse(data.dueDate + 'T' + data.dueTime)),
                // targetDate: new Date(Date.parse(data.targetDate)),
                user_id: data.member,
                // status: data?.status,
                priority: data.priority,
                type: data?.type,
              },
            },
            refetchQueries: [
              {
                query: getTaskById,
                variables: {
                  id: taskID,
                },
              },
            ],
          });
          resetA();
          refetchTasks && refetchTasks();
          ui.setToast(true, {
            type: 'success',
            title: 'Task updated',
            message: 'Task has been updated successfully',
          });
        } catch (err) {
          console.log(err);
          ui.setToast(true, {
            type: 'error',
            title: 'Error updating task',
            message: err.message,
          });
        }
      } else {
        try {
          await createTask({
            variables: {
              data: {
                title: data.title,
                content: data.content,
                data: data.data,
                due: new Date(Date.parse(data.dueDate + 'T' + data.dueTime)),
                // targetDate: new Date(Date.parse(data.targetDate)),
                status: 'TODO',
                user_id: data.member,
                priority: data.priority,
                type: data?.type,
              },
            },
            refetchQueries: [
              {
                query: getTaskById,
                variables: {
                  id: taskID,
                },
              },
            ],
          });
          resetA();
          refetchTasks && refetchTasks();
          ui.setToast(true, {
            type: 'success',
            title: 'Task saved',
            message: 'Task has been saved successfully',
          });
        } catch (err) {
          ui.setToast(true, {
            type: 'error',
            title: 'Error saving task',
            message: err.message,
          });
        }
      }
    } catch (err) {
      ui.setToast(true, {
        type: 'error',
        title: 'Error saving task',
        message: err.message,
      });
    }
    setTimeout(() => {
      close();
    }, 500);

    setSaving(false);
  };

  const teamMembers = useMemo(() => {
    return org && org?.team && org.team.length > 0
      ? org.team
          .filter((x) => x.active)
          .map((member) => ({
            id: member.user?.id,
            given_name: member.user?.name,
            family_name: member.user?.surname,
            avatar: member.user?.avatar[0]?.asset?.url,
          }))
      : [];
  }, [org]);

  const filteredPeople = useMemo(() => {
    return query === ''
      ? teamMembers
      : teamMembers.filter(
          (person) =>
            person?.given_name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')) ||
            person?.family_name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
        );
  }, [query, teamMembers]);

  if (!singleTask && taskID) {
    return null;
  }

  const P = ({ node, isOwned, ...props }) => {
    return (
      <p
        className={`mb-2 ${isOwned ? 'pr-12' : ''} font-light leading-relaxed`}
        {...props}
      />
    );
  };

  return (
    <form onSubmit={handleSubmitA(submit)} className="flex flex-col flex-1">
      <div className="grid grid-cols-6 gap-12 flex-1">
        <div className="col-span-4 flex flex-col">
          <input
            type="text"
            disabled={isOwned !== 'NEW' && !isOwned}
            {...registerA('title', { required: true })}
            className={`font-semibold text-xl border-b border-gray-300 py-1 px-2 rounded bg-white focus:outline-none focus:ring-purple-500 focus:border-purple-500 w-full mt-1 disabled:opacity-50`}
            placeholder="Task title"
          />
          {singleTask?.creator && (
            <p className="text-xs text-light mb-4 text-gray-600 pl-1">
              Created by:{' '}
              {singleTask.creator.given_name +
                ' ' +
                singleTask.creator.family_name}
            </p>
          )}
          <div className="my-2 flex-1 flex flex-col">
            <p className="font-semibold mb-2 text-md">Description</p>
            {!loadingTask &&
            singleTask &&
            (singleTask.data ?? singleTask?.content) ? (
              <Editor
                ref={commentEditorRef}
                content={singleTask.data ?? singleTask?.content}
                taskId={taskID}
                onUpdate={(json) => setValueA('data', json)}
              />
            ) : (
              <Editor
                ref={commentEditorRef}
                taskId={taskID}
                onUpdate={(json) => setValueA('data', json)}
              />
            )}
          </div>
        </div>
        <div className="col-span-2 flex flex-col">
          <div>
            <span className="text-sm block mt-2">Priority:</span>
            <select
              disabled={isOwned !== 'NEW' && !isOwned}
              className={`text-sm cursor-pointer rounded-md border-gray-300 w-full bg-white disabled:opacity-50focus:ring-purple-500 sm:text-sm font-light focus:outline-none focus:ring-purple-500 focus:border-purple-500 disabled:opacity-50`}
              {...registerA('priority', { required: true })}
            >
              {priorityArray.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  {priority.displayText}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-2 space-x-4 mt-2">
            <label className="block">
              <span className="text-sm block mb-1">Due date:</span>
              <input
                disabled={isOwned !== 'NEW' && !isOwned}
                type="date"
                {...registerA('dueDate', { required: true })}
                name="dueDate"
                className={className}
              />
            </label>
            <label className="block">
              <span className="text-sm block mb-1">Due time:</span>
              <input
                disabled={isOwned !== 'NEW' && !isOwned}
                type="time"
                {...registerA('dueTime', { required: true })}
                name="dueTime"
                className={className}
              />
            </label>
          </div>

          <div className="mb-4 flex-1">
            <div className="grid grid-cols-1 mt-2">
              <div>
                <span className="text-sm block mb-1">Assigned to:</span>

                <Controller
                  control={controlA}
                  name="member"
                  render={({ field: { onChange, value } }) => {
                    const selectedMember =
                      teamMembers &&
                      teamMembers.length > 0 &&
                      teamMembers?.find((member) => member.id === value);
                    return (
                      <Combobox
                        value={selectedMember}
                        onChange={(member) => {
                          onChange(member);
                        }}
                      >
                        <div className="relative mt-1">
                          <div className="relative w-full cursor-default overflow-hidden rounded-md border border-gray-300 bg-white text-left shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="text-sm cursor-pointer rounded-md w-full bg-white  sm:text-sm font-light border-none focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100 disabled:opacity-50"
                              displayValue={(person: any) =>
                                person &&
                                `${person?.given_name} ${person?.family_name}`
                              }
                              onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 top-0 right-0 flex items-center pr-2">
                              <ChevronDownIcon
                                className="h-4 w-4 text-gray-600"
                                strokeWidth={2}
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                          >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full divide-y divide-gray-100 overflow-auto rounded-md bg-white z-[5] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {filteredPeople &&
                              filteredPeople?.length === 0 &&
                              query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                  Nothing found.
                                </div>
                              ) : (
                                filteredPeople &&
                                filteredPeople.map((person) => (
                                  <Combobox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-1.5 pl-4 pr-4 ${
                                        active
                                          ? 'bg-teal-600 text-white'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={person.id}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={cn(
                                            `truncate flex items-center gap-3`,
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal',
                                            active
                                              ? 'text-white'
                                              : 'text-gray-600',
                                          )}
                                        >
                                          {person?.avatar ? (
                                            <img
                                              src={person?.avatar}
                                              className="h-7 w-7 rounded-full"
                                              alt=""
                                            />
                                          ) : (
                                            <UserCircleIcon className="w-8 h-8" />
                                          )}
                                          {person.given_name}{' '}
                                          {person?.family_name}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 right-4  flex items-center pl-3 ${
                                              active
                                                ? 'text-white'
                                                : 'text-teal-600'
                                            }`}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button type="submit" disabled={saving} className="btn-primary">
              {singleTask ? 'Save changes' : 'Create new task'}
            </button>
          </div>
        </div>
      </div>
      {!isOwned && (
        <div className="grid items-center mt-4 mb-2">
          <p className="text-sm bg-white p-2 border border-gray-300">
            Please note that you cannot edit tasks created by others. You can
            only mark tasks as complete/incomplete.
          </p>
        </div>
      )}
    </form>
  );
}
