import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import Leads from 'pages/incoming/leads';
import { useState } from 'react';

import Status from 'components/atoms/status';

import { getAllLeadsById } from 'queries/leads';

const Modal = dynamic(() => import('components/molecules/modal'));

export const LeadsTab = ({ leadID }: { leadID: string[] }) => {
  const { data: leadData } = useQuery(getAllLeadsById, {
    variables: {
      id: leadID,
    },
  });

  const [state, setState] = useState({
    leadModal: null,
  });

  if (!leadData?.leads) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden divide divide-gray-300">
      {state.leadModal && (
        <Modal
          full
          open={!!state.leadModal}
          close={() => setState((s) => ({ ...s, leadModal: null }))}
        >
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <Leads leadID={state.leadModal} />
          </div>
        </Modal>
      )}

      {leadData?.leads.map((lead, index) => {
        return (
          <button
            onClick={() => setState({ ...state, leadModal: lead?.id })}
            className="sm:overflow-hidden w-full text-left hover:bg-gray-50 p-4"
            key={index}
          >
            <div className="space-y-2 max-w-lg mx-auto">
              <div className="flex justify-between">
                <div className="text-sm font-semibold">
                  {lead?.client?.name
                    ? `${lead?.client?.name} ${lead?.client?.surname}`
                    : lead?.client?.phone
                      ? lead?.client?.phone
                      : lead?.content?.name
                        ? `${lead?.content?.name} ${lead?.content?.surname}`
                        : lead?.createdAt}
                </div>
                <Status value={lead?.status} />
              </div>

              {lead?.metadata?.title && (
                <div className="pl-1">
                  <p className="text-xs capitalize">{lead?.metadata?.title}</p>
                  <p className="text-xs capitalize">{lead?.source}</p>
                </div>
              )}

              {lead?.metadata?.courses && lead.metadata.courses.length > 0 && (
                <div>
                  <div className="font-semibold text-sm">Courses</div>
                  <ul className="list-disc list-inside  marker:text-purple-500 pb-2 px-2 pt-1">
                    {lead?.metadata?.courses.map((course, index) => {
                      return (
                        <li className="text-xs" key={index}>
                          {course?.title ??
                            course?.selectedEvent?.detail?.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div className="text-xs text-gray-500">
                {lead?.createdAt &&
                  format(new Date(lead.createdAt), "do LLL y 'at' h:mm aaa")}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default LeadsTab;
