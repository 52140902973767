import DocViewer, { PDFRenderer } from '@cyntler/react-doc-viewer';
import { RiDownloadLine } from '@remixicon/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { ErrorState } from 'components/error';

const Loader = dynamic(() => import('components/atoms/loader'));

interface Props {
  receipt: {
    id: string;
    url: string;
    props: {
      name: string;
      type: 'application/pdf';
      lastModified: number;
    };
  };
}

export default function ViewReceipt({ receipt }: Props) {
  const [document, setDocument] = useState(null);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentError, setDocumentError] = useState(false);

  const url = receipt.url;

  useEffect(() => {
    async function fetchData() {
      const res = await genUploadLink(url);
    }
    if (url) {
      fetchData();
    }
  }, [url]);

  const genUploadLink = async (target: string) => {
    setDocumentError(false);
    setDocumentLoading(true);

    if (!target) {
      setDocumentError(true);
      return;
    }

    try {
      const part = target.split('/private')[1];
      const req = await fetch(`/api/upload-requests?key=${part}`);
      const { url } = await req.json();

      setDocument(url);
      setDocumentLoading(false);
    } catch (err) {
      console.log(err);
      setDocumentError(true);
      setDocumentLoading(false);
    }
  };

  if (documentLoading) {
    return (
      <div className="flex h-full w-full items-center my-auto justify-center">
        <Loader stroke="#0c9498" width={100} height={100} />
      </div>
    );
  }

  return (
    <>
      {documentError && (
        <ErrorState
          onClick={() => {
            setDocumentLoading(false);
            setDocumentError(false);
            setDocument(null);
          }}
        />
      )}
      {document ? (
        <DocViewer
          documents={[
            {
              uri: document,
              fileName: receipt?.props?.name,
              fileType: 'pdf',
            },
          ]}
          style={{ border: 0 }}
          config={{
            noRenderer: {
              overrideComponent: () => (
                <div>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={document}
                    className="btn-primary w-8 h-8 p-0 flex justify-center items-center ml-4 mb-4"
                  >
                    <RiDownloadLine className="w-5 h-5" />
                  </Link>
                  <iframe
                    src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(document)}`}
                    style={{ border: 0 }}
                    onLoad={() => {
                      setDocumentLoading(false);
                      setDocumentError(false);
                    }}
                    onLoadedData={() => {
                      setDocumentLoading(false);
                      setDocumentError(false);
                    }}
                    onError={() => {
                      setDocumentError(false);
                    }}
                    width="100%"
                    height="650"
                    frameBorder="0"
                  />
                </div>
              ),
            },
          }}
          pluginRenderers={[PDFRenderer]}
        />
      ) : null}
    </>
  );
}
