import {
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { AvailibilityRecord, CalendarAppointment } from 'interfaces';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface SubscriptionContextValue {
  appointments: CalendarAppointment[];
  availability: AvailibilityRecord[];
  period: Period;
  appointmentLoading: boolean;
  availibiltyLoading: boolean;
  setPeriod: Dispatch<SetStateAction<Period>>;
  setup: (
    date: any,
    apply?: any,
  ) => void | {
    start: Date;
    end: Date;
    calendarDates: {
      textDayOfWeek: string;
      numDayOfMonth: string;
      isToday: boolean;
    }[];
    current?: Date;
    resourceId?: string | null;
    mode?: 'week' | 'day';
  };
}

const initialState = {};

const SubscriptionContext = createContext(
  initialState as SubscriptionContextValue,
);

type Period = {
  start: Date;
  current?: Date;
  resourceId?: string | null;
  mode?: 'week' | 'day';
  end: Date;
  // targets?: string[];
};

const start = startOfWeek(new Date(), { weekStartsOn: 1 });
const end = endOfWeek(new Date(), { weekStartsOn: 1 });

export function SP({ children }: { children: React.ReactNode }) {
  const [period, setPeriod] = useState<Period>({
    mode: 'week',
    start,
    end,
    // @ts-ignore
    calendarDates: eachDayOfInterval({
      start,
      end,
    }).map((x) => {
      return {
        date: x,
        textDayOfWeek: format(x, 'E'),
        numDayOfMonth: format(x, 'd'),
        isToday: isSameDay(x, new Date()),
      };
    }),
    current: startOfDay(new Date()),
    resourceId: '2b16948f-2534-4e60-9b40-1e5dcf921a9c',
    // targets: team && team.length > 0 ? team.map((x) => x.user.id) : [],
  });

  // useEffect(() => {
  //   if (period.targets.length === 0) {
  //     setPeriod({
  //       ...period,
  //       targets: team && team.length > 0 ? team.map((x) => x.user.id) : [],
  //     });
  //   }
  // }, [team]);

  useEffect(() => {
    // if (period.targets.length === 0) {
    setPeriod({
      ...period,
      // targets: team && team.length > 0 ? team.map((x) => x.user.id) : [],
    });
    // }
  }, []);

  // const {
  //   error: availibiltyError,
  //   data: availibiltyData,
  //   loading: availibiltyLoading,
  // } = uS(getAvailibility, {
  //   variables: {
  //     start: period.start,
  //     end: period.end,
  //     // employees: period.targets,
  //   },
  //   fetchPolicy: 'cache-first',
  //   onSubscriptionData: (e) => {
  //     // console.log("availibiltyData onSubscriptionData", e);
  //   },
  // });

  const setup = useCallback(
    function (date, apply = false) {
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });
      if (apply) {
        return setPeriod({
          ...period,
          start,
          end,
          current: date,
          // @ts-ignore
          calendarDates: eachDayOfInterval({
            start,
            end,
          }).map((x) => {
            return {
              date: x,
              textDayOfWeek: format(x, 'E'),
              numDayOfMonth: format(x, 'd'),
              isToday: isSameDay(x, new Date()),
            };
          }),
        });
      }

      return {
        start,
        end,
        calendarDates: eachDayOfInterval({
          start,
          end,
        }).map((x) => {
          return {
            date: x,
            textDayOfWeek: format(x, 'E'),
            numDayOfMonth: format(x, 'd'),
            isToday: isSameDay(x, new Date()),
          };
        }),
      };
    },
    [period],
  );

  const value = {
    setup,
    // appointments,
    // availability: availibiltyData
    //   ? availibiltyData.availability.map((x) => ({
    //       ...x,
    //       startDate: new Date(x.date),
    //     }))
    //   : [],
    period,
    setPeriod,
    // appointmentLoading,
    // availibiltyLoading,
  };

  return (
    // @ts-ignore
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

const SubscriptionProvider = SP;

export const useSubscription = () => useContext(SubscriptionContext);

export default SubscriptionProvider;
