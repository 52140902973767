import { gql } from '@apollo/client';

const coreClient = gql`
  fragment CoreClient on user {
    id
    cognito_id
    title
    name: given_name
    surname: family_name
    email
    gender
    birthday
    fullName: full_name
    date_of_birth
    phoneNumber: contact_number
    points
    organization
    account: stripe_id
    eLearningId: elearning_id
    createdAt: created_at
    updatedAt: updated_at
    code
    signed_in
    onboarded
    onboardCode: onboard_code
    set_password
  }
`;

export const createClient = gql`
  ${coreClient}
  mutation CreateClient($data: user_insert_input!) {
    client: insert_user_one(object: $data) {
      ...CoreClient
    }
  }
`;

export const getClients = gql`
  ${coreClient}
  query Clients {
    clients: user(order_by: { given_name: asc }) {
      ...CoreClient
      model_interest {
        id
      }
      labels: user_labels(order_by: { created_at: desc }, limit: 2) {
        id
        description
        color
        label {
          id
          name
        }
      }
    }
  }
`;

export const getEnrollmentClients = gql`
  query Clients {
    clients: user(order_by: { given_name: asc }) {
      id
      name: given_name
      surname: family_name
      email
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getClient = gql`
  ${coreClient}
  query Client($id: uuid!) {
    client: user(where: { id: { _eq: $id } }, limit: 1) {
      ...CoreClient
      address
      identity_confirmed
      user: user_notes {
        data: noteByNote {
          created_at
          content
          user
          title
          updated_at
          id
        }
        note
        id
        created_at
        user
        updated_at
      }
      registrations {
        created_at
        event_id
        id
        session_id
        status
        type
        updated_at
        user_id
        package {
          id
          title
        }
        payments {
          id
          data: payment {
            id
            amount
            status
            transactionId: transaction_id
            createdAt: created_at
          }
        }
        user {
          family_name
          given_name
          contact_number
        }
        event {
          detail {
            title
          }
          start
          end
          seat_price
          seats
          status
          created_at
        }
      }
      referralLink: referral_links {
        id
        link
        clicks
        credits
        enrollments {
          id
        }
      }
      user_forms {
        formByForm {
          id
          title
        }
      }
    }
  }
`;

export const getAllClientsById = gql`
  ${coreClient}
  query Client($id: [uuid!]!) {
    client: user(where: { id: { _in: $id } }) {
      ...CoreClient
      user: user_notes {
        data: noteByNote {
          created_at
          content
          user
          title
          updated_at
          id
        }
        note
        id
        created_at
        user
        updated_at
      }
      registrations {
        created_at
        event_id
        id
        session_id
        status
        type
        updated_at
        user_id
        package {
          id
          title
        }
        payments {
          id
          data: payment {
            id
            amount
            status
            transactionId: transaction_id
            createdAt: created_at
          }
        }
        user {
          family_name
          given_name
          contact_number
        }
        event {
          detail {
            title
          }
          start
          end
          seat_price
          seats
          status
          created_at
        }
      }
      user_forms {
        formByForm {
          id
          title
        }
      }
    }
  }
`;

export const getClientDocuments = gql`
  query getStudentDocuments($userId: uuid!) {
    client: user(where: { id: { _eq: $userId } }, limit: 1) {
      upload_requests(order_by: { created_at: desc }) {
        course
        user
        id
        updated_at
        complete
        client_complete
        url
        verification_id
        completed_at
        verification_data
        uploadRequest {
          id
          title
          description
          updated_at
        }
        event {
          id
          detail {
            title
          }
        }
        assets {
          id
          created_at
          updated_at
          user_upload_id
          url
        }
      }
      user_forms(order_by: { created_at: desc }) {
        id
        createdAt: created_at
        updatedAt: updated_at
        course
        user
        form_id: form
        updated_at
        responsesByResponses {
          id
          asset
          complete
          client_complete
          responses
        }
        form: formByForm {
          id
          title
          content
          formQuestions(
            where: {
              _and: [
                { archived: { _eq: false } }
                { questionByQuestion: { archived: { _eq: false } } }
              ]
            }
            order_by: { order: asc }
          ) {
            id
            order
            properties
            question: questionByQuestion {
              id
              label
              archived
              public
              type
              properties
            }
          }
        }
        formByForm {
          id
          archived
          title
          updatedAt
        }
        eventByEvent {
          id
          detail {
            title
          }
        }
      }
    }
  }
`;

export const mergeAccounts = gql`
  mutation mergeAccounts($primaryUserId: uuid!, $secondaryUserIds: [uuid!]) {
    mergeAccount(
      data: {
        primaryUserId: $primaryUserId
        secondaryUserIds: $secondaryUserIds
      }
    ) {
      userId
    }
  }
`;

export const getClientPayments = gql`
  query getClientPayments($userId: uuid!) {
    payment(where: { user: { _eq: $userId } }, order_by: { created_at: desc }) {
      amount
      id
      created_at
      employee
      invoice_id
      method
      status
      transaction_id
      enrollment_id
      modelSessionPayment: model_session_payment {
        id
        model_session_purchase {
          id
        }
      }
      employee
      user
      updated_at
      refunds {
        amount
        created_at
        refund_id
        id
        explanation
      }
    }
  }
`;

export const getClientInvoices = gql`
  query getClientInvoices($userId: uuid!) {
    invoice(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      amount
      due_date
      invoice_id
      status
      user_id
      refunds {
        amount
        created_at
        refund_id
        id
        explanation
      }
      payment {
        id
        enrollment_id
      }
    }
  }
`;

export const getClientNotes = gql`
  query getClientNotes($userId: uuid!) {
    user_note(
      where: { user: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      note: noteByNote {
        id
        title
        content
        course
        employee {
          given_name
          family_name
          id
          user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
            asset: assetByAsset {
              id
              type
              url
            }
          }
        }
        created_at
        __typename
      }
    }
  }
`;

export const getClientLeads = gql`
  query getClientLeads($userId: uuid!) {
    lead(where: { user: { _eq: $userId } }) {
      id
      created_at
      updated_at
      metadata
      user
      content
      source
      status
      notes(order_by: { created_at: desc }) {
        id
        created_at
        note: noteByNote {
          id
          title
          content
          course
          user
          employee {
            given_name
            family_name
            id
            user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
              asset: assetByAsset {
                id
                type
                url
              }
            }
          }
          created_at
          __typename
        }
      }
      contacts {
        id
      }
    }
  }
`;

export const getLeadNote = gql`
  query getLeadNote($id: String!) {
    lead_note(id: $id) {
      id
      created_at
      updated_at
      lead
      note
      note: noteByNote(order_by: { created_at: desc }) {
        id
        created_at
        updated_at
        title
        content
        user
      }
    }
  }
`;

export const getClientCalls = gql`
  query getClientCalls($userId: uuid!) {
    call(where: { user_id: { _eq: $userId } }, order_by: { created_at: desc }) {
      id
      created_at
      updated_at
      employee {
        name: given_name
      }
      type
      payload
      user_id
      status
      due
      notes(order_by: { noteByNote: { created_at: desc } }) {
        id
        created_at
        note: noteByNote {
          id
          created_at
          updated_at
          title
          content
          user
          employee {
            given_name
            family_name
            id
            user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
              asset: assetByAsset {
                id
                type
                url
              }
            }
          }
        }
      }
      contacts {
        id
      }
    }
  }
`;

export const getEmailsByUser = gql`
  query getEmailsByUser($userId: uuid!) {
    emails: email(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      updated_at
      user_id
      employee_id
      status
      subject
      message
      employee {
        full_name
        email
      }
      user {
        full_name
        email
      }
    }
  }
`;

export const getClientEmails = gql`
  query getClientEmails($userId: uuid!) {
    emails: follow_up_email(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      updated_at
      user_id
      tutor_id
      status
      subject
      message
      tutorByTutorId {
        full_name
        email
      }
      userByUserId {
        full_name
        email
      }
    }
  }
`;

export const getClientEngagement = gql`
  query getUserEngagement($userId: uuid!) {
    engagements: engagement_user(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      id
      engagement {
        content
        type
        status
        created_at
      }
    }
  }
`;

export const clientAppointments = gql`
  query ClientAppointments($id: uuid!) {
    appointments: appointment(
      where: {
        _and: [
          { user: { _eq: $id } }
          { status: { _neq: deleted } }
          { status: { _neq: pending } }
        ]
      }
      order_by: { updated_at: desc }
    ) {
      id
      appointmentId: id
      status: appointmentStatus {
        value: status
      }
      services: entities(
        where: { appointment_status: { status: { _neq: "DELETED" } } }
        order_by: { start: asc }
      ) {
        id
        start
        end
        employee
        who
        service: serviceByService {
          id
          title
        }
        option: pricing {
          id
          label
          price
          attributes
          type
          discount
        }
        subOption: pricingByPricingSubOption {
          id
          label
          price
          attributes
          type
          discount
        }
        status: appointment_status {
          value: status
        }
        createdAt: created_at
        updatedAt: updated_at
      }
      notes: appointment_notes(order_by: { noteByNote: { updated_at: desc } }) {
        note: noteByNote {
          id
          title
          content
          createdAt: created_at
          updatedAt: updated_at
        }
      }
      payments {
        data: paymentByPayment {
          id
          transaction_id
          discount
          amount
          createdAt: created_at
        }
      }
      createdAt: created_at
      updatedAt: updated_at
      metadata
    }
  }
`;

export const clientAssets = gql`
  query ClientAssets($id: uuid!) {
    assets: user_asset(
      where: { user: { _eq: $id } }
      order_by: { assetByAsset: { updatedAt: desc } }
    ) {
      id
      asset: assetByAsset {
        id
        type: assetType {
          value
        }
        url
        createdAt
        updatedAt
      }
      user: userByUser {
        id
        name: given_name
        surname: family_name
      }
    }
  }
`;

export const clientAddresses = gql`
  query ClientAddresses($id: uuid!) {
    addresses: user_address(
      where: { user: { _eq: $id } }
      order_by: { addressByAddress: { updatedAt: desc } }
    ) {
      address: addressByAddress {
        id
        data
        createdAt
        updatedAt
      }
    }
  }
`;

export const clientStats = gql`
  query ClientStats($id: uuid!) {
    appointments: appointment_entity_aggregate(
      where: { appointmentByAppointment: { user: { _eq: $id } } }
    ) {
      data: aggregate {
        total: count
      }
    }
    payments: payment_aggregate(where: { user: { _eq: $id } }) {
      data: aggregate {
        total: sum {
          amount
        }
      }
    }
    outstanding: appointment_entity(
      where: { appointmentByAppointment: { user: { _eq: $id } } }
    ) {
      pricing {
        price
      }
      pricingByPricingSubOption {
        price
      }
    }
  }
`;

export const insertClientAsset = gql`
  mutation InsertClientAsset($data: user_asset_insert_input!) {
    insert_user_asset_one(object: $data) {
      id
    }
  }
`;

export const updateUserProperty = gql`
  ${coreClient}
  mutation UpdateUserProperty($id: uuid!, $user: user_set_input!) {
    update_user(where: { id: { _eq: $id } }, _set: $user) {
      affected_rows
      returning {
        ...CoreClient
      }
    }
  }
`;

export const findClient = gql`
  query FindClient($term: String!, $limit: Int) {
    clients: user(
      limit: $limit
      where: {
        _or: [
          { given_name: { _ilike: $term } }
          { family_name: { _ilike: $term } }
          { email: { _ilike: $term } }
        ]
      }
    ) {
      id
      name: given_name
      surname: family_name
      fullName: full_name
      email
      phoneNumber: contact_number
      account: stripe_id
    }
  }
`;

export const getClientData = gql`
  query ClientPayments($id: uuid!) {
    payments: payment_aggregate(
      where: { _and: [{ user: { _eq: $id } }, { status: { _eq: "complete" } }] }
    ) {
      aggregate {
        count(columns: amount)
        sum {
          amount
        }
      }
    }
  }
`;

export const clientNotes = gql`
  query ClientNotes($id: uuid!) {
    user: user_by_pk(id: $id) {
      id
      appointments {
        id
        notes: appointment_notes(
          order_by: { noteByNote: { updated_at: desc } }
        ) {
          id
          appointment: appointmentByAppointment {
            id
            entities {
              start
              service: serviceByService {
                title
              }
            }
          }
          note: noteByNote {
            id
            title
            content
            user
            updatedAt: updated_at
          }
        }
      }
      general: user_notes(order_by: { noteByNote: { updated_at: desc } }) {
        id
        note: noteByNote {
          id
          title
          content
          user
          updatedAt: updated_at
        }
      }
    }
  }
`;

export const createNote = gql`
  mutation AddNote($data: user_note_insert_input!) {
    insert_user_note_one(object: $data) {
      id
      note: noteByNote {
        id
        title
        content
        user
        updatedAt: updated_at
      }
    }
  }
`;

export const deleteNote = gql`
  mutation deleteNote($id: uuid!) {
    delete_note_by_pk(id: $id) {
      id
    }
  }
`;

export const updateNote = gql`
  mutation UpdateNote(
    $id: uuid!
    $course: uuid
    $title: String
    $content: String!
  ) {
    update_note_by_pk(
      pk_columns: { id: $id }
      _set: { content: $content, title: $title, course: $course }
    ) {
      id
      updatedAt: updated_at
    }
  }
`;

export const changeNote = gql`
  mutation changeNote($id: uuid!, $content: String!) {
    update_note_by_pk(pk_columns: { id: $id }, _set: { content: $content }) {
      id
    }
  }
`;

export const addClientPhoto = gql`
  mutation AddClientPhoto($data: asset_insert_input!) {
    insert_asset_one(object: $data) {
      id
    }
  }
`;

export const addPlatformAsset = gql`
  mutation addPlatformAsset($data: platform_asset_insert_input!) {
    insert_platform_asset_one(object: $data) {
      id
      url
      props
    }
  }
`;

export const updateClientPhoneNumber = gql`
  ${coreClient}
  mutation UpdateClientPhoneNumber($id: uuid!, $phoneNumber: String!) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { contact_number: $phoneNumber }
    ) {
      ...CoreClient
    }
  }
`;

export const updateClientDetails = gql`
  ${coreClient}
  mutation UpdateClientDetails(
    $id: uuid!
    $given_name: String
    $family_name: String
    $phoneNumber: String
    $email: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        given_name: $given_name
        family_name: $family_name
        contact_number: $phoneNumber
        email: $email
      }
    ) {
      ...CoreClient
    }
  }
`;

export const clientByAuthId = gql`
  ${coreClient}
  query client($id: String!) {
    user(where: { cognito_id: { _eq: $id } }) {
      ...CoreClient
    }
  }
`;

export const getTopClients = gql`
  query getTopClients {
    user(
      limit: 100
      order_by: { payments_aggregate: { sum: { amount: desc_nulls_last } } }
    ) {
      appointments_aggregate {
        aggregate {
          numberOfBookings: count
        }
      }
      id
      fullName: full_name
      email
      createdAt: created_at
      payments(limit: 1, order_by: { created_at: desc }) {
        id
        amount
        discount
        created_at
        appointment_payments {
          appointmentByAppointment {
            id
            created_at
          }
        }
      }
      payments_aggregate {
        aggregate {
          sum {
            totalSpent: amount
            totalDiscount: discount
          }
        }
      }
    }
  }
`;

export const insertUserForm = gql`
  mutation insertUserForm($data: user_form_insert_input!) {
    insert_user_form_one(object: $data) {
      course
      user
      form
      member
      id
      updated_at
      formByForm {
        id
        archived
        title
        updatedAt
      }
    }
  }
`;

export const insertUserForms = gql`
  mutation insertUserForms($data: [user_form_insert_input!]!) {
    insert_user_form(objects: $data) {
      returning {
        id
      }
    }
  }
`;

export const insertUserUploadRequest = gql`
  mutation insertUserUploadRequest($data: user_upload_request_insert_input!) {
    insert_user_upload_request_one(object: $data) {
      course
      user
      upload_request
      member
      id
      updated_at
      uploadRequest {
        id
        title
        updated_at
        description
      }
    }
  }
`;

export const completeRequest = gql`
  mutation completeRequest($id: uuid!, $complete: Boolean!) {
    update_user_upload_request_by_pk(
      pk_columns: { id: $id }
      _set: { complete: $complete }
    ) {
      complete
      id
    }
  }
`;
export const completeDocument = gql`
  mutation completeDocument($id: uuid!, $complete: Boolean!) {
    update_form_response_by_pk(
      pk_columns: { id: $id }
      _set: { complete: $complete }
    ) {
      complete
      id
    }
  }
`;

export const createUploadRequestDoc = gql`
  mutation createUploadRequestDoc($data: upload_request_insert_input!) {
    insert_upload_request_one(object: $data) {
      id
      title
      description
    }
  }
`;

export const insertResponses = gql`
  mutation insertResponses($data: form_response_insert_input!) {
    insert_form_response_one(object: $data) {
      id
    }
  }
`;

export const clientLabelsById = gql`
  query ClientLabels($id: uuid!) {
    user: user_by_pk(id: $id) {
      id
      labels: user_labels {
        id
        description
        color
        label {
          id
          name
        }
      }
    }
  }
`;

export const getAllClientLabels = gql`
  query GetAllLabels {
    labels {
      id
      created_at
      updated_at
      name
    }
  }
`;

export const insertClientLabel = gql`
  mutation InsertUserLabel($data: user_labels_insert_input!) {
    insert_user_labels_one(object: $data) {
      id
      created_at
      updated_at
    }
  }
`;

export const removeClientLabel = gql`
  mutation RemoveUserLabel($labelId: uuid!, $userId: uuid!) {
    delete_user_labels(
      where: {
        label_id: { _eq: $labelId }
        _and: { user_id: { _eq: $userId } }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const createClientLabel = gql`
  mutation CreateLabel($name: String!) {
    insert_labels_one(object: { name: $name }) {
      id
      created_at
      updated_at
      name
    }
  }
`;

export const updateClientLabel = gql`
  mutation UpdateLabel($id: uuid!, $data: user_labels_set_input!) {
    update_user_labels_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getLabelsByName = gql`
  query GetLabelByName($name: String!) {
    labels(where: { name: { _eq: $name } }) {
      id
      name
    }
  }
`;
