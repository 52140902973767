export default function Status({ value, small = false, type = '' }) {
  const cls = small ? 'font-normal' : 'font-semibold';

  return (
    <>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-turkey-600 text-white
          ${value === 'NEW' ? '' : 'hidden'}
        `}
      >
        New
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-red-600 text-white
          ${value === 'EXPIRED' ? '' : 'hidden'}
        `}
      >
        Expired
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-turkey-600 text-white
          ${value === 'REQUESTED' ? '' : 'hidden'}
        `}
      >
        Requested
      </p>
      <p
        className={`
          text-xxs xl:text-xs px-2 inline-flex items-center justify-center leading-5
          ${cls} rounded-full bg-yellow-100 text-yellow-600
          ${value === 'DRAFT' ? '' : 'hidden'}
        `}
      >
        Draft
      </p>
      <p
        className={`
          text-xxs xl:text-xs px-2 inline-flex items-center justify-center leading-5
          ${cls} rounded-full bg-yellow-100 text-yellow-600
          ${value === 'TENTATIVE' ? '' : 'hidden'}
        `}
      >
        Tentative
      </p>
      <p
        className={`
          text-xxs xl:text-xs px-2 inline-flex items-center justify-center leading-5
          ${cls} rounded-full bg-green-100 text-green-800
          ${value === 'CONFIRMED' ? '' : 'hidden'}
        `}
      >
        Confirmed
      </p>
      <p
        className={`
          text-xxs xl:text-xs px-2 inline-flex items-center justify-center leading-5
          ${cls} rounded-full bg-green-100 text-green-800
          ${value === 'ORDERED' ? '' : 'hidden'}
        `}
      >
        Ordered
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-chalk-700 text-white
          ${value === 'IN_PROGRESS' ? '' : 'hidden'}
        `}
      >
        In Progress
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-turkey-500 text-white
          ${value === 'COURSE_SESSION' ? '' : 'hidden'}
        `}
      >
        Course Session
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-gray-500 text-white
          ${value === 'PENDING' || value === 'pending' ? '' : 'hidden'}
        `}
      >
        Pending
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-green-500 text-white
          ${value === 'COMPLETE' ? '' : 'hidden'}
        `}
      >
        Complete
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-green-500 text-white
          ${value === 'COMPLETED' ? '' : 'hidden'}
        `}
      >
        Completed
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-red-500 text-white
          ${value === 'INCOMPLETED' ? '' : 'hidden'}
        `}
      >
        Incomplete
      </p>

      <p
        style={{ backgroundColor: 'rgb(0 185 255)' }}
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full text-white
          ${value === 'COURSE' ? '' : 'hidden'}
        `}
      >
        Course
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-red-600 text-white
          ${value === 'CANCELLED' ? '' : 'hidden'}
        `}
      >
        Cancelled
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-seashell-600 text-white
          ${value === 'REFUNDED' ? '' : 'hidden'}
        `}
      >
        Refunded
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-red-600 text-white
          ${value === 'NO_SHOW' ? '' : 'hidden'}
        `}
      >
        No Show
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-red-600 text-white
          ${value === 'OVERDUE' ? '' : 'hidden'}
        `}
      >
        Overdue
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-green-600 text-white
          ${value === 'ACTIVE' ? '' : 'hidden'}
        `}
      >
        Active
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-red-600 text-white
          ${value === 'BUSY' ? '' : 'hidden'}
        `}
      >
        Busy
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-pink-600 text-white
          ${value === 'NO' ? '' : 'hidden'}
        `}
      >
        No
      </p>

      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-blue-600 text-white
          ${value === 'CONTACTED' ? '' : 'hidden'}
        `}
      >
        Contacted
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'CONVERTED' ? '' : 'hidden'
        }`}
      >
        Booked
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-turkey-600 text-white
          ${value === 'OPEN' ? '' : 'hidden'}
        `}
      >
        Open
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'CLOSED' ? '' : 'hidden'
        }`}
      >
        Closed
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'BOOKED' ? '' : 'hidden'
        }`}
      >
        Booked
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-blue-500 text-white ${
          value === 'Yes' ? '' : 'hidden'
        }`}
      >
        Yes
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-blue-500 text-white ${
          value === 'ONLINE ONLY' ? '' : 'hidden'
        }`}
      >
        Online Only
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-orange-500 text-white ${
          value === 'No' ? '' : 'hidden'
        }`}
      >
        No
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === true ? '' : 'hidden'
        }`}
      >
        Active
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'TRUE' ? '' : 'hidden'
        }`}
      >
        True
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-red-500 text-white ${
          value === 'FALSE' ? '' : 'hidden'
        }`}
      >
        False
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-red-500 text-white ${
          value === false ? '' : 'hidden'
        }`}
      >
        Inactive
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-amber-500 text-white ${
          value === 'DEPOSIT' ? '' : 'hidden'
        }`}
      >
        Deposit
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-red-500 text-white ${
          value === 'FAILED' ? '' : 'hidden'
        }`}
      >
        Failed
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-red-500 text-white ${
          value === 'High Priority' ? '' : 'hidden'
        }`}
      >
        High Priority
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-orange-500 text-white ${
          value === 'Medium Priority' ? '' : 'hidden'
        }`}
      >
        Medium Priority
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-orange-300 text-white ${
          value === 'Low Priority' ? '' : 'hidden'
        }`}
      >
        Low Priority
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'Completed' ? '' : 'hidden'
        }`}
      >
        Completed
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-red-500 text-white ${
          value === 'Overdue' ? '' : 'hidden'
        }`}
      >
        Overdue
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'complete' ? '' : 'hidden'
        }`}
      >
        Complete
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-5 ${cls} rounded-full bg-green-500 text-white ${
          value === 'PAID' ? '' : 'hidden'
        }`}
      >
        PAID
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-gray-500 text-white
          ${value === 'MODEL' ? '' : 'hidden'}
        `}
      >
        Model
      </p>
      <p
        className={`
          px-2 inline-flex text-xs items-center justify-center leading-5
          ${cls} rounded-full bg-gray-500 text-white
          ${value === 'STUDENT' ? '' : 'hidden'}
        `}
      >
        Student
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-2 ${cls} rounded-full bg-yellow-500 text-white ${
          value === 'AWAITING RESPONSE' ? '' : 'hidden'
        }`}
      >
        Awaiting Response
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-2 ${cls} rounded-full bg-orange-500 text-white ${
          value === 'REQUIRES INPUT' ? '' : 'hidden'
        }`}
      >
        Requires Input
      </p>
      <p
        className={`px-2 inline-flex text-xs items-center justify-center leading-2 ${cls} rounded-full bg-green-500 text-white ${
          value === 'RESPONSE SENT' ? '' : 'hidden'
        }`}
      >
        Response Sent
      </p>
    </>
  );
}
