import { Combobox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import * as F from 'fuse.js';
import { UserProfile } from 'interfaces';
import { Fragment, useEffect, useRef, useState } from 'react';

import classNames from 'utils/classnames';

import { useBase } from 'providers/base';

type ClientSearchProps = {
  removeClient: () => void;
  selectedClients: UserProfile[];
  setSelectedClients: (clients: UserProfile[]) => void;
  disabled?: boolean;
};

export default function ClientSearch({
  removeClient,
  selectedClients,
  setSelectedClients,
  disabled = false,
}: ClientSearchProps) {
  const { clients } = useBase();
  const searchKeysArray = ['name', 'surname', 'email', 'phone'];
  const [query, setQuery] = useState('');
  const [result, setResult] = useState<UserProfile[]>(null);
  useEffect(() => {
    fuse.current = new F.default(clients, {
      includeMatches: true,
      minMatchCharLength: 1,
      threshold: 0.2,
      keys: searchKeysArray,
    });
  }, [clients]);

  const fuse = useRef(
    new F.default(clients, {
      includeMatches: true,
      minMatchCharLength: 3,
      threshold: 0.2,
      keys: searchKeysArray,
    }),
  );

  useEffect(() => {
    if (query && query.length > 1) {
      const res = fuse.current.search(query);
      const results = res.map((item) => {
        return item.item;
      });
      setResult(results);
    } else {
      setResult(null);
    }
  }, [query, clients]);

  return (
    <div className="relative mt-1 w-full">
      <div className="relative w-full">
        <Combobox.Input
          className={classNames(
            selectedClients &&
              selectedClients.length > 0 &&
              !disabled &&
              'pt-10',
            'relative w-full py-2 pl-2 pr-10 text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 text-sm',
          )}
          placeholder="Choose client"
          autoComplete="off"
          onChange={(event) => {
            setQuery(event.target.value);
            removeClient();
          }}
        />
        {selectedClients && selectedClients.length > 0 && (
          <div className="flex flex-row items-center space-x-2 overflow-x-scroll scrollbar-hide absolute top-2 left-2 w-[93%]">
            {selectedClients.map((client) => {
              return (
                <div
                  className="flex flex-row items-center space-x-1 bg-gray-100 rounded-full w-fit p-1 px-2"
                  key={client.id}
                >
                  <p className="text-gray-800 text-xs font-medium whitespace-nowrap">
                    {client.name} {client.surname}
                  </p>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedClients(
                        selectedClients.filter((c) => c.id !== client.id),
                      );
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 hover:text-blue-600" />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!disabled && (
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="w-4 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        )}
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Combobox.Options className="z-40 absolute w-full py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-xxs xl:text-xs">
          {!result || result.length === 0 ? (
            <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
              Nothing found.
            </div>
          ) : (
            result &&
            result.length > 0 &&
            result.map((person) => (
              <Combobox.Option
                key={person.id}
                className={({ active }) =>
                  `cursor-default select-none relative py-2 pl-7 pr-4 ${
                    active ? 'text-white bg-purple-600' : 'text-gray-900'
                  }`
                }
                value={person}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block capitalize truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {person.name} {person.surname}
                      <span className="lowercase"> ({person.email})</span>
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 -left-1 flex items-center pl-3 ${
                          active ? 'text-white' : 'text-purple-600'
                        }`}
                      >
                        <CheckIcon className="w-4 h-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Transition>
    </div>
  );
}
