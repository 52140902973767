import { useMutation } from '@apollo/client';
import { Combobox } from '@headlessui/react';
import { AtSymbolIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { endOfDay, startOfDay, startOfMonth, subMonths } from 'date-fns';
import * as F from 'fuse.js';
import { UserProfile } from 'interfaces';
import { debounce } from 'lodash';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import classNames from 'utils/classnames';

import {
  addNewComplaint,
  getAllComplaints,
  getClientComplaints,
} from 'queries/complaints';

import { useBase } from 'providers/base';
import { useOrganization } from 'providers/organization';
import { useUI } from 'providers/ui';

const Avatar = dynamic(() => import('components/atoms/avatar'));

export const memberSearchOptions = {
  includeMatches: true,
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.3,
  keys: [
    {
      name: 'user.name',
      weight: 3,
    },
    {
      name: 'user.surname',
      weight: 2,
    },
    {
      name: 'user.email',
      weight: 1,
    },
  ],
};

export const searchOptions = {
  includeMatches: true,
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.3,
  keys: [
    {
      name: 'name',
      weight: 3,
    },
    {
      name: 'surname',
      weight: 1,
    },
    {
      name: 'email',
      weight: 2,
    },
    {
      name: 'phoneNumber',
      weight: 1,
    },
  ],
};

const AddComplaint = ({
  closeModal,
  dateState,
  client,
  callData,
}: {
  closeModal: () => void;
  dateState: any;
  client?: UserProfile;
  callData?: any;
}) => {
  const { clients } = useBase();
  const { team } = useOrganization();
  const ui = useUI();

  const cFuse = useRef(new F.default(clients, searchOptions));
  const mFuse = useRef(new F.default(team, memberSearchOptions));

  useEffect(() => {
    cFuse.current = new F.default(clients, searchOptions);
    mFuse.current = new F.default(team, memberSearchOptions);
  }, [team, clients]);

  const [note, setNote] = useState(false);
  const [selected, setSelected] = useState({
    name: null,
    surname: null,
    id: null,
  });

  const [assignTo, setAssignedTo] = useState({
    id: null,
    user: { name: null, surname: null, id: null },
  });

  const [insertNewComplaint] = useMutation(addNewComplaint);

  const {
    register: registercomplaints,
    handleSubmit: handleComplaints,
    setValue: setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: null,
      message: null,
      id: null,
      resolution: null,
      client: null,
      member: null,
      note: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (client) {
      setValue('client', client.id);
      setSelected({
        name: client.name,
        surname: client.surname,
        id: client.id,
      });
    }
  }, [client?.id]);

  const debouncedClientChange = useRef(
    debounce((inputValue) => {
      setState((s) => ({
        ...s,
        query: inputValue,
        clientInputItems:
          cFuse &&
          cFuse.current &&
          cFuse?.current?.search(inputValue).map((x) => x.item),
      }));
    }, 50),
  );

  const debouncedMemberChange = useRef(
    debounce((inputValue) => {
      setState((s) => ({
        ...s,
        memberQuery: inputValue,
        memberInputItems:
          mFuse &&
          mFuse.current &&
          mFuse?.current?.search(inputValue).map((x) => x.item),
      }));
    }, 50),
  );
  const [state, setState] = useState({
    query: '',
    recent: [],
    focused: null,
    memberQuery: '',
    clientInputItems:
      cFuse && cFuse.current && cFuse?.current?.search('').map((x) => x.item),
    memberInputItems:
      mFuse && mFuse.current && mFuse?.current?.search('').map((x) => x.item),
  });

  // insert new complaint
  const insertComplaint = async (data) => {
    try {
      const payload: { [key: string]: any } = {
        user_id: data.client,
        title: data.title,
        complaint: data.message,
        assignee_id: data?.member && data.member?.id,
      };

      if (data.note) {
        payload.notes = {
          data: [
            {
              note: {
                data: {
                  content: data.note,
                },
              },
            },
          ],
        };
      }

      if (callData) {
        payload.calls = {
          data: [
            {
              call_id: callData.id,
            },
          ],
        };
      }

      const result = await insertNewComplaint({
        variables: {
          data: payload,
        },
        refetchQueries: [
          {
            query: getAllComplaints,
            variables: {
              start: dateState?.controlOption?.value
                ? startOfDay(dateState.controlOption.value.start)
                : startOfMonth(startOfDay(subMonths(new Date(), 3))),
              end: dateState?.controlOption?.value
                ? endOfDay(dateState.controlOption.value.end)
                : endOfDay(new Date()),
            },
          },
          {
            query: getClientComplaints,
            variables: {
              id: data.client,
            },
          },
        ],
      });

      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Complaint Added Successfully',
          message: '',
        });
      }
      reset({
        title: null,
        message: null,
        client: null,
        member: null,
      });
      setSelected({
        name: null,
        surname: null,
        id: null,
      });
      setAssignedTo({
        id: null,
        user: { name: null, surname: null, id: null },
      });
      closeModal();
    } catch (error) {
      console.log(error, 'error');
      ui.setToast(true, {
        type: 'error',
        title: 'Error',
        message: 'Something went wrong',
      });
      console.log(error);
      reset({
        title: null,
        message: null,
        client: null,
        member: null,
      });
      setSelected({
        name: null,
        surname: null,
        id: null,
      });
      setAssignedTo({
        id: null,
        user: { name: null, surname: null, id: null },
      });
      closeModal();
    }
  };

  return (
    <section className="flex flex-1 h-full justify-between flex-col divide-y divide-gray-200 bg-white overflow-hidden">
      <div className="flex overflow-scroll flex-col">
        <form className="space-y-4">
          <div className="bg-white space-y-4 p-4">
            <div className="flex flex-col-2 space-y-2">
              <div className="flex-wrap w-full flex-col space-y-3">
                <div className="py-2">
                  <span className="font-semibold text-sm xl:text-sm">
                    Complaint Title
                  </span>
                  <input
                    type="text"
                    name="title"
                    {...registercomplaints('title', { required: true })}
                    className="transition-all w-full text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                  />
                </div>

                <div className="border-t py-2">
                  <span className="font-semibold text-sm xl:text-sm">
                    Complaint Message:
                  </span>
                  <textarea
                    name="complaint"
                    {...registercomplaints('message', { required: true })}
                    className="transition-all w-full text-left h-44 bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                  ></textarea>
                </div>
                <>
                  {/* Find Client to */}
                  <Combobox
                    {...registercomplaints('client', { required: true })}
                    value={
                      selected.name && selected.surname
                        ? `${selected.name} ${selected.surname}`
                        : null
                    }
                    //@ts-ignore
                    onChange={(value: UserProfile) => {
                      setValue('client', value.id);
                      setSelected(value);
                    }}
                  >
                    {({ activeOption }) => {
                      return (
                        <div className="relative mt-1 border-t py-2">
                          <span className="font-semibold text-sm xl:text-sm">
                            Find Client
                          </span>
                          <Combobox.Input
                            className="transition-all w-full text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                            placeholder={!selected.name && 'No Client Selected'}
                            onChange={(event) => {
                              if (event && event.target.value.length > 0) {
                                debouncedClientChange.current(
                                  event.target.value,
                                );
                              } else {
                                setState((s) => ({ ...s, query: '' }));
                              }
                            }}
                          />
                          {(state.clientInputItems.length > 0 ||
                            (state.query === '' &&
                              state.recent.length !== 0)) && (
                            <Combobox.Options
                              as="div"
                              className={classNames(
                                `flex absolute mt-1 max-h-40 rounded w-full ${
                                  state.query && 'bg-white shadow-sm'
                                } z-[40] divide-x divide-gray-100`,
                              )}
                            >
                              <div
                                className={classNames(
                                  'max-h-[250px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-4 py-2',
                                  activeOption && 'sm:max-h-[250px]',
                                  state.recent.length > 0 &&
                                    'divide-y divide-gray-100 space-y-2',
                                )}
                              >
                                <div className="-mx-2 text-xs xl:text-sm text-gray-700 max-h-[250px] overflow-x-scroll">
                                  {(state.query === ''
                                    ? state.recent
                                    : state.clientInputItems
                                  ).map((client, i) => {
                                    return (
                                      <Combobox.Option
                                        as="div"
                                        key={client.id}
                                        value={client}
                                        className={({ active }) =>
                                          classNames(
                                            'flex select-none items-center rounded-md p-2',
                                            active &&
                                              'bg-gray-100 text-gray-900',
                                          )
                                        }
                                      >
                                        {() => (
                                          <span className="flex-auto truncate flex items-center capitalize">
                                            {client.name} {client.surname}
                                            {client.email && (
                                              <span className="ml-1 inline-flex items-center text-xs lowercase">
                                                <AtSymbolIcon className="w-4 mr-1" />{' '}
                                                {client.email}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </Combobox.Option>
                                    );
                                  })}
                                </div>
                              </div>
                            </Combobox.Options>
                          )}
                        </div>
                      );
                    }}
                  </Combobox>

                  {/* Assign to*/}
                  <Combobox
                    {...registercomplaints('member', { required: true })}
                    value={
                      assignTo?.user?.name
                        ? `${assignTo?.user?.name} ${assignTo?.user?.surname}`
                        : null
                    }
                    onChange={(e: any) => {
                      setValue('member', e);
                      setAssignedTo(e);
                    }}
                  >
                    {({ activeOption }) => {
                      return (
                        <div className="relative mt-1 border-t pt-2">
                          <span className="font-semibold text-sm xl:text-sm">
                            Assign to:
                          </span>
                          <Combobox.Input
                            className="transition-all w-full text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                            placeholder={
                              !assignTo?.user?.name
                                ? 'No Team Member Selected'
                                : ''
                            }
                            onChange={(event) => {
                              if (event && event.target.value.length > 0) {
                                debouncedMemberChange.current(
                                  event.target.value,
                                );
                              } else {
                                setState((s) => ({
                                  ...s,
                                  memberQuery: '',
                                }));
                              }
                            }}
                          />
                          {(state.memberInputItems.length > 0 ||
                            (state.memberQuery === '' &&
                              state.recent.length !== 0)) && (
                            <Combobox.Options
                              as="div"
                              className={classNames(
                                `flex absolute mt-1 max-h-40 rounded w-full ${
                                  state.memberQuery && 'bg-white shadow-sm'
                                } z-[40] divide-x divide-gray-100`,
                              )}
                            >
                              <div
                                className={classNames(
                                  'max-h-[250px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-4 py-2',
                                  activeOption && 'sm:max-h-[250px]',
                                  state.recent.length > 0 &&
                                    'divide-y divide-gray-100 space-y-2',
                                )}
                              >
                                <div className="-mx-2 text-xs xl:text-sm text-gray-700 max-h-[250px] overflow-x-scroll">
                                  {(state.memberQuery === ''
                                    ? state.recent
                                    : state.memberInputItems
                                  ).map((member) => {
                                    return (
                                      <Combobox.Option
                                        as="div"
                                        key={member.id}
                                        value={member}
                                        className={({ active }) =>
                                          classNames(
                                            'flex select-none items-center rounded-md p-2',
                                            active &&
                                              'bg-gray-100 text-gray-900',
                                          )
                                        }
                                      >
                                        {member.user.avatar &&
                                          member.user.avatar.length > 0 && (
                                            <Avatar
                                              size={{
                                                width: 8,
                                                height: 8,
                                              }}
                                              layout="fill"
                                              src={
                                                member.user.avatar[0].asset.url
                                              }
                                              alt={`${member.user.name} ${member.user.surname}`}
                                            />
                                          )}
                                        <span className="flex-auto truncate flex pl-1 items-center capitalize">
                                          {member.user.name}{' '}
                                          {member.user.surname}
                                        </span>
                                      </Combobox.Option>
                                    );
                                  })}
                                </div>
                              </div>
                            </Combobox.Options>
                          )}
                        </div>
                      );
                    }}
                  </Combobox>

                  {/*Add Note*/}
                  <div className="relative z-0 w-full">
                    {note === true ? (
                      <div className="relative border-t mt-3 pt-3 flex flex-col items-start flex-1">
                        <button
                          type="button"
                          className="transition-all absolute top-3 right-0 rounded-full text-gray-400 p-[2px] focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500"
                          onClick={() => {
                            setNote(false);
                          }}
                        >
                          <XMarkIcon className="w-3" />
                        </button>
                        <p className="font-semibold text-sm text-gray-700">
                          Note
                        </p>
                        <textarea
                          name="note"
                          className="transition-all mt-3 p-2 pr-8 bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 text-xs w-full"
                          placeholder="Add note"
                          rows={4}
                          {...registercomplaints('note', {
                            required: false,
                          })}
                          spellCheck={true}
                        />
                      </div>
                    ) : (
                      <div className="flex space-x-2 border-t mt-3 pt-3 flex-1">
                        <button
                          type="button"
                          className="transition-all rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 flex-1"
                          onClick={() => setNote(true)}
                        >
                          Add a note
                        </button>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>

          <div className="flex w-full pr-4 place-content-end">
            <button
              className="panel-primary-button"
              onClick={handleComplaints(insertComplaint)}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
export default AddComplaint;
