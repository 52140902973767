import { useQuery } from '@apollo/client';
import {
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
} from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import Calls from 'pages/incoming/calls';
import { useState } from 'react';

import Status from 'components/atoms/status';

import { getAllCallsById } from 'queries/calls';

const Modal = dynamic(() => import('components/molecules/modal'));

export const CallsTab = ({ callID }: { callID: string[] }) => {
  const { data: callData } = useQuery(getAllCallsById, {
    variables: {
      id: callID,
    },
  });

  const [state, setState] = useState({
    callModal: null,
  });

  if (!callData?.call) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 hover:bg-gray-50 sm:rounded sm:overflow-hidden">
      {state.callModal && (
        <Modal
          full
          open={!!state.callModal}
          close={() => setState((s) => ({ ...s, callModal: null }))}
        >
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <Calls callID={state.callModal} />
          </div>
        </Modal>
      )}

      {callData?.call.map((calls, index) => {
        const callType =
          calls?.payload && calls?.payload?.text
            ? calls.payload.text
                .split('New')
                .filter(Boolean)
                .map((x) => x.trim())[0]
                .split('call')[0]
                .trim()
            : null;

        return (
          <button
            onClick={() => setState({ ...state, callModal: calls?.id })}
            className="sm:overflow-hidden w-full text-left p-4 md:p-0"
            key={index}
          >
            <div className="p-4 space-y-1 max-w-lg mx-auto cursor-pointer">
              <div className="font-semibold flex justify-between">
                {calls?.user ? (
                  <div className="">
                    {calls?.user?.name} {calls?.user?.surname}
                  </div>
                ) : (
                  <div className="">{calls?.payload?.text}</div>
                )}
                <Status value={calls?.status} />
              </div>

              <div className="text-sm font-semibold leading-6 text-gray-800 flex pt-1 space-x-2">
                {calls?.type === 'incoming' && (
                  <PhoneArrowDownLeftIcon className="w-4 text-purple-500" />
                )}
                {calls?.type === 'outgoing' && (
                  <PhoneArrowUpRightIcon className="w-4 text-purple-500" />
                )}
                <div className="">{calls?.payload?.number}</div>
              </div>

              <div className="pb-2">
                <div className="capitalize text-xs font-medium">
                  {callType} call
                </div>
                <div className="capitalize text-xs font-medium ">
                  {calls?.payload?.type}
                </div>
              </div>

              <div className="text-xs text-gray-500">
                {calls?.createdAt &&
                  format(new Date(calls.createdAt), "do LLL y 'at' h:mm aaa")}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default CallsTab;
