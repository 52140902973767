import { useQuery } from '@apollo/client';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';

import { getAllClientsById } from 'queries/clients';

export const StudentsTab = ({
  studentID,
  action,
}: {
  studentID: string[];
  action: any;
}) => {
  const { data: studentData } = useQuery(getAllClientsById, {
    variables: {
      id: studentID,
    },
  });

  if (!studentData?.client) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden">
      {studentData?.client.map((student, index) => {
        return (
          <button
            onClick={() => action(student.id)}
            className="sm:overflow-hidden w-full hover:bg-gray-50"
            key={index}
          >
            <div className=" max-w-lg p-4 text-left">
              <button className="text-sm font-semibold">
                {student?.name} {student?.surname}
              </button>

              <div className="text-sm">{student?.email}</div>
              {student?.createdAt && (
                <div className="text-xs pt-2">
                  Joined on{' '}
                  {format(
                    new Date(student.createdAt),
                    "do LLL y 'at' h:mm aaa",
                  )}
                </div>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default StudentsTab;
