import { ApolloClient } from '@apollo/client';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import { findClient } from 'queries/clients';

import MentionList from './mention-list';

const createSuggestion = (client?: ApolloClient<object>) => {
  const suggestion = {
    items: async ({ query }) => {
      const { data } = await client?.query({
        query: findClient,
        variables: {
          term: `%${query}%`,
          limit: 5,
        },
      });

      const clients =
        data.clients && data.clients.length > 0
          ? data.clients
              .map((client) => {
                return {
                  name: client.fullName,
                  email: client.email,
                  id: client.id,
                };
              })
              .filter(Boolean)
              .slice(0, 5)
          : [];

      return clients;
    },

    render: () => {
      let component;
      let popup;

      return {
        onStart: (props) => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },

        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return component?.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
  return suggestion;
};

export default createSuggestion;
