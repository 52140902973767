import { gql } from '@apollo/client';

export const getAllEnrollments = gql`
  query getAllEnrollments($start: timestamptz, $end: timestamptz) {
    enrollment(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
      order_by: { created_at: desc }
    ) {
      id
      balance
      created_at
      updated_at
      total
      status
      metadata
      deposit_required
      registered
      courses {
        id
        type
        created_at
        updated_at
        status
        event_id
        enrollment_id
        package {
          title
        }
        event {
          id
          start
          end
          date
          seat_price
          event_resources {
            user {
              given_name
            }
          }
          detail {
            title
          }
        }
      }
      credits {
        id
        credit_id
        created_at
        credit {
          id
          amount
          explanation
          redeemed
        }
      }
      discounts {
        id
        discount_id
        created_at
        discount {
          id
          value
          type
          active
          code
        }
      }
      payments {
        id
        created_at
        updated_at
        enrollment_id
        payment {
          created_at
          updated_at
          amount
          currency
          transaction_id
          method
          metadata
          status
          invoice_id
          invoice {
            id
            due_date
            due: amount
            status
          }
        }
      }
      notes {
        id
        created_at
        note
        noteByNote {
          title
          content
          user
        }
      }
      employee {
        given_name
        family_name
        id
        user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
          asset: assetByAsset {
            id
            type
            url
          }
        }
      }
      user {
        given_name
        family_name
        id
        email
        contact_number
      }
      online_courses {
        id
        data
        created_at
        updated_at
        coassemble_course_id
        coassemble_group_id
        complete
        event_detail {
          title
        }
      }
      uploads {
        id
        url
        complete
        member
        updated_at
        uploadRequest {
          id
          title
          description
        }
      }
      forms {
        id
        created_at
        updated_at
        createdAt: created_at
        updatedAt: updated_at
        formByForm {
          id
          title
          content
          formQuestions {
            id
            order
            question: questionByQuestion {
              label
              type
            }
          }
        }
        responsesByResponses {
          id
          complete
          responses
        }
      }
    }
  }
`;

export const getEnrollments = gql`
  query getEnrollments($userId: uuid!) {
    enrollment(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      id
      balance
      created_at
      updated_at
      total
      status
      metadata
      deposit_required
      registered
      courses {
        id
        type
        created_at
        updated_at
        status
        event_id
        enrollment_id
        package {
          title
        }
        event {
          id
          start
          end
          date
          seat_price
          event_resources {
            user {
              given_name
            }
          }
          detail {
            title
          }
        }
      }
      credits {
        id
        credit_id
        created_at
        credit {
          id
          amount
          explanation
          redeemed
        }
      }
      discounts {
        id
        discount_id
        created_at
        discount {
          id
          value
          type
          active
          code
        }
      }
      payments {
        id
        created_at
        updated_at
        enrollment_id
        payment {
          created_at
          updated_at
          amount
          currency
          transaction_id
          method
          metadata
          status
          invoice_id
          invoice {
            id
            invoice_id
            due: amount
            status
            due_date
          }
        }
      }
      notes {
        id
        created_at
        note
        noteByNote {
          title
          content
          user
        }
      }
      employee {
        given_name
        family_name
        id
        user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
          asset: assetByAsset {
            id
            type
            url
          }
        }
      }
      user {
        given_name
        family_name
        id
        email
        contact_number
      }
      online_courses {
        id
        data
        created_at
        updated_at
        coassemble_course_id
        coassemble_group_id
        complete
        event_detail {
          title
        }
      }
      uploads {
        id
        url
        complete
        member
        updated_at
        uploadRequest {
          id
          title
          description
        }
      }
      forms {
        id
        created_at
        updated_at
        createdAt: created_at
        updatedAt: updated_at
        form: formByForm {
          id
          title
          content
          formQuestions {
            id
            order
            question: questionByQuestion {
              label
              type
            }
          }
        }
        formByForm {
          id
          title
          content
          formQuestions {
            id
            order
            question: questionByQuestion {
              label
              type
            }
          }
        }
        responsesByResponses {
          id
          complete
          responses
        }
      }
    }
  }
`;

export const getFutureEvents = gql`
  query getFutureEvents($now: timestamptz) {
    event_detail(
      where: { archived: { _neq: true } }
      order_by: { title: asc, is_package: desc }
    ) {
      id
      title
      standard_seat
      updated_at
      created_at
      contentful_id
      metadata
      type
      archived
      wasPrice: was_price
      standard_price
      is_package
      events(
        where: { status: { _neq: "ARCHIVED" }, start: { _gte: $now } }
        order_by: { start: asc }
      ) {
        id
        date
        start
        end
        seats
        seat_price
        status
        type
        detail_id
        detail {
          title
          elearning_course_id
          is_package
        }
        event_resources {
          id
          user_id
          type
          user {
            given_name
          }
        }
        countStudentRegistrations: registrations_aggregate(
          where: {
            _and: { status: { _neq: "CANCELLED" }, type: { _eq: "STUDENT" } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const updateReferral = gql`
  mutation updateReferral($id: uuid!, $data: referral_link_set_input!) {
    referral: update_referral_link_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getReferralLink = gql`
  query getReferralLink($id: uuid!) {
    referralLink: referral_link(where: { user_id: { _eq: $id } }) {
      id
      credits
      link
    }
  }
`;
export const addEnrollment = gql`
  mutation addEnrollment($data: enrollment_insert_input!) {
    insert_enrollment_one(object: $data) {
      id
      balance
      total
      updated_at
      user_id
      employee_id
      metadata
    }
  }
`;

export const createEnrollmentNote = gql`
  mutation createEnrollmentNote($data: enrollment_note_insert_input!) {
    insert_enrollment_note_one(object: $data) {
      id
      note: noteByNote {
        id
        title
        content
        user
        updatedAt: updated_at
      }
    }
  }
`;

export const createEnrollmentPayment = gql`
  mutation createEnrollmentPayment($data: enrollment_payment_insert_input!) {
    insert_enrollment_payment_one(object: $data) {
      id
    }
  }
`;

export const createEnrollmentCredit = gql`
  mutation createEnrollmentCredit($data: enrollment_credit_insert_input!) {
    insert_enrollment_credit_one(object: $data) {
      id
    }
  }
`;

export const createEnrollmentDiscount = gql`
  mutation createEnrollmentDiscount($data: enrollment_discount_insert_input!) {
    insert_enrollment_discount_one(object: $data) {
      id
    }
  }
`;

export const updatePaymentById = gql`
  mutation updatePaymentById($id: uuid!, $data: payment_set_input!) {
    update: update_payment(
      where: { enrollment_id: { _eq: $id } }
      _set: $data
    ) {
      affected_rows
    }
  }
`;

export const updateEnrollmentPaymentById = gql`
  mutation updateEnrollmentPaymentById(
    $id: uuid!
    $data: enrollment_payment_set_input!
  ) {
    update: update_enrollment_payment_by_pk(
      pk_columns: { id: $id }
      _set: $data
    ) {
      id
      enrollment_id
    }
  }
`;

export const updateEnrollment = gql`
  mutation updateEnrollment($id: uuid!, $data: enrollment_set_input!) {
    update: update_enrollment_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getPackageEvents = gql`
  query getPackageEvents($id: uuid, $now: timestamptz) {
    event_detail_package(where: { package_id: { _eq: $id } }) {
      id
      course_id
      course {
        title
        id
        events(
          where: { status: { _neq: "ARCHIVED" }, start: { _gte: $now } }
          order_by: { start: asc }
        ) {
          id
          start
          date
          end
          seats
          type
          seat_price
          countStudentRegistrations: registrations_aggregate(
            where: {
              _and: { status: { _neq: "CANCELLED" }, type: { _eq: "STUDENT" } }
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export const updateEnrollmentCheckList = gql`
  mutation updateEnrollmentCheckList($id: uuid!, $checklist: jsonb!) {
    update_enrollment_by_pk(
      pk_columns: { id: $id }
      _set: { checklist: $checklist }
    ) {
      id
    }
  }
`;

export const getEnrollment = gql`
  query getEnrollment($id: uuid!) {
    enrollment: enrollment_by_pk(id: $id) {
      id
      balance
      created_at
      updated_at
      total
      status
      checklist
      metadata
      client_data
      deposit_required
      registered
      receipt_id
      receipt {
        id
        url
        props
      }
      enrollmentCall {
        call_id
      }
      tasks {
        id
        task {
          id
          title
          content
          status
          registrations {
            id
          }
          uploads {
            id
          }
          forms {
            id
          }
          assignee {
            name: given_name
            surname: family_name
          }
        }
      }
      courses {
        id
        type
        created_at
        updated_at
        status
        event_id
        enrollment_id
        package {
          title
        }
        event {
          id
          start
          end
          date
          detail_id
          seat_price
          event_resources {
            user {
              given_name
            }
          }
          detail {
            title
            elearning_course_id
          }
        }
      }
      credits {
        id
        credit_id
        created_at
        credit {
          id
          amount
          explanation
          redeemed
        }
      }
      discounts {
        id
        discount_id
        created_at
        discount {
          id
          value
          type
          active
          code
        }
      }
      payments {
        id
        created_at
        updated_at
        enrollment_id
        payment {
          id
          created_at
          updated_at
          completed_at
          amount
          currency
          transaction_id
          method
          metadata
          status
          invoice_id
          invoice {
            id
            invoice_id
            due_date
            due: amount
            status
          }
        }
      }
      notes {
        id
        created_at
        note
        noteByNote {
          title
          content
          user
          employee {
            given_name
            family_name
            id
            user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
              asset: assetByAsset {
                id
                type
                url
              }
            }
          }
        }
      }
      employee {
        given_name
        family_name
        id
        user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
          asset: assetByAsset {
            id
            type
            url
          }
        }
      }
      user {
        name: given_name
        surname: family_name
        id
        email
        contact_number
      }
      online_courses {
        id
        data
        created_at
        updated_at
        coassemble_course_id
        coassemble_group_id
        complete
        event_detail {
          id
          title
        }
      }
      uploads {
        id
        url
        complete
        client_complete
        member
        updated_at
        completed_at
        uploadRequest {
          id
          title
          description
        }
        assets {
          id
          created_at
          updated_at
          url
        }
      }
      forms {
        id
        created_at
        updated_at
        createdAt: created_at
        updatedAt: updated_at
        form: formByForm {
          id
        }
        formByForm {
          id
          title
          content
          formQuestions {
            id
            order
            question: questionByQuestion {
              label
              public
              properties
              archived
              type
            }
          }
        }
        responsesByResponses {
          id
          completed_at
          complete
          client_complete
          responses
        }
      }
    }
  }
`;

export const markEnrollmentComplete = gql`
  mutation markEnrollmentAsPaid($id: uuid!) {
    enrollment: update_enrollment_by_pk(
      pk_columns: { id: $id }
      _set: { balance: null, status: "completed" }
    ) {
      id
    }
    update_payment(
      where: { enrollment_id: { _eq: $id } }
      _set: { status: "complete" }
    ) {
      affected_rows
    }
  }
`;

export const deleteEnrollment = gql`
  mutation deleteEnrollment($id: uuid!) {
    delete_enrollment_payment(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_enrollment_call(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_enrollment_credit(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_enrollment_discount(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_enrollment_note(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_payment(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_credit(where: { enrollment_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_enrollment(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const getQuotes = gql`
  query getQuotes(
    $where: enrollment_quote_bool_exp
    $order_by: [enrollment_quote_order_by!]
  ) {
    quotes: enrollment_quote(where: $where, order_by: $order_by) {
      id
      created_at
      updated_at
      total
      balance
      metadata
      status
      user {
        id
        given_name
        family_name
        email
      }
      employee {
        id
        given_name
        family_name
        email
        user_assets {
          asset: assetByAsset {
            id
            url
          }
        }
      }
      discounts {
        id
        discount {
          id
          code
          value
          type
        }
      }
    }
  }
`;
