import { gql } from '@apollo/client';

export const getNotificationsByUserID = gql`
  subscription getNotificationsByUserID($user_id: uuid!) {
    notification(
      where: { user_id: { _eq: $user_id } }
      limit: 30
      order_by: { created_at: desc }
    ) {
      id
      content
      created_at
      seen
      title
      type
      reference_id
    }
  }
`;

export const setNotificationSeenStatus = gql`
  mutation setNotificationSeenStatus($id: uuid!, $seen: Boolean) {
    update_notification_by_pk(pk_columns: { id: $id }, _set: { seen: $seen }) {
      id
    }
  }
`;

export const getUnseenNotificationsByUser = gql`
  subscription getUnseenNotificationsByUser($user_id: uuid!) {
    notification(
      where: { user_id: { _eq: $user_id }, seen: { _eq: false } }
      limit: 30
    ) {
      id
    }
  }
`;

export const getUnseenNotificationsByUserQuery = gql`
  query getUnseenNotificationsByUser($user_id: uuid!) {
    notification(
      where: { user_id: { _eq: $user_id }, seen: { _eq: false } }
      limit: 30
    ) {
      id
    }
  }
`;
