import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import Status from 'components/atoms/status';
import ViewEnrollmentModal from 'components/organisms/enrollment/view-enrollment';

import { currency } from 'utils/formats';

import { getEnrollment } from 'queries/enrollment';

const Modal = dynamic(() => import('components/molecules/modal'));

export const Payment = ({ payment }: { payment: any }) => {
  const [state, setState] = useState({
    enrollmentId: null,
    viewModal: false,
  });

  const [getEnrolmentData, { data: enrolmentData }] = useLazyQuery(
    getEnrollment,
    {
      variables: {
        id: payment?.enrollment?.id,
      },
    },
  );

  useEffect(() => {
    getEnrolmentData();
    setState((s) => ({ ...s, enrollmentId: enrolmentData?.enrollment.id }));
  }, [enrolmentData]);

  return (
    <div className="sm:overflow-hidden w-full text-left text-sm hover:bg-gray-50">
      <div
        onClick={() => setState((s) => ({ ...s, viewModal: true }))}
        className="p-4 space-y-1 max-w-lg mx-auto cursor-pointer"
      >
        <div className="flex justify-between">
          <div className="text-sm font-semibold">
            {payment?.user?.name
              ? `${payment?.user?.name} ${payment?.user?.surname}`
              : `${currency(payment?.amount, payment?.currency)}`}
          </div>
          <Status value={payment?.status} />
        </div>
        {payment?.user?.name && (
          <p className="text-xs">
            {currency(payment?.amount, payment?.currency)}
          </p>
        )}

        {payment?.method && (
          <div className="capitalize font-semibold">{payment.method}</div>
        )}
        {payment?.transactionId && (
          <div className="text-purple-600 text-xs">{payment.transactionId}</div>
        )}
        {payment?.employee?.name && (
          <div className="font-semibold text-xs">
            Handled by {payment?.employee?.name} {payment?.employee?.surname}
          </div>
        )}

        {payment?.createdAt && (
          <div className="text-xs mt-1">
            {format(new Date(payment.createdAt), "do LLL y 'at' h:mm aaa")}
          </div>
        )}
      </div>
      {state.viewModal && (
        <Modal
          title={`Enrollment: ${state.enrollmentId}`}
          full
          open={!!state.viewModal}
          close={() => {
            setState((s) => ({ ...s, viewModal: false }));
          }}
        >
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <ViewEnrollmentModal
              enrollmentId={state.enrollmentId}
              onComplete={() => {
                setState((s) => ({ ...s, viewModal: false }));
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Payment;
