import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { RiDownloadLine } from '@remixicon/react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { extname } from 'path';
import { useEffect, useMemo, useState } from 'react';

import { ErrorState } from './error';

const Modal = dynamic(() => import('components/molecules/modal'));
const Loader = dynamic(() => import('components/atoms/loader'));

type UploadProps = {
  data: {
    id: string;
    created_at: string;
    updated_at: string;
    url: string;
    user_upload_id: string;
    user_id: string;
    assets: {
      id: string;
      created_at: string;
      updated_at: string;
      url: string;
      user_upload_id: string;
      __typename: string;
    }[];
  };
};

export const UploadRequestModal = ({ data }: UploadProps) => {
  const [selected, setSelected] = useState<string | null>(null);

  const assetData = useMemo(
    () => (data.assets.length > 0 ? data.assets : [data]),
    [data],
  );

  console.log('assetData', assetData);

  if (!assetData) {
    return null;
  }

  return (
    <div className="w-full h-full flex-1 flex flex-wrap gap-2">
      {assetData.map((x) => {
        return (
          <div
            key={x.id}
            className="w-[32%] relative border border-gray-300 rounded-lg cursor-pointer"
          >
            {x.url && <Item url={x.url} />}
          </div>
        );
      })}

      {!!selected ? (
        <Modal
          full
          open={!!selected}
          close={() => {
            setSelected(null);
          }}
        >
          <div className="w-full h-full flex-1">
            <Image
              onClick={() => setSelected(null)}
              src={selected}
              alt=""
              layout="fill"
              objectFit="contain"
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

function Item({ url }: { url: string }) {
  const [document, setDocument] = useState(null);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [documentType, setDocumentType] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const res = await genUploadLink(url);
    }
    if (url) {
      fetchData();
    }
  }, [url]);

  const genUploadLink = async (target: string) => {
    setDocumentError(false);
    setDocumentLoading(true);

    if (!target) {
      setDocumentError(true);
      return;
    }
    const contentType = getContentType(url);

    try {
      const part = target.split('/private')[1];
      const req = await fetch(`/api/upload-requests?key=${part}`);
      const { url } = await req.json();
      if (!contentType) throw new Error('Unsupported file type');

      let viewerUrl;

      const mimeType = contentType.toLowerCase();
      const mimeTypes = [
        'application/pdf',
        'application/msword',
        'text/plain',
        'binary/octet-stream',
        'application/rtf',
      ];

      if (mimeType === 'application/pdf') {
        viewerUrl = url;
        setDocumentType('pdf');
      } else if (
        mimeType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
        setDocumentType('googleDocs');
      } else if (mimeTypes.includes(mimeType)) {
        viewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(url)}`;
        setDocumentType('googleDocs');
      } else if (mimeType.startsWith('image/')) {
        viewerUrl = url;
        setDocumentType('image');
      } else if (mimeType.startsWith('video/')) {
        viewerUrl = url;
        setDocumentType('video');
      } else {
        throw new Error('Unsupported file type for viewing');
      }

      setDocument(viewerUrl);
      setDocumentLoading(false);
    } catch (err) {
      console.log(err);
      setDocumentError(true);
      setDocumentLoading(false);
    }
  };

  if (documentLoading) {
    return (
      <div className="flex h-full w-full items-center my-auto justify-center">
        <Loader stroke="#0c9498" width={100} height={100} />
      </div>
    );
  }

  return (
    <>
      {documentLoading && <Loader />}
      {documentError && (
        <ErrorState
          onClick={() => {
            setDocumentType(null);
            setDocumentLoading(false);
            setDocumentError(false);
            setDocument(null);
          }}
        />
      )}
      {document && documentType === 'googleDocs' ? (
        <iframe
          src={document}
          style={{ border: 0 }}
          onLoad={() => {
            setDocumentLoading(false);
            setDocumentError(false);
          }}
          onLoadedData={() => {
            setDocumentLoading(false);
            setDocumentError(false);
          }}
          onError={() => {
            setDocumentError(false);
          }}
          width="100%"
          height="650"
          frameBorder="0"
        ></iframe>
      ) : document && documentType === 'pdf' ? (
        <DocViewer
          documents={[{ uri: document, fileType: 'pdf' }]}
          style={{ border: 0 }}
          config={{
            noRenderer: {
              overrideComponent: () => (
                <div>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={document}
                    className="btn-primary w-8 h-8 p-0 flex justify-center items-center ml-4 mb-4"
                  >
                    <RiDownloadLine className="w-5 h-5" />
                  </Link>
                  <iframe
                    src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(document)}`}
                    style={{ border: 0 }}
                    onLoad={() => {
                      setDocumentLoading(false);
                      setDocumentError(false);
                    }}
                    onLoadedData={() => {
                      setDocumentLoading(false);
                      setDocumentError(false);
                    }}
                    onError={() => {
                      setDocumentError(false);
                    }}
                    width="100%"
                    height="650"
                    frameBorder="0"
                  />
                </div>
              ),
            },
          }}
          pluginRenderers={DocViewerRenderers}
        />
      ) : document && documentType === 'image' ? (
        <img
          src={document}
          onError={() => {
            setDocumentError(false);
          }}
          onLoad={() => {
            setDocumentLoading(false);
            setDocumentError(false);
          }}
          onLoadedData={() => {
            setDocumentLoading(false);
            setDocumentError(false);
          }}
          style={{ maxWidth: '100%', maxHeight: '650px' }}
          alt="Document"
        />
      ) : document && documentType === 'video' ? (
        <video
          controls
          onLoad={() => {
            setDocumentLoading(false);
            setDocumentError(false);
          }}
          onLoadedData={() => {
            setDocumentLoading(false);
            setDocumentError(false);
          }}
          onError={() => {
            setDocumentError(false);
          }}
          style={{ maxWidth: '100%', maxHeight: '650px' }}
          src={document}
        />
      ) : null}
    </>
  );
}

const getContentType = (key: string): string => {
  const ext = extname(key).toLowerCase();

  switch (ext) {
    case '.pdf':
      return 'application/pdf';
    case '.doc':
      return 'application/msword';
    case '.docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case '.rtf':
      return 'application/rtf';
    case '.txt':
      return 'text/plain';
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg';
    case '.png':
      return 'image/png';
    case '.gif':
      return 'image/gif';
    case '.bmp':
      return 'image/bmp';
    case '.webp':
      return 'image/webp';
    case '.mp4':
      return 'video/mp4';
    case '.avi':
      return 'video/x-msvideo';
    case '.mov':
      return 'video/quicktime';
    case '.mkv':
      return 'video/x-matroska';
    default:
      return '';
  }
};

const getExtension = (str) => str.slice(str.lastIndexOf('.'));
