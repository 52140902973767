import { Menu } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { TagIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

const Label = ({
  x,
  handleRemoveTag,
  classNames,
}: {
  x: any;
  handleRemoveTag?: any;
  classNames?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <span
      className={`${classNames} inline-flex items-center mb-1 rounded-full ${
        x?.color && x.color
      } bg-gray-100 py-0.5 pl-1 pr-1 border text-xs font-medium text-black`}
    >
      <Menu as="div" className="flex">
        {x?.description ? (
          <Menu.Button onClick={() => setIsOpen(true)} className="">
            <InformationCircleIcon className="w-4" />
          </Menu.Button>
        ) : (
          <TagIcon className="w-3" />
        )}

        {isOpen && (
          <Menu.Items className="absolute left-20 mt-6 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white z-[20] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                <div className="px-1 py-2">
                  <div className="flex justify-between">
                    <h2 className="font-bold">Description</h2>
                    <XMarkIcon
                      className="w-4 cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    />
                  </div>
                  <p>{x.description}</p>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        )}
      </Menu>

      <p className="truncate ml-1">{x?.label?.name}</p>
      {handleRemoveTag && (
        <button
          type="button"
          onClick={() => handleRemoveTag(x?.label?.id)}
          className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-black hover:bg-purple-300  focus:outline-none"
        >
          <span className="sr-only">Remove Tag</span>
          <svg
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
  );
};

export default Label;
