import {
  ApolloClient,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  RiAlignCenter,
  RiAlignLeft,
  RiAlignRight,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiImage2Line,
  RiItalic,
  RiLink,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
  RiQuoteText,
  RiStrikethrough,
  RiTaskLine,
  RiUnderline,
} from '@remixicon/react';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import FileHandler from '@tiptap-pro/extension-file-handler';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { generateHTML } from '@tiptap/html';
import {
  Content,
  Editor,
  EditorContent,
  JSONContent,
  mergeAttributes,
  useEditor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  Fragment,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import classNames from 'utils/classnames';

import { getTaskById, updateTaskById } from 'queries/tasks';

import { useUI } from 'providers/ui';

import createSuggestion from './suggestion';
import { TaskItem } from './task-item';

const MenuBar = ({ editor, short }: { editor: Editor; short: boolean }) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);
    if (url === null) {
      return;
    }
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  const { setToast } = useUI();

  const addImage = useCallback(
    async (event) => {
      const files = event.target.files;
      await files.forEach(async (file) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          editor
            .chain()
            .focus()
            .insertContent({
              type: 'image',
              attrs: {
                src: fileReader.result,
              },
            })
            // .insertContentAt(editor, {
            //   type: 'image',
            //   attrs: {
            //     src: fileReader.result,
            //   },
            // })
            .focus()
            .run();
        };
      });
    },
    [editor],
  );

  return (
    <div className="text-black inline-flex leading-none gap-0.5 flex-row flex-wrap p-1 items-center bg-white rounded-t-lg  shadow-sm border border-neutral-200 absolute top-0 left-0 right-0 z-10">
      <div className="flex-1 flex items-center">
        <button
          type="button"
          title="Bold"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            editor && editor && !editor.can().chain().focus().toggleBold().run()
          }
          className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('strong') ? 'is-active' : ''}`}
        >
          <RiBold size={16} />
        </button>
        <button
          type="button"
          title="Italic"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            editor && !editor.can().chain().focus().toggleItalic().run()
          }
          className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('italic') ? 'is-active' : ''}`}
        >
          <RiItalic size={16} />
        </button>
        <button
          type="button"
          title="Underline"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            editor && !editor.can().chain().focus().toggleUnderline().run()
          }
          className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('underline') ? 'is-active' : ''}`}
        >
          <RiUnderline size={16} />
        </button>
        <button
          type="button"
          title="Strikethrough"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            editor && !editor.can().chain().focus().toggleStrike().run()
          }
          className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('strikethrough') ? 'is-active' : ''}`}
        >
          <RiStrikethrough size={16} />
        </button>
        <button
          type="button"
          title="List"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('bulletList') ? 'is-active' : ''}`}
        >
          <RiListUnordered size={16} />
        </button>
        {!short ? (
          <>
            <button
              title="Ordered List"
              type="button"
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('orderedList') ? 'is-active' : ''}`}
            >
              <RiListOrdered size={16} />
            </button>
            <button
              title="Quote"
              type="button"
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('blockquote') ? 'is-active' : ''}`}
            >
              <RiQuoteText size={16} />
            </button>
            <button
              title="AlignLeft"
              type="button"
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('blockquote') ? 'is-active' : ''}`}
            >
              <RiAlignLeft size={16} />
            </button>
            <button
              title="AlignCenter"
              type="button"
              onClick={() =>
                editor.chain().focus().setTextAlign('center').run()
              }
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('blockquote') ? 'is-active' : ''}`}
            >
              <RiAlignCenter size={16} />
            </button>
            <button
              title="AlignRight"
              type="button"
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('blockquote') ? 'is-active' : ''}`}
            >
              <RiAlignRight size={16} />
            </button>
            <button
              onClick={setLink}
              title="Link"
              type="button"
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('link') ? 'is-active' : ''}`}
            >
              <RiLink size={16} />
            </button>
            <label
              title="Image"
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('blockquote') ? 'is-active' : ''}`}
            >
              <input
                type="file"
                name="image"
                className="opacity-0 w-0"
                onChange={addImage}
                accept="image/*"
                multiple
              />
              <RiImage2Line size={16} />
            </label>
            <button
              type="button"
              title="Task List"
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              className={`flex group items-center justify-center border text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto ${editor && editor.isActive('taskList') ? 'is-active' : ''}`}
            >
              <RiTaskLine size={16} />
            </button>
          </>
        ) : null}
        <div className="w-[1px] h-[22px] border-r border-slate-300 mr-3" />
        <Menu as="div" className="relative -ml-px block">
          <Menu.Button className="relative inline-flex items-center rounded-md bg-white px-2 py-2 text-slate-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 text-xs">
            Style
            <ChevronDownIcon className="h-4 w-4 ml-2" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute -right-32 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col text-sm text-slate-600">
              <div className="py-1 space-y-1">
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().setParagraph().run()
                      }
                      className={classNames(
                        editor && editor.isActive('paragraph')
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiParagraph size={16} />
                      <span>Paragraph</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 1 }).run()
                      }
                      className={classNames(
                        editor && editor.isActive('heading', { level: 1 })
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiH1 size={16} />
                      <span>Heading 1</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 2 }).run()
                      }
                      className={classNames(
                        editor && editor.isActive('heading', { level: 2 })
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiH2 size={16} />

                      <span>Heading 2</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 3 }).run()
                      }
                      className={classNames(
                        editor && editor.isActive('heading', { level: 3 })
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiH3 size={16} />
                      <span>Heading 3</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 4 }).run()
                      }
                      className={classNames(
                        editor && editor.isActive('heading', { level: 4 })
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiH4 size={16} />
                      <span>Heading 4</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 5 }).run()
                      }
                      className={classNames(
                        editor && editor.isActive('heading', { level: 5 })
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiH5 size={16} />
                      <span>Heading 5</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item as="div">
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 6 }).run()
                      }
                      className={classNames(
                        editor && editor.isActive('heading', { level: 6 })
                          ? 'is-active'
                          : '',
                        'flex items-center w-full text-left p-1 px-2 space-x-2',
                      )}
                    >
                      <RiH6 size={16} />
                      <span>Heading 6</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <div className="w-[1px] h-[22px] border-r border-slate-300" />

      <button
        type="button"
        title="Undo"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={editor && !editor.can().chain().focus().undo().run()}
        className="cursor-pointer flex group items-center justify-center border text-sm font-semibold rounded-md whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto"
      >
        <RiArrowGoBackLine size={16} />
      </button>
      <button
        type="button"
        title="Redo"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={editor && !editor.can().chain().focus().redo().run()}
        className="cursor-pointer flex group items-center justify-center border text-sm font-semibold rounded-md whitespace-nowrap bg-transparent border-transparent text-slate-600 hover:bg-black/5 hover:text-neutral-700 active:bg-black/10 active:text-neutral-800  h-8 gap-1 min-w-[2rem] px-2 w-auto"
      >
        <RiArrowGoForwardLine size={16} />
      </button>
    </div>
  );
};

function createExtenstions(client?: ApolloClient<object>) {
  const extensions = [
    // @ts-ignore
    TextStyle.configure({ types: [ListItem.name] }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      renderHTML({ node, options }) {
        return [
          'button',
          mergeAttributes(options?.HTMLAttributes, {
            onclick: `openEditorUser('${node.attrs?.id?.id}')`,
          }),
          `${options.suggestion.char}${node.attrs.id.name}`,
        ];
      },
      suggestion: createSuggestion(client),
    }),
    Underline,
    TaskList,
    TaskItem.configure({
      nested: true,
      apolloClient: client,
      onReadOnlyChecked: (node, checked) => {
        const event = new CustomEvent('onReadOnlyCheck', {
          detail: { node, checked },
        });
        dispatchEvent(event);
        return true;
      },
    }),
    Emoji.configure({
      emojis: gitHubEmojis,
      enableEmoticons: true,
    }),
    Link,
    Image,
    FileHandler.configure({
      allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
      onDrop: (currentEditor, files, pos) => {
        files.forEach((file) => {
          const fileReader = new FileReader();

          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            currentEditor
              .chain()
              .insertContentAt(pos, {
                type: 'image',
                attrs: {
                  src: fileReader.result,
                },
              })
              .focus()
              .run();
          };
        });
      },
      onPaste: (currentEditor, files, htmlContent) => {
        files.forEach((file) => {
          if (htmlContent) {
            // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
            // you could extract the pasted file from this url string and upload it to a server for example
            console.log(htmlContent); // eslint-disable-line no-console
            return false;
          }

          const fileReader = new FileReader();

          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            currentEditor
              .chain()
              .insertContentAt(currentEditor.state.selection.anchor, {
                type: 'image',
                attrs: {
                  src: fileReader.result,
                },
              })
              .focus()
              .run();
          };
        });
      },
    }),
  ];

  return extensions;
}

const Tiptap = forwardRef(
  (
    {
      content,
      onUpdate,
      readOnly = false,
      taskId,
      refetch,
      shortMenu = false,
      placeholder = 'Enter text here',
      className,
      classes = 'pb-32',
    }: {
      content?: Content;
      placeholder?: string;
      readOnly?: boolean;
      taskId?: string;
      refetch?: () => Promise<void>;
      shortMenu?: boolean;
      onUpdate: (json: JSONContent) => void;
      className?: string;
      classes?: string;
    },
    ref,
  ) => {
    const { setClient } = useUI();

    const client = useApolloClient();

    const extensions = createExtenstions(client);

    const handleOnReadOnlyCheck = (event: CustomEventInit) => {
      if (!!editor) {
        editor.state.doc.descendants((subnode, pos) => {
          if (subnode.eq(event.detail.node)) {
            const { tr } = editor.state;
            tr.setNodeMarkup(pos, undefined, {
              ...event.detail.node.attrs,
              checked: event.detail.checked,
            });

            // Dispatch only the transaction without resetting the entire content
            editor.view.dispatch(tr);
          }
        });
      }
    };

    addEventListener('onReadOnlyCheck', handleOnReadOnlyCheck);

    const action = useCallback(
      (userId: string) => {
        setClient(true, userId);
      },
      [setClient],
    );

    // @ts-ignore
    window.openEditorUser = function (id: string) {
      action(id);
    };

    // @ts-ignore
    window.markTaskAsComplete = async function (attributes) {
      console.log('markTaskAsComplete', attributes);
      if (attributes?.taskId) {
        const { data } = await client?.mutate({
          mutation: updateTaskById,
          variables: {
            id: taskId,
            status: attributes.checked === 'true' ? 'COMPLETE' : 'TODO',
          },
        });
        console.log(data, 'DATA');
      }
    };

    const editor = useEditor({
      editable: !readOnly,
      extensions: [
        ...extensions,
        Placeholder.configure({
          placeholder,
        }),
      ],
      onUpdate({ editor }) {
        onUpdate(editor.getJSON());
      },
      onBlur({ editor, event }) {
        onUpdate(editor.getJSON());
      },
      editorProps: {
        attributes: {
          class: `flex-1 prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none ${readOnly ? 'readonly' : ''} ${className}`,
        },
      },
      content: content ? content : null,
    });

    useImperativeHandle(ref, () => ({
      setData(data: JSONContent) {
        if (editor && ref) {
          editor.commands.setContent(data);
        }
      },
      reset() {
        if (editor && ref) {
          editor.commands.clearContent();
        }
      },
      getText() {
        if (editor && ref) {
          return editor.getText();
        }
      },
    }));

    return (
      <div
        className={classNames(
          'relative flex flex-col flex-1 editor-container',
          classes,
        )}
      >
        {!readOnly ? <MenuBar editor={editor} short={shortMenu} /> : null}
        <EditorContent editor={editor} readOnly={readOnly} />
      </div>
    );
  },
);

Tiptap.displayName = 'Tiptap';

export const generate = (content: JSONContent) => {
  return generateHTML(content, createExtenstions());
};

export default Tiptap;
