import { useQuery } from '@apollo/client';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { startOfDay } from 'date-fns';
import format from 'date-fns/format';
import * as F from 'fuse.js';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getEvents } from 'queries/events';

type Value = any;

export default function AddToEventComponent({
  submit,
  title = 'Add to upcoming event',
}) {
  const { register, handleSubmit, setValue, control } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const { data: courseEvents, loading } = useQuery(getEvents, {
    variables: {
      where: {
        start: {
          _gte: startOfDay(new Date()),
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const openCourseEvents = useMemo(() => {
    return (
      courseEvents &&
      courseEvents.events &&
      courseEvents.events.filter((x) => {
        if (x.seats - x.countStudentRegistrations.aggregate.count > 0) {
          return x;
        }
      })
    );
  }, [courseEvents?.events]);

  const [search, setSearch] = useState('');
  const [courseResults, setCourseResults] = useState<any>(null);
  const searchKeyArray = ['detail.title'];
  const fuse = useRef(null);

  useEffect(() => {
    fuse.current = new F.default(openCourseEvents, {
      includeMatches: true,
      minMatchCharLength: 1,
      threshold: 0.2,
      keys: searchKeyArray,
    });
  }, [openCourseEvents]);

  useEffect(() => {
    if (openCourseEvents && search && search.length > 1) {
      let result = fuse.current.search(search);
      // @ts-ignore
      result = result.map((item) => {
        return item.item;
      });
      const limitedResults = result.slice(0, 30);
      setCourseResults(limitedResults);
    } else {
      const limitedResults =
        openCourseEvents && openCourseEvents.length > 0
          ? openCourseEvents?.slice(0, 30)
          : [];

      setCourseResults(limitedResults);
    }
  }, [search, openCourseEvents]);

  return (
    <div className="flex flex-col space-y-4 h-full min-w-[50vw] overflow-visible">
      <form onSubmit={handleSubmit(submit)} className="space-y-4">
        <div className="bg-white space-y-4">
          <div className="flex justify-between">
            <h4 className="text-xl flex-1">{title}</h4>
          </div>
          <div className="flex flex-col space-y-2">
            <Controller
              control={control}
              name="upcoming_event"
              render={({ field: { value } }) => {
                return (
                  <Combobox
                    value={value}
                    {...register('upcoming_event', {
                      required: true,
                    })}
                    //@ts-ignore
                    onChange={(e) => {
                      const item = e && e.id ? e : null;
                      setValue('upcoming_event', item);
                    }}
                  >
                    {({ open }) => (
                      <div className="relative mt-1 border-t py-2 w-full">
                        <div className="relative w-full">
                          <Combobox.Input
                            displayValue={(course: Value) =>
                              `£${course.price / 100} | ${
                                course.detail.title
                              } | (${
                                course.countStudentRegistrations.aggregate.count
                              }/ ${course.seats}) |${' '}${format(
                                new Date(course.start),
                                'EEE dd/MM/yyyy @ HH:mm',
                              )}`
                            }
                            className={`relative w-full py-2 pl-2 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 text-xs`}
                            placeholder="Choose a course"
                            autoComplete="off"
                            onChange={(event) => {
                              setSearch(event.target.value);
                            }}
                            onFocus={(e) => {
                              if (open) {
                                return;
                              }
                              // A hacky way of making the combobox show all available options straight away when focused.
                              const button = e.target
                                .nextSibling as HTMLButtonElement;
                              button.click();
                            }}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="w-4 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          // afterLeave={() =>
                          //   setState((s) => ({ ...s, client: null }))
                          // }
                        >
                          <Combobox.Options className="z-30 absolute w-full py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-xxs xl:text-xs">
                            {courseResults && courseResults.length === 0 ? (
                              <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                                {loading ? 'Loading...' : 'No results found'}
                              </div>
                            ) : (
                              courseResults &&
                              courseResults.map((course) => (
                                <Combobox.Option
                                  key={course.id}
                                  className={({ active }) =>
                                    `cursor-default select-none relative py-2 pl-7 pr-4 ${
                                      active
                                        ? 'text-white bg-purple-600'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={course}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block capitalize truncate ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        £{course.price / 100} |{' '}
                                        {course.detail.title} | (
                                        {
                                          course.countStudentRegistrations
                                            .aggregate.count
                                        }
                                        /{course.seats}) |{' '}
                                        {format(
                                          new Date(course.start),
                                          'EEE dd/MM/yyyy @ HH:mm',
                                        )}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 -left-1 flex items-center pl-3 ${
                                            active
                                              ? 'text-white'
                                              : 'text-purple-600'
                                          }`}
                                        >
                                          <CheckIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    )}
                  </Combobox>
                );
              }}
            />
          </div>
          <div className="flex space-x-4 w-full">
            <button
              // disabled={!formState.isDirty}
              className="btn-primary"
            >
              Add to event
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
