import { useLazyQuery, useMutation } from '@apollo/client';
import { ArrowsPointingOutIcon, TrashIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getUser } from 'queries/certificates';
import { createClient } from 'queries/clients';

import { useBase } from 'providers/base';
import { useUI } from 'providers/ui';

const errors = {
  unique: 'Email address is already registerd',
};

export default function NewClientModal({
  onComplete,
  prefill = null,
}: {
  onComplete: (data: any) => void;
  prefill: { [key: string]: string } | null;
}) {
  const ui = useUI();
  const { loadClients, clients } = useBase();
  const [error, setError] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const { register, handleSubmit, reset, control, watch } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      given_name: prefill ? prefill.name || '' : '',
      family_name: prefill ? prefill.surname || '' : '',
      email: prefill ? prefill.email || '' : '',
      contact_number: prefill ? prefill.phone || '' : '',
    },
  });

  // console.log("prefill", prefill)

  const [create] = useMutation(createClient);

  const handle = async (data) => {
    setError(null);
    const en = data.contact_number.startsWith('0');
    const contact_number = en
      ? `+44${data.contact_number.replace(/^0+/, '').replace(/ /g, '')}`
      : data.contact_number.replace(/ /g, '');

    const payload = {
      ...data,
      email: data.email.toLowerCase(),
      contact_number,
      role: '4506a6ca-8bb2-4ec9-8fb5-6c081f9cb2ef',
    };

    try {
      const response = await create({
        variables: {
          data: payload,
        },
      });

      if (response.data && response.data.client) {
        reset();
        onComplete(response.data.client);
        loadClients();
        ui.setToast(true, {
          type: 'success',
          title: 'New client added',
          message: `${data.given_name} ${data.family_name} has been added to the system`,
        });
      }
    } catch (err) {
      if (err.message) {
        if (err.message.includes('unique constraint')) {
          setError(errors.unique);
        }
      }
    }
  };

  const email = watch('email');
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    if (email && email.length > 3) {
      const currentMatches = clients
        .filter((client) => {
          return client.email === email;
        })
        .slice(0, 3);

      if (currentMatches && currentMatches?.length > 0) {
        setMatches(currentMatches);
        setDisableForm(true);
      } else {
        setMatches([]);
        setDisableForm(false);
      }
    }
  }, [email]);

  const action = useCallback(
    (user) => {
      ui.setClient(true, user);
    },
    [ui.setClient],
  );

  return (
    <section>
      <form onSubmit={handleSubmit(handle)} className="space-y-4">
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <label className="flex flex-col space-y-2">
          <span className="text-sm">First name</span>
          <input
            type="text"
            disabled={disableForm}
            name="given_name"
            {...register('given_name', { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light"
          />
        </label>
        <label className="flex flex-col space-y-2">
          <span className="text-sm">Last name</span>
          <input
            type="text"
            disabled={disableForm}
            name="family_name"
            {...register('family_name', { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light"
          />
        </label>
        <Controller
          control={control}
          name="email"
          render={() => {
            return (
              <label className="flex flex-col space-y-2">
                <span className="text-sm">Email address</span>
                <input
                  type="text"
                  name="email"
                  {...register('email', { required: true })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light"
                />
              </label>
            );
          }}
        />

        {matches && matches.length > 0 && (
          <div>
            <p className="text-sm font-semibold mb-1">Possible matches</p>
            {matches.map((client) => (
              <li
                key={client.name}
                className="flex px-3 py-2 border w-full items-center justify-between border-gray-300 rounded-md"
              >
                <div className="min-w-0">
                  <p className="text-sm font-medium leading-3 text-gray6900">
                    {client.name} {client.surname}
                  </p>
                  <p className="text-xs font-medium mt-1 text-gray-600">
                    {client.email}
                  </p>
                  {client?.createdAt && (
                    <p className="text-xs text-gray-500">
                      Created on{' '}
                      {format(new Date(client.createdAt), 'do MMMM yyyy') +
                        ' at ' +
                        format(new Date(client.createdAt), 'HH:mm')}
                    </p>
                  )}
                </div>

                <button
                  onClick={() => {
                    onComplete && onComplete({});
                    action(client?.id);
                  }}
                  className="btn-primary w-32"
                >
                  Show Profile
                </button>
              </li>
            ))}
          </div>
        )}
        <label className="flex flex-col space-y-2">
          <span className="text-sm">Phone number</span>
          <input
            type="text"
            disabled={disableForm}
            name="contact_number"
            {...register('contact_number', { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light"
          />
        </label>
        <div>
          <button disabled={disableForm} className="btn-primary">
            Add Client
          </button>
        </div>
      </form>
    </section>
  );
}
