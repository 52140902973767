import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface UIContextValue {
  client: boolean;
  clientData: string | undefined;
  panel: boolean;
  global: boolean;
  toast: boolean;
  panelData: any;
  globalData: any;
  toastData: any;
  search: string | null;
  image: any | null;
  detail: boolean;
  detailType: string | null;
  type: string | null;
  detailData: any;
  detailHandle: boolean;
  fullScreen: any | null;
  protected: boolean;
  call: boolean;
  callData: any;
  setPanel: (
    panel: boolean,
    panelData?: any,
    type?: any,
    callback?: () => void,
  ) => void;
  setGlobal: (global: boolean, type: string, globalData?: any) => void;
  setClient: (client: boolean, clientData?: any) => void;
  setToast: (toast: boolean, toastData?: any) => void;
  setSearch: (term: string) => void;
  setImage: (src?: string) => void;
  setFullScreen: (data: any | null) => void;
  setProtected: (pin?: string | boolean) => void;
  setCall: (call: boolean, data: any) => void;
}

const initialState = {
  client: false,
  clientData: undefined,
  panel: false,
  global: false,
  panelData: null,
  globalData: null,
  search: null,
  toast: false,
  toastData: null,
  image: null,
  type: null,
  fullScreen: null,
  protected: false,
  detail: false,
  detailType: null,
  detailData: null,
  detailHandle: false,
};

const UIContext = createContext(initialState as UIContextValue);

interface State {
  client: boolean;
  clientData: string | undefined;
  panel: boolean;
  global: boolean;
  panelData: any;
  globalData: any;
  toast: boolean;
  toastData: any;
  detail: boolean;
  detailHandle: boolean;
  detailType: string | null;
  detailData: null;
  type: string | null;
  search: string | null;
  image: string | null;
  fullScreen: string | null;
  protected: boolean;
  call: boolean;
  callData: any;
}

export const UI = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const [state, setState] = useState<State>({
    ...initialState,
    call: false,
    callData: null,
    protected: (() => {
      const t = localStorage.getItem('mg.protected');
      if (t) {
        return Boolean(t);
      }
      return false;
    })(),
  });

  useEffect(() => {
    setState((s) => ({ ...s, search: '' }));
  }, [router.pathname]);

  // const setPanel = useCallback(
  //   (panel: boolean, panelData?: any) => {
  //     setState((s) => ({ ...s, panel, panelData }));
  //   },
  //   [setState]
  // );

  const setPanel = useCallback(
    (panel: boolean, panelData?: any, type?: any, callback?: () => void) => {
      if (!panel) {
        setState((s) => {
          setTimeout(() => {
            setState((s) => ({
              ...s,
              panel,
              type,
              panelData: panelData,
            }));

            callback && callback();
          }, 600);
          return {
            ...s,
            panel: false,
          };
        });
      } else {
        setState((s) => ({
          ...s,
          panel,
          type,
          panelData: panelData,
        }));
      }
    },
    [setState],
  );

  const setClient = useCallback(
    (client: boolean, clientData?: any) => {
      setState((s) => ({ ...s, client, clientData }));
    },
    [setState],
  );

  const setToast = useCallback(
    (toast: boolean, toastData?: any) => {
      setState((s) => ({ ...s, toast, toastData }));
    },
    [setState],
  );

  const setSearch = useCallback(
    (term: string) => {
      setState((s) => ({ ...s, search: term }));
    },
    [setState],
  );

  const setImage = useCallback(
    (src?: any) => {
      setState((s) => ({ ...s, image: src }));
    },
    [setState],
  );

  const setFullScreen = useCallback(
    (data: any | null) => {
      setState((s) => ({ ...s, fullScreen: data }));
    },
    [setState],
  );

  const setProtected = useCallback(
    (pin?: string) => {
      if (pin) {
        localStorage.setItem('mg.protected', pin);
        setState((s) => ({ ...s, protected: true }));
      } else {
        setState((s) => ({ ...s, protected: false }));
      }
    },
    [setState],
  );

  const setCall = useCallback(
    (call, callData) => {
      setState((s) => ({ ...s, call, callData }));
    },
    [setState],
  );

  const setGlobal = useCallback(
    (global, type, globalData) => {
      setState((s) => ({ ...s, global, type, globalData }));
    },
    [setState],
  );

  const setDetail = useCallback(
    (
      detail: boolean,
      detailData: any = null,
      detailType: string | null = null,
    ) => {
      if (!detail) {
        setState((s) => {
          setTimeout(() => {
            setState((s) => ({
              ...s,
              detail: false,
              detailData,
              detailType,
            }));
          }, 550);
          return {
            ...s,
            detailHandle: false,
          };
        });
      } else {
        setState((s) => {
          return {
            ...s,
            detailHandle: true,
            detail,
            detailData,
            detailType,
          };
        });
      }
    },
    [setState],
  );

  const value: UIContextValue = useMemo(
    () => ({
      ...state,
      setPanel,
      setClient,
      setSearch,
      setToast,
      setImage,
      setFullScreen,
      setProtected,
      setCall,
      setDetail,
      setGlobal,
    }),
    [
      state,
      setFullScreen,
      setPanel,
      setToast,
      setSearch,
      setImage,
      setProtected,
      setDetail,
    ],
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

const UIProvider = UI;

export const useUI = () => useContext(UIContext);

export default UIProvider;
