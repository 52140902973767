import { gql } from 'graphql-request';

export const Vendor = gql`
  fragment Vendor on vendor {
    id
    name
    description
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Expense = gql`
  fragment Expense on expense {
    id
    amount
    expense_number
    employee_id
    user: employee {
      id
      memberAvatar: user_assets {
        asset: assetByAsset {
          id
          url
        }
      }
      name: given_name
    }
    explanation
    includes_vat
    vendor_id
    vendor {
      id
      name
      description
    }
    expense_assets {
      asset {
        url
        id
      }
    }
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const DiscountCode = gql`
  fragment DiscountCode on discount_code {
    id
    code
    type
    value
    quantity
    expires
    active
    options
    createdAt: created_at
    updatedAt: updated_at
  }
`;

export const Complaints = gql`
  fragment Complaints on complaints {
    id
    created_at
    updated_at
    title
    complaint
    resolution
    user_id
    employee_id
    assignee_id
    assignee {
      id
      user {
        id
        given_name
        family_name
        full_name
      }
    }
    user {
      id
      full_name
    }
    employee {
      id
      given_name
      family_name
      full_name
      user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
        asset: assetByAsset {
          id
          type
          url
        }
      }
    }
    notes {
      id
      note {
        id
        title
        created_at
        updated_at
        content
      }
    }
    calls {
      id
      complaint_id
      call_id
    }
  }
`;

export const Refund = gql`
  fragment Refund on refund {
    id
    amount
    status
    explanation
    employeeId: employee_id
    paymentId: payment_id
    createdAt: created_at
    updatedAt: updated_at
    employee: employee {
      id
      given_name
      family_name
      user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
        asset: assetByAsset {
          id
          type
          url
        }
      }
      memberAvatar: user_assets {
        asset: assetByAsset {
          id
          url
        }
      }
      name: given_name
    }
  }
`;

export const Invoice = gql`
  fragment Invoice on invoice {
    id
    amount
    user {
      id
      given_name
      family_name
    }
    status
    createdAt: created_at
    updatedAt: updated_at
    invoice_id
  }
`;

export const Payment = gql`
  fragment Payment on payment {
    id
    amount
    discount
    discountExplanation: discount_explanation
    user_id: user
    user: userByUser {
      id
      given_name
      family_name
    }
    status
    createdAt: created_at
    updatedAt: updated_at
    transaction_id
    method
    employee
    metadata
  }
`;
