import { gql } from '@apollo/client';

export const getForms = gql`
  query Forms {
    form(where: { archived: { _eq: false } }, order_by: { title: asc }) {
      id
      title
      content
      createdAt
      updatedAt
      archived
      formQuestions(order_by: { order: asc }) {
        id
        order
        properties
        question: questionByQuestion {
          id
          label
          archived

          type
          properties
        }
      }
    }
  }
`;

export const getUploadRequests = gql`
  query getUploadRequests {
    upload_request(order_by: { title: asc }) {
      id
      title
      description
      created_at
      updated_at
    }
  }
`;

export const getAllUserFormsById = gql`
  query getAllUserFormsById($id: [uuid!]!) {
    userForms: user_form(where: { id: { _in: $id } }) {
      id
      createdAt: created_at
      updatedAt: updated_at
      responsesByResponses {
        complete
        client_complete
      }
      formByForm {
        id
        title
        createdAt
        updatedAt
      }
      userByUser {
        id
        name: given_name
        surname: family_name
        email
      }
    }
  }
`;

export const getAllUserUploadRequestsById = gql`
  query getAllUploadRequestsById($id: [uuid!]!) {
    userUpload: user_upload_request(where: { id: { _in: $id } }) {
      id
      createdAt: created_at
      updatedAt: updated_at
      complete
      client_complete
      uploadRequest {
        id
        title
        description
        created_at
        updated_at
      }
      userByUser {
        id
        name: given_name
        surname: family_name
        email
      }
    }
  }
`;

export const getForm = gql`
  query getForm($id: uuid!) {
    form: user_form_by_pk(id: $id) {
      id
      createdAt: created_at
      updatedAt: updated_at
      responsesByResponses {
        complete
        asset
        responses
        id
      }
      form: formByForm {
        id
        title
        content
        formQuestions(order_by: { order: asc }) {
          id
          order
          properties
          question: questionByQuestion {
            id
            label
            archived
            type
            type
            properties
          }
        }
      }
    }
  }
`;

export const getDoc = gql`
  query FormById($id: uuid!) {
    form: form_by_pk(id: $id) {
      id
      formQuestions(order_by: { order: asc }) {
        id
        order
        properties
        question: questionByQuestion {
          id
          archived
          label
          type
          properties
        }
      }
    }
  }
`;

export const getDocs = gql`
  query FormsByIds($ids: [uuid!]) {
    forms: form(where: { id: { _in: $ids } }) {
      id
      formQuestions(order_by: { order: asc }) {
        id
        order
        properties
        question: questionByQuestion {
          id
          archived
          label
          type
          properties
        }
      }
    }
  }
`;

export const getNote = gql`
  query NoteById($id: uuid!) {
    note: note_by_pk(id: $id) {
      id
      title
      content
      course
      created_at
      __typename
    }
  }
`;

export const getFormForEdit = gql`
  query FormById($id: uuid!) {
    form: form_by_pk(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
      type
      formQuestions(order_by: { order: asc }) {
        id
        order
        properties
        question: questionByQuestion {
          id
          label
          type
          public
          archived
          properties
        }
      }
    }
  }
`;

export const updateResponses = gql`
  mutation updateResponses($id: uuid!, $responses: jsonb!, $user_form: uuid!) {
    update_form_response_by_pk(
      pk_columns: { id: $id }
      _set: { responses: $responses, user_form: $user_form }
    ) {
      id
      asset
      responses
      form
      createdAt
      updatedAt
    }
  }
`;

export const archiveForm = gql`
  mutation deleteFormByID($id: uuid!) {
    delete_form(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const deleteForm = gql`
  mutation deleteFormByID($id: uuid!) {
    delete_form(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const createForm = gql`
  mutation createForm($data: form_insert_input!) {
    insert_form_one(object: $data) {
      id
      content
    }
  }
`;

export const getQuestionTypes = gql`
  query getQuestionTypes {
    question_type {
      value
    }
  }
`;

export const insertFormQuestions = gql`
  mutation insertFormQuestions($data: [form_question_insert_input!]!) {
    insert_form_question(objects: $data) {
      affected_rows
      returning {
        id
        questionByQuestion {
          id
        }
      }
    }
  }
`;

export const updateFormByID = gql`
  mutation updateFormByID($id: uuid!, $data: form_set_input!) {
    update_form_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const upsertFormQuestions = gql`
  mutation upsertFormQuestions($data: [form_question_insert_input!]!) {
    insert_form_question(
      objects: $data
      on_conflict: {
        constraint: form_question_pkey
        update_columns: [order, question, form, properties]
      }
    ) {
      affected_rows
      returning {
        id
        questionByQuestion {
          id
        }
      }
    }
  }
`;

export const upsertQuestions = gql`
  mutation upsertQuestions($data: [question_insert_input!]!) {
    insert_question(
      objects: $data
      on_conflict: {
        constraint: question_pkey
        update_columns: [label, type, properties, archived, public]
      }
    ) {
      affected_rows
      returning {
        id
        archived
        label
        type
        properties
      }
    }
  }
`;

export const deleteFormQuestionsByID = gql`
  mutation deleteFormQuestionsByID($ids: [uuid!]!) {
    delete_form_question(where: { id: { _in: $ids } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const updateFormResponses = gql`
  mutation updateResponses($id: uuid!, $responses: jsonb!) {
    update_form_response_by_pk(
      pk_columns: { id: $id }
      _set: { responses: $responses }
    ) {
      id
      asset
      complete
      responses
      form
      appointment
      createdAt
      updatedAt
    }
  }
`;

export const insertFormResponse = gql`
  mutation insertFormResponse($data: [form_response_insert_input!]!) {
    insert_form_response(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const addUrl = gql`
  mutation addUrl($id: uuid!, $url: String!, $complete: Boolean!) {
    update_user_upload_request_by_pk(
      pk_columns: { id: $id }
      _set: { url: $url, complete: $complete }
    ) {
      id
      url
      complete
      user
    }
  }
`;

export const updateUserFormByID = gql`
  mutation updateUserFormByID($id: uuid!, $data: user_form_set_input!) {
    update_user_form_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;
