import { useQuery } from '@apollo/client';
import { Disclosure } from '@headlessui/react';
import {
  FolderPlusIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import format from 'date-fns/format';
import dynamic from 'next/dynamic';
import Calls from 'pages/incoming/calls';
import { useState } from 'react';

import AddComplaint from 'components/molecules/panels/complaints/add-complaint';
import ManageComplaint from 'components/molecules/panels/complaints/manage-complaint';
import { options } from 'components/organisms/period';

import { getClientComplaints } from 'queries/complaints';

const Modal = dynamic(() => import('components/molecules/modal'));

const Status = dynamic(() => import('components/atoms/status'));

export enum ModalType {
  None = 'None',
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}

export enum StatusType {
  Open = 'Open',
  Closed = 'Closed',
  AwaitingResponse = 'Awaiting Response',
  RequiresInput = 'Requires Input',
  ResponseSent = 'Response Sent',
}

export default function CallComplaints({
  userId,
  handleComplaint,
  selected,
  callId,
}: {
  userId: string;
  handleComplaint: (id) => void;
  selected: string;
  callId: string;
}) {
  const [state, setState] = useState({
    expandItem: null,
    expandItemIndex: 0,
    hoveredItem: null,
    isEditingItem: false,
    editingItemID: null,
    selectedCategories: [],
    selectedStatus: [],
    loading: false,
    showModal: ModalType.None,
    data: null,
    lead: null,
    type: null,
  });

  const [dateState, setDateState] = useState({
    selectedStatus: [],
    selectedType: [],
    controlOption: options[5],
  });

  const { data: complaintsData } = useQuery(getClientComplaints, {
    variables: {
      id: userId,
    },
  });

  const foundComplaintData = complaintsData?.complaints?.filter((elem) => {
    // If no filter then display all
    if (state.selectedStatus.length === 0) {
      return true;
    }

    // If only resolution
    if (state.selectedStatus.length > 0) {
      const status = state.selectedStatus.map((x) => x.name);
      return status.includes(elem.resolution);
    }
  });

  const closeModal = () => {
    setState((s) => ({ ...s, showModal: ModalType.None }));
  };

  const editMode = () => {
    if (state.showModal === ModalType.Edit) {
      setState((s) => ({ ...s, showModal: ModalType.View }));
      return;
    }
    setState((s) => ({ ...s, showModal: ModalType.Edit }));
  };

  const handleClick = ({ type, lead }) => {
    setState((s) => ({ ...s, type, lead }));
  };

  return (
    <>
      {/* MODALS */}
      <Modal
        title="Add Complaint"
        open={state.showModal === ModalType.Add}
        close={closeModal}
      >
        <AddComplaint closeModal={closeModal} dateState={dateState} />
      </Modal>

      <Modal
        title={
          state.showModal === ModalType.View
            ? 'View Complaint'
            : 'Edit Complaint'
        }
        full
        open={
          state.showModal === ModalType.View ||
          state.showModal === ModalType.Edit
        }
        close={closeModal}
      >
        <ManageComplaint
          dateState={dateState}
          complaintId={state.data}
          editMode={editMode}
          closeModal={closeModal}
          mode={state.showModal}
          handleClick={handleClick}
        />
      </Modal>

      {state.lead && (
        <Modal
          full
          open={!!state.lead}
          close={() => setState((s) => ({ ...s, type: null, lead: null }))}
        >
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <Calls callID={state.lead} />
          </div>
        </Modal>
      )}

      <div className="flex-1">
        {foundComplaintData && foundComplaintData?.length > 0 ? (
          <div className="flex-1 overflow-hidden flex flex-col">
            <ul
              role="list"
              className=" border-gray-300 divide-y divide-gray-300 flex flex-col flex-1 overflow-hidden"
            >
              <div className="overflow-hidden xl:p-4 p-2 flex-1">
                <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-full overflow-scroll bg-white">
                  <div className="min-w-full divide-y divide-gray-300 border">
                    <div className="grid grid-cols-9 bg-gray-100 px-2 pt-2">
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-2">
                        Title
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-2">
                        Date
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-2">
                        Assignee
                      </div>
                      <div className="text-xs ml-5 xl:text-sm font-semibold text-gray-900 col-span-2">
                        Status
                      </div>
                      <div className="text-xs xl:text-sm font-semibold text-gray-900 col-span-1">
                        Details
                      </div>
                    </div>
                    {foundComplaintData && foundComplaintData?.length > 0 && (
                      <div className="flex flex-col flex-1 relative overflow-auto">
                        <div className="inline-block min-w-full align-middle divide-y divide-gray-200">
                          {foundComplaintData &&
                            foundComplaintData?.map((complaint, i) => {
                              const isSelected = selected === complaint.id;
                              const callArray = complaint.calls.map(
                                (x) => x.call_id,
                              );
                              const alreadyExists = callArray.includes(callId);

                              return (
                                <Disclosure
                                  key={complaint.id}
                                  as="div"
                                  className="min-w-full"
                                >
                                  {() => (
                                    <>
                                      <Disclosure.Button
                                        disabled={alreadyExists}
                                        className={`${
                                          isSelected && 'bg-purple-400'
                                        }  ${
                                          alreadyExists &&
                                          '!bg-gray-400 cursor-not-allowed'
                                        } flex w-full min-w-full justify-between items-center px-2 py-0 text-left text-sm font-medium text-purple-900 ${
                                          !isSelected && 'hover:bg-purple-100'
                                        }  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
                                        onClick={() => {
                                          handleComplaint(complaint.id);
                                        }}
                                      >
                                        <div className="grid grid-cols-9 w-full items-center mr-2 font-normal">
                                          {/* title col */}
                                          <div className="col-span-2 truncate text-xs xl:text-sm text-gray-500 p-2 w-full">
                                            {complaint.title || 0}
                                          </div>

                                          {/* dates col */}
                                          <div className="col-span-2 text-xs xl:text-sm text-gray-500 p-2">
                                            {format(
                                              new Date(complaint.created_at),
                                              "do MMM yyyy 'at' HH:mm ",
                                            )}
                                          </div>

                                          {/* assignee col */}
                                          <div className="col-span-2 text-xs xl:text-sm text-gray-500 p-2">
                                            {complaint.employee ? (
                                              <div className="flex">
                                                {/* <Avatar
                                                    id={
                                                      complaint.assignee_id &&
                                                      complaint.assignee_id
                                                    }
                                                  /> */}
                                                <span className="leading-6 pl-1 block truncate">
                                                  {complaint?.assignee?.user
                                                    ?.full_name ||
                                                    'No Assignee'}
                                                </span>
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>

                                          {/* status col */}

                                          <div className="col-span-2 text-xs text-center xl:text-sm mr-2 text-gray-500 ">
                                            <Status
                                              value={complaint.resolution.toUpperCase()}
                                            />
                                          </div>

                                          <div className="col-span-1 flex items-center justify-center text-xs text-center xl:text-sm mr-2 text-gray-500 ">
                                            <InformationCircleIcon
                                              className="w-4 h-4"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setState((s) => ({
                                                  ...s,
                                                  data: complaint?.id,
                                                  showModal: ModalType.View,
                                                }));
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Disclosure.Button>
                                    </>
                                  )}
                                </Disclosure>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ul>
          </div>
        ) : (
          <div className="text-center flex flex-col items-center justify-center my-8">
            <div className="flex flex-1 flex-col h-full flex-grow p-8 px-4 items-center justify-center">
              <FolderPlusIcon className="h-12 w-12 text-purple-400 stroke-1" />
            </div>
            <h3 className="-mt-4 text-sm font-medium text-gray-700">
              No Complaints
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {`No Complaints Meet This Criteria in The Selected Period`}
            </p>
          </div>
        )}
      </div>
    </>
  );
}
