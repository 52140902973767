import { Disclosure } from '@headlessui/react';
import { CameraIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import classNames from 'utils/classnames';

import UploadsTab from '../uploads';

export default function UploadPanel({
  uploadIds,
  action,
}: {
  uploadIds: string[];
  action: (user: any) => void;
}) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="text-gray-700 hover:text-purple-600 hover:bg-gray-50 group gap-x-3 flex justify-between items-center rounded-md w-full p-2 text-sm leading-6 font-semibold">
            <div className="flex items-center gap-x-3">
              <CameraIcon
                className="text-gray-400 group-hover:text-purple-600 h-6 w-6 shrink-0"
                aria-hidden="true"
              />
              <span>Uploads</span>
            </div>
            <ChevronRightIcon
              className={classNames(
                open ? 'rotate-90' : '',
                '-mr-1 ml-2 h-4 w-4 text-purple-600 hover:text-purple-500 transition-all',
              )}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Disclosure.Panel className="py-2 text-sm text-gray-500 border-t border-gray-300">
            <UploadsTab action={action} uploadID={uploadIds} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
