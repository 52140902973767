import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import Status from 'components/atoms/status';

import { getAllUserUploadRequestsById } from 'queries/documents';

export const UploadsTab = ({
  uploadID,
  action,
}: {
  uploadID: string[];
  action: any;
}) => {
  const { data: uploadData } = useQuery(getAllUserUploadRequestsById, {
    variables: {
      id: uploadID,
    },
  });

  if (!uploadData?.userUpload) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 divide-y sm:rounded sm:overflow-hidden">
      {uploadData?.userUpload.map((upload, index) => {
        return (
          <button
            onClick={() => action(upload?.userByUser?.id)}
            className="sm:overflow-hidden w-full text-left hover:bg-gray-50 px-4"
            key={index}
          >
            <div className="p-5 md:py-5 md:px-0 space-y-1 max-w-lg mx-auto">
              <div className="flex justify-between">
                <div className="font-semibold">
                  {upload?.userByUser?.name} {upload?.userByUser?.surname}
                </div>

                <div className="whitespace-nowrap text-sm text-gray-500">
                  {upload.complete ? (
                    <p className="bg-green-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                      Complete
                    </p>
                  ) : upload.client_complete ? (
                    <p className="bg-amber-500 text-center p-1 px-2.5 text-white text-xs rounded-full">
                      Review
                    </p>
                  ) : (
                    <p className="bg-red-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                      Incomplete
                    </p>
                  )}
                </div>
              </div>
              <div className="text-base font-semibold leading-6 text-gray-800">
                {upload?.uploadRequest?.title}
              </div>
              <div className="">{upload?.uploadRequest?.description}</div>
              {upload?.createdAt && (
                <div className="text-xs text-gray-500 pt-2">
                  {format(new Date(upload.createdAt), "do LLL y 'at' h:mm aaa")}
                </div>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default UploadsTab;
