import { useMutation } from '@apollo/client';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  deleteMessage,
  getDefinedMessages,
  updateMessage,
} from 'queries/leads';

import { useUI } from 'providers/ui';

const types = [
  {
    name: 'Email',
  },
  {
    name: 'Text',
  },
];

export default function EditDefinedMessage({
  state,
  item,
  setState,
  resetText,
  resetEmail,
  setSelected,
}) {
  const ui = useUI();
  const [selectedType, setSelectedType] = useState(types[0]);
  const [editMessage] = useMutation(updateMessage);
  const [removeMessage] = useMutation(deleteMessage);

  const {
    register: register,
    handleSubmit: handleSubmit,
    reset: reset,
    setValue: setValue,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      title: item.title,
      content: item.content,
      type: item.type,
    },
  });

  useEffect(() => {
    setSelectedType({ name: item.type });
  }, [item]);

  const submit = async (data) => {
    try {
      const result = await editMessage({
        variables: {
          id: item.id,
          data: {
            title: data.title,
            content: data.content,
            type: data.type,
          },
        },
        refetchQueries: [
          {
            query: getDefinedMessages,
            variables: {
              where: {
                _and: [{ type: { _eq: 'Email' } }, { type: { _eq: 'Text' } }],
              },
            },
          },
        ],
      });
      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Successfully Edited Message',
          message: '',
        });
        reset();
        resetText();
        resetEmail();
        setSelected(null);
        setSelectedType(null);
        setState({ ...state, editMessageModal: false });
      }
    } catch (err) {
      console.log(err, '1x: error');
      ui.setToast(true, {
        type: 'error',
        title: 'Error Editing Message',
        message: '',
      });
    }
  };
  const deleteMessages = async () => {
    try {
      const result = await removeMessage({
        variables: {
          id: item.id,
        },
        refetchQueries: [
          {
            query: getDefinedMessages,
            variables: {
              where: {
                _and: [{ type: { _eq: 'Email' } }, { type: { _eq: 'Text' } }],
              },
            },
          },
        ],
      });
      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Successfully Deleted Message',
          message: '',
        });
        reset();
        resetText();
        resetEmail();
        setSelected(null);
        setSelectedType(null);
      }
    } catch (err) {
      console.log('1x: error', err);
      ui.setToast(true, {
        type: 'error',
        title: 'Error Deleting Message',
        message: '',
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="-mt-4">
          <h4 className="font-semibold mb-3">Edit Defined Message</h4>
        </div>
        <div>
          <label className="font-medium text-gray-700 select-none text-sm">
            <span className="block mb-1">Type</span>
            <Listbox
              value={selectedType}
              onChange={(x) => {
                setSelectedType(x);
                setValue('type', x.name);
              }}
            >
              {({ open }) => (
                <>
                  <div className="mt-1 relative w-full">
                    <Listbox.Button
                      className={`bg-white appearance-none block w-full h-8 text-left px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2`}
                    >
                      <span className="block truncate">
                        {selectedType
                          ? selectedType.name
                          : 'Choose A Message Type'}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-purple-600"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        static
                        className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-xs sm:text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
                      >
                        {types.map((type, i) => (
                          <Listbox.Option
                            key={i}
                            className={({ active }) =>
                              active
                                ? 'text-white bg-purple-600 cursor-default select-none relative py-2 pl-3 pr-9 text-xs sm:text-sm'
                                : 'text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 text-xs sm:text-sm'
                            }
                            value={type}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={
                                    selected
                                      ? 'font-semibold'
                                      : 'font-normal block truncate'
                                  }
                                >
                                  {type?.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={
                                      active
                                        ? 'text-white absolute inset-y-0 right-0 flex items-center pr-4'
                                        : 'text-purple-600 absolute inset-y-0 right-0 flex items-center pr-4'
                                    }
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </label>
        </div>
        {selectedType && selectedType.name === 'Email' && (
          <div>
            <label className="font-medium text-gray-700 select-none text-sm">
              <span className="block mb-1">Subject</span>
              <input
                type="text"
                name="title"
                defaultValue={item.title}
                className="appearance-none block w-full h-8 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
                {...register('title')}
              />
            </label>
          </div>
        )}
        {selectedType && selectedType.name === 'Text' && (
          <div>
            <label className="font-medium text-gray-700 select-none text-sm">
              <span className="block mb-1">Title</span>
              <input
                defaultValue={item.title}
                type="text"
                name="title"
                className="appearance-none block w-full h-8 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
                {...register('title')}
              />
            </label>
          </div>
        )}
        <div>
          <label className="font-medium text-gray-700 select-none text-sm">
            <span className="block mb-1">Content</span>
            <textarea
              defaultValue={item.content}
              name="content"
              className="appearance-none block w-full h-16 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
              {...register('content', { required: true })}
            />
          </label>
        </div>

        <div className="flex justify-between pt-6">
          <div className="w-40">
            <button
              type="button"
              onClick={(s) => {
                setState({ ...s, editMessageModal: false });
                deleteMessages();
              }}
              className="btn-red cursor-pointer"
            >
              Remove
            </button>
          </div>
          <div className="w-40">
            <button className="btn-primary">Edit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
