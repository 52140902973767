import { useMutation, useQuery } from '@apollo/client';
import { Combobox, Disclosure, Listbox, Transition } from '@headlessui/react';
import {
  AtSymbolIcon,
  CheckCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { endOfDay, format, startOfDay } from 'date-fns';
import * as F from 'fuse.js';
import { debounce } from 'lodash';
import dynamic from 'next/dynamic';
import { ModalType } from 'pages/complaints';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import Avatar from 'components/atoms/avatar';

import classNames from 'utils/classnames';

import {
  addNoteOnUpdate,
  deleteComplaintNoteOne,
  deleteNoteOne,
  getAllComplaints,
  getComplaintById,
  getComplaintCalls,
  getComplaintNotes,
  updateComplaint,
  updateComplaintNote,
} from 'queries/complaints';

import { useBase } from 'providers/base';
import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

import { memberSearchOptions, searchOptions } from './add-complaint';
import ComplaintCall from './complaint-call';
import ComplaintClient from './user-profile';

const resolutions = [
  'Open',
  'Closed',
  'Awaiting Response',
  'Requires Input',
  'Response Sent',
];

const ManageComplaint = ({
  dateState,
  complaintId,
  editMode,
  mode,
  closeModal,
  handleClick,
}: {
  editMode: () => void;
  dateState: any;
  complaintId: string;
  mode: ModalType;
  closeModal: () => void;
  handleClick: ({ type, lead }) => void;
}) => {
  const { claims } = useSession();
  const role = claims['x-hasura-user-id'];
  const [selectedResolution, setSelectedResolution] = useState(null);
  const [updateComplaintMutation] = useMutation(updateComplaint);
  const [addNewNoteOnUpdate] = useMutation(addNoteOnUpdate);
  const [editNoteHandler] = useMutation(updateComplaintNote);

  const [deleteSingleNote] = useMutation(deleteNoteOne);
  const [deleteComplaintNote] = useMutation(deleteComplaintNoteOne);
  const ui = useUI();

  const [note, setNote] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [editedNote, setEditedNote] = useState(null);
  const { clients } = useBase();
  const { team } = useOrganization();
  const [selected, setSelected] = useState({
    name: null,
    surname: null,
    id: null,
  });

  const [assignTo, setAssignedTo] = useState({
    id: null,
    user: { name: null, surname: null, id: null },
  });

  const cFuse = useRef(new F.default(clients, searchOptions));
  const mFuse = useRef(new F.default(team, memberSearchOptions));

  useEffect(() => {
    cFuse.current = new F.default(clients, searchOptions);
    mFuse.current = new F.default(team, memberSearchOptions);
  }, [team, clients]);

  const { data: allComplaintData } = useQuery(getComplaintById, {
    variables: {
      id: complaintId,
    },
  });

  const complaintData = allComplaintData?.complaints_by_pk;

  const [state, setState] = useState({
    query: '',
    recent: [],
    focused: null,
    memberQuery: '',
    clientInputItems: cFuse.current.search('').map((x) => x.item),
    memberInputItems: mFuse.current.search('').map((x) => x.item),
    data: null,
  });

  const debouncedClientChange = useRef(
    debounce((inputValue) => {
      setState((s) => ({
        ...s,
        query: inputValue,
        clientInputItems: cFuse.current.search(inputValue).map((x) => x.item),
      }));
    }, 50),
  );

  const debouncedMemberChange = useRef(
    debounce((inputValue) => {
      setState((s) => ({
        ...s,
        memberQuery: inputValue,
        memberInputItems: mFuse.current.search(inputValue).map((x) => x.item),
      }));
    }, 50),
  );

  useEffect(() => {
    setSelectedResolution(complaintData && complaintData.resolution);
    setSelected({
      name: complaintData?.user?.given_name,
      surname: complaintData?.user?.family_name,
      id: complaintData?.user?.id,
    });
    setAssignedTo({
      id: complaintData?.assignee?.id,
      user: {
        name: complaintData?.assignee?.user?.given_name,
        surname: complaintData?.assignee?.user?.family_name,
        id: complaintData?.assignee?.user?.id,
      },
    });

    setTimeout(() => {
      setValue('title', complaintData?.title);
      setValue('message', complaintData?.complaint);
      setValue('resolution', complaintData?.resolution);
      setValue('client', complaintData?.user_id);
      setValue('member', complaintData?.employee?.id);
      setValue('assignee', complaintData?.assignee?.id);
      setValue('note', null);
    }, 50);
  }, [complaintData]);

  const {
    register: registercomplaints,
    handleSubmit: handleComplaints,
    reset: resetComplaints,
    setValue: setValue,
  } = useForm({
    defaultValues: {
      title: complaintData?.title,
      message: complaintData?.complaint,
      resolution: complaintData?.resolution,
      client: complaintData?.user_id,
      member: complaintData?.employee?.id,
      assignee: complaintData?.assignee?.id,
      note: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { data: complaintNotesData } = useQuery(getComplaintNotes, {
    variables: {
      cid: complaintData?.id,
    },
  });

  const { data: complaintCallsData } = useQuery(getComplaintCalls, {
    variables: {
      cid: complaintData?.id,
    },
  });

  const handleUpdate = async (e: string) => {
    const payload = {
      resolution: e,
    };

    await updateComplaintMutation({
      variables: {
        id: complaintData?.id,
        data: payload,
      },
      refetchQueries: [
        {
          query: getAllComplaints,
          variables: {
            end: endOfDay(dateState.controlOption.value.end),
            start: startOfDay(dateState.controlOption.value.start),
          },
        },
      ],
    });
  };

  // edit complaint
  const submitEdit = async (data) => {
    try {
      const payload: { [key: string]: any } = {
        assignee_id: data && data.assignee,
        title: data && data.title,
        complaint: data && data.message,
        resolution: data && data.resolution,
        user_id: data && data.client,
      };

      const result = await updateComplaintMutation({
        variables: {
          id: complaintData.id,
          data: payload,
        },
        refetchQueries: [
          {
            query: getAllComplaints,
            variables: {
              end: endOfDay(dateState.controlOption.value.end),
              start: startOfDay(dateState.controlOption.value.start),
            },
          },
        ],
      });

      if (result) {
        submitNote(data);
        ui.setToast(true, {
          type: 'success',
          title: 'Complaint Updated Successfully',
          message: '',
        });
      }
      resetComplaints();
      closeModal();
    } catch (err) {
      ui.setToast(true, {
        type: 'error',
        title: 'Unable to update Complaint',
        message: `${err}`,
      });
      closeModal();
    }
  };

  const submitEditedNote = async (data) => {
    if (!data) return;

    try {
      setEditNote(false);
      await editNoteHandler({
        variables: {
          id: data.id,
          content: data.content,
        },
        refetchQueries: [
          {
            query: getComplaintNotes,
            variables: {
              cid: complaintData.id,
            },
          },
        ],
      });
      resetComplaints();
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  //delete single note
  const deleteOneNote = async (id: string) => {
    if (confirm('Are you sure you want to delete this note?')) {
      await deleteSingleNote({
        variables: {
          nid: id,
        },
        refetchQueries: [
          {
            query: getComplaintNotes,
            variables: {
              cid: complaintData?.id,
            },
          },
        ],
      });
      await deleteComplaintNote({
        variables: {
          id: id,
        },
        refetchQueries: [
          {
            query: getComplaintNotes,
            variables: {
              cid: complaintData?.id,
            },
          },
        ],
      });
    }
  };

  //submit note
  const submitNote = async (data) => {
    try {
      let result = await addNewNoteOnUpdate({
        variables: {
          data: {
            complaint_id: complaintId,
            note: {
              data: {
                content: data.note,
              },
            },
          },
        },
        refetchQueries: [
          {
            query: getComplaintNotes,
            variables: {
              cid: complaintData?.id,
            },
          },
        ],
      });
      if (result) {
        setNote(false);
      }
      resetComplaints();
    } catch (error) {
      return null;
    }
  };

  return (
    <section className="flex flex-1 h-full justify-between flex-col divide-y divide-gray-200 overflow-hidden">
      <div className="flex overflow-scroll flex-col">
        <ComplaintClient id={complaintData?.user_id} />

        {mode === ModalType.View && (
          <div className="space-y-4">
            <div className="space-y-4 px-4 pb-4 pt-2">
              <div className="flex flex-col-2 space-y-2">
                <div className="w-full">
                  <div className="flex justify-between">
                    <span className="py-1 text-sm text-gray-500">Title</span>
                    <p className="py-1 text-sm xl:text-sm text-gray-800">
                      {complaintData?.title}
                    </p>
                  </div>
                  <div className="flex justify-between border-b">
                    <span className="py-3 text-sm text-gray-500 ">Status</span>
                    <div className="py-2 w-1/2">
                      <Listbox
                        value={selectedResolution}
                        onChange={(e) => {
                          setSelectedResolution(e);
                          setValue('resolution', e);
                          handleUpdate(e);
                        }}
                      >
                        <Listbox.Button className="transition-all w-full text-left px-4 bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs ">
                          {selectedResolution}
                        </Listbox.Button>

                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="relative m-0 bg-white">
                            <Listbox.Options className="absolute z-2 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {resolutions.map((resolution) => (
                                <Listbox.Option
                                  key={resolution}
                                  value={resolution}
                                  onChange={(e) =>
                                    setSelectedResolution(
                                      (selectedResolution) =>
                                        selectedResolution === e,
                                    )
                                  }
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 rounded-md ${
                                      active
                                        ? 'bg-purple-500 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate rounded-full ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {resolution}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-800">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </div>
                        </Transition>
                      </Listbox>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <span className="py-2 text-xs text-gray-500 ">
                      Created at
                    </span>
                    <p className="py-2 text-xs text-gray-800">
                      {complaintData &&
                        complaintData.created &&
                        format(
                          new Date(complaintData?.created),
                          'do MMM yyyy',
                        )}{' '}
                      at{' '}
                      {complaintData &&
                        complaintData.created &&
                        format(new Date(complaintData?.created), 'HH:mm')}
                    </p>
                  </div>

                  <div className="flex justify-between border-b">
                    <span className="py-2 text-xs text-gray-500 ">
                      Last Updated At
                    </span>
                    <p className="py-2 text-xs text-gray-800">
                      {complaintData &&
                        complaintData.updated &&
                        format(
                          new Date(complaintData?.updated),
                          'do MMM yyyy ',
                        )}{' '}
                      at{' '}
                      {complaintData &&
                        complaintData.updated &&
                        format(new Date(complaintData?.updated), 'HH:mm')}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <span className=" py-2 text-xs text-gray-500 ">
                      Updated By
                    </span>
                    <div className="py-2 text-xs flex gap-1 xl:text-xs text-gray-800">
                      <Avatar
                        size={{
                          width: 6,
                          height: 6,
                        }}
                        layout="fill"
                        src={
                          complaintData?.employee?.user_assets[0]?.asset?.url
                        }
                      />
                      {complaintData?.employee?.full_name
                        ? complaintData.employee.full_name
                        : `${complaintData?.employee?.given_name} ${complaintData?.employee?.family_name}`}
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <span className=" py-2 text-xs text-gray-500 ">
                      Assigned To
                    </span>

                    <div className="py-2 flex items-center gap-1 text-xs xl:text-xs text-gray-800">
                      <Avatar
                        size={{
                          width: 6,
                          height: 6,
                        }}
                        layout="fill"
                        src={
                          complaintData?.assignee?.user?.user_assets[0]?.asset
                            ?.url
                        }
                      />
                      {complaintData?.assignee?.user?.given_name}{' '}
                      {complaintData?.assignee?.user?.family_name}
                    </div>
                  </div>
                  <div className="flex flex-col justify-between border-b">
                    <span className=" py-3 text-sm text-gray-500 ">
                      Nature Of Complaint
                    </span>
                    <p className=" py-3  text-sm xl:text-sm text-gray-800">
                      {complaintData?.complaint}
                    </p>
                  </div>
                  <div className=" relative flex flex-col justify-between border-b">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={
                              'flex justify-between py-3 text-sm text-gray-500'
                            }
                          >
                            Calls (
                            {complaintCallsData &&
                              complaintCallsData.complaint_call.length}
                            )
                            <ChevronUpIcon
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } self-left h-5 w-5 text-purple-500 ml-4`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            {complaintCallsData ? (
                              <div>
                                {complaintCallsData.complaint_call.map(
                                  ({ call, idx }) => {
                                    if (!call) return null;
                                    return (
                                      <ComplaintCall
                                        key={idx}
                                        call={call}
                                        handleClick={handleClick}
                                      />
                                    );
                                  },
                                )}
                              </div>
                            ) : (
                              <p className=" py-3  text-sm xl:text-sm text-gray-800">
                                No notes added to complaint
                              </p>
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  <div className=" relative flex flex-col justify-between border-b">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={
                              'flex justify-between py-3 text-sm text-gray-500'
                            }
                          >
                            Notes (
                            {complaintNotesData &&
                              complaintNotesData.complaint_note.length}
                            )
                            <ChevronUpIcon
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } self-left h-5 w-5 text-purple-500 ml-4`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            {complaintNotesData ? (
                              <div>
                                {complaintNotesData.complaint_note.map(
                                  ({ note }) => {
                                    if (!note) return null;
                                    return (
                                      <div
                                        className="relative flex-col my-2 pt-3 w-full items-center shadow-md px-4 py-1.5 border border-gray-300 text-xs leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 justify-between"
                                        key={`complaint-note-${note.id}`}
                                      >
                                        {editNote === note.id ? (
                                          <>
                                            <CheckCircleIcon
                                              className="absolute right-6 top-1 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-purple-700"
                                              onClick={() => {
                                                submitEditedNote(editedNote);
                                                setIsEditNote(false);
                                                setEditNote(null);
                                              }}
                                            />
                                            <XCircleIcon
                                              className="absolute right-1 top-1 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-purple-700"
                                              onClick={() => {
                                                setIsEditNote(false);
                                                setEditNote(null);
                                              }}
                                            />

                                            <textarea
                                              autoFocus
                                              defaultValue={note.content}
                                              onChange={(e) =>
                                                setEditedNote({
                                                  content: e.target.value,
                                                  id: note.id,
                                                })
                                              }
                                              className={`${
                                                isEditNote ? '' : 'hidden'
                                              } transition-all px-1 py-1 text-left w-10/12 bg-white shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white rounded border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500  text-xs`}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <div className="border-b pb-1">
                                              <PencilSquareIcon
                                                className={`${
                                                  role === note.user
                                                    ? ''
                                                    : 'hidden'
                                                } absolute right-6 top-2 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-purple-700`}
                                                onClick={() => {
                                                  setEditNote(note.id);
                                                  setIsEditNote(true);
                                                }}
                                              />
                                              <TrashIcon
                                                className={`${
                                                  role === note.user
                                                    ? ''
                                                    : 'hidden'
                                                }  absolute right-1 top-2 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-red-400`}
                                                onClick={() => {
                                                  deleteOneNote(note.id);
                                                }}
                                              />
                                              <div>
                                                <Avatar
                                                  size={{
                                                    width: 6,
                                                    height: 6,
                                                  }}
                                                  layout="fill"
                                                  src={
                                                    note?.employee?.assets[0]
                                                      ?.asset?.url
                                                  }
                                                />
                                                {note?.employee?.name}{' '}
                                                {note?.employee?.surname}
                                              </div>
                                            </div>
                                            <p className="text-xs pt-1 w-5/6">
                                              {note.content}
                                            </p>
                                          </>
                                        )}

                                        {note && note.updated_at && (
                                          <p className="text-xs text-right pt-2 text-gray-400">
                                            Last Updated:{' '}
                                            {format(
                                              new Date(note.updated_at),
                                              "do MMM yyyy 'at' HH:mm",
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            ) : (
                              <p className=" py-3  text-sm xl:text-sm text-gray-800">
                                No notes added to complaint
                              </p>
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  <div className="relative z-0 w-full">
                    {note ? (
                      <div className="relative mt-2 pt-3 flex flex-col items-start flex-1">
                        <button
                          type="button"
                          className="transition-all absolute top-3 right-0 rounded-full text-gray-400 p-[2px] focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500"
                          onClick={() => {
                            setNote(false);
                          }}
                        >
                          <XMarkIcon className="w-3" />
                        </button>
                        <button
                          className="transition-all absolute top-3 right-6 rounded-full text-gray-400 p-[2px] focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500"
                          onClick={handleComplaints(submitNote)}
                        >
                          <CheckIcon className="w-3" />
                        </button>
                        <p className="font-semibold text-sm text-gray-700">
                          Note
                        </p>
                        <textarea
                          name="note"
                          className="transition-all mt-3 p-2 pr-8 bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 text-xs w-full"
                          placeholder="Add note"
                          rows={4}
                          defaultValue={''}
                          {...registercomplaints('note', {
                            required: false,
                          })}
                          spellCheck={true}
                        />
                      </div>
                    ) : (
                      <div className="flex space-x-2 mt-3 pt-3 flex-1">
                        <button
                          type="button"
                          className="transition-all rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 flex-1"
                          onClick={() => setNote(true)}
                        >
                          Add a note
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {mode === ModalType.Edit && (
          <form className="space-y-4">
            <div className="bg-white space-y-4 p-4">
              <div className="flex flex-col-2 space-y-2">
                <div className="flex-wrap w-full flex-col space-y-3">
                  <div className="py-2">
                    <span className="font-semibold text-sm xl:text-sm">
                      Complaint Title
                    </span>
                    <input
                      type="text"
                      name="title"
                      {...registercomplaints('title', { required: true })}
                      className="transition-all w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                    />
                  </div>
                  <div className="border-t py-2">
                    <span className="font-semibold text-sm xl:text-sm">
                      Complaint Message:
                    </span>
                    <textarea
                      name="complaint"
                      {...registercomplaints('message', { required: true })}
                      className="transition-all w-full text-left h-44 bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                    />
                  </div>
                  <div className="border-t py-2">
                    <span className="font-semibold text-xs xl:text-sm">
                      Resolution Status
                    </span>
                    <Listbox
                      value={selectedResolution}
                      onChange={(e) => {
                        setSelectedResolution(e);
                        setValue('resolution', e);
                      }}
                    >
                      <Listbox.Button className="transition-all w-full text-left pl-2 bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs ">
                        {selectedResolution}
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="relative m-0 bg-white">
                          <Listbox.Options className="absolute z-2 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {resolutions.map((resolution) => (
                              <Listbox.Option
                                key={resolution}
                                value={resolution}
                                onChange={(e) =>
                                  setSelectedResolution(
                                    (selectedResolution) =>
                                      selectedResolution === e,
                                  )
                                }
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 rounded-md ${
                                    active
                                      ? 'bg-purple-500 text-white'
                                      : 'text-gray-900'
                                  }`
                                }
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate rounded-full ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                      {resolution}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-800">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </div>
                      </Transition>
                    </Listbox>
                  </div>
                  <Combobox
                    value={
                      selected.name && selected.surname
                        ? `${selected.name} ${selected.surname}`
                        : null
                    }
                    //@ts-ignore
                    onChange={(value: UserProfile) => {
                      setValue('client', value.id);
                      setSelected(value);
                    }}
                  >
                    {({ activeOption, activeIndex }) => {
                      return (
                        <div className="relative mt-1 border-t py-2">
                          <span className="font-semibold text-sm xl:text-sm">
                            Find Client
                          </span>
                          <Combobox.Input
                            className="transition-all w-full text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                            placeholder={!selected.name && 'No Client Selected'}
                            onChange={(event) => {
                              if (event && event.target.value.length > 0) {
                                debouncedClientChange.current(
                                  event.target.value,
                                );
                              } else {
                                setState((s) => ({ ...s, query: '' }));
                              }
                            }}
                          />
                          {(state.clientInputItems.length > 0 ||
                            (state.query === '' &&
                              state.recent.length !== 0)) && (
                            <Combobox.Options
                              as="div"
                              className={classNames(
                                `flex absolute mt-1 max-h-40 rounded w-full ${
                                  state.query && 'bg-white shadow-sm'
                                } z-[40] divide-x divide-gray-100`,
                              )}
                            >
                              <div
                                className={classNames(
                                  'max-h-[250px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-4 py-2',
                                  activeOption && 'sm:max-h-[250px]',
                                  state.recent.length > 0 &&
                                    'divide-y divide-gray-100 space-y-2',
                                )}
                              >
                                <div className="-mx-2 text-xs xl:text-sm text-gray-700 max-h-[250px] overflow-x-scroll">
                                  {(state.query === ''
                                    ? state.recent
                                    : state.clientInputItems
                                  ).map((client, i) => {
                                    return (
                                      <Combobox.Option
                                        as="div"
                                        key={client.id}
                                        value={client}
                                        className={({ active }) =>
                                          classNames(
                                            'flex select-none items-center rounded-md p-2',
                                            active &&
                                              'bg-gray-100 text-gray-900',
                                          )
                                        }
                                      >
                                        <span className="flex-auto truncate flex items-center capitalize">
                                          {client.name} {client.surname}
                                          {client.email && (
                                            <span className="ml-1 inline-flex items-center text-xs lowercase">
                                              <AtSymbolIcon className="w-4 mr-1" />{' '}
                                              {client.email}
                                            </span>
                                          )}
                                        </span>
                                      </Combobox.Option>
                                    );
                                  })}
                                </div>
                              </div>
                            </Combobox.Options>
                          )}
                        </div>
                      );
                    }}
                  </Combobox>

                  <Combobox
                    value={
                      assignTo?.user?.name
                        ? `${assignTo?.user?.name} ${assignTo?.user?.surname}`
                        : null
                    }
                    onChange={(e: any) => {
                      setValue('member', e);
                      setAssignedTo(e);
                    }}
                  >
                    {({ activeOption }) => {
                      return (
                        <div className="relative mt-1 border-t pt-2">
                          <span className="font-semibold text-sm xl:text-sm">
                            Assign to:
                          </span>
                          <Combobox.Input
                            className="transition-all w-full text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 py-1.5 xl:py-3 text-xs "
                            placeholder={
                              !assignTo?.user?.name
                                ? 'No Team Member Selected'
                                : ''
                            }
                            onChange={(event) => {
                              if (event && event.target.value.length > 0) {
                                debouncedMemberChange.current(
                                  event.target.value,
                                );
                              } else {
                                setState((s) => ({
                                  ...s,
                                  memberQuery: '',
                                }));
                              }
                            }}
                          />
                          {(state.memberInputItems.length > 0 ||
                            (state.memberQuery === '' &&
                              state.recent.length !== 0)) && (
                            <Combobox.Options
                              as="div"
                              className={classNames(
                                `flex absolute mt-1 max-h-40 rounded w-full ${
                                  state.memberQuery && 'bg-white shadow-sm'
                                } z-[40] divide-x divide-gray-100`,
                              )}
                            >
                              <div
                                className={classNames(
                                  'max-h-[250px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-4 py-2',
                                  activeOption && 'sm:max-h-[250px]',
                                  state.recent.length > 0 &&
                                    'divide-y divide-gray-100 space-y-2',
                                )}
                              >
                                <div className="-mx-2 text-xs xl:text-sm text-gray-700 max-h-[250px] overflow-x-scroll">
                                  {(state.memberQuery === ''
                                    ? state.recent
                                    : state.memberInputItems
                                  ).map((member, i) => {
                                    return (
                                      <Combobox.Option
                                        as="div"
                                        key={member.id}
                                        value={member}
                                        className={({ active }) =>
                                          classNames(
                                            'flex select-none items-center rounded-md p-2',
                                            active &&
                                              'bg-gray-100 text-gray-900',
                                          )
                                        }
                                      >
                                        {/* <Avatar
                                          size={{
                                            width: 4,
                                            height: 4,
                                          }}
                                          src={
                                            member?.user?.user_assets[0]?.assets
                                              ?.url
                                          }
                                        /> */}
                                        <span className="flex-auto truncate flex pl-1 items-center capitalize">
                                          {member.user.name}{' '}
                                          {member.user.surname}
                                        </span>
                                      </Combobox.Option>
                                    );
                                  })}
                                </div>
                              </div>
                            </Combobox.Options>
                          )}
                        </div>
                      );
                    }}
                  </Combobox>
                  <div className=" relative flex flex-col justify-between border-b">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={
                              'flex justify-between py-3 text-sm text-gray-500'
                            }
                          >
                            Notes (
                            {complaintNotesData &&
                              complaintNotesData.complaint_note.length}
                            )
                            <ChevronUpIcon
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } self-left h-5 w-5 text-purple-500 ml-4`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            {complaintNotesData ? (
                              <div>
                                {complaintNotesData.complaint_note.map(
                                  ({ note }) => {
                                    if (!note) return null;
                                    return (
                                      <div
                                        className="relative flex-col my-2 pt-3 w-full items-center shadow-md px-4 py-1.5 border border-gray-300 text-xs leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 justify-between"
                                        key={`complaint-note-${note.id}`}
                                      >
                                        {editNote === note.id ? (
                                          <>
                                            <CheckCircleIcon
                                              className="absolute right-6 top-1 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-purple-700"
                                              onClick={() => {
                                                submitEditedNote(editedNote);
                                                setIsEditNote(false);
                                                setEditNote(null);
                                              }}
                                            />
                                            <XCircleIcon
                                              className="absolute right-1 top-1 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-purple-700"
                                              onClick={() => {
                                                setIsEditNote(false);
                                                setEditNote(null);
                                              }}
                                            />

                                            <textarea
                                              autoFocus
                                              defaultValue={note.content}
                                              onChange={(e) =>
                                                setEditedNote({
                                                  content: e.target.value,
                                                  id: note.id,
                                                })
                                              }
                                              className={`${
                                                isEditNote ? '' : 'hidden'
                                              } transition-all px-1 py-1 text-left w-10/12 bg-white shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white rounded border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500  text-xs`}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <div className="border-b pb-1">
                                              <PencilSquareIcon
                                                className={`${
                                                  role === note.user
                                                    ? ''
                                                    : 'hidden'
                                                } absolute right-6 top-2 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-purple-700`}
                                                onClick={() => {
                                                  setEditNote(note.id);
                                                  setIsEditNote(true);
                                                }}
                                              />
                                              <TrashIcon
                                                className={`${
                                                  role === note.user
                                                    ? ''
                                                    : 'hidden'
                                                }  absolute right-1 top-2 inline mr-3 w-4 h-4 cursor-pointer text-purple-500 hover:text-red-400`}
                                                onClick={() => {
                                                  deleteOneNote(note.id);
                                                }}
                                              />
                                              <div>
                                                <Avatar
                                                  size={{
                                                    width: 6,
                                                    height: 6,
                                                  }}
                                                  layout="fill"
                                                  src={
                                                    note?.employee?.assets[0]
                                                      ?.asset?.url
                                                  }
                                                />
                                                {note?.employee?.name}{' '}
                                                {note?.employee?.surname}
                                              </div>
                                            </div>
                                            <p className="text-xs pt-1 w-5/6">
                                              {note.content}
                                            </p>
                                          </>
                                        )}

                                        {note && note.updated_at && (
                                          <p className="text-xs text-right pt-2 text-gray-400">
                                            Last Updated:{' '}
                                            {format(
                                              new Date(note.updated_at),
                                              "do MMM yyyy 'at' HH:mm",
                                            )}
                                          </p>
                                        )}
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            ) : (
                              <p className=" py-3  text-sm xl:text-sm text-gray-800">
                                No notes added to complaint
                              </p>
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  <div className="relative z-0 w-full">
                    {note ? (
                      <div className="relative border-t mt-3 pt-3 flex flex-col items-start flex-1">
                        <button
                          type="button"
                          className="transition-all absolute top-3 right-0 rounded-full text-gray-400 p-[2px] focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500"
                          onClick={() => {
                            setNote(false);
                          }}
                        >
                          <XMarkIcon className="w-3" />
                        </button>
                        <button
                          className="transition-all absolute top-3 right-6 rounded-full text-gray-400 p-[2px] focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500"
                          onClick={handleComplaints(submitNote)}
                        >
                          <CheckIcon className="w-3" />
                        </button>
                        <p className="font-semibold text-sm text-gray-700">
                          Note
                        </p>
                        <textarea
                          name="note"
                          className="transition-all mt-3 p-2 pr-8 bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 text-xs w-full"
                          placeholder="Add note"
                          rows={4}
                          defaultValue={''}
                          {...registercomplaints('note', {
                            required: false,
                          })}
                          spellCheck={true}
                        />
                      </div>
                    ) : (
                      <div className="flex space-x-2 mt-3 pt-3 flex-1">
                        <button
                          type="button"
                          className="transition-all rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 flex-1"
                          onClick={() => setNote(true)}
                        >
                          Add a note
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>

      <div className="flex z-0 w-full p-2 place-content-end">
        {mode === ModalType.View ? (
          <button className="panel-primary-button" onClick={editMode}>
            Edit
          </button>
        ) : (
          <>
            <button className="panel-secondary-button" onClick={editMode}>
              Cancel
            </button>

            <button
              className="panel-primary-button"
              onClick={handleComplaints(submitEdit)}
            >
              Save
            </button>
          </>
        )}
      </div>
    </section>
  );
};

export default ManageComplaint;
