export type EmailObject = {
  id: number;
  email: string;
};

export const emails: EmailObject[] = [
  { id: 1, email: 'support@cosmetic.college' },
  { id: 2, email: 'admissions@cosmetic.college' },
  { id: 3, email: 'vtct@cosmetic.college' },
  { id: 4, email: 'models@cosmetic.college' },
  { id: 8, email: 'management@cosmetic.college' },
];
