import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { subDays } from 'date-fns';
import format from 'date-fns/format';
import { LeadNote, TeamMember } from 'interfaces';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';

import { addCallNote, getCallById } from 'queries/calls';
import { addLeadNote, getLeadById } from 'queries/leads';

import { OrganizationContextValue } from 'providers/organization';
import { useUI } from 'providers/ui';

// import { addCallNote, getCallById } from "queries/calls";

// @ts-ignore
const TimeAgo = dynamic(() => import('react-timeago'), {
  ssr: false,
});
const Loader = dynamic(() => import('components/atoms/loader'));

type Props = {
  lead: string;
  notes: LeadNote[];
  org: OrganizationContextValue;
  member: TeamMember;
  type: string;
};

export default function Notes({ org, member, lead, notes, type }: Props) {
  const [saving, setSaving] = useState(false);
  const ui = useUI();
  const { register, handleSubmit, reset } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [createLeadNote] = useMutation(addLeadNote);
  const [createCallNote] = useMutation(addCallNote);

  const saveNote = async (data) => {
    if (!data.content) return;
    try {
      setSaving(true);

      if (type === 'lead') {
        const payload = {
          data: {
            lead: lead,
            noteByNote: {
              data: {
                content: data.content,
                title: '',
              },
            },
          },
        };
        await createLeadNote({
          variables: payload,
          refetchQueries: [
            {
              query: getLeadById,
              variables: {
                id: lead,
              },
            },
          ],
        });
        reset();
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New note added',
        });
      } else if (type === 'call') {
        const payload = {
          data: {
            call: lead,
            noteByNote: {
              data: {
                content: data.content,
                title: '',
              },
            },
          },
        };
        await createCallNote({
          variables: payload,
          refetchQueries: [
            {
              query: getCallById,
              variables: {
                id: lead,
              },
            },
          ],
        });
        reset();
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New note added',
        });
      }
    } catch (err) {
      setSaving(false);
      console.log(err);
      ui.setToast(true, {
        type: 'error',
        title: 'Error adding note',
      });
      Sentry.captureException(err);
    }
  };

  const P = ({ node, ...props }) => {
    return <p className="mb-2 font-light leading-relaxed" {...props} />;
  };

  if (!org.team || (org.team && org.team.length === 0)) {
    return null;
  }

  return (
    <>
      <section id="notes" aria-labelledby="notes-title">
        <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden">
          <div className="divide-y divide-gray-300">
            <div className="px-4 py-5 sm:px-6">
              <h2 id="notes-title" className="text-lg font-bold text-gray-900">
                Notes
              </h2>
            </div>
            <section aria-labelledby="notes-title">
              <div className="sm:overflow-hidden">
                <div className="divide-y divide-gray-300">
                  {notes && notes.length > 0 && (
                    <div className="px-4 py-6 sm:px-6">
                      <ul className="space-y-8">
                        {notes.map((note, i) => {
                          const member = org.team.find(
                            (x) => x.user.id === note.data.user,
                          );
                          const img =
                            member &&
                            member.user.avatar &&
                            member.user.avatar.length > 0
                              ? member.user.avatar[0].asset
                              : null;
                          const greater =
                            new Date(note.data.updatedAt) <=
                            subDays(new Date(), 2);
                          return (
                            <li key={note.id}>
                              <div className="flex space-x-3">
                                {img && (
                                  <div className="flex-shrink-0 h-8 w-8 relative">
                                    <Image
                                      layout="fill"
                                      className="h-8 w-8 rounded-full"
                                      src={img.url}
                                      alt={`${member.user.name}`}
                                    />
                                  </div>
                                )}
                                {!img && member && member.user && (
                                  <div className="flex-shrink-0">
                                    <div className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                      {member.user.name.charAt(0)}
                                      {member.user.surname.charAt(0)}
                                    </div>
                                  </div>
                                )}
                                <div className="flex-1">
                                  <div className="text-sm flex items-center">
                                    <p className="font-semibold text-gray-900 flex-1">
                                      {member &&
                                        member.user &&
                                        member.user.name}
                                    </p>
                                    <span className="text-gray-900 text-xs font-medium">
                                      {!greater && (
                                        <TimeAgo
                                          live
                                          date={note.data.updatedAt}
                                        />
                                      )}
                                      {greater &&
                                        format(
                                          new Date(note.data.updatedAt),
                                          'HH:mm do MMM yyyy',
                                        )}
                                    </span>
                                  </div>
                                  <div className="mt-1 text-sm text-gray-600">
                                    <ReactMarkdown
                                      components={{
                                        p: P,
                                      }}
                                    >
                                      {note.data.content}
                                    </ReactMarkdown>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {!notes ||
                    (notes.length === 0 && (
                      <div className="px-4 py-6 sm:px-6">
                        <p className="text-gray-600 text-xs">
                          No notes have been added
                        </p>
                      </div>
                    ))}
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6 border-t border-gray-300">
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                      {member &&
                        member.user.avatar &&
                        member.user.avatar.length > 0 && (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={member.user.avatar[0].asset.url}
                            alt={`${member.user.name} ${member.user.surname}`}
                          />
                        )}
                    </div>
                    <div className="min-w-0 flex-1">
                      <form onSubmit={handleSubmit(saveNote)}>
                        <div>
                          <label htmlFor="note" className="sr-only">
                            Add note
                          </label>
                          <textarea
                            id="note"
                            name="content"
                            rows={3}
                            className="shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300 rounded-md"
                            placeholder="Add a note"
                            {...register('content', { required: true })}
                          ></textarea>
                        </div>
                        <div className="mt-3 flex items-center justify-end">
                          <button
                            disabled={saving}
                            type="submit"
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                          >
                            {saving && (
                              <>
                                <Loader
                                  // @ts-ignore
                                  styles={{}}
                                  stroke="#fff"
                                  width="24"
                                  height="24"
                                />
                                <span className="ml-2">Saving</span>
                              </>
                            )}
                            {!saving && 'Add notes'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
