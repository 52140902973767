import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

export function currency(
  amount,
  currency = 'GBP',
  locale = 'en-GB',
  opts = {},
) {
  if (amount === null || amount === undefined) return null;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...opts,
  }).format(amount / 100);
}

function toDate(service: any, value: any): Date | null {
  if (!service.date || !value) {
    return null;
  }

  const d = new Date(service.date);
  const timeSplit = value.split(':');
  const final = setMinutes(
    setHours(d, Number(timeSplit[0])),
    Number(timeSplit[1]),
  );
  return final;
}

export function formatDateTimeComponent(
  dateTimeComponent: string | number,
): string {
  return dateTimeComponent.toString().padStart(2, '0');
}

export function formatTimeEmployeeAvailability(time: string): string {
  return time.replace(/:[^:]+$/i, '');
}

export function formatTimeAppointment(dateTime: string): string {
  const matches = dateTime.match(/T\d+:\d+/gi);
  return matches?.length > 0 ? matches[0].replace('T', '') : dateTime;
}

interface TimeDetails {
  hour: string | number;
  minute?: string | number;
  seperator?: string;
}

export function concatTimeComponents({
  hour,
  minute = '0',
  seperator = ':',
}: TimeDetails): string {
  return (
    formatDateTimeComponent(hour) + seperator + formatDateTimeComponent(minute)
  );
}
