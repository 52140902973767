import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import Status from 'components/atoms/status';

import { getAllUserFormsById } from 'queries/documents';

export const FormsTab = ({
  formIds,
  action,
}: {
  formIds: string[];
  action: any;
}) => {
  const { data: formData } = useQuery(getAllUserFormsById, {
    variables: {
      id: formIds,
    },
  });

  if (!formData?.userForms) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 divide-y sm:rounded sm:overflow-hidden">
      {formData?.userForms.map((form, index) => {
        return (
          <button
            onClick={() => action(form?.userByUser?.id)}
            className="sm:overflow-hidden w-full text-left hover:bg-gray-50 px-4"
            key={index}
          >
            <div className="p-5 md:py-5 md:px-0 max-w-lg mx-auto">
              <div className="flex justify-between">
                <div className="font-semibold">
                  {form?.userByUser?.name} {form?.userByUser?.surname}
                </div>

                <div className="whitespace-nowrap text-sm text-gray-500">
                  {form.complete ? (
                    <p className="bg-green-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                      Complete
                    </p>
                  ) : form.client_complete ? (
                    <p className="bg-amber-500 text-center p-1 px-2.5 text-white text-xs rounded-full">
                      Review
                    </p>
                  ) : (
                    <p className="bg-red-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                      Incomplete
                    </p>
                  )}
                </div>
              </div>
              <div className="text-base font-semibold leading-6 text-gray-800">
                {form.formByForm?.title}
              </div>

              {form?.createdAt && (
                <div className="text-xs text-gray-500 mt-2">
                  {format(new Date(form.createdAt), "do LLL y 'at' h:mm aaa")}
                </div>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default FormsTab;
