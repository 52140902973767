import { gql } from '@apollo/client';

export const checkOnlineCourses = gql`
  query checkOnlineCourses($coassemble_id: Int!) {
    online_courses(
      where: { coassemble_id: { _eq: $coassemble_id } }
      limit: 1
    ) {
      id
      name
      group_id
    }
  }
`;
