import {
  eachDayOfInterval,
  endOfMonth,
  format,
  formatDistanceToNow,
  getDay,
  isSameDay,
  isSameMonth,
  isToday,
  isYesterday,
  startOfMonth,
} from 'date-fns';

export function createDates(
  start: Date,
  end: Date,
  selected: Date,
): {
  date?: Date;
  previous: boolean;
  currentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
}[] {
  let datesInMonth = eachDayOfInterval({
    start: startOfMonth(start),
    end: endOfMonth(end),
  }).map((x) => ({
    date: x,
    previous: false,
    currentMonth: isSameMonth(x, new Date()),
    isToday: isToday(x),
    isSelected: isSameDay(x, selected),
  }));
  let firstDate = getDay(datesInMonth[0].date);
  let emptyCells = firstDate === 0 ? 7 : firstDate;
  datesInMonth.unshift(
    // @ts-ignore
    ...Array.from(Array(emptyCells - 1)).map((x) => ({ previous: true })),
  );
  return datesInMonth;
}

export const timeAgo = (date: Date): string => {
  if (isToday(date)) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, 'HH:mm')}`;
  } else {
    return format(date, 'do MMMM yyyy HH:mm');
  }
};
