import { gql } from '@apollo/client';

import { Complaints } from './fragments';

export const getAllComplaints = gql`
  query getAllComplaints($start: timestamptz, $end: timestamptz) {
    complaints(
      order_by: { updated_at: desc }
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      id
      updated: updated_at
      created: created_at
      title
      complaint
      resolution
      assignee_id
      employee_id
      organization
      user_id
      user {
        id
        given_name
        full_name
        family_name
        contact_number
        email
        created_at
      }
      employee {
        id
        given_name
        family_name
        full_name
        user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
          asset: assetByAsset {
            id
            type
            url
          }
        }
      }
      assignee {
        id
        user {
          id
          given_name
          family_name
          full_name
          user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
            asset: assetByAsset {
              id
              type
              url
            }
          }
        }
      }
    }
  }
`;

export const getComplaintById = gql`
  query GetComplaintById($id: uuid!) {
    complaints_by_pk(id: $id) {
      id
      updated: updated_at
      created: created_at
      title
      complaint
      resolution
      assignee_id
      employee_id
      organization
      user_id
      user {
        id
        given_name
        full_name
        family_name
        contact_number
        email
        created_at
      }
      employee {
        id
        given_name
        family_name
        full_name
        user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
          asset: assetByAsset {
            id
            type
            url
          }
        }
      }
      assignee {
        id
        user {
          id
          given_name
          family_name
          full_name
          user_assets(where: { assetByAsset: { type: { _eq: AVATAR } } }) {
            asset: assetByAsset {
              id
              type
              url
            }
          }
        }
      }
    }
  }
`;

export const getClientComplaints = gql`
  ${Complaints}
  query GetClientComplaints($id: uuid!) {
    complaints(where: { user_id: { _eq: $id } }) {
      ...Complaints
    }
  }
`;

export const getAllUsers = gql`
  query getAllComplaints($start: timestamptz, $end: timestamptz) {
    complaints(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      id
      title
      complaint
      resolution
    }
  }
`;

export const addNewComplaint = gql`
  ${Complaints}
  mutation addComplaint($data: complaints_insert_input!) {
    insert_complaints_one(object: $data) {
      ...Complaints
    }
  }
`;

export const updateComplaint = gql`
  ${Complaints}
  mutation updateComplaintById($id: uuid!, $data: complaints_set_input!) {
    update_complaints_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...Complaints
      id
    }
  }
`;

export const addComplaintCall = gql`
  mutation addComplaintCall($data: complaint_call_insert_input!) {
    insert_complaint_call_one(object: $data) {
      id
    }
  }
`;

export const addNoteOnUpdate = gql`
  mutation addNoteUpdate($data: complaint_note_insert_input!) {
    insert_complaint_note_one(object: $data) {
      id
    }
  }
`;

export const deleteComplaintById = gql`
  ${Complaints}
  mutation deleteComplaintById($id: uuid!) {
    delete_complaints_by_pk(id: $id) {
      ...Complaints
      id
    }
  }
`;

export const deleteComplaintNotes = gql`
  mutation deleteComplaintNotes($id: uuid) {
    delete_complaint_note(where: { complaint_id: { _eq: $id } }) {
      returning {
        complaint_id
      }
    }
  }
`;

export const updateComplaintNote = gql`
  mutation UpdateNote($id: uuid!, $content: String!) {
    update_note_by_pk(pk_columns: { id: $id }, _set: { content: $content }) {
      id
      updatedAt: updated_at
    }
  }
`;

export const deleteNoteOne = gql`
  mutation deleteNoteOne($nid: uuid) {
    delete_note(where: { id: { _eq: $nid } }) {
      affected_rows
    }
  }
`;

export const deleteComplaintNoteOne = gql`
  mutation deleteComplaintNotes($id: uuid) {
    delete_complaint_note(where: { note_id: { _eq: $id } }) {
      returning {
        complaint_id
      }
    }
  }
`;

export const getComplaintNotes = gql`
  query GetComplaintNotes($cid: uuid!) {
    complaint_note(
      where: { complaint_id: { _eq: $cid } }
      order_by: { created_at: desc }
    ) {
      note {
        id
        created_at
        updated_at
        content
        user
        employee {
          id
          name: given_name
          surname: family_name
          assets: user_assets {
            asset: assetByAsset {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const getComplaintCalls = gql`
  query GetComplaintCalls($cid: uuid!) {
    complaint_call(
      where: { complaint_id: { _eq: $cid } }
      order_by: { created_at: desc }
    ) {
      id
      call_id
      call {
        id
        createdAt: created_at
        updatedAt: updated_at
        status
        payload
        type
        notes_aggregate {
          aggregate {
            count
          }
        }
        contacts_aggregate {
          aggregate {
            count
          }
        }
        user {
          id
          name: given_name
          surname: family_name
        }
      }
      complaint {
        title
        complaint
      }
    }
  }
`;
