import { gql } from '@apollo/client';

export const updateModelSessionPurchase = gql`
  mutation updateModelSession(
    $id: uuid!
    $data: model_session_purchase_set_input!
  ) {
    update_model_session_purchase_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const registration = gql`
  fragment registration on registration {
    event_id
    created_at
    updated_at
    student_id
    source
    model_session_purchase_id
    modelReport {
      status
      id
    }
    type
    id
    status
    package {
      id
      title
    }
    user {
      id
      given_name
      family_name
      user_forms {
        id
        form
        user
        course
        responsesByResponses {
          complete
          id
        }
        formByForm {
          title
          feedback
          formQuestions {
            id
          }
          formResponses {
            id
          }
        }
      }
      certificates {
        event_id
        url
      }
    }
    payments {
      id
      payment {
        id
        createAt: created_at
        amount
        status
        transactionId: transaction_id
      }
    }
    event {
      id
      start
      end
      detail {
        title
        type
        id
        elearning_course_id
      }
      event_resources {
        type
        user {
          given_name
        }
      }
    }
  }
`;

export const onlineRegistration = gql`
  fragment onlineRegistration on online_registration {
    data
    user_id
    updated_at
    source
    removed
    id
    created_at
    coassemble_group_id
    complete
    coassemble_course_id
    certificate
    added
  }
`;

export const insertRegistration = gql`
  ${registration}
  mutation insertRegistration($data: registration_insert_input!) {
    insert_registration_one(object: $data) {
      ...registration
    }
  }
`;

export const getStudentRegistrationsByUserID = gql`
  ${registration}
  query getStudentRegistrationsByUserID($userId: uuid!) {
    registration(
      where: { user_id: { _eq: $userId } }
      order_by: { event: { date: desc } }
    ) {
      ...registration
    }
  }
`;

export const updateRegistration = gql`
  ${registration}
  mutation updateRegistration($id: uuid!, $data: registration_set_input!) {
    update_registration_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...registration
    }
  }
`;

export const getOnlineRegistrationsByUserID = gql`
  ${onlineRegistration}
  query getOnlineRegistrationsByUserID($userId: uuid!) {
    online_registration(where: { user_id: { _eq: $userId } }) {
      ...onlineRegistration
    }
  }
`;

export const insertOnlineRegistration = gql`
  ${onlineRegistration}
  mutation insertOnlineRegistration($data: online_registration_insert_input!) {
    insert_online_registration_one(object: $data) {
      ...onlineRegistration
    }
  }
`;

export const setRemoveOnlineRegistration = gql`
  ${onlineRegistration}
  mutation setRemoveOnlineRegistration(
    $userID: uuid
    $coassemble_courseID: String
  ) {
    update_online_registration(
      where: {
        _and: {
          user_id: { _eq: $userID }
          coassemble_course_id: { _eq: $coassemble_courseID }
        }
      }
      _set: { removed: true }
    ) {
      returning {
        ...onlineRegistration
      }
    }
  }
`;

export const removeModelFromStudent = gql`
  ${registration}
  mutation RemoveModelFromStudent($id: uuid!) {
    update_registration_by_pk(
      pk_columns: { id: $id }
      _set: { student_id: null }
    ) {
      ...registration
    }
  }
`;

export const addModelToStudent = gql`
  ${registration}
  mutation AddModelFromStudent($id: uuid!, $studentId: uuid!) {
    update_registration_by_pk(
      pk_columns: { id: $id }
      _set: { student_id: $studentId }
    ) {
      ...registration
    }
  }
`;
