import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Menu, Tab, Transition } from '@headlessui/react';
import {
  ArrowPathIcon,
  ArrowsPointingOutIcon,
  AtSymbolIcon,
  BuildingOffice2Icon,
  CameraIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  DocumentIcon,
  FolderPlusIcon,
  GlobeAltIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  PhoneArrowUpRightIcon,
  PhoneIcon,
  PlusIcon,
  ReceiptPercentIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import {
  endOfDay,
  format,
  fromUnixTime,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from 'date-fns';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Calls from 'pages/incoming/calls';
import React, { Fragment, useCallback, useState } from 'react';

import Avatar from 'components/atoms/avatar';
import { UploadRequestModal } from 'components/uploads';

import { getCourseGroup } from 'utils/coassemble';
import { calculateEnrollment } from 'utils/enrollment';
import { currency } from 'utils/formats';

import { completeDocument, completeRequest } from 'queries/clients';
import {
  deleteEnrollment,
  getAllEnrollments,
  getEnrollment,
  getPackageEvents,
  markEnrollmentComplete,
  updateEnrollmentCheckList,
} from 'queries/enrollment';
import { getIncomingStatus } from 'queries/leads';
import { checkOnlineCourses } from 'queries/online-courses';
import { getInvoice, voidInvoice } from 'queries/payments';
import { insertOnlineRegistration } from 'queries/registration';
import { completeDocuments, completeUploads } from 'queries/services';
import { updateTaskById } from 'queries/tasks';

import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

import ViewReceipt from './view-receipt';

const Status = dynamic(() => import('components/atoms/status'));
const Modal = dynamic(() => import('components/molecules/modal'));
const EditPayment = dynamic(() => import('./edit-payment'));
const NewNote = dynamic(() => import('./new-note'));
const ViewDocument = dynamic(() => import('./view-document'));
const RequestUpload = dynamic(() => import('./request-uploads'));
const RequestForm = dynamic(() => import('./request-forms'));
const PackageModal = dynamic(() => import('./package-courses-modal'));
const NewMessage = dynamic(
  () => import('components/molecules/template/contact'),
);

const Icon = ({ method }) => {
  return (
    <>
      {method === 'stripe' && (
        <Image width={32} height={20} alt="stripe" src="/icons/stripe.svg" />
      )}
      {method === 'klarna' && (
        <Image
          alt="klarna"
          src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
          width={48}
          height={25}
        />
      )}
    </>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ViewEnrollmentModal({
  onComplete,
  enrollmentId,
  controlOption,
}: {
  onComplete?: () => void;
  enrollmentId: string;
  controlOption?: any;
}) {
  const ui = useUI();
  const router = useRouter();
  const { user, claims } = useSession();
  const org = useOrganization();
  const member = user && org.teamMember(claims['x-hasura-user-id']);

  const { data, refetch } = useQuery(getEnrollment, {
    variables: {
      id: enrollmentId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: statusValues } = useQuery<{ status: { value: string }[] }>(
    getIncomingStatus,
  );

  const enrollment = data?.enrollment;

  const tabs = [
    {
      name: 'Courses',
      icon: UserIcon,
      current: false,
    },
    {
      name: 'Registrations',
      icon: ClipboardDocumentCheckIcon,
      current: false,
    },
    {
      name: 'Online Registrations',
      icon: GlobeAltIcon,
      current: false,
    },
    {
      name: 'Payments',
      icon: BuildingOffice2Icon,
      current: false,
    },
    {
      name: 'Credits',
      icon: UsersIcon,
      current: false,
    },
    {
      name: 'Discounts',
      icon: CreditCardIcon,
      current: false,
    },
    {
      name: 'Notes',
      icon: UsersIcon,
      current: false,
    },
    {
      name: 'Upload Requests',
      icon: UsersIcon,
      current: false,
    },
    {
      name: 'Documents',
      icon: UsersIcon,
      current: false,
    },
  ];

  if (
    !(
      (enrollment && enrollment.status === 'completed') ||
      enrollment?.status === 'deposit'
    )
  ) {
    tabs.push({
      name: 'Delete',
      icon: UsersIcon,
      current: false,
    });
  }

  if (enrollment?.receipt_id) {
    tabs.push({
      name: 'Receipt',
      icon: ReceiptPercentIcon,
      current: false,
    });
  }

  const [state, setState] = useState({
    resending: false,
    tab: tabs[0],
    modal: false,
    payment: {},
    enrollment: {},
    formModal: false,
    invoices: [],
    newNote: false,
    packageCourseModal: false,
    uploadRequestModal: false,
    formRequestModal: false,
    newMessage: false,
    form: {},
    checklist: [],
    call: null,
    showAssetModal: false,
    imageData: null,
  });

  const [getPackage, { data: packageData }] = useLazyQuery(getPackageEvents);
  const [getInv, { data: invoiceData }] = useLazyQuery(getInvoice);

  const [checkCourseOnline, { data: onlineCourseData }] =
    useLazyQuery(checkOnlineCourses);
  const [completeUploadRequest] = useMutation(completeRequest);
  const [updateUploadStatus] = useMutation(completeUploads);
  const [updateFormStatus] = useMutation(completeDocuments);
  const [completeEnroll] = useMutation(markEnrollmentComplete);
  const [updateChecklist] = useMutation(updateEnrollmentCheckList);
  const [completeDocumentRequest] = useMutation(completeDocument);
  const [createOnlineRegistration] = useMutation(insertOnlineRegistration);
  const [removeEnrollment] = useMutation(deleteEnrollment);
  const [voidInv] = useLazyQuery(voidInvoice);
  const [editTask] = useMutation(updateTaskById);

  const viewRequest = async (data) => {
    setState((s) => ({ ...s, showAssetModal: true, imageData: data }));
  };

  const resendLink = async (paymentId: string) => {
    setState((s) => ({ ...s, resending: true }));

    if (!paymentId) {
      setState((s) => ({ ...s, resending: false }));
      return ui.setToast(true, {
        type: 'error',
        title: 'Failed to resend link',
        message: 'No Payment ID',
      });
    }
    const url = 'https://functions.cosmetic.college/checkout/resend';
    try {
      const req = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          paymentId,
        }),
      });

      const res = await req.json();
      console.log('RESEND RESULT', res);

      if (res) {
        ui.setToast(true, {
          type: 'success',
          title: 'Success',
          message: 'Link Resent',
        });
      }

      await refetch();
      setState((s) => ({ ...s, resending: false }));
      return res;
    } catch (e) {
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to resend link',
        message: e.message,
      });
    }
    setState((s) => ({ ...s, resending: true }));
  };

  const markRequestAsComplete = async (id: string, complete: boolean) => {
    try {
      const result = await completeUploadRequest({
        variables: {
          id: id,
          complete,
        },
        refetchQueries: [
          {
            query: getEnrollment,
            variables: {
              id: enrollmentId,
            },
          },
        ],
      });
      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Success',
          message: 'Upload Request Completion Status Changed',
        });
      }
    } catch (error) {
      console.log('1x: error:', error);
      ui.setToast(true, {
        type: 'error',
        title: 'Error',
        message: 'Failed to Mark as complete',
      });
    }
  };

  const changUploadsStatus = async (complete) => {
    const ids = enrollment.uploads.map((c) => c.id);

    try {
      const result = await updateUploadStatus({
        variables: {
          ids,
          complete,
        },
        refetchQueries: [
          {
            query: getEnrollment,
            variables: {
              id: enrollmentId,
            },
          },
        ],
      });
      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Success',
          message: 'Upload Requests Completion Status Changed',
        });
      }
    } catch (error) {
      console.log('1x: error:', error);
      ui.setToast(true, {
        type: 'error',
        title: 'Error',
        message: 'Failed to Mark as complete',
      });
    }
  };

  const changeFormStatus = async (complete) => {
    const ids = enrollment.forms.map((c) => c.responsesByResponses.id);
    try {
      const result = await updateFormStatus({
        variables: {
          ids,
          complete,
        },
        refetchQueries: [
          {
            query: getEnrollment,
            variables: {
              id: enrollmentId,
            },
          },
        ],
      });
      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Success',
          message: 'Document Completion Status Changed',
        });
      }
    } catch (error) {
      console.log('1x: error:', error);
      ui.setToast(true, {
        type: 'error',
        title: 'Error',
        message: 'Failed to Mark as complete',
      });
    }
  };

  const markDocumentAsComplete = async (id: string, complete: boolean) => {
    try {
      const result = await completeDocumentRequest({
        variables: {
          id,
          complete,
        },
        refetchQueries: [
          {
            query: getEnrollment,
            variables: {
              id: enrollmentId,
            },
          },
        ],
      });
      if (result) {
        ui.setToast(true, {
          type: 'success',
          title: 'Success',
          message: 'Document Completion Status Changed',
        });
      }
    } catch (error) {
      console.log('1x: error:', error);
      ui.setToast(true, {
        type: 'error',
        title: 'Error',
        message: 'Failed to Mark as complete',
      });
    }
  };

  const completeEnrollment = async () => {
    if (
      confirm('Are you sure you want to mark this enrollment as Paid?') == true
    ) {
      try {
        const result = await completeEnroll({
          variables: {
            id: enrollment.id,
          },
          refetchQueries: [
            {
              query: getAllEnrollments,
              variables: {
                start: startOfDay(controlOption.value.start),
                end: endOfDay(controlOption.value.end),
              },
            },
            {
              query: getEnrollment,
              variables: {
                id: enrollmentId,
              },
            },
          ],
        });
        if (result) {
          ui.setToast(true, {
            type: 'success',
            title: 'Success',
            message: 'Successfully Completed Enrollment',
          });
        }
      } catch (err) {
        console.log(err);
        ui.setToast(true, {
          type: 'error',
          title: 'error',
          message: `Error When Completing Enrollment ${err}`,
        });
      }
    }
  };

  React.useEffect(() => {
    if (
      enrollment &&
      enrollment.checklist &&
      Object.keys(enrollment.checklist).length === 0
    ) {
      const courseIds =
        enrollment && enrollment.metadata.map((course) => course.detail_id);

      const onlineCourseIds =
        enrollment &&
        enrollment.online_courses.flatMap((course) => {
          if (Array.isArray(course.event_detail)) {
            return course.event_detail.map((x) => x.id);
          }
          return course?.event_detail?.id;
        });
      const onlineCoursesAdded =
        (onlineCourseIds &&
          courseIds &&
          courseIds.every((val) => onlineCourseIds.includes(val))) ||
        false;
      const registrationIds =
        enrollment &&
        enrollment.courses.map((course) => course.event.detail_id);
      const onSiteCoursesAdded =
        registrationIds &&
        courseIds &&
        courseIds.every((val) => registrationIds.includes(val));

      const kits =
        enrollment?.metadata && enrollment?.metadata.length > 0
          ? enrollment?.metadata.reduce(
              (acc, obj) => (obj.kit ? [...acc, obj.kit] : acc),
              [],
            )
          : [];

      // const kitExist =
      //   enrollment && enrollment?.client_data && enrollment?.client_data?.kits
      //     ? enrollment?.client_data?.kits
      //     : [];

      // const newKits =
      //   kitExist && kitExist.length > 0
      //     ? kitExist.map(({ name: kitName, price: kitPrice }) => ({
      //         kitName,
      //         kitPrice,
      //       }))
      //     : [];

      const constCombinedKits = [...kits];

      const checklist = [
        {
          id: 'onboarding_call',
          title: 'Onboarding Call',
          complete:
            enrollment.enrollmentCall && enrollment?.enrollmentCall?.call_id,
          description: 'Call with the student to discuss the course',
        },
        {
          id: 'registrations',
          title: 'Registrations',
          description: 'Student has been registered',
          complete: onSiteCoursesAdded,
          subChecks: enrollment.metadata.map((course) => {
            return {
              title: course.detail.title,
              courseId: course.id,
              complete: registrationIds.includes(course.detail_id),
            };
          }),
        },
        {
          id: 'online_registrations',
          title: 'Online Registarations',
          complete: onlineCoursesAdded,
          description: 'Student has registered online',
          subChecks: enrollment.metadata.map((course) => {
            return {
              title: course.detail.title,
              courseId: course.id,
              complete: onlineCourseIds.includes(course.detail_id),
            };
          }),
        },
        {
          id: 'online_courses_complete',
          title: 'Online Courses Complete',
          complete:
            (enrollment.online_courses.length > 0 &&
              enrollment.online_courses.every((x) => x.complete)) ||
            false,
          description: 'Student has registered online',
          subChecks: enrollment.online_courses.map((course) => {
            const title = Array.isArray(course.event_detail)
              ? course.event_detail[0].title
              : course?.event_detail?.title
                ? course?.event_detail?.title
                : course?.data?.course?.title;
            return {
              title,
              courseId: course.id,
              complete: course.complete,
            };
          }),
        },
        {
          id: 'documents_complete',
          title: 'Documents Complete',
          description: 'Student has completed all forms',
          subChecks: enrollment.forms.map((form) => {
            return {
              title: form.formByForm.title,
              id: form.responsesByResponses.id,
              complete: form.responsesByResponses.complete,
            };
          }),
        },
        {
          id: 'uploads_complete',
          title: 'Uploads Complete',
          description: 'Student has uploaded all required documents',
          subChecks: enrollment.uploads.map((upload) => {
            return {
              title: upload.uploadRequest.title,
              status: upload.complete ? 'COMPLETE' : 'REQUESTED',
              id: upload.id,
              complete: upload.complete,
            };
          }),
        },
        {
          id: 'student_kits',
          title: 'Student Kits',
          description: 'Student has received all student kits',
          subChecks:
            constCombinedKits && constCombinedKits.length > 0
              ? constCombinedKits.map((course) => {
                  return {
                    complete: false,
                    title: course.kitName,
                    price: course.kitPrice,
                  };
                })
              : [],
        },
        {
          id: 'payments_complete',
          title: 'Payments Complete',
          description: 'Student has paid for the course',
          complete: enrollment.balance === 0 || enrollment.balance == null,
          subChecks: [
            {
              title: 'Full Payment',
              complete: enrollment.balance === 0 || enrollment.balance == null,
              description: 'Student has paid the full amount',
            },
          ],
        },
      ];

      setState((s) => ({ ...s, checklist }));
    } else if (
      enrollment &&
      enrollment.checklist &&
      Object.keys(enrollment.checklist).length > 0
    ) {
      setState((s) => ({ ...s, checklist: enrollment.checklist }));
    }
  }, [enrollment]);

  React.useEffect(() => {
    if (state.checklist && state.checklist.length > 0) {
      const newArr = state.checklist.map((check) => {
        delete check.function;
        return check;
      });
      updateChecklist({
        variables: {
          id: enrollment.id,
          checklist: newArr,
        },
      });
    }
  }, [state.checklist]);

  const deleteEnrollmentOne = async (enrollmentId) => {
    if (confirm('Are you sure you want to delete this enrollment?') == true) {
      const payments = enrollment?.payments
        .map((x) => x.payment?.invoice?.invoice_id)
        .filter(Boolean);

      try {
        if (payments && payments.length > 0) {
          Promise.all(
            payments.map((x) => {
              return voidInv({
                variables: {
                  id: x,
                },
              });
            }),
          ).then((x) => {
            console.log('voided', x);
          });
        }

        const result = await removeEnrollment({
          variables: {
            id: enrollmentId,
          },
          refetchQueries: [
            {
              query: getAllEnrollments,
              variables: {
                start: startOfDay(controlOption.value.start),
                end: endOfDay(controlOption.value.end),
              },
            },
          ],
        });
        if (result) {
          ui.setToast(true, {
            type: 'success',
            title: 'Success',
            message: 'Enrollment has been deleted',
          });
          onComplete && onComplete();
        }
      } catch {
        ui.setToast(true, {
          type: 'error',
          title: 'Error',
          message: 'There was an error deleting the enrollment',
        });
      }
    }
  };

  const addToOnlineCourse = async (data) => {
    setState((s) => ({
      ...s,
      isLoading: true,
    }));

    let group_id: string | null = null;
    const res = await checkCourseOnline({
      variables: {
        coassemble_id: Number(data.selected_online_course),
      },
    });

    if (
      res.data.online_courses &&
      res.data.online_courses.length > 0 &&
      res.data.online_courses[0].group_id
    ) {
      group_id = res.data.online_courses[0].group_id;
    } else {
      const rep = await getCourseGroup(data.selected_online_course);
      group_id = rep[0].id;
    }

    try {
      await createOnlineRegistration({
        variables: {
          data: {
            user_id: enrollment.user.id,
            coassemble_course_id: data.selected_online_course,
            coassemble_group_id: group_id,
            enrollment_id: enrollmentId,
            removed: false,
            added: false,
            complete: false,
            source: 'admin',
          },
        },
        refetchQueries: [
          {
            query: getEnrollment,
            variables: {
              id: enrollmentId,
            },
          },
        ],
      });

      ui.setToast(true, {
        type: 'success',
        title: `Added Student to Online Course`,
        message: '',
      });
      setState((s) => ({
        ...s,
        isLoading: false,
      }));
    } catch (error) {
      ui.setToast(true, {
        type: 'error',
        title: 'Adding student failed',
        message: `${error}`,
      });
      setState((s) => ({
        ...s,
        isLoading: false,
      }));
    }
  };

  const shouldHaveTasks = React.useMemo(
    () => isAfter(new Date(enrollment?.created_at), new Date('2024-02-15')),
    [enrollment],
  );

  const { discountAmount, creditAmount, paidAmount, realTotal, subTotal } =
    React.useMemo(() => {
      return calculateEnrollment(enrollment, state.invoices);
    }, [enrollment, state.invoices]);

  const onlineCoursesNotAdded = React.useMemo(() => {
    return enrollment &&
      enrollment?.courses &&
      enrollment?.online_courses &&
      enrollment?.courses.length > 0 &&
      enrollment?.online_courses.length > 0
      ? enrollment?.courses.filter(
          (obj) =>
            !enrollment?.online_courses.find(
              (o) =>
                o.coassemble_course_id === obj.event.detail.elearning_course_id,
            ),
        )
      : [];
  }, [enrollment]);

  const action = useCallback(
    (user) => {
      ui.setClient(true, user);
    },
    [ui.setClient],
  );

  React.useEffect(() => {
    if (enrollment && enrollment?.payments && enrollment?.payments.length > 0) {
      Promise.all(
        enrollment?.payments
          .filter((x) => x.payment && x.payment.invoice_id)
          .map((x) => {
            return getInv({
              variables: {
                id: x.payment.transaction_id,
              },
            });
          }),
      )
        .then((r) => {
          const filteredItems = [];
          const uniqueCharges = new Set<string>();

          for (const item of r) {
            if (!uniqueCharges.has(item.data.getInvoice.charge)) {
              uniqueCharges.add(item.data.getInvoice.charge);
              filteredItems.push(item.data.getInvoice);
            }
          }

          setState((s) => ({
            ...s,
            invoices: filteredItems,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [enrollment && enrollment?.payments]);

  async function updateTask(taskId: string, status: string) {
    try {
      await editTask({
        variables: {
          id: taskId,
          data: {
            status,
          },
        },
      });
      await refetch();
      ui.setToast(true, {
        type: 'success',
        title: 'Task successfully updated',
        message: 'Task status has been updated',
      });
    } catch (error) {
      console.log(error);
      ui.setToast(true, {
        type: 'error',
        title: 'Error updating task',
        message: 'Failed to update task status',
      });
    }
  }

  let sortedMetadata = null;
  if (enrollment?.metadata) {
    sortedMetadata = [...enrollment.metadata].sort((a, b) =>
      isAfter(new Date(a.start), new Date(b.start)) ? 1 : -1,
    );
  }

  let sortedCourses = null;
  if (enrollment?.courses) {
    sortedCourses = [...enrollment.courses].sort((a, b) =>
      isAfter(new Date(a.event.start), new Date(b.event.start)) ? 1 : -1,
    );
  }

  const vat =
    enrollment?.discounts?.[0]?.discount?.code === 'VATFREE' ? 0 : 0.2;

  return (
    <div className="-mt-2 overflow-hidden">
      {enrollment && (
        <div className="flex-grow rounded-lg shadow border border-gray-200 w-full h-full mx-auto lg:flex overflow-hidden">
          {/* Left sidebar & main wrapper */}
          <div className="flex-1 min-w-0 bg-gray-50 xl:flex">
            <div className="hidden xl:block border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:border-r xl:border-gray-200 bg-white">
              <div className="h-full overflow-y-auto">
                {/* Start left column area */}
                <div className="w-44 xl:w-52 flex flex-col space-y-2 text-left">
                  <div className="h-full relative">
                    <Tab.Group
                      vertical
                      onChange={(e) => {
                        const tab = tabs[e];
                        setState((s) => ({ ...s, tab: tab }));
                      }}
                    >
                      <Tab.List className="flex flex-col p-1 space-y-1">
                        {tabs.map((tab, index) => {
                          return (
                            <Tab
                              key={index}
                              className={({ selected }) =>
                                classNames(
                                  'transition-all text-left px-4 w-full py-1.5 xl:py-2.5 text-xs xl:text-sm leading-5 font-medium rounded-lg',
                                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-purple-600 ring-white ring-opacity-60',
                                  selected
                                    ? 'bg-purple-600 shadow bg-purple-900/80 text-white'
                                    : 'bg-white hover:bg-purple/[0.12] hover:text-purple-800 text-black',
                                )
                              }
                            >
                              <span>{tab.name}</span>
                            </Tab>
                          );
                        })}
                      </Tab.List>
                    </Tab.Group>
                  </div>
                </div>
                {/* End left column area */}
              </div>
            </div>
            {/* ************************ */}
            <div className="lg:min-w-0 lg:flex-1 flex flex-col overflow-hidden h-full">
              <div className="flex-1 overflow-scroll py-3 px-3 xl:py-6 pb-20 xl:px-6">
                {/* Start main area*/}
                <section aria-labelledby="quick-links-title" className="">
                  {state.tab.name === 'Courses' && (
                    <div>
                      {sortedMetadata?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {sortedMetadata
                              ?.filter((x) => x !== null)
                              ?.map((x) => {
                                return (
                                  <li key={`${x.id}-${x.detail_id}`}>
                                    <div className="px-4 py-4 sm:px-6">
                                      <div className="flex items-center justify-between">
                                        <a
                                          href={`/events/${x.id}`}
                                          className="text-sm font-medium text-purple-600 truncate hover:text-purple-500 transition-all duration-75"
                                        >
                                          {x.detail?.title}{' '}
                                        </a>
                                        <div className="ml-2 flex-shrink-0 space-x-2 flex">
                                          {x.status && (
                                            <Status
                                              small
                                              value={x.status.toUpperCase()}
                                            />
                                          )}
                                        </div>
                                      </div>

                                      {!x?.detail?.is_training ? (
                                        <div className="flex items-center my-2 justify-between text-gray-500 text-sm">
                                          <p>
                                            Amount: {currency(x.seat_price)}
                                          </p>
                                          <div className="flex items-center space-x-2">
                                            <span>Is Package:</span>
                                            <span className="text-gray-900">
                                              <Status
                                                value={
                                                  x?.detail?.is_package
                                                    ? 'Yes'
                                                    : 'No'
                                                }
                                                small
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="flex items-center my-2 justify-between text-gray-500 text-sm">
                                          <p>
                                            Amount: {currency(x.seat_price)}
                                          </p>
                                          <div className="flex items-center space-x-2">
                                            <span>Is Training:</span>
                                            <span className="text-gray-900">
                                              <Status
                                                value={
                                                  x?.detail?.is_training
                                                    ? 'Yes'
                                                    : 'No'
                                                }
                                                small
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      <div className="flex flex-col col-span-10 text-sm">
                                        {!x?.detail?.is_package && (
                                          <div className="flex">
                                            Date:{' '}
                                            {format(
                                              new Date(x?.start),
                                              'do MMMM yyyy',
                                            ) +
                                              ' at ' +
                                              format(
                                                new Date(x?.start),
                                                'HH:mm',
                                              ) +
                                              ' to ' +
                                              format(new Date(x?.end), 'HH:mm')}
                                          </div>
                                        )}
                                      </div>

                                      <div className="mt-2 flex justify-between items-center">
                                        <p className="flex items-center text-sm text-gray-500">
                                          <UsersIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          {x.event_resource &&
                                          x.event_resources[0]?.user?.given_name
                                            ? x.event_resources[0]?.user
                                                ?.given_name
                                            : 'Unknown'}
                                        </p>
                                        <div className="flex items-center">
                                          {x?.detail?.is_package &&
                                            !x?.detail?.is_training && (
                                              <div>
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={() => {
                                                    getPackage({
                                                      variables: {
                                                        id: x.detail?.id,
                                                        now: new Date(),
                                                      },
                                                    }),
                                                      setState({
                                                        ...state,
                                                        packageCourseModal:
                                                          true,
                                                      });
                                                  }}
                                                >
                                                  Add to Courses
                                                </button>
                                              </div>
                                            )}

                                          {x.type && (
                                            <p className="capitalize mt-2 mr-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                              <MapPinIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                              {x.type
                                                .replace('_', ' ')
                                                .toLowerCase()}{' '}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                      {!sortedMetadata ||
                        (sortedMetadata.length === 0 && (
                          <EmptyState type="Courses" />
                        ))}
                    </div>
                  )}
                  {state.tab.name === 'Online Registrations' && (
                    <div>
                      {enrollment?.online_courses?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment.online_courses
                              .filter((x) => x !== null)
                              .map((x) => {
                                return (
                                  <li key={`${x.id}`}>
                                    <div className="px-4 py-4 sm:px-6">
                                      <div className="flex items-center justify-between">
                                        <a className="text-sm font-medium text-purple-600 truncate">
                                          {x?.event_detail?.[0]?.title ??
                                            x?.event_detail?.title ??
                                            x.data?.course?.title}{' '}
                                        </a>
                                        <div className="ml-2 flex-shrink-0 space-x-2 flex items-center">
                                          <span className="text-gray-500 text-sm mt-1">
                                            Complete:
                                          </span>
                                          <span className="text-gray-900">
                                            <Status
                                              value={
                                                x?.detail?.is_package
                                                  ? 'Yes'
                                                  : 'No'
                                              }
                                              small
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex items-center my-2 justify-between text-gray-500 text-sm">
                                        <p>
                                          Course ID: {x?.coassemble_course_id}
                                        </p>
                                        <p>
                                          Group ID: {x?.coassemble_group_id}
                                        </p>
                                      </div>
                                      <div className="flex flex-col col-span-10 text-sm">
                                        <div className="flex">
                                          Date Issued:{' '}
                                          {format(
                                            new Date(x?.created_at),
                                            'do MMMM yyyy',
                                          ) +
                                            ' at ' +
                                            format(
                                              new Date(x?.created_at),
                                              'HH:mm',
                                            )}
                                        </div>
                                      </div>

                                      <div className="mt-2 flex justify-between items-center">
                                        <p className="flex items-center text-sm text-gray-500">
                                          Percentage Complete:{' '}
                                          {x?.data?.progress_percent
                                            ? x?.data?.progress_percent
                                            : 0}
                                          %
                                        </p>
                                        <div className="flex items-center">
                                          <p className="capitalize mt-2 mr-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                            <GlobeAltIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            Online
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}

                      {onlineCoursesNotAdded &&
                        onlineCoursesNotAdded.length > 0 && (
                          <div className="flex w-full justify-end p-4">
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="z-20 w-full flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white hover:bg-purple-500 bg-purple-600 transition-all duration-75">
                                  Add To Online Courses
                                  <ChevronDownIcon
                                    className="ml-1 -mr-1 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute z-40 right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="px-1 py-1 ">
                                    {onlineCoursesNotAdded.map(
                                      (course, index) => {
                                        return (
                                          <Menu.Item key={index}>
                                            {({ active }) => (
                                              <button
                                                onClick={() =>
                                                  addToOnlineCourse({
                                                    selected_online_course:
                                                      course.event.detail
                                                        .elearning_course_id,
                                                  })
                                                }
                                                className={`${
                                                  active
                                                    ? 'bg-purple-500 text-white'
                                                    : 'text-gray-900'
                                                } group flex w-full items-center truncate rounded-md px-2 py-2 text-sm`}
                                              >
                                                <PlusIcon
                                                  className="mr-2 h-5 w-5 text-purple-500 group-hover:text-white"
                                                  aria-hidden="true"
                                                />
                                                {course?.event?.detail?.title}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        );
                                      },
                                    )}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        )}

                      {!enrollment.online_courses ||
                        (enrollment.online_courses?.length === 0 && (
                          <EmptyState type="Online Registrations" />
                        ))}
                    </div>
                  )}
                  {state.tab.name === 'Registrations' && (
                    <div>
                      {sortedCourses?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {sortedCourses.map((x) => {
                              const online_courses =
                                x.event.detail.elearning_course_id &&
                                x.event.detail.elearning_course_id.length > 0
                                  ? x.event.detail.elearning_course_id.split(
                                      ',',
                                    )
                                  : '';

                              return (
                                <li key={`${x.id}-${x.created_at}`}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                      <a
                                        href={`/events/${x.event.id}`}
                                        className="text-sm font-medium text-purple-600 truncate hover:text-purple-500 transition-all duration-75"
                                      >
                                        {x.event?.detail?.title}{' '}
                                      </a>
                                      <div className="ml-2 flex-shrink-0 space-x-2 flex">
                                        {x?.status && (
                                          <Status
                                            small
                                            value={x?.status?.toUpperCase()}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="text-gray-500 my-2 space-y-4">
                                      <div className="text-sm flex justify-between">
                                        <p>
                                          Amount:{' '}
                                          {currency(x?.event?.seat_price)}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex flex-col col-span-10 text-sm">
                                      <div className="flex">
                                        Date:{' '}
                                        {format(
                                          new Date(x?.event?.start),
                                          'do MMMM yyyy',
                                        ) +
                                          ' at ' +
                                          format(
                                            new Date(x?.event?.start),
                                            'HH:mm',
                                          ) +
                                          ' to ' +
                                          format(
                                            new Date(x?.event?.end),
                                            'HH:mm',
                                          )}
                                      </div>
                                    </div>

                                    <div className="mt-2 flex justify-between items-center">
                                      <div className="flex">
                                        {x.event.event_resources && (
                                          <p className="flex items-center text-sm text-gray-500">
                                            <UsersIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {x.event.event_resources[0]?.user
                                              ?.given_name
                                              ? x.event.event_resources[0]?.user
                                                  ?.given_name
                                              : 'Unknown'}
                                          </p>
                                        )}
                                        {x.type && (
                                          <p className="capitalize mt-2 mr-1 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                            <MapPinIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {x.type
                                              .replace('_', ' ')
                                              .toLowerCase()}{' '}
                                          </p>
                                        )}
                                      </div>

                                      {online_courses &&
                                        online_courses.length > 0 &&
                                        !enrollment.online_courses.some(
                                          (online_course) =>
                                            online_courses.includes(
                                              online_course.coassemble_course_id,
                                            ),
                                        ) && (
                                          <div className="w-54">
                                            <div
                                              onClick={() => {
                                                Promise.all(
                                                  online_courses.map((x) => {
                                                    return addToOnlineCourse({
                                                      selected_online_course: x,
                                                    });
                                                  }),
                                                );
                                              }}
                                              className="btn-primary"
                                            >
                                              Add to Online Course
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.courses?.length === 0 && (
                        <EmptyState type="Registrations" />
                      )}
                    </div>
                  )}
                  {state.tab.name === 'Payments' && (
                    <div>
                      {enrollment?.payments?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment?.payments
                              ?.filter((x) => !x.payment?.invoice_id)
                              .map((x) => {
                                const paymentsBegun =
                                  !enrollment?.payments?.some(
                                    (x) =>
                                      x?.payment?.status === 'complete' ||
                                      x?.payment?.status === 'completed',
                                  );

                                return (
                                  <li
                                    key={`${x.id}-${x.payment?.transaction_id}`}
                                  >
                                    <div className="px-4 py-4 sm:px-6">
                                      <div className="flex justify-between items-center">
                                        <p
                                          className="truncate text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75 cursor-pointer"
                                          onClick={() =>
                                            router.push(
                                              x.payment?.method === 'stripe'
                                                ? x.payment?.metadata?.stripeURL
                                                : x.payment?.method === 'klarna'
                                                  ? x.payment?.metadata
                                                      .klarnaURL
                                                  : '',
                                            )
                                          }
                                        >
                                          {x?.payment?.transaction_id
                                            ? x?.payment?.transaction_id
                                            : 'No Transaction ID'}
                                        </p>
                                        {x.payment?.metadata?.expired ? (
                                          <div className="flex items-center space-x-2">
                                            <div className="space-x-2">
                                              {x?.payment?.status && (
                                                <Status small value="EXPIRED" />
                                              )}
                                            </div>
                                            <button
                                              onClick={() => {
                                                console.log(
                                                  'CURRENT PAYMENT',
                                                  x,
                                                );
                                                resendLink(x.payment?.id);
                                              }}
                                              className={classNames(
                                                state.resending &&
                                                  'animate-spin',
                                              )}
                                            >
                                              <p className="w-34 whitespace-nowrap hidden group-hover:inline rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xs text-center -top-6 -right-10">
                                                {`Click to resend ${x.payment?.method} link`}
                                              </p>
                                              <ArrowPathIcon className="h-6 w-6 text-gray-500" />
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="flex items-center space-x-2">
                                            <div className="flex group">
                                              <p className="w-34 whitespace-nowrap hidden group-hover:inline rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xs text-center -top-6 -right-10">
                                                {`Click to open ${x.payment?.method} link`}
                                              </p>
                                              {(x.payment?.method ===
                                                'stripe' ||
                                                x.payment?.method ===
                                                  'klarna') && (
                                                <button
                                                  className="inline-flex"
                                                  onClick={() =>
                                                    router.push(
                                                      x.payment?.method ===
                                                        'stripe'
                                                        ? x.payment?.metadata
                                                            .stripeURL
                                                        : x.payment?.method ===
                                                            'klarna'
                                                          ? x.payment?.metadata
                                                              .klarnaURL
                                                          : '',
                                                    )
                                                  }
                                                >
                                                  <ArrowsPointingOutIcon className="h-6 w-6 text-gray-500" />
                                                </button>
                                              )}
                                            </div>
                                            <div className="flex group">
                                              <p className="w-34 whitespace-nowrap hidden group-hover:block rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xs text-center -top-6 -right-10">
                                                {`Click to copy ${x.payment?.method} link`}
                                              </p>
                                              {(x.payment?.method ===
                                                'stripe' ||
                                                x.payment?.method ===
                                                  'klarna') && (
                                                <button
                                                  onClick={() =>
                                                    navigator.clipboard.writeText(
                                                      x.payment?.method ===
                                                        'stripe'
                                                        ? x.payment?.metadata
                                                            .stripeURL
                                                        : x.payment?.method ===
                                                            'klarna'
                                                          ? x.payment?.metadata
                                                              .klarnaURL
                                                          : '',
                                                    )
                                                  }
                                                >
                                                  <LinkIcon className="h-6 w-6 text-gray-500" />
                                                </button>
                                              )}
                                            </div>
                                            <div className="space-x-2">
                                              {x?.payment?.status && (
                                                <Status
                                                  small
                                                  value={x?.payment?.status.toUpperCase()}
                                                />
                                              )}

                                              {x?.payment?.invoice_id &&
                                                x?.payment?.status !==
                                                  'complete' && (
                                                  <Status
                                                    small
                                                    value={
                                                      isBefore(
                                                        new Date(
                                                          x.payment?.invoice?.due_date,
                                                        ),
                                                        new Date(),
                                                      )
                                                        ? 'OVERDUE'
                                                        : ''
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <div className="text-gray-500 mt-2 space-y-4">
                                        <div className="text-sm flex justify-between">
                                          <p>
                                            Amount:{' '}
                                            {currency(x?.payment?.amount)}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="mt-2 flex text-sm justify-between items-center">
                                        <div className="flex">
                                          <p>
                                            Transaction Created:{' '}
                                            {x.created_at &&
                                              format(
                                                new Date(x.created_at),
                                                'do MMMM yyyy HH:mm',
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                      {x.payment?.status === 'complete' && (
                                        <div className="mt-2 flex text-sm justify-between items-center">
                                          <div className="flex">
                                            <p>
                                              Payment Completed:{' '}
                                              {x.payment.completed_at &&
                                                format(
                                                  new Date(
                                                    x.payment.completed_at,
                                                  ),
                                                  'do MMMM yyyy HH:mm',
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      <div className="mt-2 flex text-sm justify-between items-center">
                                        <div className="flex">
                                          {x?.payment?.invoice_id && (
                                            <p>
                                              Due Date:{' '}
                                              {format(
                                                new Date(
                                                  x?.payment?.invoice.due_date,
                                                ),
                                                'do MMMM yyyy',
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="flex justify-between">
                                        <div className="mt-2">
                                          {enrollment.employee && (
                                            <p className="flex items-center text-sm text-gray-500">
                                              <UsersIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                              {enrollment.employee?.given_name
                                                ? enrollment.employee
                                                    ?.given_name
                                                : 'Unknown'}
                                            </p>
                                          )}
                                        </div>
                                        <div className="flex items-center space-x-4">
                                          <div className="text-sm">
                                            {x?.payment?.method ? (
                                              <Icon
                                                method={x.payment?.method}
                                              />
                                            ) : null}
                                            {x?.payment?.method === 'finance' &&
                                              'Finance'}
                                          </div>
                                          {x?.payment?.status !==
                                            'complete' && (
                                            <div>
                                              {paymentsBegun ||
                                                (enrollment.status !==
                                                  'completed' && (
                                                  <button
                                                    className="btn-primary"
                                                    onClick={() =>
                                                      setState({
                                                        ...state,
                                                        payment: x,
                                                        enrollment: enrollment,
                                                        modal: true,
                                                      })
                                                    }
                                                  >
                                                    Edit
                                                  </button>
                                                ))}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            {state.invoices &&
                              state.invoices.length > 0 &&
                              state.invoices.map((invoice) => {
                                const currentStatus =
                                  invoice?.status?.toUpperCase();
                                return (
                                  <li key={invoice.id}>
                                    <div className="px-4 py-4 sm:px-6">
                                      <div className="flex justify-between items-center">
                                        <p
                                          className="truncate text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75 cursor-pointer"
                                          onClick={() =>
                                            router.push(invoice.url)
                                          }
                                        >
                                          {invoice.id}
                                        </p>
                                        <div className="flex items-center space-x-2">
                                          <div className="flex group">
                                            <p className="w-34 whitespace-nowrap hidden group-hover:inline rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xs text-center -top-6 -right-10">
                                              {`Click to open Stripe link`}
                                            </p>

                                            <button
                                              className="inline-flex"
                                              onClick={() =>
                                                router.push(invoice.url)
                                              }
                                            >
                                              <ArrowsPointingOutIcon className="h-6 w-6 text-gray-500" />
                                            </button>
                                          </div>
                                          <div className="flex group">
                                            <p className="w-34 whitespace-nowrap hidden group-hover:block rounded-md shadow-lg p-1 bg-gray-100 text-gray-600 text-xs text-center -top-6 -right-10">
                                              {`Click to copy Stripe link`}
                                            </p>

                                            <button
                                              onClick={() =>
                                                navigator.clipboard.writeText(
                                                  invoice.url,
                                                )
                                              }
                                            >
                                              <LinkIcon className="h-6 w-6 text-gray-500" />
                                            </button>
                                          </div>
                                          <div className="space-x-2">
                                            <Status
                                              small
                                              value={currentStatus}
                                            />

                                            {currentStatus !== 'PAID' && (
                                              <Status
                                                small
                                                value={
                                                  isBefore(
                                                    new Date(
                                                      fromUnixTime(
                                                        invoice.dueDate,
                                                      ),
                                                    ),
                                                    new Date(),
                                                  )
                                                    ? 'OVERDUE'
                                                    : ''
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="text-gray-500 mt-2 space-y-4">
                                        <div className="text-sm flex justify-between">
                                          <p>Amount: {currency(invoice.due)}</p>
                                        </div>
                                      </div>

                                      <div className="mt-2 flex text-sm justify-between items-center">
                                        <div className="flex">
                                          <p>
                                            Transaction Created:{' '}
                                            {invoice.created &&
                                              format(
                                                new Date(
                                                  fromUnixTime(invoice.created),
                                                ),
                                                'do MMMM yyyy HH:mm',
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                      {invoice.datePaid && (
                                        <div className="mt-2 flex text-sm justify-between items-center">
                                          <div className="flex">
                                            <p>
                                              Payment Completed:{' '}
                                              {invoice.datePaid &&
                                                format(
                                                  new Date(
                                                    fromUnixTime(
                                                      invoice.datePaid,
                                                    ),
                                                  ),
                                                  'do MMMM yyyy HH:mm',
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      <div className="mt-2 flex text-sm justify-between items-center">
                                        <div className="flex">
                                          {invoice.dueDate && (
                                            <p>
                                              Due Date:{' '}
                                              {format(
                                                new Date(
                                                  fromUnixTime(invoice.dueDate),
                                                ),
                                                'do MMMM yyyy',
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="flex justify-between">
                                        <div className="mt-2">
                                          {enrollment.employee && (
                                            <p className="flex items-center text-sm text-gray-500">
                                              <UsersIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                              {enrollment.employee?.given_name
                                                ? enrollment.employee
                                                    ?.given_name
                                                : 'Unknown'}
                                            </p>
                                          )}
                                        </div>
                                        <div className="flex items-center space-x-4">
                                          <div className="text-sm">
                                            <Icon method="stripe" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.payments?.length === 0 && (
                        <EmptyState type="Payments" />
                      )}
                    </div>
                  )}
                  {state.tab.name === 'Upload Requests' && (
                    <div>
                      <div className="flex justify-end">
                        <div className="w-40 justify-end flex">
                          <button
                            onClick={() =>
                              setState((s) => ({
                                ...s,
                                uploadRequestModal: true,
                              }))
                            }
                            className="btn-primary"
                          >
                            Request Upload
                          </button>
                        </div>
                      </div>
                      {enrollment?.uploads?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment.uploads.map((x) => {
                              return (
                                <li key={`${x.id}`}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex justify-between items-center">
                                      <p className="text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75">
                                        {x?.uploadRequest.title}
                                      </p>

                                      <div className="whitespace-nowrap text-sm text-gray-500">
                                        {x.complete ? (
                                          <p className="bg-green-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                                            Uploaded
                                          </p>
                                        ) : x.client_complete ? (
                                          <p className="bg-amber-500 text-center p-1 px-2.5 text-white text-xs rounded-full">
                                            Review
                                          </p>
                                        ) : (
                                          <p className="bg-red-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                                            Not Uploaded
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="text-gray-500 mt-2 space-y-4">
                                      <div className="text-sm flex justify-between">
                                        <p>{x.uploadRequest.description}</p>
                                      </div>
                                    </div>

                                    <div className="mt-2 flex text-sm justify-between items-center">
                                      <div className="flex space-x-1">
                                        <span>Last Updated:</span>
                                        <div className="flex">
                                          {x.updated_at &&
                                            format(
                                              new Date(x.updated_at),
                                              'do MMMM yyyy HH:mm',
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    {x?.completed_at ? (
                                      <div className="flex text-sm justify-between items-center">
                                        <div className="flex space-x-1">
                                          <span>Completed At:</span>
                                          <div className="flex">
                                            {x.completed_at &&
                                              format(
                                                new Date(x.completed_at),
                                                'do MMMM yyyy HH:mm',
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    <div className="flex justify-between">
                                      <div className="mt-2 flex space-x-2">
                                        {enrollment.employee && (
                                          <p className="flex items-center text-sm text-gray-500">
                                            <UsersIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {enrollment.employee?.given_name
                                              ? enrollment.employee?.given_name
                                              : 'Unknown'}
                                          </p>
                                        )}
                                      </div>
                                      <div className="flex items-center space-x-2 flex-1 max-w-[300px]">
                                        <div className="group cursor-pointer flex space-x-1 text-gray-600 flex-1">
                                          {!x?.complete ? (
                                            <button
                                              className="btn btn-secondary flex items-center space-x-1 flex-1"
                                              onClick={() => {
                                                markRequestAsComplete(
                                                  x.id,
                                                  true,
                                                );
                                              }}
                                            >
                                              <span>Mark As Complete</span>
                                              <CheckCircleIcon className="h-6 w-6" />
                                            </button>
                                          ) : null}
                                        </div>
                                        {x.assets && x.assets.length > 0 && (
                                          <div>
                                            <button
                                              className="btn-secondary group-hover:bg-gray-100"
                                              onClick={() => {
                                                viewRequest(x);
                                              }}
                                            >
                                              View
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.uploads?.length === 0 && (
                        <EmptyState type="Upload Requests" />
                      )}
                    </div>
                  )}
                  {state.tab.name === 'Documents' && (
                    <div>
                      <div className="flex justify-end">
                        <div className="w-40 justify-end flex">
                          <button
                            onClick={() =>
                              setState((s) => ({
                                ...s,
                                formRequestModal: true,
                              }))
                            }
                            className="btn-primary"
                          >
                            Add Document
                          </button>
                        </div>
                      </div>
                      {enrollment?.forms?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment?.forms?.map((x) => {
                              return (
                                <li key={`${x.id}`}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex justify-between items-center">
                                      <p className="text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75">
                                        {x?.formByForm?.title}
                                      </p>

                                      <div className="whitespace-nowrap text-sm text-gray-500">
                                        {x?.responsesByResponses?.complete ? (
                                          <p className="bg-green-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                                            Complete
                                          </p>
                                        ) : x?.responsesByResponses
                                            ?.client_complete ? (
                                          <p className="bg-amber-500 text-center p-1 px-2.5 text-white text-xs rounded-full">
                                            Review
                                          </p>
                                        ) : (
                                          <p className="bg-red-600 text-center p-1 px-2.5 text-white text-xs rounded-full">
                                            Incomplete
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="text-gray-500 mt-2 space-y-4">
                                      <div className="text-sm flex justify-between">
                                        <p>
                                          {
                                            x?.formByForm?.content
                                              .treatmentDescription
                                          }
                                        </p>
                                      </div>
                                    </div>

                                    <div className="mt-2 flex text-sm justify-between items-center">
                                      <div className="flex space-x-1">
                                        <span>Last Updated:</span>
                                        <div className="flex">
                                          {x.updated_at &&
                                            format(
                                              new Date(x.updated_at),
                                              'do MMMM yyyy HH:mm',
                                            )}
                                        </div>
                                      </div>

                                      <div></div>
                                    </div>

                                    {x?.responsesByResponses?.completed_at ? (
                                      <div className="flex text-sm justify-between items-center">
                                        <div className="flex space-x-1">
                                          <span>Completed At:</span>
                                          <div className="flex">
                                            {x?.responsesByResponses
                                              ?.completed_at &&
                                              format(
                                                new Date(),
                                                'do MMMM yyyy HH:mm',
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="flex justify-between">
                                      <div className="mt-2">
                                        {enrollment.employee && (
                                          <p className="flex items-center text-sm text-gray-500">
                                            <UsersIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {enrollment.employee?.given_name
                                              ? enrollment.employee?.given_name
                                              : 'Unknown'}
                                          </p>
                                        )}
                                      </div>
                                      <div className="flex items-center space-x-2">
                                        <Menu
                                          as="div"
                                          className="relative inline-block text-left"
                                        >
                                          <div>
                                            <Menu.Button className="z-20 w-full flex justify-center py-2 px-4 border border-purple-500 rounded-md shadow-sm text-sm font-medium text-purple-500 hover:bg-gray-100 bg-white transition-all duration-75">
                                              Options
                                              <ChevronDownIcon
                                                className="ml-1 -mr-1 h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </Menu.Button>
                                          </div>
                                          <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <Menu.Items className="absolute z-40 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="px-1 py-1 ">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => {
                                                        ui.setFullScreen({
                                                          formData: x,
                                                          loadForm: true,
                                                          userID:
                                                            enrollment?.user
                                                              ?.id,
                                                          formId: x.form.id,
                                                        });
                                                      }}
                                                      className={`${
                                                        active
                                                          ? 'bg-purple-500 text-white'
                                                          : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      <PencilIcon
                                                        className="mr-2 h-5 w-5 text-purple-400"
                                                        aria-hidden="true"
                                                      />
                                                      Allow Student To Edit
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => {
                                                        setState((s) => ({
                                                          ...s,
                                                          tab: {
                                                            name: 'Courses',
                                                            icon: UserIcon,
                                                            current: false,
                                                          },
                                                          form: x,
                                                          formModal: true,
                                                        }));
                                                      }}
                                                      className={`${
                                                        active
                                                          ? 'bg-purple-500 text-white'
                                                          : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      <BuildingOffice2Icon
                                                        className="mr-2 h-5 w-5 text-purple-400"
                                                        aria-hidden="true"
                                                      />
                                                      Edit As Admin
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      onClick={() => {
                                                        markDocumentAsComplete(
                                                          x.id,
                                                          true,
                                                        );
                                                      }}
                                                      className={`${
                                                        active
                                                          ? 'bg-purple-500 text-white'
                                                          : 'text-gray-900'
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      <CheckIcon
                                                        className="mr-2 h-5 w-5 text-purple-400"
                                                        aria-hidden="true"
                                                      />
                                                      Mark As Complete
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                            </Menu.Items>
                                          </Transition>
                                        </Menu>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.forms?.length === 0 && (
                        <EmptyState type="Documents" />
                      )}
                    </div>
                  )}
                  {state.tab.name === 'Credits' && (
                    <div>
                      {enrollment?.credits?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment?.credits?.map((x) => {
                              return (
                                <li key={`${x.id}-${x?.credit_id}`}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex justify-between items-center">
                                      <p className="text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75">
                                        {x?.credit?.explanation
                                          ? x?.credit?.explanation
                                          : 'No Explanation'}
                                      </p>
                                      <div className="ml-2 justify-end flex w-full ">
                                        {x?.credit?.redeemed && (
                                          <Status
                                            small
                                            value={x?.credit?.redeemed}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="flex flex-col col-span-10 text-sm">
                                      {x?.payment?.method ? (
                                        <Icon method={x.payment?.method} />
                                      ) : null}
                                      {x?.payment?.method === 'finance' &&
                                        'Finance'}
                                    </div>
                                    <div className="text-gray-500 mt-2 space-y-4">
                                      <div className="text-sm flex justify-between">
                                        <p>
                                          Amount: {currency(x?.credit?.amount)}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="mt-2 flex text-sm justify-between items-center">
                                      <div className="flex">
                                        {x.created_at &&
                                          format(
                                            new Date(x.created_at),
                                            'do MMMM yyyy HH:mm',
                                          )}
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      {enrollment.employee && (
                                        <p className="flex items-center text-sm text-gray-500">
                                          <UsersIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          {enrollment.employee?.given_name
                                            ? enrollment.employee?.given_name
                                            : 'Unknown'}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.credits?.length === 0 && (
                        <EmptyState type="Credits" />
                      )}
                    </div>
                  )}
                  {state.tab.name === 'Discounts' && (
                    <div>
                      {enrollment?.discounts?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          {' '}
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment?.discounts?.map((x) => {
                              return (
                                <li key={`${x.id}-${x?.discount_id}`}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex justify-between items-center">
                                      <p className="capitalize text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75">
                                        {x?.discount?.code
                                          ? x?.discount?.code
                                          : 'No Explanation'}
                                      </p>
                                      <div className="ml-2 justify-end flex w-full ">
                                        {x?.discount?.active && (
                                          <Status
                                            small
                                            value={x?.discount?.active}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="flex flex-col col-span-10 text-sm">
                                      {x?.payment?.method ? (
                                        <Icon method={x.payment.method} />
                                      ) : null}
                                      {x?.payment?.method === 'finance' &&
                                        'Finance'}
                                    </div>
                                    <div className="text-gray-500 mt-2 space-y-4">
                                      <div className="text-sm flex justify-between">
                                        {x?.discount?.type === 'percent' && (
                                          <p>
                                            Discount Percentage:{' '}
                                            {x?.discount?.value}%
                                          </p>
                                        )}
                                        {x?.discount?.type === 'pound' && (
                                          <p>
                                            Discount Amount:{' '}
                                            {x?.discount?.value}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="mt-2 flex text-sm justify-between items-center">
                                      <div className="flex">
                                        {x.created_at &&
                                          format(
                                            new Date(x.created_at),
                                            'do MMMM yyyy HH:mm',
                                          )}
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      {enrollment.employee && (
                                        <p className="flex items-center text-sm text-gray-500">
                                          <UsersIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          {enrollment.employee?.given_name
                                            ? enrollment.employee?.given_name
                                            : 'Unknown'}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.discounts?.length === 0 && (
                        <EmptyState type="Discounts" />
                      )}
                    </div>
                  )}
                  {state.tab.name === 'Notes' && (
                    <div>
                      {enrollment?.notes?.length > 0 && (
                        <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg px-6">
                          <ul role="list" className="divide-y divide-gray-200">
                            {enrollment.notes.map((x) => {
                              return (
                                <li key={`${x.id}`}>
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex justify-between items-center">
                                      <p className="capitalize truncate text-sm font-medium text-purple-600 whitespace-nowrap hover:text-purple-500 transition-all duration-75">
                                        {x?.noteByNote?.title
                                          ? x?.noteByNote?.title
                                          : 'No Title'}
                                      </p>
                                      <div className="ml-2 justify-end flex w-full ">
                                        {x?.discount?.active && (
                                          <Status
                                            small
                                            value={x?.discount?.active}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="text-gray-500 mt-2 space-y-4">
                                      <div className="text-sm flex justify-between">
                                        {x?.noteByNote?.content && (
                                          <p>{x?.noteByNote?.content}</p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="mt-2 flex text-sm justify-between items-center">
                                      <div className="flex">
                                        {x.created_at &&
                                          format(
                                            new Date(x.created_at),
                                            'do MMMM yyyy HH:mm',
                                          )}
                                      </div>
                                    </div>
                                    <div className="mt-2">
                                      {enrollment.employee && (
                                        <p className="flex items-center text-sm text-gray-500">
                                          {enrollment?.employee.user_assets &&
                                          enrollment?.employee.user_assets
                                            .length ? (
                                            <div className="relative w-8 h-8 overflow-hidden rounded-full mr-2">
                                              <Image
                                                src={
                                                  enrollment?.employee
                                                    .user_assets[0].asset.url
                                                }
                                                layout="fill"
                                                alt={
                                                  enrollment?.employee
                                                    .given_name
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <UsersIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          )}
                                          {enrollment.employee?.given_name
                                            ? enrollment.employee?.given_name
                                            : 'Unknown'}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      {enrollment?.notes?.length === 0 && (
                        <EmptyState type="Notes" />
                      )}
                      <div className="flex w-full justify-end space-x-2 px-5">
                        {state.tab.name === 'Notes' && (
                          <div className="w-56 my-3">
                            <button
                              className="btn-primary"
                              onClick={() =>
                                setState({ ...state, newNote: true })
                              }
                            >
                              New Note
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {state.tab.name === 'Delete' && (
                    <div className="mt-4 bg-white shadow border border-gray-100 overflow rounded-lg p-6">
                      <p className="text-lg text-center py-4 font-medium text-gray-900">
                        Deleting Enrollment will <b>remove all data</b>{' '}
                        associated with this enrollment including all payments,
                        discounts, notes, and attendance.
                        <br />
                        <br />
                        Are you sure you want to delete this enrollment?
                      </p>
                      <button
                        onClick={() => deleteEnrollmentOne(enrollment.id)}
                        className="btn-primary "
                      >
                        Delete
                      </button>
                    </div>
                  )}

                  {state.tab.name === 'Receipt' && (
                    <ViewReceipt receipt={enrollment.receipt} />
                  )}
                </section>
                {/* End main area */}
              </div>
            </div>
          </div>
          <div className="bg-white lg:flex-shrink-0 lg:border-l lg:border-gray-200 h-full overflow-y-scroll">
            <div className="h-full lg:w-80">
              {/* Start right column area */}
              {enrollment && enrollment.user && (
                <div className="lg:w-80">
                  <div className="flex flex-col text-left shadow-sm p-4">
                    <div>
                      <h3
                        onClick={() => action(enrollment?.user?.id)}
                        className="font-semibold text-base text-gray-900"
                      >
                        {enrollment?.user?.name} {enrollment?.user?.surname}
                      </h3>
                      <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                        <div className="flex items-center justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Created: </dt>
                          <dd className="hidden xl:flex text-purple-700 text-xs">
                            {format(
                              parseISO(enrollment?.created_at),
                              'do MMMM yyyy HH:mm',
                            )}
                          </dd>
                          <dd className="flex xl:hidden text-purple-700 text-xs">
                            Joined:{' '}
                            {format(
                              parseISO(enrollment?.created_at),

                              'dd MMMM yyyy',
                            )}
                          </dd>
                        </div>
                        <div className="flex justify-between items-center py-3 text-sm font-medium">
                          <button
                            type="button"
                            onClick={() =>
                              setState((s) => ({ ...s, newMessage: true }))
                            }
                            className="text-purple-700 hover:text-purple-600 text-sm flex items-center relative group cursor-pointer w-full"
                          >
                            <AtSymbolIcon className="w-3.5 mr-1 text-gray-500" />
                            {enrollment?.user?.email}
                          </button>
                        </div>
                        <div className="flex justify-between items-center py-3 text-sm font-medium">
                          <button
                            type="button"
                            className="text-purple-700 hover:text-purple-600 text-sm flex items-center relative group cursor-pointer w-full"
                            onClick={() => {
                              ui.setCall(true, {
                                to: enrollment.user.contact_number,
                              });
                            }}
                          >
                            <PhoneIcon className="w-3.5 mr-1 text-gray-500" />
                            {enrollment.user.contact_number}
                          </button>
                        </div>

                        <div className="flex items-center justify-between py-3 text-xs font-medium">
                          <dt className="text-gray-500">Training Total:</dt>
                          <dd className="text-purple-700  truncate">
                            {currency(subTotal)}
                          </dd>
                        </div>
                        {/* <div className="flex items-center justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Extras: </dt>
                          <dd className="text-purple-700 text-sm truncate">
                            {currency(extrasTotal)}
                          </dd>
                        </div> */}
                        {discountAmount > 0 && (
                          <div className="flex items-center justify-between py-3 text-xs font-medium">
                            <dt className="text-gray-500 flex items-center">
                              <span>Discount:</span>
                              <span className="text-xxs px-2 py-0.5 rounded-full bg-gray-400 opacity-80 mx-2 text-white shadow">
                                {enrollment?.discounts[0]?.discount?.code}
                              </span>
                            </dt>
                            <dd className="text-red-600  truncate">
                              -{currency(discountAmount)}
                              {enrollment.discounts[0].discount?.type ===
                                'percent' && (
                                <span className="text-gray-500 ml-1 text-xs">
                                  ({enrollment.discounts[0].discount?.value}%)
                                </span>
                              )}
                            </dd>
                          </div>
                        )}
                        {creditAmount > 0 && (
                          <div className="flex items-center justify-between py-3 text-xs font-medium">
                            <dt className="text-gray-500 flex items-center">
                              Credit:
                            </dt>
                            <dd className="text-red-600  truncate">
                              -{currency(creditAmount)}
                            </dd>
                          </div>
                        )}
                        <div className="flex items-center justify-between py-3 text-xs font-medium">
                          <dt className="text-gray-500">Sub Total:</dt>
                          <dd className="text-purple-700  truncate">
                            {currency(subTotal - discountAmount - creditAmount)}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between py-3 text-xs font-medium">
                          <dt className="text-gray-500">VAT:</dt>
                          <dd className="text-purple-700  truncate">
                            {currency(
                              vat * (subTotal - discountAmount - creditAmount),
                            )}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between py-3 text-sm font-semibold">
                          <dt className="text-gray-500">Total:</dt>
                          <dd className="text-purple-700 text-sm truncate">
                            {currency(realTotal)}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between py-3 text-xs font-medium">
                          <dt className="text-gray-500">Paid: </dt>
                          <dd className="text-red-600 truncate">
                            -{currency(paidAmount)}
                          </dd>
                        </div>
                        <div className="flex items-center justify-between py-3 text-sm font-semibold">
                          <dt className="text-gray-500">Remaining Balance: </dt>
                          <dd className="text-purple-700 text-sm truncate">
                            {currency(realTotal - paidAmount)}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    {!shouldHaveTasks && (
                      <div className="">
                        <h3 className="font-semibold text-base text-gray-900 mt-4">
                          CheckList
                        </h3>
                        <ul
                          role="list"
                          className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200"
                        >
                          {state.checklist &&
                            state.checklist.map((check, checkIndex) => {
                              return (
                                <li
                                  key={`${check.title}-${checkIndex}`}
                                  className="py-3"
                                >
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        check.id === 'documents_complete'
                                          ? changeFormStatus(e.target.checked)
                                          : check.id === 'uploads_complete'
                                            ? changUploadsStatus(
                                                e.target.checked,
                                              )
                                            : null;
                                        const newList = state.checklist.map(
                                          (item, i) => {
                                            if (i !== checkIndex) {
                                              return item;
                                            }
                                            const subChecks =
                                              item.subChecks &&
                                              item.subChecks.map((subCheck) => {
                                                return {
                                                  ...subCheck,
                                                  complete: e.target.checked,
                                                  status:
                                                    check.id ===
                                                      'documents_complete' ||
                                                    check.id ===
                                                      'uploads_complete'
                                                      ? e.target.checked
                                                        ? 'COMPLETE'
                                                        : 'REQUESTED'
                                                      : null,
                                                };
                                              });
                                            return {
                                              ...item,
                                              complete: e.target.checked,
                                              employeeId: member.user.id,
                                              employeeAvatar:
                                                member?.user?.avatar[0]?.asset
                                                  ?.url,
                                              subChecks,
                                            };
                                          },
                                        );

                                        setState((s) => ({
                                          ...s,
                                          checklist: newList,
                                        }));
                                      }}
                                      checked={
                                        check.complete
                                          ? check.complete
                                          : undefined
                                      }
                                      className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded"
                                    />
                                    <p
                                      onClick={() => {
                                        if (
                                          check.id === 'onboarding_call' &&
                                          !(
                                            enrollment &&
                                            enrollment.enrollmentCall &&
                                            enrollment.enrollmentCall.call_id
                                          )
                                        ) {
                                          ui.setCall(true, {
                                            to: enrollment.user.contact_number,
                                            enrollmentId,
                                          });
                                        } else if (
                                          check.id === 'onboarding_call' &&
                                          enrollment &&
                                          enrollment.enrollmentCall &&
                                          enrollment.enrollmentCall.call_id
                                        ) {
                                          setState((s) => ({
                                            ...s,
                                            call: enrollment.enrollmentCall
                                              .call_id,
                                          }));
                                        }
                                      }}
                                      className={classNames(
                                        check.id === 'onboarding_call' &&
                                          'hover:text-purple-600 transition-all cursor-pointer ',
                                        'ml-3 text-xs font-semibold tracking-wide text-gray-900',
                                      )}
                                    >
                                      {check.id === 'onboarding_call' &&
                                      !(
                                        enrollment &&
                                        enrollment.enrollmentCall &&
                                        enrollment.enrollmentCall.call_id
                                      ) ? (
                                        <span className="text-white font-semibold hover:bg-purple-900 flex items-center bg-purple-600 p-3 rounded-full">
                                          <PhoneArrowUpRightIcon className="w-4 h-4 mr-2" />
                                          Conduct onboarding call
                                        </span>
                                      ) : (
                                        <span>{check.title}</span>
                                      )}
                                    </p>
                                  </div>
                                  {check.subChecks && (
                                    <div className="flex-col flex pt-2 space-y-2">
                                      {check.subChecks.length > 0 ? (
                                        check.subChecks.map(
                                          (subCheck, index) => {
                                            const onlineCourse =
                                              subCheck?.courseId &&
                                              enrollment?.online_courses.find(
                                                (course) =>
                                                  course?.id ===
                                                  subCheck?.courseId,
                                              );
                                            return (
                                              <div
                                                key={`${subCheck.title}-${index}`}
                                                className={classNames(
                                                  subCheck?.employeeAvatar
                                                    ? 'ml-0'
                                                    : 'ml-7',
                                                  'flex items-center my-1',
                                                )}
                                              >
                                                {subCheck?.employeeAvatar && (
                                                  <Avatar
                                                    size={{
                                                      width: 6,
                                                      height: 6,
                                                    }}
                                                    layout="fill"
                                                    classes="mr-2"
                                                    src={
                                                      subCheck.employeeAvatar
                                                    }
                                                    alt={subCheck.employeeId}
                                                  />
                                                )}
                                                <input
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    check.id ===
                                                    'documents_complete'
                                                      ? markDocumentAsComplete(
                                                          subCheck.id,
                                                          !!e.target.checked,
                                                        )
                                                      : check.id ===
                                                          'uploads_complete'
                                                        ? markRequestAsComplete(
                                                            subCheck.id,
                                                            !!e.target.checked,
                                                          )
                                                        : null;
                                                    const newList =
                                                      state.checklist.map(
                                                        (item, i) => {
                                                          if (
                                                            i !== checkIndex
                                                          ) {
                                                            return item;
                                                          }
                                                          const subChecks =
                                                            item.subChecks.map(
                                                              (sub, j) => {
                                                                if (
                                                                  j !== index
                                                                ) {
                                                                  return sub;
                                                                }
                                                                return {
                                                                  ...sub,
                                                                  employeeId:
                                                                    member.user
                                                                      .id,
                                                                  employeeAvatar:
                                                                    member.user
                                                                      .avatar[0]
                                                                      .asset
                                                                      .url,

                                                                  complete:
                                                                    e.target
                                                                      .checked,
                                                                  status: e
                                                                    .target
                                                                    .checked
                                                                    ? 'COMPLETE'
                                                                    : 'REQUESTED',
                                                                };
                                                              },
                                                            );
                                                          return {
                                                            ...item,
                                                            subChecks,
                                                          };
                                                        },
                                                      );

                                                    setState((s) => ({
                                                      ...s,
                                                      checklist: newList,
                                                    }));
                                                  }}
                                                  checked={subCheck?.complete}
                                                  className={classNames(
                                                    check.complete
                                                      ? 'text-purple-500 '
                                                      : 'text-purple-600 ',
                                                    'focus:ring-purple-500 h-4 w-4 border-purple-500 rounded',
                                                  )}
                                                />
                                                <div className="w-full flex items-center justify-between">
                                                  <p className="ml-2 text-xs  text-gray-900">
                                                    {subCheck?.title
                                                      ? subCheck?.title
                                                      : 'Unknown'}
                                                  </p>
                                                  {subCheck?.price && (
                                                    <p className="text-xs">
                                                      {currency(subCheck.price)}
                                                    </p>
                                                  )}
                                                  {onlineCourse && (
                                                    <p className="text-xs">
                                                      {onlineCourse?.data
                                                        ?.progress_percent
                                                        ? onlineCourse?.data
                                                            ?.progress_percent
                                                        : 0}
                                                      %
                                                    </p>
                                                  )}
                                                  {subCheck?.status && (
                                                    <Status
                                                      value={subCheck.status}
                                                      small
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          },
                                        )
                                      ) : (
                                        <div className="w-full flex items-center justify-between">
                                          <p className="ml-2 text-xs text-gray-500">
                                            No Items To Display
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                    {shouldHaveTasks && (
                      <div className="">
                        <h3 className="font-semibold text-base text-gray-900 mt-4">
                          Tasks
                        </h3>
                        <ul
                          role="list"
                          className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200"
                        >
                          {enrollment &&
                          enrollment.tasks &&
                          enrollment.tasks.length > 0 ? (
                            enrollment.tasks.map((task) => {
                              let Icon = PhoneIcon;
                              if (
                                task.task.forms &&
                                task.task.forms.length > 0
                              ) {
                                Icon = DocumentIcon;
                              } else if (
                                task.task.uploads &&
                                task.task.uploads.length > 0
                              ) {
                                Icon = CameraIcon;
                              } else if (
                                task.task.registrations &&
                                task.task.registrations.length > 0
                              ) {
                                Icon = UserIcon;
                              }

                              return (
                                <li key={task.id} className="py-3 w-full">
                                  <div
                                    onClick={() => {
                                      if (task.task.status === 'COMPLETE') {
                                        updateTask(task.task.id, 'TODO');
                                      } else {
                                        updateTask(task.task.id, 'COMPLETE');
                                      }
                                    }}
                                    className="flex items-center gap-2.5 w-full cursor-pointer"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={task.task.status === 'COMPLETE'}
                                      className="focus:ring-purple-500 h-4 w-4 cursor-pointer text-purple-600 border-gray-300 rounded"
                                    />
                                    <div className="w-full">
                                      <p className="hover:text-purple-600 transition-all w-full cursor-pointer text-xs font-medium tracking-wide text-gray-800">
                                        {task.task.title}
                                      </p>
                                      {task.task.assignee && (
                                        <p className="text-xxs font-semibold tracking-wide text-gray-900">
                                          {task.task.assignee.name}{' '}
                                          {task.task.assignee.surname}
                                        </p>
                                      )}
                                    </div>
                                    <Icon className="h-5 w-5 text-purple-600" />
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <EmptyState
                              type="Tasks"
                              description="No tasks for this enrollment"
                            />
                          )}
                        </ul>
                      </div>
                    )}
                    {!(enrollment?.status === 'completed') && (
                      <div className="flex justify-center w-full">
                        <div className="mt-4 w-56">
                          <button
                            onClick={() => completeEnrollment()}
                            className="btn-primary"
                          >
                            Mark Enrollment As Paid
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* End right column area */}
            </div>
          </div>
        </div>
      )}
      {state.modal && (
        <Modal
          open={state.modal}
          close={() => setState({ ...state, modal: false })}
        >
          <EditPayment
            data={state.payment}
            controlOption={controlOption}
            onComplete={() => {
              setState({
                ...state,
                modal: false,
              });
              onComplete && onComplete();
            }}
          />
        </Modal>
      )}
      {state.uploadRequestModal && (
        <Modal
          open={state.uploadRequestModal}
          close={() => setState((s) => ({ ...s, uploadRequestModal: false }))}
        >
          <RequestUpload
            enrollmentId={enrollmentId}
            userId={enrollment?.user?.id}
            onComplete={() => {
              setState((s) => ({ ...s, uploadRequestModal: false }));
              refetch();
            }}
          />
        </Modal>
      )}
      {state.formRequestModal && (
        <Modal
          open={state.formRequestModal}
          close={() => setState((s) => ({ ...s, formRequestModal: false }))}
        >
          <RequestForm
            enrollmentId={enrollmentId}
            userId={enrollment?.user?.id}
            onComplete={() => {
              setState((s) => ({ ...s, formRequestModal: false }));
              refetch();
            }}
          />
        </Modal>
      )}
      {state.formModal && (
        <Modal
          open={state.formModal}
          close={() => setState({ ...state, formModal: false })}
        >
          <ViewDocument
            data={state.form}
            onComplete={() => {
              setState({
                ...state,
                formModal: false,
              });
              onComplete && onComplete();
            }}
          />
        </Modal>
      )}
      {state.newMessage && (
        <Modal
          overflow="overflow-y-auto"
          open={state.newMessage}
          close={() => setState((s) => ({ ...s, newMessage: false }))}
        >
          <NewMessage
            users={[enrollment?.user]}
            refetch={() => {}}
            onClose={() =>
              setState((s) => ({
                ...s,
                newMessage: false,
              }))
            }
          />
        </Modal>
      )}
      {state.packageCourseModal && packageData && (
        <Modal
          open={state.packageCourseModal}
          close={() => setState({ ...state, packageCourseModal: false })}
        >
          <PackageModal
            data={packageData}
            enrollment={enrollment}
            controlOption={controlOption}
            enrollmentId={enrollmentId}
            onComplete={() => {
              setState({
                ...state,
                packageCourseModal: false,
              });
              onComplete && onComplete();
            }}
          />
        </Modal>
      )}
      {state.newNote && (
        <Modal
          open={state.newNote}
          close={() => setState({ ...state, newNote: false })}
        >
          <NewNote
            controlOption={controlOption}
            enrollmentId={enrollmentId}
            onComplete={() => {
              setState({
                ...state,
                newNote: false,
              });
            }}
          />
        </Modal>
      )}
      {!!state.showAssetModal ? (
        <Modal
          title="Student Uploads"
          full
          open={!!state.showAssetModal}
          close={() => setState((s) => ({ ...s, showAssetModal: false }))}
        >
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <UploadRequestModal data={state.imageData && state.imageData} />
          </div>
        </Modal>
      ) : null}
      <Modal
        full
        open={!!state.call}
        close={() => setState((s) => ({ ...s, call: null }))}
      >
        <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
          {state.call && (
            <Calls
              callID={state.call}
              statusValues={statusValues}
              columns={columns}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export function EmptyState({ type, description = null }) {
  return (
    <div className="text-center my-4">
      <FolderPlusIcon
        strokeWidth={1}
        className="mx-auto h-12 w-12 text-gray-400"
      />
      <h3 className="text-sm font-medium text-gray-900">{`No ${type}`}</h3>
      {description ? (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      ) : null}
    </div>
  );
}

export const columns = {
  OPEN: {
    order: 1,
    color: 'blue',
    label: 'Calls',
  },
  NEW: {
    order: 2,
    color: 'blue',
    label: 'New',
  },
  CALL_1: {
    order: 4,
    color: 'yellow',
    label: 'First Call',
  },
  CALL_2: {
    order: 5,
    color: 'yellow',
    label: 'Second Call',
  },
  CALL_3: {
    order: 6,
    color: 'yellow',
    label: 'Third Call',
  },
  INFO_SENT: {
    order: 7,
    color: 'orange',
    label: 'Information Sent',
  },
  FINANCE_APPLICATION: {
    order: 8,
    color: 'purple',
    label: 'Finance',
  },
  BOOKED: {
    order: 9,
    color: 'green',
    label: 'Booked',
  },
  CLOSED: {
    order: 10,
    color: 'gray',
    label: 'Closed',
  },
  NEEDS_FOLLOWUP: {
    order: 3,
    color: 'orange',
    label: 'Follow Up',
  },
};
