import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';

import { updateCallById } from 'queries/calls';
import { updateLeadById } from 'queries/leads';

import { useUI } from 'providers/ui';

export default function SetReminder({ type, item, close }) {
  const ui = useUI();
  const [updateLead] = useMutation(updateLeadById);
  const [updateCall] = useMutation(updateCallById);

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      due_date:
        type === 'lead' && item.lead.due
          ? format(new Date(item.lead.due), 'yyyy-MM-dd')
          : type === 'call' && item.call.due
            ? format(new Date(item.call.due), 'yyyy-MM-dd')
            : format(new Date(), 'yyyy-MM-dd'),
      due_time:
        type === 'lead' && item.lead.due
          ? format(new Date(item.lead.due), 'hh:mm')
          : type === 'call' && item.call.due
            ? format(new Date(item.call.due), 'hh:mm')
            : format(new Date(), 'hh:mm'),
    },
  });

  const removeReminder = async () => {
    try {
      if (type === 'lead') {
        updateLead({
          variables: {
            id: item.lead.id,
            data: {
              due: null,
            },
          },
        });
      }
      if (type === 'call') {
        updateCall({
          variables: {
            id: item.call.id,
            data: {
              due: null,
            },
          },
        });
      }

      ui.setToast(true, {
        type: 'success',
        title: 'Reminder removed',
        message: '',
      });
      close();
    } catch (error) {
      Sentry.captureException(error);
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to remove reminder',
        message: `${error}`,
      });
    }
  };

  const setReminder = async (data) => {
    try {
      let dueDateTime = new Date(
        Date.parse(data.due_date + 'T' + data.due_time),
      );

      if (type === 'lead') {
        updateLead({
          variables: {
            id: item.lead.id,
            data: {
              due: dueDateTime,
            },
          },
        });
      }
      if (type === 'call') {
        updateCall({
          variables: {
            id: item.call.id,
            data: {
              due: dueDateTime,
            },
          },
        });
      }

      ui.setToast(true, {
        type: 'success',
        title: 'Reminder added',
        message: '',
      });
      close();
    } catch (error) {
      Sentry.captureException(error);
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to add reminder',
        message: `${error}`,
      });
    }
  };

  return (
    <div className="flex flex-col space-y-4 h-full overflow-auto">
      <form onSubmit={handleSubmit(setReminder)} className="space-y-4">
        <div className="bg-white space-y-4">
          <div className="justify-between">
            <h4 className="text-xl flex-1">Reminder</h4>
            <p className="text-sm text-gray-500">
              Create a reminder to follow up with this client.
            </p>
          </div>
          <div className="flex flex-col space-y-2">
            <span className="text-sm">Date</span>
            <input
              type="date"
              name="due_date"
              {...register('due_date', {
                required: true,
              })}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm  font-light"
            />
            <span className="text-sm">Time</span>
            <input
              type="time"
              name="due_time"
              {...register('due_time', {
                required: true,
              })}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm  font-light"
            />
            <div className="flex space-x-4 w-full pt-2">
              <button disabled={!formState.isDirty} className="btn-primary">
                Set reminder
              </button>
            </div>
          </div>
        </div>
      </form>
      {item.call && item.call.due && (
        <div className="flex space-x-4 w-full">
          <button onClick={() => removeReminder()} className="btn-red">
            Remove reminder
          </button>
        </div>
      )}
      {item.lead && item.lead.due && (
        <div className="flex space-x-4 w-full">
          <button onClick={() => removeReminder()} className="btn-red">
            Remove reminder
          </button>
        </div>
      )}
    </div>
  );
}
