import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import { Lead, UserProfile } from 'interfaces';
import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { getClients } from 'queries/clients';
import { getLeads } from 'queries/leads';

interface AppointmentStatus {
  name: string;
  status: string;
}

interface BaseContextValue {
  clients: UserProfile[];
  leads: Lead[];
  leadsAggregate: any;
  refetchClients: any;
  loadLeads: (options?: QueryLazyOptions<OperationVariables>) => void;
  loadClients: (options?: QueryLazyOptions<OperationVariables>) => void;
}

const initialState = {};

const BaseContext = createContext(initialState as BaseContextValue);

function BP({ children }) {
  const [loadClients, { data: clients, refetch: refetchClients }] =
    useLazyQuery<{
      clients: UserProfile[];
    }>(getClients, {
      fetchPolicy: 'cache-and-network',
    });

  const [
    loadLeads,
    {
      called: leadsCalled,
      error: leadsError,
      data: leadsData,
      loading: leadsLoading,
      refetch: refetchLeads,
    },
  ] = useLazyQuery<{
    leads: Lead[];
    agg?: any;
  }>(getLeads, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    loadClients();
    const currentCount = localStorage.getItem('cc.activetabs')
      ? Number(localStorage.getItem('cc.activetabs'))
      : 0;
    localStorage.setItem('cc.activetabs', String(currentCount + 1));

    window.addEventListener('beforeunload', () => {
      const currentCount = localStorage.getItem('cc.activetabs')
        ? Number(localStorage.getItem('cc.activetabs'))
        : 1;
      localStorage.setItem('cc.activetabs', String(currentCount - 1));
    });
  }, []);

  const value: BaseContextValue = useMemo(
    () => ({
      clients: clients ? clients.clients : [],
      leads: leadsData ? leadsData.leads : [],
      leadsAggregate: leadsData ? leadsData.agg : {},
      loadClients,
      loadLeads,
      refetchClients,
    }),
    [clients, leadsData, leadsData],
  );

  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
}

const BaseProvider = BP;

export const useBase = () => useContext(BaseContext);

export default BaseProvider;
