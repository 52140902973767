import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import { currency } from 'utils/formats';

import { getClient, getClientData } from 'queries/clients';

const Loader = dynamic(() => import('components/atoms/loader'));

export default function ComplaintClient(props) {
  const [loadClientData, { data: clientData }] = useLazyQuery(getClientData);
  const [loadClient, { data }] = useLazyQuery(getClient);

  useEffect(() => {
    loadClient({
      variables: {
        id: props.id,
      },
    });
    loadClientData({
      variables: {
        id: props.id,
      },
    });
  }, [props.id]);

  const client = data && data.client && data.client[0];

  if (!client) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-6 sm:px-6 mt-4 md:mt-0">
      <div className="flex lg:items-center space-x-5">
        <figure className="flex">
          <img
            className="h-16 w-16 rounded-full ring-4 ring-white shadow-lg"
            src={`https://www.gravatar.com/avatar/${client.avatar}?d=identicon&s=200`}
            alt=""
          />
        </figure>
        <div className="flex-1 min-w-0 flex items-center justify-end space-x-6 pb-1">
          <div className="min-w-0 flex-1">
            <h1 className="text-2xl font-bold text-gray-900 md:truncate">
              {client.name} {client.surname}
            </h1>
            <div className="mt-1 flex space-x-6">
              {clientData && clientData.payments && clientData.payments && (
                <p className="mt-1 text-sm text-gray-600">
                  Account value:{' '}
                  {currency(clientData.payments.aggregate.sum.amount || 0)}
                </p>
              )}
              <p className="mt-1 text-sm text-gray-600">
                Account points: {client.points}
              </p>
            </div>
            <div className="mt-1 flex lg:space-x-6 flex-col lg:flex-row">
              <p className="mt-1 text-xs text-gray-600">
                <p className="">Customer since:</p>
                <span>
                  {format(new Date(client.createdAt), 'dd MMMM yyyy')}
                </span>
              </p>
              {clientData &&
                clientData.lastAppointment &&
                clientData.lastAppointment.length > 0 && (
                  <p className="mt-1 text-xs text-gray-600">
                    <span className="block">Last visit:</span>
                    <span>
                      {format(
                        new Date(clientData.lastAppointment[0].start),
                        'dd MMMM yyyy',
                      )}
                    </span>
                  </p>
                )}
              {clientData &&
                clientData.nextAppointment &&
                clientData.nextAppointment.length > 0 && (
                  <p className="mt-1 text-xs text-gray-600">
                    <span className="block">Next visit:</span>
                    <span>
                      {format(
                        new Date(clientData.nextAppointment[0].start),
                        'dd MMMM yyyy',
                      )}
                    </span>
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
