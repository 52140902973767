type Props = {
  onClick?: () => void;
  type?: string;
};

export function ErrorState({ onClick, type = 'evidence' }: Props) {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-purple-600">Error</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl capitalize">
          {type} failed to load
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, there was an error loading this {type}. Please try again
        </p>
        {onClick ? (
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              type="button"
              onClick={() => {
                onClick();
              }}
              className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
              Try Again
            </button>
          </div>
        ) : null}
      </div>
    </main>
  );
}
