import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import Status from 'components/atoms/status';

import { currency } from 'utils/formats';

import { getAllEventSessionsById } from 'queries/events';

export const ModelsTab = ({
  modelID,
  action,
}: {
  modelID: string[];
  action: any;
}) => {
  const { data: modelData } = useQuery(getAllEventSessionsById, {
    variables: {
      id: modelID,
    },
  });

  if (!modelData?.eventSession) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden">
      {modelData?.eventSession.map((session, index) => {
        return (
          <div className="sm:overflow-hidden" key={index}>
            <div className="p-4 space-y-1 max-w-lg mx-auto">
              <div className="">
                <div className="text-sm font-semibold">
                  {session?.registrations &&
                    session?.registrations[0]?.event?.detail?.title}
                </div>
                <div className="py-1">
                  {session?.seat_price && (
                    <div className="text-xs">
                      {currency(session.seat_price)}
                    </div>
                  )}
                  {session?.seats && (
                    <div className="text-xs">{session.seats} Seats</div>
                  )}
                </div>

                {session?.employee?.name && (
                  <div className="text-xs">
                    Handled by {session?.employee?.name}{' '}
                    {session?.employee?.surname}
                  </div>
                )}

                {session?.registrations &&
                  session?.registrations.length > 0 && (
                    <div>
                      <div className="font-semibold text-sm">Registrations</div>
                      <ul className="list-disc list-inside space-y-1 marker:text-purple-500 pb-2 px-2 pt-1">
                        {session?.registrations.map((registee, index) => {
                          return (
                            <li
                              className="text-xs w-full flex items-center justify-between"
                              key={index}
                            >
                              <div className="flex items-center gap-2">
                                <span
                                  onClick={() => action(registee?.user?.id)}
                                  className="hover:underline cursor-pointer capitalize"
                                >
                                  {registee?.user?.name}{' '}
                                  {registee?.user?.surname}
                                </span>
                                <span>-</span>
                                <span className="font-semibold">
                                  {registee?.type}
                                </span>
                              </div>

                              <Status value={registee?.status} small />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </div>

              {session?.start && session?.end && (
                <div className="text-xs">
                  {format(new Date(session.start), 'do LLL y h:mm aaa')} -{' '}
                  {format(new Date(session.end), 'do LLL y h:mm aaa')}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ModelsTab;
