import {
  BookOpenIcon,
  ChatBubbleBottomCenterIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon,
  PencilSquareIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { format, isSameDay } from 'date-fns';

import Label from 'components/label';

const ComplaintCall = ({
  call,
  handleClick,
}: {
  call: any;
  handleClick: ({ type, lead }) => void;
}) => {
  let leadIcon = <StarIcon className="w-6 h-6" />;
  const leadTitle =
    // @ts-ignore
    call.__typename === 'lead' && call.metadata && call.metadata.title;

  if (leadTitle && leadTitle === 'Brochure Request') {
    leadIcon = <BookOpenIcon className="w-6 h-6" />;
  }

  if (leadTitle && leadTitle === 'Callback Request') {
    leadIcon = <DevicePhoneMobileIcon className="w-6 h-6" />;
  }

  if (leadTitle && leadTitle === 'Payment Plans Application') {
    leadIcon = <CreditCardIcon className="w-6 h-6" />;
  }

  if (leadTitle && leadTitle === 'Contact Submission') {
    leadIcon = <ClipboardDocumentListIcon className="w-6 h-6" />;
  }

  const title =
    call.user && call.user.name
      ? `${call.user.name} ${call.user.surname}`
      : call.content
        ? `${call.content.name} ${call.content.surname}`
        : call.payload.number;

  const callType =
    call.payload && call.payload.text
      ? call.payload.text
          .split('New')
          .filter(Boolean)
          .map((x) => x.trim())[0]
          .split('call')[0]
          .trim()
      : null;

  return (
    <div
      className="py-4 px-2 flex max-w-[200px] cursor-pointer mb-2 flex-col justify-center transition-colors duration-100 hover:shadow-sm rounded bg-blue-100 hover:bg-blue-200 text-blue-800"
      onClick={() => {
        handleClick({ type: call.type, lead: call });
      }}
    >
      <div className="flex space-x-1 items-center">
        <div className="w-6">
          {
            // @ts-ignore
            call.type === 'outgoing' && call.__typename === 'call' && (
              <PhoneArrowUpRightIcon className="w-6 h-6 text-green-500" />
            )
          }
          {
            // @ts-ignore
            call.type === 'incoming' && call.__typename === 'call' && (
              <PhoneArrowDownLeftIcon className="w-6 h-6 text-orange-500" />
            )
          }
          {call.__typename === 'lead' && leadIcon}
        </div>
        <div className="truncate px-1 w-full">
          <p className="text-xs capitalize font-semibold truncate">{title}</p>
          {callType && (
            <p className="text-xxs capitalize font-semibold truncate">
              {callType} Call
            </p>
          )}
          {
            // @ts-ignore
            call.employee && (
              <p className="text-xxs capitalize font-semibold truncate">
                {/* @ts-ignore */}
                Team member: {call.employee.name}
              </p>
            )
          }
          <div>
            {isSameDay(new Date(call.createdAt), new Date()) ? (
              // Only time for same day
              <p className="text-xxs">{`${format(
                new Date(call.createdAt),
                'HH:mm',
              )}`}</p>
            ) : (
              // Otherwise date plus time
              <p className="text-xxs">{`${format(
                new Date(call.createdAt),
                'dd/MM/yy',
              )} @ ${format(new Date(call.createdAt), 'HH:mm')}`}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end justify-between">
          <div className="flex space-x-4">
            {call.notes_aggregate && (
              <p className="flex text-xxs items-center">
                <PencilSquareIcon className="w-4 h-4 mr-1" />
                <span className="font-medium">
                  {call.notes_aggregate.aggregate.count}
                </span>
              </p>
            )}
            {call.contacts_aggregate && (
              <p className="flex text-xxs items-center">
                <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-1" />
                <span className="font-medium">
                  {call.contacts_aggregate.aggregate.count}
                </span>
              </p>
            )}
          </div>
          {call &&
            call.user &&
            call.user.labels &&
            call.user.labels.length > 0 && (
              <Label x={call.user.labels[0]} classNames={'!mb-0 !mt-2'} />
            )}
        </div>
        {/* {reminderStatus()} */}
      </div>
    </div>
  );
};

export default ComplaintCall;
