import { useMutation, useQuery } from '@apollo/client';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/nextjs';
import { subDays } from 'date-fns';
import format from 'date-fns/format';
import { Messages, TeamMember } from 'interfaces';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';

import EditDefinedMessage from 'components/molecules/edit-defined-message ';

import { addCallContact, getCallById } from 'queries/calls';
import { addLeadContact, getDefinedMessages, getLeadById } from 'queries/leads';

import { OrganizationContextValue } from 'providers/organization';
import { useUI } from 'providers/ui';

import Chat from '../../../assets/chat.svg';
import EmailOut from '../../../assets/email-out.svg';

// @ts-ignore
const TimeAgo = dynamic(() => import('react-timeago'), {
  ssr: false,
});
const Loader = dynamic(() => import('components/atoms/loader'));
const Modal = dynamic(() => import('components/molecules/modal'));
const AddDefinedMessage = dynamic(
  () => import('components/molecules/add-defined-message'),
);
type Props = {
  lead: string;
  messages: Messages[];
  org: OrganizationContextValue;
  member: TeamMember;
  type: string;
  client: TeamMember['user'] & { user: { email: string } };
};

enum ViewTypes {
  'email',
  'text',
  'messages',
  'notes',
}

export default function Message({
  org,
  member,
  lead,
  messages,
  type,
  client,
}: Props) {
  const [state, setState] = useState({
    addMessageModal: false,
    editMessageModal: false,
    newMessage: false,
  });

  const { data: definedMessages } = useQuery(getDefinedMessages, {
    variables: {
      where: {
        _or: [{ type: { _eq: 'Email' } }, { type: { _eq: 'Text' } }],
      },
    },
    fetchPolicy: 'network-only',
  });
  const allMessages = definedMessages && definedMessages?.defined_messages;
  const [selected, setSelected] = useState(null);

  const [saving, setSaving] = useState(false);
  const ui = useUI();
  const {
    register: registerText,
    handleSubmit: handleSubmitText,
    reset: resetText,
    setValue: setValueText,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
    setValue: setValueEmail,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [createLeadMessage] = useMutation(addLeadContact);
  const [createCallMessage] = useMutation(addCallContact);

  const [view, setView] = useState(ViewTypes.email);

  const links = [
    {
      text: 'Email',
      type: ViewTypes.email,
    },
    {
      text: 'Text',
      type: ViewTypes.text,
    },
  ];

  const sendSmsText = async (data) => {
    if (!data.smsContent) return;
    try {
      setSaving(true);

      if (type === 'lead') {
        const payload = {
          data: {
            message: data.smsContent,
            lead: lead,
            type: 'sms',
            source: 'application',
          },
        };
        await createLeadMessage({
          variables: payload,
          refetchQueries: [
            {
              query: getLeadById,
              variables: {
                id: lead,
              },
            },
          ],
        });

        resetEmail();
        resetText();
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New message added',
        });
      } else if (type === 'call') {
        const payload = {
          data: {
            message: data.smsContent,
            call: lead,
            type: 'sms',
            source: 'application',
          },
        };
        await createCallMessage({
          variables: payload,
          refetchQueries: [
            {
              query: getCallById,
              variables: {
                id: lead,
              },
            },
          ],
        });

        resetEmail();
        resetText();
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New message added',
        });
      }
    } catch (err) {
      setSaving(false);
      console.log(err);
      ui.setToast(true, {
        type: 'error',
        title: 'Error adding message',
      });
      Sentry.captureException(err);
    }
  };

  const sendMessage = async (data) => {
    if (!data.content) return;
    try {
      setSaving(true);

      if (type === 'lead') {
        const payload = {
          data: {
            message: data.content,
            subject: `${
              data.subject === ''
                ? 'A message from Cosmetic College'
                : data.subject
            }`,
            lead: lead,
            type: 'email',
            source: 'application',
          },
        };
        await createLeadMessage({
          variables: payload,
          refetchQueries: [
            {
              query: getLeadById,
              variables: {
                id: lead,
              },
            },
          ],
        });

        resetEmail();
        resetText();
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New message added',
        });
      } else if (type === 'call') {
        const payload = {
          data: {
            message: data.content,
            subject: `${
              data.subject === ''
                ? 'A message from Cosmetic College'
                : data.subject
            }`,
            call: lead,
            type: 'email',
            source: 'application',
          },
        };
        await createCallMessage({
          variables: payload,
          refetchQueries: [
            {
              query: getCallById,
              variables: {
                id: lead,
              },
            },
          ],
        });

        resetEmail();
        resetText();
        setSaving(false);
        ui.setToast(true, {
          type: 'success',
          title: 'New message added',
        });
      }
    } catch (err) {
      setSaving(false);
      console.log(err);
      ui.setToast(true, {
        type: 'error',
        title: 'Error adding message',
      });
      Sentry.captureException(err);
    }
  };

  return (
    <>
      <section id="messages" aria-labelledby="messages-title">
        <div className="bg-white border border-gray-300 sm:rounded sm:overflow-hidden">
          <div className="divide-y divide-gray-300">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="messages-title"
                className="text-lg font-bold text-gray-900"
              >
                Message History
              </h2>
            </div>
            <section aria-labelledby="messages-title">
              <div className="sm:overflow-hidden">
                <div className="divide-y divide-gray-300">
                  {messages && messages.length > 0 && (
                    <div className="px-4 py-6 sm:px-6">
                      <ul className="space-y-8">
                        {messages.map((message, i) => {
                          let member;

                          if (type === 'lead') {
                            member = org.team.find(
                              (x) => x.user.id === message.user.id,
                            );
                          } else if (type === 'call') {
                            member = org.team.find(
                              (x) => x.user.id === message.user.id,
                            );
                          }

                          const img =
                            member &&
                            member.user.avatar &&
                            member.user.avatar.length > 0
                              ? member.user.avatar[0].asset
                              : null;
                          const greater =
                            new Date(message.updatedAt) <=
                            subDays(new Date(), 2);
                          return (
                            <li className="" key={message.id}>
                              <div className="flex space-x-3">
                                {img && (
                                  <div className="flex-shrink-0 w-8 h-8 relative">
                                    <Image
                                      layout="fill"
                                      className="h-8 w-8 rounded-full"
                                      src={img.url}
                                      alt={`${member.user.name}`}
                                    />
                                  </div>
                                )}
                                {!img && (
                                  <div className="flex-shrink-0">
                                    <div className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                      {member &&
                                        member.user &&
                                        member.user.name.charAt(0)}
                                      {member &&
                                        member.user &&
                                        member.user.surname.charAt(0)}
                                    </div>
                                  </div>
                                )}
                                <div className="flex-1">
                                  <div className="text-sm flex items-center mb-2">
                                    <p className="font-semibold text-gray-900 flex-1">
                                      {member &&
                                        member.user &&
                                        member.user.name}
                                    </p>
                                    <span className="text-gray-900 text-xs font-medium">
                                      {!greater && (
                                        <TimeAgo
                                          live
                                          date={message.updatedAt}
                                          // className="font-bold color text-gray-500"
                                        />
                                      )}
                                      {greater &&
                                        format(
                                          new Date(message.updatedAt),
                                          'HH:mm do MMM yyyy',
                                        )}
                                    </span>
                                  </div>
                                  <div className="text-sm text-gray-600 flex mb-2 float-right">
                                    <p className="text-xs leading-relaxed font-semibold">
                                      {message.type === 'email' && (
                                        <EmailOut
                                          className="w-4 h-4 inline"
                                          // fill="#000"
                                        />
                                      )}
                                      {message.type === 'sms' && (
                                        <Chat
                                          className="w-4 h-4 inline"
                                          // fill="#000"
                                        />
                                      )}{' '}
                                      {message.source === 'application' &&
                                        message.type === 'sms' &&
                                        'Outbound SMS'}
                                      {message.source === 'application' &&
                                        message.type === 'email' &&
                                        'Outbound Email'}
                                      {message.source === 'client' &&
                                        message.type === 'sms' &&
                                        'Inbound SMS'}
                                      {message.source === 'client' &&
                                        message.type === 'email' &&
                                        'Inbound Email'}
                                    </p>
                                  </div>
                                  {message.type === 'email' && (
                                    <div className="text-sm text-gray-600 flex mb-2">
                                      <span className="text-sm leading-relaxed mr-2">
                                        Subject:
                                      </span>
                                      <p className="text-sm leading-relaxed">
                                        {message.subject}
                                      </p>
                                    </div>
                                  )}
                                  <div className="text-sm text-gray-600">
                                    <span className="text-sm leading-relaxed mr-2 mb-2 block">
                                      Message:
                                    </span>
                                    {message &&
                                    message.message &&
                                    message.message.length > 0 ? (
                                      <div
                                        className="leading-relaxed fnt"
                                        dangerouslySetInnerHTML={{
                                          __html: message.message
                                            .split('\n')
                                            .map(
                                              (x) =>
                                                `<p style="margin-bottom:10px;" class="mb-2 font-light leading-relaxed">${x}</p>`,
                                            )
                                            .join(''),
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {!messages ||
                    (messages.length === 0 && (
                      <div className="px-4 py-6 sm:px-6">
                        <p className="text-gray-600 text-xs">
                          No messages have been sent
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section>
        <div
          id="messaging-options"
          className="bg-white border border-gray-300 rounded overflow"
        >
          <section aria-labelledby="new-message-title mb-4">
            <div className="bg-white border-b border-gray-300">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="new-message-title"
                  className="text-lg font-bold text-gray-900"
                >
                  New message
                </h2>
              </div>
            </div>
          </section>
          <div className="">
            <div className="sm:hidden p-4">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                // @ts-ignore
                onChange={(e) => {
                  setView(ViewTypes[e.target.value]);
                }}
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded"
                // defaultValue={tabs.find((tab) => tab.current).name}
              >
                {links.map((link, key) => (
                  <option value={ViewTypes[link.type]} key={`tab-nav-${key}`}>
                    {link.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="relative z-0 flex divide-x divide-gray-300"
                aria-label="Tabs"
              >
                {links.map((link, key) => (
                  <button
                    key={`client-link-${key}`}
                    onClick={() => {
                      setSelected(null);
                      resetText();
                      resetEmail();
                      setState({
                        ...state,
                        addMessageModal: false,
                        editMessageModal: false,
                        newMessage: false,
                      });
                      setView(link.type);
                    }}
                    className={[
                      'w-4/12 group relative min-w-0 lg:flex-1 overflow-hidden bg-white py-4 px-2 lg:px-4 text-xs font-semibold text-center border-b border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none',
                      view === link.type
                        ? 'text-gray-900'
                        : 'text-gray-500 hover:text-gray-700',
                      link.type === ViewTypes.messages ||
                      link.type === ViewTypes.notes
                        ? 'col-span-3 lg:col-span-2'
                        : 'col-span-2',
                    ].join(' ')}
                    aria-current="page"
                  >
                    <span>{link.text}</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        view === link.type ? 'bg-purple-500' : 'bg-transparent '
                      } absolute inset-x-0 bottom-0 h-0.5`}
                    ></span>
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Send texts block */}
          {view === ViewTypes.text && (
            <div className="bg-white px-4 py-6 sm:px-6">
              <div className="flex justify-end items-center space-x-3 mb-4">
                <div className="w-56 ">
                  <button
                    onClick={() => {
                      resetText();
                      setState({ ...state, newMessage: true });
                    }}
                    className="btn-primary"
                  >
                    New Message
                  </button>
                </div>
                {allMessages && (
                  <>
                    <div className="w-56">
                      <Listbox
                        value={selected}
                        onChange={(x) => {
                          client && client?.name
                            ? setValueText(
                                'smsContent',
                                `Dear ${client.name} ${client.surname},\n\n${x.content}\n\nKind Regards,\n${member.user.name}, ${member.user.surname}\n\n${member.user.email}`,
                              )
                            : setValueText(
                                'smsContent',
                                `Dear Client,\n\n${x.content}\n\nKind Regards,\n${member.user.name}, ${member.user.surname}\n\n${member.user.email}`,
                              );
                          setSelected(x);
                          setState({
                            ...state,
                            addMessageModal: false,
                            editMessageModal: false,
                            newMessage: true,
                          });
                        }}
                      >
                        <div className="relative ">
                          <Listbox.Button className="relative w-56 cursor-default rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-2 focus-visible:border-purple-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-300 sm:text-sm">
                            <span className="block truncate">
                              {selected ? selected.title : 'Choose A Template'}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {allMessages
                                .filter((x) => x.type === 'Text')
                                .map((message, messageIdx) => (
                                  <Listbox.Option
                                    key={messageIdx}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? 'bg-purple-100 text-purple-900'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={message}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal'
                                          }`}
                                        >
                                          {message.title}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <div className="flex space-x-2 text-gray-400">
                      <button
                        onClick={() =>
                          setState({ ...state, addMessageModal: true })
                        }
                      >
                        <PlusCircleIcon
                          className="h-5 w-5 text-purple-600"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        onClick={() =>
                          setState({ ...state, editMessageModal: true })
                        }
                      >
                        <PencilSquareIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </>
                )}
              </div>
              {state.newMessage && (
                <div className="flex md:space-x-3">
                  {member.user.avatar && member.user.avatar.length > 0 && (
                    <div className="flex-shrink-0 w-8 h-8 relative">
                      <Image
                        layout="fill"
                        className="h-8 w-8 rounded-full"
                        src={member.user.avatar[0].asset.url}
                        alt={`${member.user.name} ${member.user.surname}`}
                      />
                    </div>
                  )}
                  <div className="min-w-0 flex-1">
                    <form onSubmit={handleSubmitText(sendSmsText)}>
                      <label
                        htmlFor="smsMessage"
                        className="text-sm mb-2 block text-gray-700"
                      >
                        Text message:
                      </label>
                      <textarea
                        id="smsMessage"
                        name="smsContent"
                        rows={4}
                        maxLength={320}
                        className="shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300 rounded"
                        placeholder="Write your text message here"
                        {...registerText('smsContent', { required: true })}
                      ></textarea>
                      <div className="mt-3 flex items-center justify-end">
                        <button
                          disabled={saving}
                          type="submit"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                          {saving && (
                            <>
                              <Loader
                                // @ts-ignore
                                styles={{}}
                                stroke="#fff"
                                width="24"
                                height="24"
                              />
                              <span className="ml-2">Saving</span>
                            </>
                          )}
                          {!saving && 'Send text'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Send email block */}
          {view === ViewTypes.email && (
            <div className="bg-white px-4 py-6 sm:px-6">
              {!client?.email && !client?.user?.email && (
                <div className="flex flex-row  pb-4 text-red-600 text-sm justify-end">
                  <ExclamationTriangleIcon className="w-5 h-5 mr-2" />
                  <span>Client Has No Email</span>
                </div>
              )}

              <div className="flex items-center justify-end space-x-3 mb-4">
                <div className="w-56 ">
                  <button
                    disabled={!client?.email && !client?.user?.email}
                    onClick={() => {
                      resetEmail();
                      setState({ ...state, newMessage: true });
                    }}
                    className="btn-primary"
                  >
                    New Message
                  </button>
                </div>
                {allMessages && (
                  <>
                    <div className="w-56">
                      <Listbox
                        value={selected}
                        disabled={!!(!client?.email && !client?.user?.email)}
                        onChange={(x) => {
                          client && client?.name
                            ? setValueEmail(
                                'content',
                                `Dear ${client.name} ${client.surname},\n\n${x.content}\n\nKind Regards,\n${member.user.name}, ${member.user.surname}\n\n${member.user.email}`,
                              )
                            : setValueEmail(
                                'content',
                                `Dear Client,\n\n${x.content}\n\nKind Regards,\n${member.user.name}, ${member.user.surname}\n${member.user.email} `,
                              );
                          setValueEmail('subject', x.title);
                          setSelected(x);
                          setState({
                            ...state,
                            addMessageModal: false,
                            editMessageModal: false,
                            newMessage: true,
                          });
                        }}
                      >
                        <div className="relative">
                          <Listbox.Button className="relative w-56 cursor-default rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-2 focus-visible:border-purple-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-300 sm:text-sm">
                            <span className="block truncate">
                              {selected ? selected.title : 'Choose A Template'}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {allMessages
                                .filter((x) => x.type === 'Email')
                                .map((message, messageIdx) => (
                                  <Listbox.Option
                                    key={messageIdx}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? 'bg-purple-100 text-purple-900'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={message}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal'
                                          }`}
                                        >
                                          {message.title}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <div className="flex space-x-2 text-gray-400">
                      <button
                        onClick={() =>
                          setState({ ...state, addMessageModal: true })
                        }
                      >
                        <PlusCircleIcon
                          className="h-5 w-5 text-purple-600"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        onClick={() =>
                          setState({ ...state, editMessageModal: true })
                        }
                      >
                        <PencilSquareIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </>
                )}
              </div>
              {state.newMessage && (
                <div className="flex md:space-x-3">
                  {member.user.avatar && member.user.avatar.length > 0 && (
                    <div className="flex-shrink-0 relative h-8 w-8">
                      <Image
                        layout="fill"
                        className="h-8 w-8 rounded-full"
                        src={member.user.avatar[0].asset.url}
                        alt={`${member.user.name} ${member.user.surname}`}
                      />
                    </div>
                  )}
                  <div className="min-w-0 flex-1">
                    <form onSubmit={handleSubmitEmail(sendMessage)}>
                      <div>
                        <label
                          htmlFor="emailSubject"
                          className="text-sm mb-2 block text-gray-700"
                        >
                          Email Subject (leave blank for default):
                        </label>
                        <textarea
                          id="emailSubject"
                          name="subject"
                          rows={1}
                          maxLength={50}
                          className="resize-none shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300 rounded mb-4"
                          placeholder="A message from Cosmetic College"
                          {...registerEmail('subject', { required: false })}
                        ></textarea>
                      </div>
                      <div>
                        <label
                          htmlFor="emailMessage"
                          className="text-sm mb-2 block text-gray-700"
                        >
                          Email Message:
                        </label>
                        <textarea
                          id="emailMessage"
                          name="content"
                          rows={10}
                          className="shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300 rounded"
                          placeholder="Write your message here. Press enter to create breaks between paragraphs."
                          {...registerEmail('content', { required: true })}
                        ></textarea>
                      </div>
                      <div className="mt-3 flex items-center justify-end">
                        <button
                          disabled={
                            saving || !!(!client.email && !client.user.email)
                          }
                          type="submit"
                          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                          {saving && (
                            <>
                              <Loader
                                // @ts-ignore
                                styles={{}}
                                stroke="#fff"
                                width="24"
                                height="24"
                              />
                              <span className="ml-2">Saving</span>
                            </>
                          )}
                          {!saving && 'Send email'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>

      <Modal
        open={state.addMessageModal}
        large={true}
        close={() => setState({ ...state, addMessageModal: false })}
      >
        <AddDefinedMessage
          setSelected={setSelected}
          resetText={resetText}
          resetEmail={resetEmail}
        />
      </Modal>
      {state.editMessageModal && selected && (
        <Modal
          open={state.editMessageModal}
          large={true}
          close={() => setState({ ...state, editMessageModal: false })}
        >
          <EditDefinedMessage
            state={state}
            item={selected}
            setState={setState}
            resetText={resetText}
            resetEmail={resetEmail}
            setSelected={setSelected}
          />
        </Modal>
      )}
    </>
  );
}
