import Image, { ImageProps } from 'next/image';

type Props = {
  size: {
    width: number;
    height: number;
  };
  classes?: string;
  alt?: string;
} & Omit<ImageProps, 'alt'>;

function Avatar(props: Props) {
  const { size, classes, ...rest } = props;
  if (!rest.src) {
    return null;
  }
  return (
    <div
      className={`w-${size.width} h-${size.height} rounded-full relative overflow-hidden ${classes}`}
    >
      <Image alt="" {...rest} />
    </div>
  );
}

export default Avatar;
