import {
  OperationVariables,
  SubscriptionResult,
  useMutation,
  useSubscription,
} from '@apollo/client';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'sonner';

import { GlobalsEnum } from 'components/molecules/globals';

import {
  getNotificationsByUserID,
  setNotificationSeenStatus,
} from 'queries/notifications';

import { useOrganization } from './organization';
import { useSession } from './session';
import { useUI } from './ui';

export interface NotificationContextValue {
  notificationSub?: SubscriptionResult<any, OperationVariables>;
}

const NotificationContext = createContext({} as NotificationContextValue);

const NP = ({ children }: { children: React.ReactNode }) => {
  const { claims, user } = useSession();
  const ui = useUI();

  const { teamMember } = useOrganization();
  const [setSeen] = useMutation(setNotificationSeenStatus);
  const [notis, setNotis] = useState([]);

  const router = useRouter();

  const member = user && teamMember(claims['x-hasura-user-id']);

  const notificationSub = useSubscription(getNotificationsByUserID, {
    variables: {
      user_id: member?.user?.id,
    },
  });

  async function setAsSeen(seen: boolean, id: string) {
    if (!seen) {
      await setSeen({
        variables: {
          id: id,
          seen: true,
        },
      });
    }
  }

  // On notification click...
  async function onClick(notification) {
    const { id, type, seen, reference_id } = notification;
    // Set notification as seen.
    await setAsSeen(seen, id);

    // No further action for notifications without these fields.
    if (!type || !reference_id) {
      return;
    }

    // What to do for each type of notification.
    switch (type) {
      case 'STUDENT':
      case 'MODEL':
        ui.setPanel(false);
        ui.setClient(true, reference_id);
        break;
      case 'EVIDENCE':
        router.push(`/programmes/evidence/${reference_id}`);
        break;

      case 'COURSE':
        ui.setPanel(false);
        router.push(`/events/${reference_id}`);
        break;

      case 'ENROLLMENT':
        ui.setPanel(false, null, null, () => {
          ui.setGlobal(true, GlobalsEnum.viewEnrollment, {
            reference_id: reference_id,
          });
        });
        break;

      case 'TASK':
        ui.setPanel(false, null, null, () => {
          ui.setGlobal(true, GlobalsEnum.viewTask, {
            reference_id: reference_id,
          });
        });
        break;

      case 'CALL':
        ui.setPanel(false, null, null, () => {
          ui.setGlobal(true, GlobalsEnum.viewCall, {
            reference_id: reference_id,
          });
        });
        break;

      case 'LEAD':
        ui.setPanel(false, null, null, () => {
          ui.setGlobal(true, GlobalsEnum.viewLead, {
            reference_id: reference_id,
          });
        });
        break;

      default:
        console.error(`Notification type not recognised: '${type}'`);
    }
  }

  const notifications = notificationSub.data?.notification || [];

  function showToast(notification) {
    toast(notification?.title, {
      closeButton: true,
      duration: Infinity,
      onDismiss: () => {
        setAsSeen(notification?.seen, notification?.id);
      },
      action: {
        label: 'View',
        onClick: (e) => {
          e.preventDefault();
          onClick(notification);
        },
      },
    });
  }

  useEffect(() => {
    if (!notificationSub?.loading && notifications) {
      if (notifications.length > 0) {
        const lastNotification = notifications[0];

        if (
          !notis.some((notification) => notification.id === lastNotification.id)
        ) {
          if (notis && notis.length > 0) {
            showToast(lastNotification);
          } else {
            const latestThree = notifications
              .slice(0, 3)
              .filter((notification) => !notification.seen);

            latestThree.forEach((notification) => {
              showToast(notification);
            });
          }

          setNotis(notifications);
        }
      }
    }
  }, [notifications, notificationSub?.loading, notis]);

  const value: NotificationContextValue = useMemo(
    () => ({
      notificationSub: notificationSub,
    }),
    [notificationSub],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

const NotificationProvider = NP;

export const useNotification = () => useContext(NotificationContext);

export default NotificationProvider;
