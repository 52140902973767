import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { sendAssets } from 'queries/leads';

import { useUI } from 'providers/ui';

type Props = {
  lead?: any;
  call?: any;
  close: () => void;
};

const assetTypes = [
  // {
  //   name: 'Permanent Makeup',
  //   value: 'PMU',
  // },
  {
    name: 'Model Pricing',
    value: 'MODELS',
  },
  {
    name: 'Injectables',
    value: 'INJECTABLES',
  },
  {
    name: 'VTCT Level 2 Facial & Skincare',
    value: 'LVL3FS',
  },
  {
    name: 'VTCT Level 3 Beauty Therapy',
    value: 'LVL3BT',
  },
  {
    name: 'VTCT Level 3 Educational & Training',
    value: 'LVL3EDUCATION',
  },
  {
    name: 'VTCT Level 3 Certificate In assessing Vocational Achievement',
    value: 'LVL3ASSESSOR',
  },
  {
    name: 'VTCT Level 3 Award in Anatomical and Physiological Knowledge of the Body System',
    value: 'LVL3ANATOMY',
  },
  {
    name: 'VTCT Laser Hair Removal',
    value: 'LASER',
  },
  {
    name: 'VTCT Level 4 Micropigmentation',
    value: 'LVL4MP',
  },
  {
    name: 'VTCT Level 4 Micropigmentation (Conversion)',
    value: 'LVL4MPCONVERSION',
  },
  {
    name: 'VTCT Level 4 Certificate in Internal Quality Assurance of Assessment Processes and Practice',
    value: 'LVL4IQA',
  },
  {
    name: 'VTCT Level 4 Certificate in Enhancing Eyebrows with Microblading Techniques',
    value: 'LVL4MICROBLADING',
  },
  {
    name: 'VTCT Level 4 Chemical Skin Peels',
    value: 'LVL4PEELS',
  },
  {
    name: 'VTCT Level 4 Dermaplaning',
    value: 'LVL4DERMAPLANING',
  },
  {
    name: 'VTCT Level 4 HIFU',
    value: 'LVL4HIFU',
  },
  {
    name: 'VTCT Level 4 Radio Frequency',
    value: 'LVL4RF',
  },
  {
    name: 'VTCT Level 4 Skin Needling',
    value: 'LVL4MICRONEEDLING',
  },
  {
    name: 'VTCT Level 4 Ultrasound',
    value: 'LVL4ULTRA',
  },
  {
    name: 'Level 4 Certificate In Aesthetic Practice',
    value: 'LVL4AESTHETICS',
  },
  {
    name: 'Level 5 Certificate In Aesthetic Practice',
    value: 'LVL5AESTHETICS',
  },
  {
    name: 'Level 7 Certificate In Aesthetic Practice',
    value: 'LVL7AESTHETICS',
  },
];

export default function SendAsset({ lead, call, close }: Props) {
  const ui = useUI();
  const [send] = useMutation(sendAssets);

  const form = useForm({
    defaultValues: {
      lead: lead && lead.id,
      call: call && call.id,
      email: lead && lead.content.email,
      message: '',
    },
  });

  const handleSubmit = async (data) => {
    const assets = Object.keys(data.assets).filter((x) => data.assets[x]);

    if (!assets || assets.length === 0) {
      return null;
    }

    const payload = {
      data: {
        call: data.call,
        lead: data.lead,
        email: data.email,
        message: data.message,
        types: assets,
      },
    };

    const t = await send({
      variables: payload,
    });

    ui.setToast(true, {
      type: 'success',
      title: `Sent assets`,
      message: '',
    });

    close();
  };

  return (
    <div>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="-mt-4">
          <h4 className="font-semibold mb-3">Send Assets</h4>
        </div>
        <input type="hidden" name="lead" {...form.register('lead')} />
        <input type="hidden" name="call" {...form.register('call')} />
        <div>
          <label className="font-medium text-gray-700 select-none text-sm">
            <span className="block mb-1">Email</span>
            <input
              type="email"
              name="email"
              className="appearance-none block w-full px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
              {...form.register('email', { required: true })}
            />
          </label>
        </div>
        <div>
          <label className="font-medium text-gray-700 select-none text-sm">
            <span className="block mb-1">Message</span>
            <textarea
              name="message"
              className="appearance-none block w-full px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
              {...form.register('message', { required: true })}
            />
          </label>
        </div>
        <div>
          <h4 className="font-semibold text-sm my-3">Select assets to send</h4>
        </div>
        <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200 max-h-[200px] overflow-scroll">
          {assetTypes.map((x) => {
            return (
              <div key={x.name} className="relative flex items-start py-2">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor={`${x.value}`}
                    className="font-medium text-gray-700 select-none"
                  >
                    {x.name}
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id={`${x.value}`}
                    name={`${x.value}`}
                    type="checkbox"
                    // @ts-ignore
                    {...form.register(`assets.${x.value}`)}
                    className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-end pt-6">
          <div className="w-40">
            <button className="btn-primary">Send</button>
          </div>
        </div>
      </form>
    </div>
  );
}
