import { gql } from '@apollo/client';

export const getReportData = gql`
  query getReportData($start: timestamptz!, $end: timestamptz!) {
    payment(
      where: {
        _and: [
          { completed_at: { _gte: $start, _lte: $end } }
          { status: { _eq: "complete" } }
        ]
      }
    ) {
      amount
      created_at
      currency
      discount
      discount_explanation
      discount_id
      invoice_id
      completed_at
      id
      employee
      method
      metadata
      status
      tracked
      enrollment {
        employee_id
      }
      transaction_id
      updated_at
    }
    pending: payment(
      where: {
        _and: [
          { created_at: { _gte: $start, _lte: $end } }
          { status: { _neq: "complete" } }
        ]
      }
    ) {
      amount
      created_at
      currency
      discount
      discount_explanation
      discount_id
      invoice_id
      id
      employee
      method
      metadata
      status
      tracked
      enrollment {
        employee_id
      }
      transaction_id
      updated_at
    }
  }
`;

export const getOrganization = gql`
  query GetOrganization($organization: uuid!) {
    organization: organization_by_pk(id: $organization) {
      id
      name
      vatNumber
      companyNumber
      createdAt: created_at
      updatedAt: updated_at
      # addresses {
      #   id
      #   addressByAddress {
      #     address_id: id
      #     data
      #   }
      # }
      # billing {
      #   id
      #   customer_id
      #   plan_id
      #   subscription_id
      #   trial
      #   trial_ends
      # }
    }
  }
`;

export const getTeam = gql`
  query Team {
    team: organization_member(
      where: { active: { _eq: true } }
      order_by: { user: { given_name: asc } }
    ) {
      id
      active
      jobTitle: job_title
      # jobType: job_type
      startDate: start_date
      endDate: end_date
      teaches
      # color
      user {
        id
        name: given_name
        surname: family_name
        # fullName: full_name
        birthday
        number: contact_number
        email
        avatar: user_assets(limit: 1, order_by: { created_at: desc }) {
          asset: assetByAsset {
            id
            url
          }
        }
        # services {
        #   id: service
        #   data: serviceByService {
        #     title
        #   }
        # }
        tasksCount: tasks_aggregate(where: { archived: { _eq: false } }) {
          aggregate {
            count
          }
        }
        call_session {
          id
          token
          status
        }
        targets {
          value
          date
        }
      }
    }
  }
`;

export const getAvailibility = gql`
  subscription Availibility($start: date!, $end: date!) {
    availibility: schedule(
      where: {
        _and: [
          { available: { _eq: true } }
          { date: { _gte: $start } }
          { date: { _lte: $end } }
        ]
      }
    ) {
      id
      date
      start
      end
      available
      week: week_number
      day: day_of_week
      member: organization_member {
        id: member
      }
    }
  }
`;

export const getAvailibilityQuery = gql`
  query Availibility($start: date!, $end: date!) {
    availibility: schedule(
      where: {
        _and: [
          { available: { _eq: true } }
          { date: { _gte: $start } }
          { date: { _lte: $end } }
        ]
      }
    ) {
      id
      date
      start
      end
      available
      week: week_number
      day: day_of_week
      member: organization_member {
        id: member
      }
    }
  }
`;

export const loadSchedule = gql`
  query LoadSchedule($start: date!, $end: date) {
    schedule(
      where: { _and: [{ date: { _gte: $start } }, { date: { _lte: $end } }] }
      order_by: { date: desc_nulls_last }
    ) {
      id
      available
      date
      is_leave
      start
      end
      type
      comment
      day: day_of_week
      user
      week: week_number
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getFinancials = gql`
  query OrgFinancials($start: timestamptz, $end: timestamptz) {
    booked: payment_aggregate(
      where: {
        _and: [
          { completed_at: { _gte: $start } }
          { completed_at: { _lte: $end } }
          { status: { _eq: "complete" } }
        ]
      }
    ) {
      aggregate {
        count(columns: amount)
        sum {
          amount
          discount
        }
        avg {
          amount
          discount
        }
        max {
          amount
          discount
        }
      }
    }
    inhouse: payment_aggregate(
      where: {
        _and: [
          { employee: { _is_null: false } }
          { completed_at: { _gte: $start } }
          { completed_at: { _lte: $end } }
          { status: { _eq: "complete" } }
        ]
      }
    ) {
      aggregate {
        count(columns: amount)
        sum {
          amount
          discount
        }
        avg {
          amount
          discount
        }
        max {
          amount
          discount
        }
      }
    }
    online: payment_aggregate(
      where: {
        _and: [
          { employee: { _is_null: true } }
          { completed_at: { _gte: $start } }
          { completed_at: { _lte: $end } }
          { status: { _eq: "complete" } }
        ]
      }
    ) {
      aggregate {
        count(columns: amount)
        sum {
          amount
          discount
        }
        avg {
          amount
          discount
        }
        max {
          amount
          discount
        }
      }
    }
    bookings: registration_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    services: event_aggregate(
      where: {
        _and: [
          { start: { _gte: $start } }
          { start: { _lte: $end } }
          # {
          #   _or: [
          #     { status: { _eq: course_session } }
          #     { status: { _eq: new } }
          #     { status: { _eq: completed } }
          #   ]
          # }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    totalUsers: user_aggregate {
      aggregate {
        count
      }
    }
    users: user_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    leads: lead_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    leadConversions: lead_conversion_aggregate(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
    ) {
      aggregate {
        count
      }
    }
    enrollments: enrollment_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $start } }
          { created_at: { _lte: $end } }
          {
            _or: [
              { status: { _eq: "completed" } }
              { status: { _eq: "deposit" } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getConversionStats = gql`
  query ConversionStats($start: date!, $end: date!) {
    conversion_stats(args: { period_end: $end, period_start: $start }) {
      applications
      application_conversions
      brochures
      brochure_conversions
      callbacks
      callback_conversions
      contacts
      contact_conversions
      total_callbacks
      total_conversions
      converted_total
    }
  }
`;

export const getLeadActivity = gql`
  query leadActivity($start: timestamptz, $end: timestamptz) {
    lead(
      where: {
        _and: [{ created_at: { _gte: $start } }, { created_at: { _lte: $end } }]
      }
      order_by: { created_at: desc }
      limit: 20
    ) {
      id
      content
      metadata
      createdAt: created_at
    }
  }
`;

export const getActivity = gql`
  query OrgActivity($start: timestamptz, $end: timestamptz) {
    registration(
      where: {
        _and: [
          { created_at: { _gte: $start } }
          { created_at: { _lte: $end } }
          # { status: { _neq: pending } }
          # { status: { _neq: deleted } }
        ]
      }
      order_by: { created_at: desc }
      limit: 40
    ) {
      id
      status
      createdAt: created_at
      type
      enrollment {
        id
        status
        payments {
          id
          payment {
            id
            amount
            status
          }
        }
      }
      session: event_session {
        id
        price: seat_price
      }
      client: user {
        id
        name: given_name
        surname: family_name
        email
        phoneNumber: contact_number
      }
      event {
        id
        price: seat_price
        detail {
          id
          title
        }
      }
      package {
        id
        title
        price: standard_price
      }
    }
  }
`;

export const memberStats = gql`
  query MemberStats($user: uuid!, $start: timestamptz, $end: timestamptz) {
    serviceBookings: payment_aggregate(
      where: {
        _and: [
          {
            appointment_payments: {
              appointmentByAppointment: {
                entities: {
                  _and: [
                    { employee: { _eq: $user } }
                    { created_at: { _gte: $start } }
                    { created_at: { _lte: $end } }
                    # { status: { _eq: completed } }
                  ]
                }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        bookings: count
        sales: sum {
          amount
        }
        discounts: sum {
          discount
        }
      }
    }
  }
`;

export const getTargetsForUserID = gql`
  query getTargetsForUserID($user_id: uuid!) {
    organization_target(
      where: { user_id: { _eq: $user_id } }
      order_by: { date: asc }
    ) {
      id
      date
      value
      type
    }
  }
`;

export const updateTargetByID = gql`
  mutation updateTargetByID($id: uuid!, $data: organization_target_set_input!) {
    update_organization_target_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const addTarget = gql`
  mutation addTask($data: organization_target_insert_input!) {
    insert_organization_target_one(object: $data) {
      id
    }
  }
`;
