import { useMutation, useQuery } from '@apollo/client';
import { Listbox, Transition } from '@headlessui/react';
import {
  BellIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ClockIcon,
  PencilSquareIcon,
  PhoneArrowDownLeftIcon,
  PlusCircleIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { isBefore, isSameDay } from 'date-fns';
import format from 'date-fns/format';
import { Lead, TeamMember } from 'interfaces';
import dynamic from 'next/dynamic';
import React, { Fragment, useMemo, useState } from 'react';
import type {
  DraggableLocation,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DropResult,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';

import TaskDetail from 'components/task/task-detail';

import classNames from 'utils/classnames';

import {
  updateTaskById as Ut,
  getIncomingStatus,
  getTasksNew,
} from 'queries/tasks';

import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useSubscription } from 'providers/subscription';

import TaskModal from './task-modal';
import Tasks from './tasks';

const Layout = dynamic(() => import('components/layout'));
const Loader = dynamic(() => import('components/atoms/loader'));

const grid: number = 8;

export const columns = {
  TODO: {
    order: 1,
    color: 'blue',
    label: 'Todo',
  },
  PRIORITY: {
    order: 2,
    color: 'red',
    label: 'Priority',
  },
  IN_PROGRESS: {
    order: 3,
    color: 'orange',
    label: 'In Progress',
  },
  IN_REVIEW: {
    order: 4,
    color: 'yellow',
    label: 'Reviewing',
  },
  COMPLETE: {
    order: 5,
    color: 'green',
    label: 'Done',
  },
  ARCHIVED: {
    order: 6,
    color: 'gray',
    label: 'Archived',
  },
};

type RowProps = {
  data: any;
  due: string;
  index: number;
  column: string;
  isDragging: boolean;
  user?: { [key: string]: any };
  content?: { [key: string]: any };
  provided?: { [key: string]: any };
  style: { [key: string]: any };
  status?: { [key: string]: any };
  payload?: { [key: string]: any };
  updatedAt?: string;
  createdAt?: string;
  notes_aggregate: any;
  contacts_aggregate: any;
  __typename: string;
  title?: string;
  assignee?: { [key: string]: any };
};

function getStyle(provided: DraggableProvided, style?: Object) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

const RowItem = function RowItem(props: RowProps) {
  const color = props.column ? columns[props.column].color : 'gray';

  const cls = props.isDragging
    ? `py-4 px-2 flex flex-col justify-center transition-colors duration-100 shadow-sm bg-${color}-200 rounded text-${color}-600`
    : props.due &&
        isBefore(new Date(props.due), new Date()) &&
        props.status.value !== 'COMPLETE'
      ? `py-4 px-2 flex flex-col justify-center transition-colors duration-100 hover:shadow-sm rounded border-2 border-red-500 bg-red-100 hover:bg-red-200 text-red-500`
      : `py-4 px-2 flex flex-col justify-center transition-colors duration-100 hover:shadow-sm rounded bg-${color}-100 hover:bg-${color}-200 text-${color}-800`;
  const title = props.title;

  const { draggableProps, dragHandleProps, innerRef } = props.provided;

  function reminderStatus() {
    let due = props.due ? new Date(props.due) : null;
    if (!due) {
      return null;
    }
    return (
      <p className="flex text-xxs items-center">
        <BellIcon fill="red" className="w-3 h-3 mr-1 text-red-500" />
      </p>
    );
    // }
  }

  const callType =
    props.payload && props.payload.text
      ? props.payload.text
          .split('New')
          .filter(Boolean)
          .map((x) => x.trim())[0]
          .split('call')[0]
          .trim()
      : null;

  let taskIcon = <StarIcon className="w-8 h-6" />;
  const leadTitle =
    // @ts-ignore
    props?.title;

  if (leadTitle && leadTitle === 'Follow Up Out Of Hours Calls') {
    taskIcon = <PhoneArrowDownLeftIcon className="w-6 h-6 text-orange-500" />;
  }

  return (
    <div
      className={cls}
      {...draggableProps}
      {...dragHandleProps}
      ref={innerRef}
      style={getStyle(props.provided, props.style)}
      onClick={() => {
        // @ts-expect-error
        props.action ? props.action(props, props.__typename) : null;
      }}
    >
      <div className="flex space-x-1 items-center justify-between">
        <div className="flex items-center gap-1">
          {props?.assignee ? (
            <div className="w-8 h-8 flex">
              {props?.assignee?.user_assets[0]?.assetByAsset?.url ? (
                <img
                  className="h-8 w-8 rounded-full"
                  src={props?.assignee?.user_assets[0]?.assetByAsset?.url}
                  alt={`${props?.assignee?.given_name} ${props?.assignee?.family_name}`}
                />
              ) : (
                <UserCircleIcon className="w-8 h-8" />
              )}
            </div>
          ) : (
            taskIcon
          )}

          <div className="truncate w-[80%] ml-1">
            <p className="text-xs line-clamp-2 capitalize font-semibold">
              {title}
            </p>
            {callType && (
              <p className="text-xxs capitalize font-semibold truncate">
                {callType} Call
              </p>
            )}
            {
              // @ts-ignore
              props.employee && (
                <p className="text-xxs capitalize font-semibold truncate">
                  {/* @ts-ignore */}
                  Team member: {props.employee.name}
                </p>
              )
            }

            {/* Created date */}
            {!props.due && (
              <div className="text-xxs">
                {isSameDay(new Date(props.createdAt), new Date()) ? (
                  // Only time for same day
                  <p>Created: {format(new Date(props.createdAt), 'HH:mm')}</p>
                ) : (
                  // Otherwise date plus time
                  <p>
                    Created:{' '}
                    {`${format(
                      new Date(props.createdAt),
                      'dd/MM/yy',
                    )} @ ${format(new Date(props.createdAt), 'HH:mm')}`}
                  </p>
                )}
              </div>
            )}

            {/* Due date */}
            {!!props.due && (
              <div className="text-xxs">
                {isSameDay(new Date(props.due), new Date()) ? (
                  // Only time for same day
                  <p>Due: {format(new Date(props.due), 'HH:mm')}</p>
                ) : (
                  // Otherwise date plus time
                  <p>
                    Due:{' '}
                    {`${format(
                      new Date(props.due),
                      'dd/MM/yy',
                    )} @ ${format(new Date(props.due), 'HH:mm')}`}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex space-x-4">
          {props.notes_aggregate && (
            <p className="flex text-xxs items-center">
              <PencilSquareIcon className="w-4 h-4 mr-1" />
              <span className="font-medium">
                {props.notes_aggregate.aggregate.count}
              </span>
            </p>
          )}
          {props.contacts_aggregate && (
            <p className="flex text-xxs items-center">
              <PencilSquareIcon className="w-4 h-4 mr-1" />
              <span className="font-medium">
                {props.contacts_aggregate.aggregate.count}
              </span>
            </p>
          )}
          {reminderStatus()}
        </div>
      </div>
    </div>
  );
};

const Row = React.memo(function Row({ data, index, style }: RowProps) {
  const patchedStyle = {
    ...style,
    left: style.left + grid,
    top: style.top + grid,
    width: `calc(${style.width} - ${grid * 2}px)`,
    height: style.height - grid,
  };

  const item = data[index];

  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <RowItem
          {...item}
          style={patchedStyle}
          ref={provided.innerRef}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  );
}, areEqual);

type ColumnProps = {
  id: string;
  heading: string;
  items: Lead[];
  dropable?: boolean;
  action: (id: string, type: string) => void;
};

const Column = React.memo(function Column(props: ColumnProps) {
  return (
    <div className="overflow-hidden rounded flex-shrink-0 flex flex-col mt-6 mb-2 mx-3 bg-white shadow w-64">
      <div className="p-2 border-b shadow">
        <p className="font-semibold flex justify-between items-center">
          {props.heading}{' '}
          <span className="text-gray-600 text-xs">({props.items.length})</span>
        </p>
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <Droppable
          droppableId={props.id}
          mode="virtual"
          renderClone={(
            provided: DraggableProvided,
            snapshot: DraggableStateSnapshot,
            rubric: DraggableRubric,
          ) => (
            // @ts-ignore
            <RowItem
              column={props?.id}
              provided={provided}
              isDragging={snapshot.isDragging}
              {...props.items[rubric.source.index]}
              style={{ margin: 0 }}
            />
          )}
        >
          {(
            droppableProvided: DroppableProvided,
            snapshot: DroppableStateSnapshot,
          ) => {
            const itemCount: number = snapshot.isUsingPlaceholder
              ? props.items.length + 1
              : props.items.length;

            const itemData = props.items
              ? props.items.map((x) => ({
                  ...x,
                  action: props.action,
                  column: props.id,
                }))
              : [];

            return (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={itemCount}
                    itemSize={70}
                    width={width}
                    innerRef={droppableProvided.innerRef}
                    outerRef={droppableProvided.innerRef}
                    itemData={itemData}
                    style={{
                      overflow: 'auto',
                    }}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            );
          }}
        </Droppable>
      </div>
    </div>
  );
});

const statuses = [
  { value: 'PRIORITY', label: 'Priority' },
  { value: 'TODO', label: 'Todo' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'IN_REVIEW', label: 'Reviewing' },
  { value: 'COMPLETE', label: 'Done' },
  { value: 'ARCHIVED', label: 'Archived' },
];

export default function Incoming() {
  const org = useOrganization();
  const session = useSession();
  useSubscription();
  // const [selectedStatus, setSelectedStatus] = useState('Active');
  const [updateTaskById] = useMutation(Ut);
  const mgr = [
    '4c730358-f6e9-4303-b634-673d7f5a0c1f',
    '1ffd3ee2-e0aa-44b5-9260-9b787756c466',
    '482f9005-1ee2-454b-af47-b80e2775b4d5',
  ];

  const [state, setState] = useState<{
    task: string | null;
    timeframe: { start: string; end: string; name: string } | null;
    displayTaskDetail: boolean;
    selectedUsers: string[] | null;
    selectedEmployee: TeamMember | null;
    mode: 'user' | 'team';
    statuses: string[];
    orgMembers:
      | {
          id: string;
          given_name: string;
          family_name: string;
          avatar: string;
        }[]
      | null;
  }>({
    task: null,
    selectedUsers: null,
    mode: mgr.includes(session.claims['x-hasura-user-id']) ? 'team' : 'user',
    statuses: ['PRIORITY', 'TODO', 'IN_PROGRESS', 'IN_REVIEW', 'COMPLETE'],
    timeframe: {
      start: format(new Date(), 'y-L-d'),
      end: format(new Date(), 'y-L-d'),
      name: 'Today',
    },
    selectedEmployee: null,
    displayTaskDetail: false,
    orgMembers:
      org?.team &&
      org.team
        .filter((x) => x.active)
        .map((member) => ({
          id: member.user?.id,
          given_name: member.user?.name,
          family_name: member.user?.surname,
          avatar: member.user?.avatar[0]?.asset?.url,
        })),
  });

  const { data: statusValues } = useQuery<{ status: { value: string }[] }>(
    getIncomingStatus,
  );

  const teamMembers = useMemo(() => {
    return org && org?.team && org.team.length > 0
      ? org.team
          .filter((x) => x.active)
          .map((member) => ({
            id: member.user?.id,
            given_name: member.user?.name,
            family_name: member.user?.surname,
            avatar: member.user?.avatar[0]?.asset?.url,
          }))
      : [];
  }, [org]);

  const variables = {
    where: {},
  };

  if (state.selectedEmployee) {
    const mg =
      session.claims['x-hasura-user-id'] ===
      '482f9005-1ee2-454b-af47-b80e2775b4d5';
    if (mg) {
      variables.where = {
        _and: [
          { status: { _in: state.statuses } },
          { silent: { _eq: false } },
          {
            user_id: {
              _eq: state.selectedEmployee.user.id,
            },
          },
          {
            archived: {
              _eq: state.statuses.includes('ARCHIVED'),
            },
          },
        ],
      };
    } else {
      variables.where = {
        _and: [
          { status: { _in: state.statuses } },
          { silent: { _eq: false } },
          {
            user_id: {
              _eq: state.selectedEmployee.user.id,
            },
          },
          {
            archived: {
              _eq: state.statuses.includes('ARCHIVED'),
            },
          },
          {
            management: {
              _eq: false,
            },
          },
        ],
      };
    }
  } else if (state.mode === 'user') {
    variables.where = {
      _and: [
        { status: { _in: state.statuses } },
        { silent: { _eq: false } },
        { user_id: { _eq: session.claims['x-hasura-user-id'] } },
        { archived: { _eq: state.statuses.includes('ARCHIVED') } },
        {
          _or: [
            {
              management: {
                _eq: false,
              },
            },
            {
              management: {
                _eq:
                  session.claims['x-hasura-user-id'] ===
                  '482f9005-1ee2-454b-af47-b80e2775b4d5',
              },
            },
          ],
        },
      ],
    };
  } else {
    variables.where = {
      _and: [
        { status: { _in: state.statuses } },
        { silent: { _eq: false } },
        { archived: { _eq: state.statuses.includes('ARCHIVED') } },
        {
          _or: [
            {
              management: {
                _eq: false,
              },
            },
            {
              management: {
                _eq:
                  session.claims['x-hasura-user-id'] ===
                  '482f9005-1ee2-454b-af47-b80e2775b4d5',
              },
            },
          ],
        },
      ],
    };
  }

  console.log('state', state);
  console.log('variables', variables);

  const {
    data: taskData,
    refetch: refetchTasks,
    loading,
  } = useQuery(getTasksNew, {
    variables,
  });

  // useEffect(() => {
  //   // refetchTasks({
  //   //   type: state.selectedType,
  //   //   start: state.timeframe.start,
  //   //   end: state.timeframe.end,
  //   // });
  // }, [state.timeframe]);

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const { draggableId: id } = result;

    const item =
      taskData.tasks.findIndex((x) => x.id === id) > -1 ? updateTaskById : null;

    if (destination.droppableId === 'PRIORITY') {
      item({
        variables: {
          id,
          data: { status: 'TODO' },
        },
      }).then(() => refetchTasks());
    } else {
      item({
        variables: {
          id,
          data: { status: destination.droppableId },
        },
      }).then(() => refetchTasks());
    }
  }

  const uniqueAssigneeIds = useMemo(() => {
    const uniqueIds: string[] = [];
    for (const task of taskData?.tasks || []) {
      const assigneeId: string | undefined = task.assignee?.id;
      if (assigneeId && !uniqueIds.includes(assigneeId)) {
        uniqueIds.push(assigneeId);
      }
    }
    return uniqueIds;
  }, [taskData]);

  const collectionForStatus = (status: string) => {
    let collection;

    if (status === 'PRIORITY') {
      collection =
        taskData && taskData.tasks
          ? taskData.tasks.filter(
              (x) => x.priority === 1 && x.status.value === 'TODO',
            )
          : [];
    } else {
      collection =
        taskData && taskData.tasks
          ? taskData.tasks.filter(
              (x) =>
                x.status.value === status &&
                !(x.priority === 1 && x.status.value === 'TODO'),
            )
          : [];
    }

    if (state.selectedEmployee) {
      collection = collection.filter(
        (x) => x.assignee?.id === state.selectedEmployee?.user.id,
      );
    }

    return [...collection].sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    ); // @ts-ignore
    // .sort((a, b) => !!b.due - !!a.due)
    // @ts-ignore
    // .sort((a, b) => new Date() - new Date(b.due))
  };

  // let orderedStatus =
  //   statusValues && statusValues.status && statusValues.status.length > 0
  //     ? [
  //         { __typename: 'task_status', value: 'PRIORITY', order: 4 },
  //         ...statusValues.status.filter((x) => x.value !== 'ARCHIVED'),
  //       ].sort((a, b) => columns[a.value].order - columns[b.value].order)
  //     : [];

  function selectEmployee(employee: TeamMember) {
    if (state.selectedEmployee?.user.id === employee.user.id) {
      setState((s) => ({ ...s, selectedEmployee: null }));
    } else {
      setState((s) => ({ ...s, selectedEmployee: employee }));
    }
  }

  return (
    <>
      <Layout wrapper="overflow-hidden overflow-x-scroll bg-gray-50 h-full w-full shadow-inner border-l px-2 pb-12">
        <section className="flex items-center justify-between gap-2 mt-4 ml-4">
          <h1 className="text-2xl font-semibold flex-1 mr-2">Tasks</h1>
          <div className="flex gap-2 items-center">
            <Listbox
              multiple
              value={state.statuses}
              onChange={(e) => {
                setState((s) => ({ ...s, statuses: e }));
              }}
            >
              <div className="relative">
                <Listbox.Button className="relative w-36 border border-gray-300 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-purple-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-300 sm:text-sm">
                  <span className="block truncate capitalize text-xs">
                    View By Status
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="z-[9999] text-xs absolute mt-1 max-h-60 w-36 overflow-auto rounded-md bg-white py-1  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {statuses.map((status) => (
                      <Listbox.Option
                        key={status.value}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pr-10 pl-4 ${
                            active || selected
                              ? 'bg-purple-100 text-purple-900'
                              : 'text-gray-900'
                          }`
                        }
                        value={status.value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {status.label}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 right-4 flex items-center pl-3 text-purple-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <Listbox
              value={state.mode}
              onChange={(e) => {
                setState((s) => ({ ...s, mode: e }));
              }}
            >
              <div className="relative">
                <Listbox.Button className="relative w-36 border border-gray-300 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-purple-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-300 sm:text-sm">
                  <span className="block truncate capitalize text-xs">
                    {state.mode === 'user' ? 'My Tasks' : 'All Tasks'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="z-80 text-xs absolute mt-1 max-h-60 w-36 overflow-auto rounded-md bg-white py-1  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Listbox.Option
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 pr-10 pl-4 ${
                          active || selected
                            ? 'bg-purple-100 text-purple-900'
                            : 'text-gray-900'
                        }`
                      }
                      value="user"
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            My Tasks
                          </span>
                          {state.mode === 'user' ? (
                            <span className="absolute inset-y-0 right-4 flex items-center pl-3 text-purple-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                    <Listbox.Option
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 pr-10 pl-4 ${
                          active || selected
                            ? 'bg-purple-100 text-purple-900'
                            : 'text-gray-900'
                        }`
                      }
                      value="team"
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            All Tasks
                          </span>
                          {state.mode === 'team' ? (
                            <span className="absolute inset-y-0 right-4 flex items-center pl-3 text-purple-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            {/* <Listbox value={selectedStatus} onChange={setSelectedStatus}>
              <div className="relative">
                <Listbox.Button className="relative w-36 border border-gray-300 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-purple-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-300 sm:text-sm">
                  <span className="block truncate capitalize text-xs">
                    {selectedStatus ? selectedStatus : 'Status'}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="z-80 text-xs absolute mt-1 max-h-60 w-36 overflow-auto rounded-md bg-white py-1  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Listbox.Option
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 pr-10 pl-4 ${
                          active || selected
                            ? 'bg-purple-100 text-purple-900'
                            : 'text-gray-900'
                        }`
                      }
                      value="Active"
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            Active
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 right-4 flex items-center pl-3 text-purple-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                    <Listbox.Option
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 pr-10 pl-4 ${
                          active || selected
                            ? 'bg-purple-100 text-purple-900'
                            : 'text-gray-900'
                        }`
                      }
                      value="Archived"
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            Archived
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 right-4 flex items-center pl-3 text-purple-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox> */}
            {!!(org?.team?.length > 0) && state.mode === 'team' ? (
              <div className="md:flex col-span-4 justify-self-end">
                <div className="flex items-center -space-x-2">
                  <Listbox
                    value={state.selectedEmployee}
                    onChange={selectEmployee}
                  >
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button className="relative w-44 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                              {state.selectedEmployee?.user?.avatar[0] && (
                                <img
                                  src={
                                    state.selectedEmployee.user.avatar[0].asset
                                      .url
                                  }
                                  alt=""
                                  className="h-4 w-4 flex-shrink-0 rounded-full object-cover"
                                />
                              )}
                              <span
                                className={classNames(
                                  state.selectedEmployee?.user?.avatar[0] &&
                                    'ml-3',
                                  'block truncate text-xs',
                                )}
                              >
                                {state.selectedEmployee?.user.name ||
                                  'Select Member'}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {org?.team.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active, selected }) =>
                                    classNames(
                                      (selected || active) &&
                                        'bg-purple-100 text-purple-900',
                                      'text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9',
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected }) => (
                                    <>
                                      <div className="flex items-center">
                                        {person.user?.avatar[0] && (
                                          <img
                                            src={
                                              person.user?.avatar[0].asset.url
                                            }
                                            alt=""
                                            className="h-4 w-4 flex-shrink-0 rounded-full object-cover"
                                          />
                                        )}
                                        <span
                                          className={classNames(
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'ml-3 block truncate text-xs',
                                          )}
                                        >
                                          {person.user.name}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            'text-purple-600 absolute inset-y-0 right-0 flex items-center pr-4',
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            ) : null}

            <button
              className="btn-primary w-32"
              onClick={() => setState({ ...state, displayTaskDetail: true })}
            >
              New
            </button>
          </div>
        </section>

        {loading && (
          <div className="flex h-full w-screen items-center my-auto justify-center absolute bg-white/20 z-[9999]">
            <Loader stroke="#0c9498" width={100} height={100} />
          </div>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <section className="flex h-full">
            {state.statuses.map((status) => {
              return (
                <Column
                  key={status}
                  id={status}
                  heading={columns[status].label}
                  items={collectionForStatus(status)}
                  action={(id: any, type: string) => {
                    setState((s) => ({ ...s, type, task: id.id }));
                  }}
                />
              );
            })}
          </section>
        </DragDropContext>
      </Layout>
      <TaskModal
        full
        overflow="overflow-y-scroll"
        open={!!state.task}
        close={() => setState((s) => ({ ...s, type: null, task: null }))}
      >
        {state.task ? (
          <div className="flex flex-col flex-1 w-full h-full overflow-scroll">
            <Tasks
              teamMembers={teamMembers}
              taskID={state.task}
              onComplete={() => {
                setState((s) => ({ ...s, type: null, task: null }));
              }}
              statusValues={statusValues}
              columns={columns}
              refetchTasks={refetchTasks}
            />
          </div>
        ) : null}
      </TaskModal>

      <TaskModal
        full
        overflow="overflow-y-scroll"
        open={state.displayTaskDetail}
        close={() => setState((s) => ({ ...s, displayTaskDetail: false }))}
      >
        {state.displayTaskDetail ? (
          <TaskDetail
            close={() => setState((s) => ({ ...s, displayTaskDetail: false }))}
            refetchTasks={refetchTasks}
            taskID={''}
          />
        ) : null}
      </TaskModal>
    </>
  );
}
